import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import toastError from "../../../components/Toast/toastError";
import { BsPencilFill } from "react-icons/bs";
import Modal from 'react-modal';
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import isNumber from "../../../components/isNumber/isNumber";
import { AiOutlineCloseCircle } from "react-icons/ai";
import FileUploader from "../../../utils/validationFileUploader";
export default function Trainer() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [adicionarOperacao, setAdicionarOperacao] = useState(false);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0);
    const [modal, setModal] = useState(false);

    const [alterar, setAlterar] = useState(false);
    const [alterarFiles, setAlterarFiles] = useState(false);

    const [nometipo, setNometipo] = useState("");
    const [entFormadora, setEntFormadora] = useState("");
    const [tipologia, setTipologia] = useState("");
    const [codigoOperacao, setCodigoOperacao] = useState("");
    const [nameOperacao, setNameOperacao] = useState("");


    const [entFormadoraList, setEntFormadoraList] = useState([]);
    const [tipologiasList, setTipologiasList] = useState([]);
    const [codigoOperacaoList, setCodigoOperacaoList] = useState([]);
    const [nameOperacaoList, setNameOperacaoList] = useState([]);
    const [outrosDocs, setOutrosDocs] = useState([])
    const [numDoc, setNumDoc] = useState("")

    document.data = data

    const params = useParams();

    useEffect(() => {
        getAdmin()
        getEntFormadoras()
        getOperacoes() // eslint-disable-next-line
    }, [cont]);

    function alterarConfirmar() {
        setAlterar(true)
    }

    function getAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    getAllAdmins()
                    setLoading(false); // Set loading back to false after the upload is complete
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    function getAllAdmins() {
        fetch(`${process.env.REACT_APP_API_URL}/admins`, {
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData2(result);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function getEntFormadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {

                setEntFormadoraList(result)
            });
    }


    function getOperacoes() {
        fetch(`${process.env.REACT_APP_API_URL}/Operacao`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setTipologiasList(result.tipologias)
                setCodigoOperacaoList(result.codigoOperacoes)
                setNameOperacaoList(result.nomeOperacoes)
            });
    }

 

    function alterarPerfil() {
        if (document.getElementById('cellNumber').value !== "" && document.getElementById('email').value !== "" && document.getElementById('locality').value !== "" && document.getElementById('nif').value !== "" && document.getElementById('name').value !== "") {
            let cellNumber = document.getElementById('cellNumber').value;
            if (isNumber(cellNumber) && cellNumber.length < 13 && cellNumber > 0) {
                let cont = 0
                function verifyEmail() {
                    for (var i = 0; i < data2.length; i++) {
                        if (data2[i].email === document.getElementById('email').value) {
                            if (document.getElementById('email').defaultValue === document.getElementById('email').value);
                            else cont++;
                        }
                    }
                }
                verifyEmail()
                // Verificar se o email tem "@" e o "."
                let checkEmail = document.getElementById('email').value.includes("@" && ".")
                if (checkEmail === true && cont === 0) {
                    if (document.getElementById('locality').value) {
                        if (check_nif_number(document.getElementById('nif').value)) {
                            if (onlyString(document.getElementById('name').value)) {
                                const formData = {
                                    fullName: document.getElementById('name').value,
                                    cellNumber: document.getElementById('cellNumber').value,
                                    email: document.getElementById('email').value,
                                    locality: document.getElementById('locality').value,
                                    nif: document.getElementById('nif').value,
                                    nomeRole: nometipo ? nometipo : data.nomeRole,
                                };
                                fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`,
                                    {
                                        body: JSON.stringify(
                                            formData
                                        ),
                                        method: "PATCH",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'authorization': localStorage.getItem('admin1')
                                        }
                                    }).then((result) => {
                                        result.json().then((resp) => {
                                            setAlterar(false);
                                        })
                                        toastSuccess('Dados alterados com sucesso!')
                                        getAdmin();
                                    })
                            } else toastError("Nome Inválido")
                        } else toastError("NIF Inválido")
                    } else toastError("Localidade inválida")
                } else toastError("Email invalido ou existente")
            } else toastError("Nº de telemóvel inválido")
        } else toastError("Dados não inseridos")
    }

    const handleFileUpload = async (event) => {
        setLoading(true); // Set loading to true before starting the upload

        const selectedFile = event.target.files[0];
        let ccForm = new FormData();
        ccForm.append('logo', selectedFile);

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin",
            body: ccForm
        };

        try {
            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
            const logoResult = await logoResponse.json();

            await fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}/photo`, {
                body: JSON.stringify({
                    photo: logoResult,
                }),
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin1')
                }
            });


            setAlterar(false);
            toastSuccess('Foto adicionada com sucesso!');
            setCont(cont + 1)

        } catch (error) {
            console.error('Error uploading image:', error);
            setLoading(false); // Make sure loading is set back to false even if an error occurs
            // Handle the error as needed
        }
        await setLoading(false); // Set loading back to false after the upload is complete
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"myProfile"} />
                </div>
                <div className="flex-grow w-full mt-8 mb-10">
                    <Link to={`/menuadmin/` + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <div className="w-full">
                        <div className="mx-10">
                            <div className="flex flex-col items-center justify-center w-full">
                                <div className="relative transition cursor-pointer group hover:opacity-80">
                                    <img alt="Profile" className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === "" ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" onClick={() => { document.getElementById('fileInput').click(); }} />
                                    <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                        <BsPencilFill className="text-black" />
                                    </div>
                                    <FileUploader
                                        inputId={"fileInput"}
                                        inputType={"file"}
                                        inputAccept={".png, .jpeg, .jpg"}
                                        inputStyle={{ display: 'none' }}
                                        onChangeSpecial={handleFileUpload}
                                        allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
                                        />
                                </div>
                                <div className="flex flex-col items-center mt-2 sm:ml-5">
                                    {alterar === true ?
                                        <input id="name" name="name" className="c input-field-long" placeholder={data.fullName} defaultValue={data.fullName} required /> :
                                        <h1 className="text-2xl font-bold text-text-main-color">{data.fullName}</h1>
                                    }
                                    <h1 className="third-title">{data.nomeRole}</h1>
                                </div>

                                <div className="flex items-center justify-end w-1/2 mt-3 sm:mt-0 sm:mr-16">

                                </div>
                            </div>
                            <div className="my-6">
                                <hr />
                            </div>
                            <div className="mb-5 sm:ml-24">
                                <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                                <div className="grid mt-4 sm:grid-cols-2">
                                    <div>
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Número de telefone:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="cellNumber" name="cellNumber" className="w-full sm:w-80 input-field-long" placeholder={data.cellNumber} minLength={9} maxLength={14} defaultValue={data.cellNumber} required></input> : data.cellNumber}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="email" name="email" className="w-full input-field-long sm:w-80" placeholder={data.email} defaultValue={data.email} required></input> : data.email}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="nif" name="nif" className="w-full input-field-long sm:w-80" placeholder={data.nif} minLength={9} maxLength={9} defaultValue={data.nif}></input> : data.nif}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="text-center sm:text-left">
                                            <div className="mt-4 text-xs uppercase text-text-second-color opacity-90 sm:mt-0">Cargo:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <select onWheel={(e) => e.target.blur()} id="tipo" name="tipo" className="w-full selector sm:w-80" defaultValue={data.nomeRole} onChange={(e) => setNometipo(e.target.value)} required >
                                                    <option disabled value="">Selecione uma das opções</option>
                                                    <option value="Administrador - Geral">Administrador - Geral</option>
                                                    <option value="Administrador - Diretor de Departamento">Administrador - Diretor de departamento</option>
                                                    <option value="Administrador - Gestor de Operação">Administrador - Gestor de Operação</option>
                                                    <option value="Administrador - Outro">Administrador - Outro</option>
                                                </select> : data.nomeRole}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                            {alterar === true ?
                                                <div className="mt-1 text-lg text-text-main-color">
                                                    <input id="locality" name="locality" className="w-full input-field sm:w-80" placeholder={data.locality} defaultValue={data.locality} required>
                                                    </input>
                                                </div>
                                                : <>{data.locality
                                                    ? <div className="mt-1 text-lg text-text-main-color">  {data.locality}</div>
                                                    : <div className="input-error-profile" />}
                                                </>
                                            }
                                        </div>
                                        <div className="flex items-center justify-center mt-4 sm:items-start sm:justify-normal">
                                            <Link to={"/menuadmin/Profile/" + params.id_candidatura + "/changepassword"}>
                                                <button className="button-geral">
                                                    Alterar Palavra-passe
                                                </button>
                                            </Link>
                                        </div>

                                    </div>

                                </div>
                                <div className="flex flex-col items-center justify-center mt-4 sm:items-start sm:justify-normal sm:flex-row row">
                                    {alterar === true ? <button className="sm:mr-5 button-cancelar w-36" onClick={() => { setAlterar(false); }}>Cancelar</button> : ""}
                                    {alterar === true ?
                                        <ConfirmToaster css={"button-confirmar mt-4 sm:mt-0 w-36 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar perfil"} message={"Deseja alterar o seu perfil ?"} confirm={alterarPerfil} tooltip={"Alterar Perfil"}></ConfirmToaster> :
                                        <button className="w-36 button-geral" onClick={alterarConfirmar}>Alterar Dados</button>
                                    }
                                </div>

                                <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center">
                                    <div className="absolute w-full h-full" onClick={() => setModal(false)}></div>
                                    <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                                        <div>
                                            <h1 className="subtitle">Currículos</h1>
                                        </div>
                                        <div className="modal-content">
                                            {data.cv_path.map((value, index) => (
                                                <div key={value}>
                                                    <div className="linkHover">
                                                        CV - {index + 1 }: <a href={value} target="_blank" rel="noreferrer" download>Link</a>
                                                    </div>
                                                </div>
                                            ))}</div>
                                        <button className="absolute top-1.5 right-1.5" onClick={() => setModal(false)}>
                                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                        </button>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );

}



