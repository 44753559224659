import React, { useState } from "react";
import { StyleFormadorFECFA } from "../Modal/stylesContratos/styled-contrato-formador-FECFA";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormadorFECFA({
  data,
  formador,
  tipo,
  token,
  setOpen,
}) {
  const index =
    formador && tipo === "formador"
      ? data.dadosContrato.findIndex((item) => item.id === formador._id)
      : 0;
  const contrato = data?.dadosContrato[index]?.dadosContrato;

  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [valores, setValores] = useState({
    //ccRepresentanteLegal: contrato?.ccRepresentanteLegal ?? "",
    //cargoRepresentanteLegal: contrato?.cargoRepresentanteLegal ?? "",
    valorHorasLecionadasFormador: contrato?.valorHorasLecionadasFormador ?? "",
    valorHoraFormador: contrato?.valorHoraFormador ?? "",
    valorDescritivo: contrato?.valorDescritivo ?? "",
    date: AlterarFormato(data?.dateBegin) ?? "",
    local: data?.percurso[0].locality ?? "",
  });

  // Function to send the contract by email
  async function enviarContratoFormador() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormadorNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = React.useState({
    //inputCcRepresentanteLegal: valores.ccRepresentanteLegal,
    //inputCargoRepresentanteLegal: valores.cargoRepresentanteLegal,
    inputValorHorasLecionadasFormador: valores.valorHorasLecionadasFormador,
    inputValorHoraFormador: valores.valorHoraFormador,
    inputValorDescritivo: valores.valorDescritivo,
    inputDate: valores.date,
    inputLocal: valores.local,
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = React.useState({
    //inputCcRepresentanteLegal: false,
    //inputCargoRepresentanteLegal: false,
    inputValorHorasLecionadasFormador: false,
    inputValorHoraFormador: false,
    inputValorDescritivo: false,
    inputDate: false,
    inputLocal: false,
  });

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {

      if (
        key === "inputValorHorasLecionadasFormador" &&
        data?.formadores?.length < 2
      ) {
        continue; // Ignore this input validation if there is only one formador
      }

      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Function to adjust the width of the input field
  const adjustWidth = (e) => {
    e.target.style.width = `${e.target.value.length + 1}ch`;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <StyleFormadorFECFA>
            <div className="body">
              <br />
              <h1>
                <b>Contrato de prestação de serviços de formação </b>
              </h1>
              <h1>
                <b>Formação empresarial conjunta</b>
              </h1>
              <br />
              <br />
              <p>
                Entre:
                <br />
                <b>{data.percurso[0].entFormadora[0].name}</b>, com sede social
                em {data.percurso[0].entFormadora[0].morada},{" "}
                {data.percurso[0].entFormadora[0].postalCode}{" "}
                {data.percurso[0].entFormadora[0].distrito}, com o Contribuinte
                nº {data.percurso[0].entFormadora[0].nif}, neste ato
                representada por{" "}
                {data.percurso[0].entFormadora[0].representanteLegal}, portadora
                do cartão de cidadão nº{" "}
                {data.percurso[0].entFormadora[0].ccRepresentanteLegal ?? "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputCcRepresentanteLegal"
                  defaultValue={valores.ccRepresentanteLegal}
                  placeholder="12345678"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      ccRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.ccRepresentanteLegal.length > 0
                        ? valores.ccRepresentanteLegal.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputCcRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                /> */}
                , na qualidade de{" "}
                {data.percurso[0].entFormadora[0].cargoRepresentanteLegal ??
                  "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputCargoRepresentanteLegal"
                  defaultValue={valores.cargoRepresentanteLegal}
                  placeholder="Cargo"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      cargoRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.cargoRepresentanteLegal.length > 0
                        ? valores.cargoRepresentanteLegal.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputCargoRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                /> */}
                , e com poderes para o ato, adiante designada por{" "}
                <b>Primeiro Outorgante</b>, e
              </p>
              <table className="dados">
                <tr>
                  <th>Nome Completo</th>
                  <td>{formador.fullName}</td>
                </tr>
                <tr>
                  <th>NIF</th>
                  <td>{formador.nif}</td>
                </tr>
                <tr>
                  <th>Cartão de Cidadão/Passaporte</th>
                  <td>{formador.ccNumber}</td>
                </tr>
                <tr>
                  <th>Morada</th>
                  <td>{formador.address}</td>
                </tr>
                <tr>
                  <th>Código Postal</th>
                  <td>{formador.postalCode}</td>
                </tr>
              </table>
              <p>
                , adiante designada por <b>Segundo Outorgante</b>, é celebrado o
                presente contrato de prestação de serviços, o qual se rege pelo
                disposto nas seguintes cláusulas:
              </p>
              <div>
                <br />
                <h2>Cláusula 1ª</h2>
                <br />
                <p>
                  1. O <b>Primeiro Outorgante</b> obriga-se a proporcionar ao{" "}
                  <b>Segundo Outorgante</b>, para a prestação de serviços o
                  exercício da função de FORMADOR/A para o Projeto
                  SIQRH-Formação Empresarial conjunta no âmbito do Programa
                  COMPETE2030- Programa Temático Inovação e Transição Digital
                  enquadrado pelas seguintes especificações:
                </p>
                <table className="dados">
                  <tr>
                    <th>Ação</th>
                    <td>{data.percurso[0].name}</td>
                    <th>Carga horária(h)</th>
                    <td>{data && data.formadores && data.formadores.length < 2 ? (data.cargaHoraria) :  (<input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorHorasLecionadasFormador"
                      defaultValue={valores.valorHorasLecionadasFormador}
                      placeholder="5"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorHorasLecionadasFormador: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorHorasLecionadasFormador.length > 0
                            ? valores.valorHorasLecionadasFormador.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorHorasLecionadasFormador
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />)}</td>
                  </tr>
                  <tr>
                    <th>Nº do Projeto</th>
                    <td colSpan="3">
                      {data.percurso[0].operacao[0].codigoOperacao}
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <p>
                  2. Quando a formação decorre no formato presencial, o local de
                  realização da formação é:
                </p>
                <table className="dados">
                  <tr>
                    <th>Local:</th>
                    <td>
                      {data?.formato !== "Online"
                        ? data.percurso[0].entFormadora[0].distrito
                        : "(Online)"}
                    </td>
                  </tr>
                  <tr>
                    <th>Morada:</th>
                    <td>
                      {data?.formato !== "Online"
                        ? data.percurso[0].entFormadora[0].morada
                        : "(Online)"}
                    </td>
                  </tr>
                  <tr>
                    <th>Código de Postal:</th>
                    <td>
                      {data?.formato !== "Online"
                        ? data.percurso[0].entFormadora[0].postalCode
                        : "(Online)"}
                    </td>
                  </tr>
                  <tr>
                    <th>Data de início prevista:</th>
                    <td>{AlterarFormato(data.dateBegin)}</td>
                  </tr>
                  <tr>
                    <th>Data de fim prevista:</th>
                    <td>{AlterarFormato(data.dateEnd)}</td>
                  </tr>
                </table>
              </div>
              <div>
                <p>
                  3. Quando a formação decorre no formato á distância, o curso
                  realiza-se através da seguinte plataforma:
                </p>

                <table className="dados">
                  <tr>
                    <th>Plataforma LMS:</th>
                    {data.percurso[0].moodle && data.percurso[0].moodle.length>0 ? (
                      <td>
                        <a href={data.percurso[0].moodle}>{data.percurso[0].moodle[0].name}</a>
                      </td>
                    ) : (
                      <td>N/A</td>
                    )}
                  </tr>
                </table>
              </div>

              <div>
                <br />
                <h2>Cláusula 2ª</h2>
                <p>
                  1. A atividade prevista na prestação de serviços, mencionada
                  na cláusula 1ª, está enquadrada pela Portaria n.º 66/2022, de
                  1 de fevereiro e contempla as seguintes condições da prestação
                  do serviço:
                </p>
                <ol className="tipoa" type="a">
                  <li>Respeitar a regulamentação da formação em vigor.</li>
                  <li>
                    Garantir a estrita confidencialidade no tratamento dos dados
                    aos quais terá acesso, garantindo que a informação não será
                    partilhada com terceiros e será utilizada apenas para os
                    fins diretamente relacionados com a formação, sendo tratados
                    de forma lícita de acordo com o Regulamento Geral de
                    Proteção de Dados e legislação aplicável.
                  </li>
                  <li>
                    Matéria a lecionar de acordo com os conteúdos programáticos
                    estabelecidos contratualmente entre o{" "}
                    <b>Primeiro Outorgante</b> e o <b>Segundo Outorgante</b>.
                  </li>
                  <li>
                    Adotar as estratégias pedagógicas estabelecidas
                    contratualmente com o <b>Primeiro Outorgante</b>.
                  </li>
                  <li>
                    Total e correto preenchimento do Dossier Técnico-Pedagógico.
                  </li>
                  <li>
                    Entregar a documentação aplicável à ação de formação
                    (manual, planos de sessão, evidências de avaliação,
                    corrigendas, grelhas e pautas de avaliação) de acordo com as
                    datas estipuladas pelo/a Gestor/a de Projeto e/ou
                    Coordenador/a Pedagógico/a.
                  </li>
                  <li>
                    Contactar em primeira instância o <b>Primeiro Outorgante</b>{" "}
                    sempre que surja algum imprevisto em relação ao que estava
                    acordado, preenchendo simultaneamente a FICHA DE
                    OCORRÊNCIAS, constante do Dossier Técnico-Pedagógico.
                  </li>
                  <li>
                    Entregar/enviar para as instalações do{" "}
                    <b>Primeiro Outorgante</b> todo o material pedagógico que
                    seja adicionalmente entregue aos formandos/as.
                  </li>
                  <li>
                    Participar nas reuniões: (1) de preparação do
                    desenvolvimento/execução e (2) de acompanhamento do
                    desenvolvimento/execução.
                  </li>
                </ol>
                <p>
                  2. Ainda segundo a Portaria n.º 66/2022, de 1 de fevereiro, no
                  seu ponto 7 do Artigo 12º , o desenvolvimento de Formação
                  Profissional Certificada, pode decorrer no formato à
                  distância, devendo nesses casos a execução da formação
                  referida no ponto 1 desta Cláusula 2ª ser orientada pelos
                  preceitos metodológicos e administrativos previstos pelo{" "}
                  <b>Primeiro Outorgante</b> e clarificados nos seguintes
                  documentos metodológicos do Sistema de Gestão baseado no norma
                  ISO 9001, que são dados a conhecer antes da celebração do
                  presente contrato:
                </p>
                <ol className="tipoa" type="a">
                  <li>
                    Procedimento 33: Conceber e executar formação à distância;
                  </li>
                  <li>METODOLOGIA do Modelo Pedagógico COM-TEC;</li>
                </ol>
                <p>
                  3. No acompanhamento destas tarefas o{" "}
                  <b>Segundo Outorgante</b> será sujeito a uma avaliação de
                  desempenho com base na Ficha de Avaliação (passível de
                  consulta no seguinte Link-
                  <a href="http://forms.gle/z8ijet5VEyw5LLrg9">
                    https://forms.gle/z8ijet5VEyw5LLrg9
                  </a>{" "}
                  ) a qual representa uma qualificação dos serviços prestados e,
                  em si mesmo, uma orientação para a melhoria e contributo do{" "}
                  <b>Segundo Outorgante</b> para o projeto referido na Cláusula
                  1ª deste contrato.
                </p>
                <p>
                  4. O <b>Segundo Outorgante</b> compromete-se a prestar apoio
                  pedagógico ao <b>Primeiro Outorgante</b>. Este apoio consiste
                  no fornecimento, discussão e esclarecimento de todos os
                  elementos pedagógicos acordados com o{" "}
                  <b>Segundo Outorgante</b>, nomeadamente: Objetivos
                  pedagógicos; Conteúdos programáticos; Referências
                  bibliográficas; Destinatários; Estratégia pedagógica, Datas,
                  horários e local de realização da intervenção e Suportes
                  pedagógicos.
                </p>
              </div>
              <br />
              <h2>Cláusula 3ª</h2>
              <br />
              <p>
                1. O preço base da prestação de serviços corresponde ao
                seguinte:
              </p>
              <table className="valor">
                <tr>
                  <th>Valor hora (€)</th>
                  <td>
                    <input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorHoraFormador"
                      defaultValue={valores.valorHoraFormador}
                      placeholder="10,00"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorHoraFormador: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorHoraFormador.length > 0
                            ? valores.valorHoraFormador.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorHoraFormador
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <th>Valor hora (descritivo)</th>
                  <td>
                    <input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorDescritivo"
                      defaultValue={valores.valorDescritivo}
                      placeholder="Mil euros"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorDescritivo: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorDescritivo.length > 0
                            ? valores.valorDescritivo.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorDescritivo
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>NOTA:</th>
                  <td>
                    Valores acrescidos de IVA à taxa lega em vigor, se de
                    direito.
                  </td>
                </tr>
              </table>
              <div>
                <br />
                <h2>Cláusula 4ª</h2>
                <br />
                <p>
                  1. O <b>Segundo Outorgante</b> deverá apresentar
                  faturas-recibo nos valores respeitantes aos pagamentos a
                  efetuar, com o descritivo em que menciona a UFCD e o Nº do
                  Projeto referidos no Ponto 1 da Cláusula 1ª, após a sua
                  realização parcial ou total, o qual satisfará as leis fiscais
                  aplicáveis aos rendimentos de trabalho independente.
                </p>
                <p>
                  2. O <b>Primeiro Outorgante</b> efetuará o pagamento das
                  respetivas importâncias após entrega/emissão de toda a
                  documentação em conformidade e exigida pelo/a Gestor/a de
                  Projeto e/ou Mediador/a.
                </p>
                <p>
                  3. O valor da prestação de serviço será paga até 60 dias,
                  desde que o Compete 2030 cumpra as obrigações previstas na
                  lei, nomeadamente a disponibilização de formulários para
                  submissão de reembolsos e a aplicação Regulamento n.º
                  944/2023, de 24 de agosto, que define os procedimentos a
                  observar na realização de pagamentos aos beneficiários das
                  tipologias de intervenção dos Sistemas de Incentivos do
                  Portugal 2030;
                </p>
              </div>
              <div>
                <br />
                <h2>Cláusula 5ª</h2>
                <br />
                <p>
                  O <b>Segundo Outorgante</b> deverá enviar/manter atualizados
                  os dados relativos ao seu ficheiro pessoal, nomeadamente:
                </p>
                <ol className="tipoa" type="a">
                  <li>Curriculum Vitae;</li>
                  <li>
                    Informação correta dos dados constantes no Cartão de
                    Cidadão;
                  </li>
                  <li>
                    Informação correta sobre o Número de Identificação Bancária;
                  </li>
                  <li>
                    Cópia dos Certificados comprovativos das Habilitações
                    Literárias;
                  </li>
                  <li>
                    Cópia do Certificado Competências Profissionais (CCP);
                  </li>
                </ol>
              </div>

              <div>
                <br />
                <h2>Cláusula 6ª</h2>
                <br />
                <ol>
                  <li>
                    O <b>Primeiro Outorgante</b> fará a recolha, conservação e
                    tratamento de dados do SEGUNDO OUTORGANTE por imposição do
                    cumprimento de obrigações legais, para cumprimento do
                    presente contrato e ainda porque o <b>Segundo Outorgante</b>{" "}
                    dá o seu consentimento.
                  </li>
                  <li>
                    O <b>Primeiro Outorgante</b> será o responsável pelo
                    tratamento dos dados do SEGUNDO OUTORGANTE cujo
                    processamento de dados será interno ficando o{" "}
                    <b>Primeiro Outorgante</b>
                    expressamente autorizada a caso assim o entenda efetuar esse
                    processamento externamente.
                  </li>
                  <li>
                    Os dados pessoais incluídos no registo são o nome, morada,
                    contactos telefónicos e endereços eletrónicos, data de
                    nascimento, género, naturalidade, nacionalidade,
                    identificação civil, identificação e enquadramento fiscal e
                    perante a segurança social, autorização de residência,
                    passaporte, currículo profissional, profissão, habilitações
                    académicas, formação profissional e experiência anterior.
                  </li>
                  <li>
                    O <b>Primeiro Outorgante</b> vai comunicar ou transferir em
                    parte ou na sua totalidade os dados pessoais do{" "}
                    <b>Segundo Outorgante</b> a entidades públicas e ou privadas
                    sempre que tal decorra de obrigação legal e ou seja
                    necessário para cumprimento deste ou outros contratos
                    ficando para tal expressamente autorizada pelo{" "}
                    <b>Segundo Outorgante</b>.
                  </li>
                  <li>
                    O <b>Primeiro Outorgante</b> vai conservar os dados do{" "}
                    <b>Segundo Outorgante</b> pelos prazos necessários a dar
                    cumprimento a obrigações legais designadamente de 10 (dez)
                    anos para cumprimento à obrigação legal de arquivo de toda a
                    documentação de escrita comercial.
                  </li>
                  <li>
                    O <b>Segundo Outorgante</b> poderá solicitar ao{" "}
                    <b>Primeiro Outorgante</b> e esta salvo impedimento legal
                    vai salvaguardar os direitos do <b>Segundo Outorgante</b> de
                    acesso aos dados pessoais que lhe digam respeito, bem como a
                    sua retificação ou o seu apagamento, e a limitação do
                    tratamento, e o direito de se opor ao tratamento, bem como
                    do direito à portabilidade dos dados. E ainda o direito de
                    retirar consentimento em qualquer altura, sem comprometer a
                    licitude do tratamento efetuado com base no cumprimento de
                    obrigações legais ou com base no consentimento previamente
                    dado. E também o direito de reclamação sobre o tratamento de
                    dados junto da Comissão Nacional de Proteção de Dados.
                  </li>
                  <li>
                    Tendo em conta as técnicas mais avançadas, os custos de
                    aplicação e a natureza, o âmbito, o contexto e as
                    finalidades do tratamento, bem como os riscos, de
                    probabilidade e gravidade variável, para os direitos e
                    3150-219 Condeixa-a-Nova liberdades das pessoas singulares,
                    o <b>Primeiro Outorgante</b> aplica as medidas técnicas e
                    organizativas adequadas para assegurar um nível de segurança
                    adequado ao risco, incluindo, consoante o que for adequado.
                  </li>
                  <li>
                    Em caso de violação de dados pessoais o{" "}
                    <b>Primeiro Outorgante</b> notifica esse facto à Comissão
                    Nacional de Proteção de Dados nos termos e condições
                    previstos na lei. Se essa violação for suscetível de
                    implicar um elevado risco para os direitos e liberdades do
                    titular comunica-lhe esse facto, nos termos e condições
                    previstos na lei.
                  </li>
                  <li>
                    Especificamente nas componentes de formação a distância
                    síncrona, que se realizam no formato de videoconferência, o{" "}
                    <b>Segundo Outorgante</b> desde já aceita disponibilizar a
                    gravação dessas sessões na plataforma LMS, para partilha e
                    revisionamento posterior, por parte dos formandos ou
                    terceiros, apenas para fins de aprendizagem ou auditoria.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 7ª</h2>
                <br />
                <ol>
                  <li>
                    O presente contrato não confere ao <b>Segundo Outorgante</b>{" "}
                    a qualidade de trabalhador/a do
                    <b>Primeiro Outorgante</b>.
                  </li>
                  <li>
                    Em consequência do disposto no nº 1, o{" "}
                    <b>Segundo Outorgante</b> não tem direito a férias, subsídio
                    de férias ou Natal, subsídio de refeição ou quaisquer outros
                    subsídios ou prestações complementares, sendo que é da sua
                    exclusiva responsabilidade a sua situação perante a
                    Administração Fiscal e Segurança Social.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 8ª</h2>
                <br />
                <p>
                  O presente contrato pode cessar, sem necessidade de aviso
                  prévio quando os <b>OUTORGANTES</b> não cumpram as obrigações
                  nele assumidas.
                </p>
              </div>
              <div className="page-break-inside">
                <br />
                <h2>Cláusula 10ª</h2>
                <br />
                <p>
                  O presente contrato entra em vigor com a data das assinaturas
                  e vigorará durante o período estabelecido para a execução da
                  formação agora contratualizada e descritos na Cláusula 1ª.
                </p>
                <br />
                <p>
                  O presente contrato é feito em duplicado ficando cada uma das
                  partes na posse de um exemplar.
                </p>
                <table className="localData">
                  <tr>
                    <th>Local:</th>
                    <td>
                      <input
                        className="w-full input-field"
                        type="text"
                        name="inputLocal"
                        defaultValue={valores.local}
                        placeholder="ex: Porto"
                        onChange={(e) => {
                          setValores({ ...valores, local: e.target.value });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputLocal
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Data:</th>
                    <td>
                      <input
                        className="w-full input-field"
                        type="text"
                        name="inputDate"
                        defaultValue={valores.date}
                        placeholder="12/12/2023"
                        onChange={(e) => {
                          setValores({
                            ...valores,
                            date: AlterarFormato(e.target.value),
                          });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputDate
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </td>
                  </tr>
                </table>
                <br />
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O PRIMEIRO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ margin: "5px auto" }}
                        id="img"
                        className="img"
                        src={
                          data.percurso[0].entFormadora[0] &&
                          data.percurso[0].entFormadora[0].assinatura
                        }
                        height="70"
                        alt="Assinatura"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O SEGUNDO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Enviar Contrato"}
                title={"Enviar Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormador}
              />
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Download Contrato"}
                title={"Download Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormadorNoEmail}
              />
            </div>
          </StyleFormadorFECFA>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
