import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';
import Footer from "../Footer";
import Modal from 'react-modal'
import ConfirmToaster from "../Toast/toastConfirmChoice";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2"
import Header from "../Header";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ExcelFormadores from "../Excel/excelBolsaFormadores/excelBolsaFormadores";
import toastError from "../Toast/toastError";
import Cookies from "js-cookie";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastSuccess from "../Toast/toastSuccess";
import { IoMdRemoveCircle } from "react-icons/io";

function BolsaFormadoresList(token) {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [source, setSource] = useState("down");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]); // Recebe os dados para apresentar no modal
    const [checkedTrainers, setCheckedTrainers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectAllVisible, setSelectAllVisible] = useState(false);
    const [savedItemsPerPage, setSavedItemsPerPage] = useState(
        Cookies.get("itemsPerPage")
    );
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-ListTrainers-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "")
    const [itemsPerPage, setItemsPag] = useState(
        savedItemsPerPage ? savedItemsPerPage : 5
      );
      const [savedCurrentPage, setSavedCurrentPage] = useState(
          Cookies.get("currentPage")
        );
        const [currentPage, setCurrentPage] = useState(
          savedCurrentPage ? parseInt(savedCurrentPage) : 1
        );

    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)
    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }
    useEffect(() => {
        Cookies.set("searchTerm", searchTerm);
        Cookies.set("itemsPerPage", itemsPerPage);
        Cookies.set("currentPage", currentPage);
        getFormadores(currentPage, itemsPerPage, searchTerm) // eslint-disable-next-line
    }, [currentPage, totalPages, searchTerm, itemsPerPage]);

    const link = token === "admin1" ? `/menuadmin/${params.id_candidatura}` : token === "coord" ? `/menuCoordenador/${params.id_candidatura}` : "/menuSuperAdmin"

    const handleSelectAll = async (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        if (isChecked) {
          // Fetch all formadores from the backend and add them to the checkedTrainers
          const response = await fetch(`${process.env.REACT_APP_API_URL}/formadores/validadosBolsaFormadores?itemsPerPage=10000`, {
            headers: {
              authorization: localStorage.getItem(token),
            },
            method: 'GET',
          });
    
          if (!response.ok) {
            throw new Error(response.statusText);
          }
    
          const result = await response.json();
          setCheckedTrainers(result.data);
        } else {
            setCheckedTrainers([]);
            setSelectAllVisible(false);
        }
      };

      const handleSelectAllVisible = (e) => {
        const isChecked = e.target.checked;
        setSelectAllVisible(isChecked);
        if (isChecked) {
          // Adiciona todos os formadores visíveis à lista de selecionados
          setCheckedTrainers((prevChecked) => [
            ...prevChecked,
            ...data.filter((item) => !prevChecked.some((trainer) => trainer._id === item._id)),
          ]);
        } else {
          // Remove todos os formadores visíveis da lista de selecionados
          setCheckedTrainers((prevChecked) =>
            prevChecked.filter((trainer) => !data.some((item) => item._id === trainer._id))
          );
        }
      };

    const associarFormadores = (e, item) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedTrainers((prevChecked) => [...prevChecked, item]);
        } else {
            setCheckedTrainers((prevChecked) =>
                prevChecked.filter((trainer) => trainer._id !== item._id)
            );
        }
    };

    //aumentar página atual
    function increasePagination() {
        setCurrentPage((prevCurrentPage) => {
            if (prevCurrentPage < totalPages) {
              return prevCurrentPage + 1;
            }
            return prevCurrentPage;
          });
        }

    //diminuir página atual
    function decreasePagination() {
        setCurrentPage((prevCurrentPage) => {
            if (prevCurrentPage > 1) {
              return prevCurrentPage - 1;
            }
            return prevCurrentPage;
          });
        }

    //Altera o estado do formador para ativo
    function alterarEstadoAtivo(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/ativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {
                })

            })
    }

    //Altera o estado do formador para inativo
    function alterarEstadoInativo(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/inativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {

                })
            })
    }

    //Envia mail ao formador para inscreve
    function sendEmail() {
        const email = document.getElementById("emailTo").value;
        if (email) {
            fetch(`${process.env.REACT_APP_API_URL}/formadores/mailInscricao`, {
                method: "POST",
                body: JSON.stringify({
                    email: email
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                toastSuccess("Email enviado com sucesso");
                document.getElementById("emailTo").value = "";
            }).catch((error) => {
                console.error('Error:', error);
                toastError("Erro ao enviar o email");
            });
        } else {
            toastError("Email inválido");
        }
    }
    //Função automatizada do estado
    function alterarEstado(date, id) {
        AlterarFormato(date)
        let dateLastUfcd = new Date(date)
        let dateLastUfcdMax = addOneYear(dateLastUfcd)
        if (dateLastUfcdMax > dateNow) {
            alterarEstadoAtivo(id)
        }
        else {
            alterarEstadoInativo(id)
        }
    }

    // Recebe todos os formadores
async function getFormadores(currentPage, itemsPerPage, searchTerm) {
    try {
      const query = new URLSearchParams({
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        searchTerm: searchTerm,
      });
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/formadores/validadosBolsaFormadores?${query}`, {
          headers: {
          authorization: localStorage.getItem(token),
        },
        method: 'GET',
    });
  
      if (!response.ok) {
          throw new Error(response.statusText);
      }
  
      const result = await response.json();
      const totalItems = result.totalItems;
      const totalPages = Math.ceil(totalItems / itemsPerPage);

      // If
      // Save the data that comes from the API and define the total of pages and the total of items
      if (currentPage > totalPages) {
        setCurrentPage(totalPages > 0 ? totalPages : 1);
      } else {
        setTotalPages(totalPages);
      }
      setLoading(false);

      if (selectAll) {
        setCheckedTrainers(result.data);
        }
      
      await Promise.all(
        result.data.map(async (id) => {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/sorted/ufcd/${id._id}`, {
            headers: {
              authorization: localStorage.getItem(token),
            },
          });
  
          const ufcdResult = await res.json();
  
          if (ufcdResult.length > 0) {
            alterarEstado(ufcdResult[0].dateEnd, id._id);
          } else {
            alterarEstadoInativo(id._id);
          }
  
          let mediac = 0;
          let data2 = [];
          let filtro = [];
  
          if (id.ufcds && id.ufcds.length > 0) {
            if (id.ufcds.dateEnd) {
              id.ufcds.sort((a, b) => b.dateEnd.localeCompare(a.dateEnd));
            }
            data2 = id.ufcds;
            filtro = data2.filter((ufcds) => ufcds.evaluation !== null);
            if (filtro.length >= 5) {
              filtro.length = 5;
            }
            for (let i = 0; i < filtro.length; i++) {
              if (filtro[i].evaluation !== null) {
                mediac += parseInt(filtro[i].evaluation);
              }
            }
            if (mediac !== 0) {
              id.media = Math.round(mediac / filtro.length);
            } else {
              id.media = -1;
            }
          }
          return id.media;
        })
      );
  
      const incomeData = result.data
      const sortedData = incomeData.sort((a, b) => a.fullName.localeCompare(b.fullName));
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setError(error);
    }
  }

  const getFormadoresOrder = () => {
    let sortedData;
    if (source === "down") {
      sortedData = [...data].sort((a, b) => a.media - b.media);
      setSource("up");
    } else {
      sortedData = [...data].sort((a, b) => b.media - a.media);
      setSource("down");
    }
    setData(sortedData);
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    //Desvalida um formador
    function unvalidFormador(id_candidatura) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id_candidatura}/desvalidar`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {
                })
                toastSuccess('Formador removido com sucesso!')
                getFormadores(currentPage, itemsPerPage, searchTerm);
            })
    }

    //Abre o modal e atribui ao state modal data os dados para apresentar no modal
    function openModal(item) {
        setModalData(item);
        setModalIsOpen(true);
    }
     // Go to the first page
  function goToFirstPage() {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }
   // Go to the last page
  function goToLastPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  }
    return (
        <>
            <main className={token !== "coord" ? "min-h-screen" : "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10"}>
                {token === "admin1" ? <div className="w-full">
                    <NavbarAdmin1 currentPage={"ListaFormador"} />
                </div> : token === "admin2" ? <div className="w-full">
                    <NavbarAdmin2 currentPage={"ListaFormador"} />
                </div> : <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"ListaFormador"} />
                </div>
                }

              
                <div className={token !== "coord" ? "flex-grow mt-8" : "h-full col-span-5 xl:col-span-8"}>
                    {token === "coord" && <Header sideMenu={"true"} />}
                    {token === "admin1" ? <Link to={link} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link> : token === "admin2" && <Link to={link} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>}
                    <h1 className="mt-3 sm:mt-0 title">Bolsa de Formadores</h1>
                    <div className="flex flex-col">
                        <div className="search-div">
                        <label className="search-input-label">Pesquisar</label>
          <div className="search-div mt-2 mb-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                defaultValue={searchTerm}
                type="text"
                className="search-input"
                placeholder="ex: Nome, Local ..."
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
          </div>
                        </div>
                    </div>
                        <div className="flex justify-center select-all-checkbox">
                        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
                            <label className="pl-1 text-main-color">Selecionar Tudo</label>
                        </div>
                        <div className="flex items-center justify-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="ml-1 selector w-14"
                                value={itemsPerPage}
                                onChange={(e) => setItemsPag(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                    {data && data.length > 0 ? <> 
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="min-w-[80px] border border-white">Selecionar tabela<br/><input type="checkbox" onChange={handleSelectAllVisible} checked={selectAllVisible}/></th>
                                        <th className="p-2 min-w-[150px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Email</th>
                                        <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                        <th className="p-2 min-w-[80px] border border-white">Habilitações</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área de Educação Formação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área de Formação Académica</th>
                                        <th className="p-2 min-w-[120px] border border-white">
                                            <div className="flex items-center justify-around ">
                                                Classificação {source === "down" ? <img alt="arrow" className="arrow" id="arrow" onClick={getFormadoresOrder} src={process.env.PUBLIC_URL + "/img/down.png"} /> : <img className="arrow" alt="arrow" id="arrow" onClick={getFormadoresOrder} src={process.env.PUBLIC_URL + "/img/up.png"} />}
                                            </div>
                                        </th>
                                        <th className="p-2 min-w-[80px] border border-white">Perfil</th>
                                        <th className="p-2 min-w-[80px] border border-white">Histórico</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avaliação</th>
                                        {token === "admin2" ? <th className="p-2 min-w-[80px] border border-white">Invalidar</th> : null}
                                    </tr>
                                </thead>
                                {data.length > 0 ? <tbody className="text-text-main-color">
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td className="min-w-[80px] border border-white">
                                                <input type="checkbox"
                                                    onChange={(e) => associarFormadores(e, item)}
                                                    checked={checkedTrainers.some((trainer) => trainer._id === item._id)}
                                                     />
                                            </td>
                                            <td className="border border-white">{item.fullName}</td>
                                            <td className="border border-white">{item.email}</td>
                                            <td className="border border-white">{item.status === "true" ? "Ativo" : "Inativo"}</td>
                                            <td className="border border-white">{item.academicDegree}</td>
                                            <td className="border border-white">{item.courseDesignation}</td>
                                            {
                                                item.area.length > 0 ?
                                                    <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => openModal(item.area)}>
                                                        <div className="flex items-center justify-center group-hover:brightness-150">
                                                            <FaEye size={24} />
                                                        </div>
                                                    </td>
                                                    :
                                                    <td className="border border-white cursor-not-allowed">
                                                        <div className="flex items-center justify-center opacity-25 ">
                                                            <FaEyeSlash size={24} />
                                                        </div>
                                                    </td>
                                            }
                                            <td className="border border-white">{item.media !== -1 ? <>
                                                {item.media ? <div>{item.media}%</div> : "Sem disciplinas"}</> : "Em atividade"}
                                            </td>
                                            {token === "admin2" ? <><td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/MenuSuperAdmin/list/" + item._id)}>
                                                <div className="flex tooltip items-center justify-center group-hover:brightness-150" data-tip={!item.profileValid ? "Perfil Incompleto": "Perfil Completo"}>
                                                    <FaEye className={item.profileValid?"":"text-red-800"} size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/MenuSuperAdmin/list/Full-ufcds/" + item._id)}>
                                                <div className="flex items-center justify-center group-hover:brightness-150">
                                                    <FaEye size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/MenuSuperAdmin/list/ufcds/" + item._id)}>
                                                <div className="flex items-center justify-center group-hover:brightness-150">
                                                    <FaEye size={24} />
                                                </div>
                                            </td></>  : <><td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate(link + "/list/" + item._id)}>
                                                <div className="flex tooltip items-center justify-center group-hover:brightness-150" data-tip={!item.profileValid ? "Perfil Incompleto": "Perfil Completo"}>
                                                    <FaEye className={item.profileValid?"":"text-red-800"} size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate(link + "/list/Full-ufcds/" + item._id)}>
                                                <div className="flex items-center justify-center group-hover:brightness-150">
                                                    <FaEye size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate(link + "/list/ufcds/" + item._id)}>
                                                <div className="flex items-center justify-center group-hover:brightness-150">
                                                    <FaEye size={24} />
                                                </div>
                                            </td></> }
                                            
                                            {token === "admin2" ? <td className="border border-white">
                                                <ConfirmToaster css={"hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Invalidar"} message={"Tem a certeza que deseja invalidar o formador ?"} confirm={unvalidFormador} id={item._id}></ConfirmToaster>
                                            </td> : null}
                                        </tr>
                                    ))}
                                </tbody> : <tbody />}
                            </table>
                        </div>
                        <div className="mt-4">
                            <button onClick={() => { checkedTrainers.length > 0 ? ExcelFormadores(checkedTrainers) : toastError("Sem formadores selecionados.") }} className="button-geral tooltip" data-tip={"Exportar Formadores Selecionados em Excel"}>Exportar</button>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={currentPage > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => goToFirstPage()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={currentPage > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {currentPage} - {totalPages}</p>
                            <button className={currentPage < totalPages ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={currentPage < totalPages ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => goToLastPage()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div>
                    </> : <p className="third-title">Não existem dados</p>}

                    <div className="mt-10 mb-28">
                        <div className="subtitle">Envio de Email de Inscrição</div>
                        <div className="third-title"> Digite o mail:</div>
                        <input className="input-field-long" type="text" name="emailTo" id="emailTo" />
                        <button className="button-geral" onClick={sendEmail}>Submeter</button>
                    </div>
                </div>
                {/* Modal das Áreas de formação de um formador */}
                <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                    <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                    <div className="relative flex flex-col items-center px-10 mx-4 shadow-2xl bg-secundary-color py-7 rounded-2xl">
                        <h1 className="title">Áreas de Ação-Formação</h1>
                        {modalData.map((item, itemIndex) => (
                            <div className="flex flex-col items-start w-full" key={itemIndex}>
                                <p className="text-left text-text-main-color">{item}</p>
                            </div>
                        ))}
                        <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </Modal>
                {token !== "coord" && <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>}
            </main>
            {token === "coord" && <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>}
        </>
    );
}
export default BolsaFormadoresList
