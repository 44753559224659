import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import ExcelGenerator from "../../../../../components/Excel/cronogramaDisciplina/index";
import toastSuccess from "../../../../../components/Toast/toastSuccess";
import toastError from "../../../../../components/Toast/toastError";
import Modal from 'react-modal'
import ConfirmToaster from "../../../../../components/Toast/toastConfirmChoice";
import NavbarCoordenador from "../../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import { FiDownload } from "react-icons/fi";
import { BsFillPencilFill, BsFillSendFill } from "react-icons/bs";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineMail, AiOutlineRight } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import { FaEye, FaEyeSlash, FaRegListAlt, FaUser, FaUserGraduate, FaUserTie } from "react-icons/fa";
import csvtojson from 'csvtojson';
import { read, utils } from 'xlsx';
import { PiUserListLight } from "react-icons/pi";
import CSVGeneratorDisciplinas from "../../../../../components/MoodleCSV/disciplinasCSV";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
import ModalEnvioContrato from "../../../../../components/Modal/ModalEnvioContrato";
import ExcelAvaliacaoCoordenadores from "../../../../../components/Excel/Formularios/formAvaliacaoCoordenadores";
import ExcelAvaliacaoFormadores from "../../../../../components/Excel/Formularios/formAvaliacaoFormandores";
import ExcelAvaliacaoFormandos from "../../../../../components/Excel/Formularios/formAvaliacaoFormandos";
import QRCodeGenerator from "../../../../../components/QRCode/QRCode";
import FileUploader from "../../../../../utils/validationFileUploader";
Modal.setAppElement('#root');

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [formadoresList, setFormadoresList] = useState(0)
    const [formador, setFormador] = useState("")
    const [formato, setFormato] = useState("")
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [error, setError] = useState(null);
    const [erro, setErro] = useState("")
    const [cont, setCont] = useState(0);
    const [substituir, setSubstituir] = useState(null);
    const [alterarCodigo, setAlterarCodigo] = useState(-1);
    const [alterarCodigoMoodle, setAlterarCodigoMoodle] = useState(-1);
    const [alterarDataInicio, setAlterarDataInicio] = useState(-1);
    const [alterarDataFinal, setAlterarDataFinal] = useState(-1);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenador-coursesIndUfcdPerc-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [modalEvaluationIsOpen, setModalEvaluationIsOpen] = useState(false);
    const [ufcdSelecionada, setUfcdSelecionada] = useState(null);
    const [ufcdSelecionadaNome, setUfcdSelecionadaNome] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [modal2Option, setModal2Option] = useState("");
    const [ufcdsGlobais, setUfcdsGlobais] = useState(null);
    const [itemSelecionado, setitemSelecionado] = useState(null);
    const [ufcdGlobaisNaoInseridas, setUfcdGlobaisNaoInseridas] = useState([]);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenador-coursesIndUfcdPerc-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coordenadores-coursesIndUfcdPerc-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)


    let id = 0
    const params = useParams();
    const navigate = useNavigate();
    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    async function downloadFile(id, nameFile) {
        await fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id}`, {
            headers: {
                'authorization': localStorage.getItem('coord')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    fetch(result.avaliacaoSatisfacaoFormando[0].file)
                        .then(response => response.blob())
                        .then(blob => {
                            const blobUrl = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', nameFile); // Set the filename to 'bruno'
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            URL.revokeObjectURL(blobUrl); // Clean up the URL object
                            document.body.removeChild(link); // Remove the link from the DOM after the click
                            setCont(cont + 1)
                        })
                        .catch(error => {
                            console.error('Error downloading file:', error);
                        });
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    async function cloudinaryFicheiro(id) {
        try {
            setLoading2(true);

            const maxSizeInBytes = 4 * 1024 * 1024; // 4MB
            const fileInput = document.getElementById("AvaliacaoFormando");

            if (fileInput && fileInput.files && fileInput.files.length > 0) {
                const fileSize = fileInput.files[0].size;

                if (fileSize < maxSizeInBytes) {
                    const ficheiro = fileInput.files[0];
                    const ficheiroForm = new FormData();
                    ficheiroForm.append('excel', ficheiro);

                    const options = {
                        method: "POST",
                        headers: { "Accept": "application/json" },
                        credentials: "same-origin",
                        body: ficheiroForm
                    };

                    const ficheiroResponse = await fetch(`${process.env.REACT_APP_API_URL}/cronograma/especifico`, options);
                    const ficheiroResult = await ficheiroResponse.json();

                    await fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id}/avaliacaoFormando`, {
                        method: 'PATCH',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem("coord")
                        },
                        body: JSON.stringify({
                            file: ficheiroResult.cloudinaryFileUrl,
                            idUtilizador: localStorage.getItem("id")
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            uploadDados(ficheiro)
                            toastSuccess("Ficheiro Uploaded com sucesso");
                            setLoading2(false);
                            setLoading(false);
                            setErro("");
                            setCont(prevCont => prevCont + 1);
                        });
                } else {
                    setErro("O tamanho do ficheiro não pode ultrapassar 4MB");
                    setLoading2(false);
                }
            } else {
                setErro("É necessário adicionar ficheiro");
                setLoading2(false);
            }
        } catch (error) {
            console.error("Error in cloudinaryFicheiro:", error);
            // Handle error appropriately
        }
    }
    useEffect(() => {
        Cookies.set("Coordenador-coursesIndUfcdPerc-Pag", paginaAtual);
        Cookies.set("Coordenador-coursesIndUfcdPerc-1", searchTerm);
        Cookies.set(`Coordenadores-coursesIndUfcdPerc-itm1`, itemsPag)
        getUfcds()
        getFormadores()
        getUfcdsGlobais()
    }, [loading, cont, paginaAtual, alterarCodigoMoodle, alterarCodigo, nPaginas, searchTerm, itemsPag, modal2Option, modalIsOpen2, ufcdSelecionadaNome]);

    useEffect(() => {
        verificarSeDisciplinaEstaInserida(); // Call the function whenever the data state changes
    }, [data, paginaAtual, nPaginas]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    let array = [];
                    setFormato(result.type)
                    result.ufcds.forEach(element => {
                        if (element.name != "PRA") {
                            array.push(element)
                        }
                    });
                    setData(array);
                    paginacaoTabelas(filterData(array));
                    let cont = 0
                    if (result.ufcds) {
                        result.ufcds.forEach(element => {
                            if (!element.fechoCoordenador) {
                                cont++
                            }
                        });
                        if (cont === 0) {
                            fetch(`${process.env.REACT_APP_API_URL}/percurso/coordenador/fechoCurso/${params.id_curso}`, {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'authorization': localStorage.getItem("coord")
                                },
                                method: 'POST',
                                body: JSON.stringify({
                                    fechoCoordenador: true
                                })
                            })
                                .then(res => res.json())
                                .then((result) => {
                                })
                        }
                    }
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    function alterarCodeMoodle(id, codeMoodle) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/codeMoodle/${id}`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem('coord')
                },
                body: JSON.stringify({
                    codeMoodle: codeMoodle
                })
            }).then((result) => {
                setAlterarCodigoMoodle(-1)
                toastSuccess("Código Moodle alterado com sucesso")
            })
    };

    function alterarCodeUfcd(id, codeUfcd) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/codeUfcd/${id}`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem('coord')
                },
                body: JSON.stringify({
                    codeUfcd: codeUfcd
                })
            }).then((result) => {
                setAlterarCodigo(-1)
                toastSuccess("Código alterado com sucesso")
            })
    };

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Obter formadores validados
    function getFormadores() {
        fetch(`${process.env.REACT_APP_API_URL}/formadores/validados`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    const sortedData = result.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
                    setFormadoresList(sortedData)
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    function postDateBegin(id, dBegin, dEnd, pDBegin, pDEnd) {
        if (dBegin) {
            if (dBegin >= pDBegin && dBegin <= pDEnd) {
                fetch(`${process.env.REACT_APP_API_URL}/ufcds/dateBegin/${id}`,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            "authorization": localStorage.getItem("coord")
                        },
                        body: JSON.stringify({
                            dateBegin: dBegin
                        })
                    }).then((result) => {
                        result.json().then((resp) => {

                            setCont(cont + 1)
                        })
                    })
                toastSuccess("Data de início inserida com sucesso")
            } else toastError("A data de início deve estar dentro do tempo do Curso")
        } else toastError("O campo não pode estar vazio")
    };
    function postDateEnd(id, dBegin, dEnd, pDBegin, pDEnd) {
        if (dEnd) {
            if (dEnd <= pDEnd) {
                if (dBegin) {
                    if (dBegin <= dEnd) {
                        fetch(`${process.env.REACT_APP_API_URL}/ufcds/dateEnd/${id}`,
                            {
                                method: "POST",
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    "authorization": localStorage.getItem('coord')
                                },
                                body: JSON.stringify({
                                    dateEnd: dEnd
                                })
                            }).then((result) => {
                                result.json().then((resp) => {
                                    toastSuccess("Data de fim inserida com sucesso")
                                    setCont(cont + 1)
                                    setAlterarDataFinal(0)
                                })
                            })
                    } else toastError("A data de fim tem de ser superior à data de início")
                } else toastError("Insira primeiro a data de início")
            } else {
                if (dBegin) {
                    if (dBegin <= dEnd) {
                        fetch(`${process.env.REACT_APP_API_URL}/ufcds/dateEnd/${id}`,
                            {
                                method: "POST",
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    "authorization": localStorage.getItem('coord')
                                },
                                body: JSON.stringify({
                                    dateEnd: dEnd
                                })
                            }).then((result) => {
                                result.json().then((resp) => {

                                })
                            })
                        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/dateEnd`,
                            {
                                method: "PATCH",
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    "authorization": localStorage.getItem('coord')
                                },
                                body: JSON.stringify({
                                    dateEnd: dEnd
                                })
                            }).then((result) => {
                                result.json().then((resp) => {
                                    toastSuccess("Data de fim inserida com sucesso")
                                    setCont(cont + 1)
                                    setAlterarDataFinal(0)
                                })
                            })
                    } else toastError("A data de fim tem de ser superior à data de início")
                } else toastError("Insira primeiro a data de início")
            }
        } else toastError("O campo não pode estar vazio")
    };


    //Adicionar um formador a uma disciplina
    function adicionarFormador(props) {
        if (formador) {
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${formador}/associar/${props}`,
                {
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem("coord")
                    }
                }).then((result) => {
                    result.json().then((resp) => {
                    })
                    toastSuccess('UFCD adicionada com sucesso ao Formador!')
                    setModalIsOpen(false)
                    setCont(cont + 1)
                })
        } else toastError("Têm de selecionar um formador")
    }

    //Substituir um formador a uma disciplina
    function substituirFormador(props, id, index) {
        const trainerIdAntigo = id
        if (formador) {
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${formador}/substituir/${props}`,
                {
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem('coord')
                    },
                    body: JSON.stringify({
                        trainerIdAntigo: trainerIdAntigo,
                        index: index
                    })
                }).then((result) => {
                    result.json().then((resp) => {
                    })
                    toastSuccess('UFCD adicionada com sucesso ao Formador!')
                    setSubstituir(null)
                    setFormador("")
                    setModalIsOpen(false)
                    setCont(cont + 1)
                })
        } else toastError("Têm de selecionar um formador")
    }

    function removerFormador(props, id, index) {
        const trainerIdAntigo = id
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/remover/${props}`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('coord')
                },
                body: JSON.stringify({
                    trainerIdAntigo: trainerIdAntigo,
                    index: index
                })
            }).then((result) => {
                result.json().then((resp) => {
                })
                toastSuccess('UFCD adicionada com sucesso ao Formador!')
                setSubstituir(null)
                setModalIsOpen(false)
                setCont(cont + 1)
                getFormadores()
            })
    }

    //Obtem todas as ufcds globais
    function getUfcdsGlobais() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`, {
            headers: {
                "authorization": localStorage.getItem("coord")
            }
        })
            .then(response => response.json())
            .then(result => {
                setUfcdsGlobais(result)
            })
    }

    function verificarSeDisciplinaEstaInserida() {
        if (data && ufcdsGlobais) {
            const idJaUsados = data.map((item) => item.codeUfcd);
            const todosIds = ufcdsGlobais.map((item) => item.codeUfcd);

            const diffArray = [];

            // Check elements from todosIds
            for (let i = 0; i < todosIds.length; i++) {
                if (diffArray.length <= todosIds.length) {
                    if (!idJaUsados.includes(todosIds[i])) {
                        diffArray.push(ufcdsGlobais[i]);
                    }
                }

            }
            const uniqueArray = [...new Set(diffArray.map(JSON.stringify))].map(JSON.parse);
            setUfcdGlobaisNaoInseridas(uniqueArray)

        }
    }

    // Converter dados do excel para formato JSON
    function convertExcelToJson(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);

                // Explicitly specify the character encoding as 'utf-8'
                const workbook = read(data, { type: 'array', encoding: 'utf-8' });

                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const csvData = utils.sheet_to_csv(worksheet);

                csvtojson()
                    .fromString(csvData)
                    .then((jsonData) => {

                        resolve(jsonData);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    // Enviar dados para a Base de dados
    async function uploadDados(file) {
        setLoading(true);
        let verif = 0
        if (file) {
            convertExcelToJson(file)
                .then((data) => {
                    let array = []
                    if (verif === data.length) {
                        toastError("Documento vazio.")
                    }
                    else {
                        data.forEach(element => {
                            if (element["1"][" Adequação dos conteúdos ao que foi anunciado/divulgado"]) {
                                array.push({
                                    criterio1: element["1"][" Adequação dos conteúdos ao que foi anunciado/divulgado"],
                                    criterio2: element["2"][" Utilidade dos conteúdos para as à suas funções/futuras funções"],
                                    criterio3: element["3"][" Qualidade Pedagógica dos materiais apresentados (vídeo aulas, se aplicável, slides, documentos de apoio, etc)"],
                                    criterio4: element["4"][" Qualidade do acompanhamento pedagógico da entidade"],
                                    criterio5: element["1"][" Domínio dos assuntos pelo formador"],
                                    criterio6: element["2"][" Utilidade dos conteúdos para as à suas funções/futuras funções"],
                                    criterio7: element["3"][" Qualidade dos equipamentos (Sala vídeo conferência / Plataforma e/ou Instalações, insonorização, luminosidade, temperatura, etc"][")"],
                                    criterio8: element["4"][" Qualidade do acompanhamento pedagógico da entidade"],
                                    sugestao: element["SUGESTÕES/ CRÍTICAS/ COMENTÁRIOS"],
                                })
                            }
                        });
                    }
                    avaliacaoSatisfacaoFormandosResultados(array)
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error)
                    toastError("Documento inválido.")
                });
        }
    };
    //Envio de Mail do questionário aos formandos
    function sendEmailSatisfacaoFormandos(id) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id}/sendMailAvaliacaoSatisfacaoFormando`, {
            headers: {
                "authorization": localStorage.getItem('coord')
            }
        })
            .then(response => response.json())
            .then(result => {
                toastSuccess("Emails enviados com sucesso")
            })
    }
    //Adicionar Avaliação de satisfação dos formandos
    function avaliacaoSatisfacaoFormandosResultados(data) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${ufcdSelecionadaNome._id}/avaliacaoSatisfacaoFormandos`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('coord')
                },
                body: JSON.stringify({
                    avaliacaoSatisfacaoFormandosResults: data
                })

            }).then((result) => {
            })
    }
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (loading2) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"cursos"} />
                </div>

                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">
                    <div className="back-button-noNavbar">
                        <button onClick={() => navigate("/MenuCoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso)} className="flex w-8 h-8">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                    </div>
                    <Header sideMenu={"true"} />
                    <h1 className="mx-4 mt-8 title sm:mt-0">Lista de Disciplinas</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="mr-2 text-xl font-bold text-text-second-color">Ver apenas: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-containerSideMenu">
                            <table className="my-table">
                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 pl-2 border border-white">Código</th>
                                        <th className="p-2 border border-white">Nome</th>
                                        {(formato === "Online" || formato === "Misto") && <th className="p-2 min-w-[150px] border border-white">Código Moodle</th>}
                                        <th className="p-2 border border-white">Data Inicio</th>
                                        <th className="p-2 border border-white">Data Fim</th>
                                        <th className="p-2 border border-white">Duração</th>
                                        <th className="p-2 pr-2 border border-white">Cronograma</th>
                                        <th className="p-2 border border-white">Gerir Eventos</th>
                                        <th className="p-2 border border-white">Turma</th>
                                        <th className="p-2 border border-white">Pauta</th>
                                        <th className="p-2 min-w-[300px] border border-white">Formador</th>
                                        <th className="p-2 border border-white">Avaliação Formador</th>
                                        <th className="p-2 border border-white">Avaliação Satistação</th>
                                        <th className="p-2 border border-white">Fecho Formador</th>
                                        <th className="p-2 border border-white">Fecho Coordenador</th>
                                    </tr>
                                </thead>
                                {dataToDisplay ? <tbody className="text-text-main-color">
                                    {filterData(dataToDisplay).map((item, index) => (
                                        <tr key={index}>
                                            {alterarCodigo !== index ? (
                                                <td className="border border-white" id={`codeUfcd${index}`} value={item.codeUfcd}>
                                                    <div className="flex flex-col">
                                                        {item.codeUfcd}
                                                        <button className="mx-auto mt-1 w-fit tooltip" data-tip="Alterar Código" onClick={() => setAlterarCodigo(index)} >
                                                            <BsFillPencilFill />
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="border border-white">
                                                    <div className="flex flex-col">
                                                        <input className="input-field" defaultValue={item.codeUfcd} id={"codeUfcd" + index} />
                                                        <div className="flex">
                                                            <button className="w-20 mt-1 mr-1 button-cancelar" onClick={() => setAlterarCodigo(-1)}>Cancelar</button>
                                                            <ConfirmToaster css={"button-geral mt-1 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar Código"} message={"Tem a certeza que deseja alterar o código da disciplina?"} confirm={() => alterarCodeUfcd(item._id, document.getElementById("codeUfcd" + index).value)} id={item._id} tooltip={"Alterar Código"}></ConfirmToaster>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                            <td className="border border-white">{item.name}</td>
                                            {(formato === "Online" || formato === "Misto") && <> {alterarCodigoMoodle !== index ? (
                                                <td className="border border-white" id={`codeMoodle${index}`} value={item.codeMoodle}>
                                                    <div className="flex flex-col">
                                                        {item.codeMoodle}
                                                        <button className="mx-auto mt-1 w-fit tooltip" data-tip="Alterar Codigo Moodle" onClick={() => setAlterarCodigoMoodle(index)} >
                                                            <BsFillPencilFill />
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="border border-white">
                                                    <div className="flex flex-col">
                                                        <input className="input-field" defaultValue={item.codeMoodle} id={"codeMoodle" + index} />
                                                        <div className="flex">
                                                            <button className="w-20 mt-1 mr-1 button-cancelar" onClick={() => setAlterarCodigoMoodle(-1)}>Cancelar</button>
                                                            <ConfirmToaster css={"button-geral mt-1 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar Código Moodle"} message={"Tem a certeza que deseja alterar o código Moodle da disciplina?"} confirm={() => alterarCodeMoodle(item._id, document.getElementById("codeMoodle" + index).value)} id={item._id} tooltip={"Alterar Código Moodle"}></ConfirmToaster>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}</>}
                                            {item.dateBegin && alterarDataInicio !== index + 1 ? (
                                                <td className="border border-white" id={`dateBegin${index}`} value={item.dateBegin}>
                                                    <div className="flex flex-col">
                                                        {AlterarFormato(item.dateBegin)}
                                                        <button className="mx-auto mt-1 w-fit tooltip" data-tip="Alterar Data Inicial" onClick={() => setAlterarDataInicio(index + 1)} >
                                                            <BsFillPencilFill />
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="border border-white">
                                                    <div className="flex flex-col">
                                                        <input className="input-field" min={item.percurso[0].dateBegin} defaultValue={item.dateBegin} id={"dateBegin" + index} type="date" />
                                                        <div className="flex">
                                                            <button className="w-20 mt-1 mr-1 button-cancelar" onClick={() => setAlterarDataInicio(-1)}>Cancelar</button>
                                                            <ConfirmToaster css={"button-geral mt-1 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar Data de Início"} message={"Tem a certeza que deseja alterar a data de início da disciplina?"} confirm={() => postDateBegin(item._id, document.getElementById("dateBegin" + index).value, document.getElementById("dateEnd" + index).value, item.percurso[0].dateBegin, item.percurso[0].dateEnd)} id={item._id} tooltip={"Alterar Data"}></ConfirmToaster>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                            {item.dateEnd && alterarDataFinal !== index + 1 ? (
                                                <td className="border border-white" id={"dateEnd" + index} value={item.dateEnd}>
                                                    <div className="flex flex-col">
                                                        {AlterarFormato(item.dateEnd)}
                                                        <button className="mx-auto mt-1 w-fit tooltip" data-tip="Alterar Data Final" onClick={() => setAlterarDataFinal(index + 1)} >
                                                            <BsFillPencilFill />
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="border border-white">
                                                    <div className="flex flex-col">
                                                        <input className="input-field" min={item.dateBegin} defaultValue={item.dateEnd} id={"dateEnd" + index} type="date" />
                                                        <div className="flex">
                                                            <button className="w-20 mt-1 mr-1 button-cancelar" onClick={() => setAlterarDataFinal(-1)}>Cancelar</button>
                                                            <ConfirmToaster css={"button-geral mt-1 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar Data Final"} message={`Tem a certeza de que deseja alterar a data de término desta disciplina? Caso a data final escolhida ultrapasse a data de final prevista do percurso (${AlterarFormato(item.percurso[0].dateEnd)}), esta será reajustada para refletir a data final da disciplina.`} confirm={() => postDateEnd(item._id, item.dateBegin, document.getElementById("dateEnd" + index).value, item.percurso[0].dateBegin, item.percurso[0].dateEnd)} id={item._id} tooltip={"Alterar Data"}></ConfirmToaster>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                            <td className="border border-white">{item.cargaHoraria}</td>
                                            <td className="border border-white">
                                                <button className="button-geral" onClick={() => ExcelGenerator(item)}><FiDownload /></button>
                                            </td>
                                            <td className="border border-white items-center " >
                                                <button onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id + "/Modulos")} className="button-geral mx-auto flex items-center justify-center group-hover:brightness-150">
                                                    Gerir
                                                </button>
                                            </td>
                                            {item.formandos.length > 0 ? <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id + "/turma")}>
                                                <div className="flex items-center justify-center group-hover:brightness-150" >
                                                    <FaEye size={24} />
                                                </div>
                                            </td> :
                                                <td className="border border-white cursor-not-allowed">
                                                    <div className="flex items-center justify-center opacity-25 ">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                            }
                                            {item.formandos.length > 0 ?
                                                <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id)}>
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td> :
                                                <td className="border border-white cursor-not-allowed">
                                                    <div className="flex items-center justify-center opacity-25 ">
                                                        <FaEyeSlash size={24} />
                                                    </div>
                                                </td>
                                            }

                                            {item.formadores.length > 0 || (item.name === "PRA" && item.Coordenador.length > 0) ?
                                                <td className="border border-white">
                                                    <div className="flex flex-col">
                                                        {item.name === "PRA" ? item.Coordenador[0].fullName : item.formadores.length === 1 ? <div className="flex justify-between items-center">
                                                            <div>{item.formadores[0].fullName}</div>
                                                            <div className="mx-2">
                                                                <ModalEnvioContrato tipo={"formador"} data={item} formador={item.formadores[0]} token={"coord"} />
                                                            </div>
                                                        </div> : item.formadores.map(item2 => <><div className="flex justify-between items-center" key={item2._id}><div> {item2.fullName} </div> <div className="mx-2"><ModalEnvioContrato tipo={"formador"} data={item} formador={item2} token={"coord"} /></div></div><hr className={index % 2 === 0 ? "border-white my-1" : "border-main-color my-1"} /></>)}
                                                        <button className="mx-auto mt-1 w-fit tooltip" data-tip="Alterar/Adicionar Formador" onClick={() => { setModalIsOpen(true); setitemSelecionado({ id: item._id, formadores: item.formadores }); setSubstituir(item.formadores[0]._id) }} >
                                                            <BsFillPencilFill />
                                                        </button>
                                                    </div>
                                                </td> :
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={() => { setModalIsOpen(true); setitemSelecionado({ id: item._id, formadores: item.formadores }) }}>Adicionar</button>
                                                </td>
                                            }
                                            {new Date() > new Date(item.dateEnd) && item.dateEnd && (!item.evaluation || item.evaluation.length < item.formadores.length) && item.formadores.length > 0 ?
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={() => (setUfcdSelecionada(item), setModalEvaluationIsOpen(true))}>Avaliar</button>
                                                </td> :
                                                <td className="border border-white">
                                                    <button className="button-geral bg-green-700 hover:bg-green-600" onClick={() => (setUfcdSelecionada(item), setModalEvaluationIsOpen(true))}>Avaliações</button>
                                                </td>}
                                            <td className="border border-white">
                                                <button className="button-geral" onClick={(e) => { setModalIsOpen2(true); setUfcdSelecionadaNome(item) }}>
                                                    <PiUserListLight />
                                                </button>
                                            </td>
                                            {item.fechoFormador === true ?
                                                <td className="border border-white">Sim</td> :
                                                <td className="border border-white">Não</td>
                                            }
                                            {item.fechoCoordenador === true ?
                                                <td className="border border-white">Sim</td> :
                                                <td className="border border-white">Não</td>}

                                        </tr>
                                    ))}

                                    {itemSelecionado && <div className="modal2" style={{ display: modalIsOpen ? "block" : "none" }}>
                                        <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                                            <div className="flex items-center justify-center h-screen">
                                                <div className="relative z-50 p-2 rounded-lg shadow-lg max-h-128 xl:w-128 bg-secundary-color shadow-gray-600">
                                                    <div className="flex flex-col justify-center my-2">
                                                        <h2 className="subtitle">Gestão de formadores</h2>
                                                    </div>
                                                    <div className="modal2-body">
                                                        <label className="mr-1 label-input">Trocar Formador</label>
                                                        {itemSelecionado.id && itemSelecionado.formadores.map((item, index) =>
                                                            <div className="flex items-center" key={index}>
                                                                {item && formadoresList && data && <>
                                                                    <select
                                                                        onWheel={(e) => e.target.blur()}
                                                                        className="selector"
                                                                        value={item._id} // Change from defaultValue to value
                                                                        onChange={(e) => {
                                                                            const newFormadorId = e.target.value;
                                                                            const updatedFormadores = [...itemSelecionado.formadores];
                                                                            updatedFormadores[index] = formadoresList.find(f => f._id === newFormadorId);
                                                                            setitemSelecionado(prev => ({
                                                                                ...prev,
                                                                                formadores: updatedFormadores
                                                                            }));
                                                                        }}
                                                                    >
                                                                        <option value="" disabled>Selecione uma opção</option>
                                                                        {formadoresList.filter(value => !itemSelecionado.formadores.some(id => id._id === value._id) || value._id === item._id).map(value => (
                                                                            <option value={value._id} key={value._id}>
                                                                                {value.fullName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <div className="flex justify-around my-4 ml-2 gap-2">
                                                                        {substituir
                                                                            ? <button className="button-geral" onClick={() => substituirFormador(itemSelecionado.id, substituir, index)}>Alterar</button>
                                                                            : <button className="button-geral" onClick={() => adicionarFormador(itemSelecionado)}>Adicionar</button>}
                                                                        <button className="button-geral" onClick={() => removerFormador(itemSelecionado.id, item._id, index)}>Remover</button>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        )}
                                                        <label className="mr-1 label-input">Adicionar Formador</label>
                                                        <div className="flex items-center">

                                                            {formadoresList && data && <>
                                                                <select
                                                                    onWheel={(e) => e.target.blur()}
                                                                    className="selector"
                                                                    value={formador || ""}
                                                                    onChange={(e) => setFormador(e.target.value)}
                                                                >
                                                                    <option value={""} disabled>Selecione uma opção</option>
                                                                    {formadoresList.map((value) => (!itemSelecionado.formadores.some(id => id._id === value._id) && (
                                                                        <option value={value._id} key={value._id}>
                                                                            {value.fullName}
                                                                        </option>)
                                                                    ))}
                                                                </select>
                                                                <div className="flex justify-around my-4">
                                                                    {substituir ? <button className="button-geral" onClick={() => substituirFormador(itemSelecionado.id, substituir)}>Adicionar</button> : <button className="button-geral" onClick={() => adicionarFormador(itemSelecionado.id)}>Adicionar</button>}
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </div>

                                                    <button className="absolute top-1.5 right-1.5" onClick={() => { setModalIsOpen(false) }}>
                                                        <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {ufcdSelecionada && <div className="modal2" style={{ display: modalEvaluationIsOpen ? "block" : "none" }}>
                                        <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                                            <div className="flex items-center justify-center h-screen">
                                                <div className="relative z-50 p-2 rounded-lg shadow-lg min-h-[28rem] xl:w-128 bg-secundary-color shadow-gray-600">
                                                    <div className="flex flex-col justify-center my-2">
                                                        <h2 className="subtitle">Avaliar formadores</h2>
                                                    </div>
                                                    <div className="modal2-body my-auto">
                                                        <div className="flex flex-col items-center space-y-6">
                                                            {ufcdSelecionada.formadores.length > 0 && ufcdSelecionada.formadores.map((item) => (
                                                                <div>
                                                                    {ufcdSelecionada.evaluation.some(ufcd => ufcd.formadorId === item._id) ?
                                                                        ufcdSelecionada.evaluation.map(ufcd =>
                                                                        (ufcd.formadorId === item._id ? <Link className="flex flex-col items-center h-[10vh] w-[10vw] justify-center border-2 rounded-md border-main-color hover:text-text-third-color"
                                                                            to={"/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + ufcdSelecionada._id + "/evaluation/" + item._id}><FaUser className="text-green-500 mb-2" size={40} />
                                                                            <p className="font-bold">{ufcd.evaluationFinal}%</p>
                                                                            <p>{item.fullName} </p></Link> : "")
                                                                        ) :
                                                                        <Link className="flex flex-col items-center h-[10vh] w-[10vw] justify-center border-2 rounded-md border-main-color hover:text-text-third-color"
                                                                            to={"/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + ufcdSelecionada._id + "/evaluation/" + item._id}>
                                                                            <FaUser className="mb-2" size={40} />
                                                                            {item.fullName}
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <button className="absolute top-1.5 right-1.5" onClick={() => { setModalEvaluationIsOpen(false) }}>
                                                        <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </tbody> : <tbody />}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                    <div className="modal2" style={{ display: modalIsOpen2 ? "block" : "none" }}>
                        <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                            <div className="flex items-center justify-center h-screen">
                                <div className="relative z-50 p-2 rounded-lg shadow-lg  xl:w-128 bg-secundary-color shadow-gray-600 min-w-[40%] min-h-[60%]">
                                    {modalIsOpen2 && modal2Option !== "" && <div className="flex flex-row gap-2 justify-center items-center">
                                        <button onClick={() => setModal2Option("formando")} className="tooltip" data-tip={"Avaliação de Satisfação do formando"}><FaUserGraduate className={modal2Option === "formando" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={60} /></button>
                                        <button onClick={() => setModal2Option("formador")} className="tooltip" data-tip={"Avaliação de Satisfação do formador"}><FaUser className={modal2Option === "formador" ? "p-2 text-third-color tooltip" : "p-2 text-main-color hover:text-text-second-color "} size={60} /></button>
                                        <button onClick={() => setModal2Option("coordenador")} className="tooltip" data-tip={"Avaliação de Satisfação do Coordenador"}><FaUserTie className={modal2Option === "coordenador" ? "p-2 text-third-color" : "p-2 text-main-color hover:text-text-second-color"} size={60} /></button>
                                    </div>}
                                    {modalIsOpen2 && modal2Option !== "" && <hr className="bg-main-color my-2 h-1" />}
                                    {modalIsOpen2 && modal2Option === "" &&
                                        <div className="h-full overflow-auto my-auto ">
                                            <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                            <div className="flex flex-col gap-10 my-auto justify-center items-center h-full">
                                                <button onClick={() => setModal2Option("formando")} className="tooltip" data-tip={"Avaliação de Satisfação dos Formandos"}>
                                                    <FaUserGraduate className={modal2Option === "formando" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                    <div>Avaliação de Satisfação dos Formandos</div>
                                                </button>
                                                <button onClick={() => setModal2Option("formador")} className="tooltip" data-tip={"Avaliação de Satisfação dos Formadores"}>
                                                    <FaUser className={modal2Option === "formador" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                    <div>Avaliação de Satisfação dos Formadores</div>
                                                </button>
                                                <button onClick={() => setModal2Option("coordenador")} className="tooltip" data-tip={"Avaliação de Satisfação do Coordenador"}>
                                                    <FaUserTie className={modal2Option === "coordenador" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                    <div>Avaliação de Satisfação do Coordenador</div>
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    {modal2Option === "formando" && <div >
                                        <div className="flex flex-col justify-center my-2">
                                            <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                            <div className="flex flex-col min-h-[35em] gap-10 my-auto justify-center items-center">
                                                <div>
                                                    <h3 className="third-title">Formulário de avaliação</h3>
                                                    <div className="flex gap-10 justify-center">
                                                        <QRCodeGenerator link={"https://magna.comenius.pt/#/FormAvaliacaoSatisfacao/" + ufcdSelecionadaNome._id}>
                                                        </QRCodeGenerator>
                                                        <button onClick={() => window.open(process.env.PUBLIC_URL + "/#/" + "FormAvaliacaoSatisfacao/" + ufcdSelecionadaNome._id, '_blank')} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Satisfação"}>
                                                            <FaRegListAlt className={"p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                        </button>
                                                        <button onClick={() => sendEmailSatisfacaoFormandos(ufcdSelecionadaNome._id)} className={"tooltip"} data-tip={"Enviar mail de preenchimento do Formulário de Satisfação"}>
                                                            <AiOutlineMail className={"p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col gap-5">
                                                    <h3 className="third-title">Upload dos Dados do Formulário</h3>
                                                    <FileUploader
                                                    inputId={"AvaliacaoFormando"}
                                                    /* onClick={() => setModal2Option("formando")} */
                                                    inputType={"file"}
                                                    inputClassName={"tooltip"}
                                                    onChangeSpecial={() => setCont(cont + 1)}
                                                    inputDataTip={"Upload do Formulário"}
                                                    />
                                                    <button onClick={() => cloudinaryFicheiro(ufcdSelecionadaNome._id)} className={document.getElementById("AvaliacaoFormando") && document.getElementById("AvaliacaoFormando").files.length > 0 ? "button-geral" : "button-disabled"} >Submeter</button>
                                                </div>

                                                <button
                                                    disabled={
                                                        !(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0)
                                                    }
                                                    onClick={() => downloadFile(ufcdSelecionadaNome._id, `ResultadosAvaliaçãoFormandos${ufcdSelecionadaNome.codeUfcd + "-" + ufcdSelecionadaNome.name}`)}
                                                    className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"}
                                                    data-tip={"Download dos Dados do Formulário"}
                                                >
                                                    <h3 className="third-title">Download dos Dados do Formulário</h3>
                                                    <FiDownload className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />

                                                </button>
                                                <button onClick={() => ExcelAvaliacaoFormandos(ufcdSelecionadaNome, "coord")} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Download do Relatório do Formulário"}>
                                                    <h3 className="third-title">Download do Relatório do Formulário</h3>
                                                    <TbReportAnalytics className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>}

                                    {modal2Option === "formador" && <div >
                                        <div className="flex flex-col justify-center my-2">
                                            <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                            <div className="flex flex-col gap-5">
                                                <h3 className="third-title">Formulário de avaliação</h3>
                                                <button onClick={() => navigate(`/MenuCoordenador/${params.id_candidatura}/cursos/${params.id_curso}/Ufcds/${ufcdSelecionadaNome._id}/FormAvaliacao/formador/${params.id_candidatura}`)} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Avaliação"}>
                                                    <FaRegListAlt className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                </button>
                                            </div>
                                            <button onClick={() => ExcelAvaliacaoFormadores(ufcdSelecionadaNome, "coord")} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Download do Relatório do Formulário"}>
                                                <h3 className="third-title">Download do Relatório do Formulário</h3>
                                                <TbReportAnalytics className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                            </button>
                                        </div>
                                    </div>}
                                    {modal2Option === "coordenador" && <div >
                                        <div className="flex flex-col justify-center my-2">
                                            <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                            <div className="flex flex-col gap-5">
                                                <h3 className="third-title">Formulário de avaliação</h3>
                                                <button onClick={() => navigate(`/MenuCoordenador/${params.id_candidatura}/cursos/${params.id_curso}/Ufcds/${ufcdSelecionadaNome._id}/FormAvaliacao/coordenador/${params.id_candidatura}`)} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Avaliação"}>
                                                    <FaRegListAlt className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoCoordenador && ufcdSelecionadaNome.avaliacaoSatisfacaoCoordenador.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                </button>
                                            </div>
                                            <button onClick={() => ExcelAvaliacaoCoordenadores(ufcdSelecionadaNome, "coord")} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoCoordenador && ufcdSelecionadaNome.avaliacaoSatisfacaoCoordenador.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Download do Relatório do Formulário"}>
                                                <h3 className="third-title">Download do Relatório do Formulário</h3>
                                                <TbReportAnalytics className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoCoordenador && ufcdSelecionadaNome.avaliacaoSatisfacaoCoordenador.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                            </button>
                                        </div>
                                    </div>}

                                    <button className="absolute top-1.5 right-1.5" onClick={(e) => setModalIsOpen2(false)}>
                                        <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(data && data.length > 0) ?
                        <div>
                            <CSVGeneratorDisciplinas data={data}></CSVGeneratorDisciplinas></div>
                        : <button className="mt-5 mb-10 button-disabled" >Exportar Turma para CSV</button>
                    }
                </div>
            </main>
            <Footer />
        </>
    );
}

export default ShowUfcd;
