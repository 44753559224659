import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import toastError from "../Toast/toastError";
import "react-toastify/dist/ReactToastify.css";
import { read, utils } from "xlsx";
import csvtojson from "csvtojson";
import containsPostalCode from "../FormChecks/containsPostalCode/containsPostalCode";
import check_nif_number from "../FormChecks/checkNif/checkNIf";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { BiCommentEdit, BiSolidCommentEdit } from "react-icons/bi";
import {
  BsFillArrowLeftCircleFill,
  BsArrowCounterclockwise,
} from "react-icons/bs";
import {
  FaUserTimes,
  FaUserCheck,
  FaUserSlash,
  FaFileUpload,
  FaCheck,
  FaQuestionCircle,
  FaUndo,
} from "react-icons/fa";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import ConfirmToaster from "../Toast/toastConfirmChoice";
import {
  AiOutlineCloseCircle,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import CSVGeneratorFormandos from "../MoodleCSV/formandosCSV";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../Header";
import Cookies from "js-cookie";
import isNumber from "../isNumber/isNumber";
import Modal from "react-modal";
import { FiDownload } from "react-icons/fi";
import { MdDeleteForever, MdEmail } from "react-icons/md";
import ConfirmInscricao from "../Modal/ModalAcceptFormando";
import ModalEnvioContrato from "../Modal/ModalEnvioContrato";
import CapitalizarNomes from "../capitalizeNames/capitalizeNames";
import ExtractWord from "../FormChecks/extractWord/extractWord";
import Nacoes from "../FormChecks/Nacoes/nacoes";
import ExcelFormandos from "../Excel/excelFormandos/excelFormandos";
import CheckTypeAdmin from "../CheckTypeAdmin/CheckTypeAdmin";
import { FaCircleCheck, FaCircleXmark, FaX } from "react-icons/fa6";
import CheckTypeTORVC from "../CheckTypeAdmin/CheckTypeTORVC";
import CheckTypeMarketing from "../CheckTypeAdmin/CheckTypeMarketing";
import ExcelFormandosSimples from "../Excel/excelFormandos/excelFormandosSimple";
import ConfirmToasterReason from "../Toast/toastConfirmChoiceReason";
import FileUploader from "../../utils/validationFileUploader";

function Candidaturas(token, route, type) {
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedCheckboxesFormandos, setSelectedCheckboxesFormandos] =
    useState([]);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataFilterCandidatos, setDataFilterCandidatos] = useState([]);
  const [dataFilterValidados, setDataFilterValidados] = useState([]);

  const [cont, setCont] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMailIsOpen, setModalMailIsOpen] = useState({
    open: false,
    email: "",
  });
  const [modalComentarIsOpen, setModalComentarIsOpen] = useState({
    open: false,
    comentario: "",
    comentarioCQ: "",
    id: "",
  });
  const [dataDesis, setDataDesis] = useState("");
  const [desistenciaFile, setDesistenciaFile] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorLeitura, setErrorLeitura] = useState([]);
  const [percurso, setPercurso] = useState("");
  const [percursoList, setPercursoList] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [idFormando, setIdFormando] = useState(null);
  const [selected, setSelected] = useState(null);
  const [admin, setAdmin] = useState("");
  const params = useParams();
  const [file, setFile] = useState(null);

  const savedPagina =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-Pag1`)
      : "";
  const [nPaginas, setNumPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(
    savedPagina ? parseInt(savedPagina) : 1
  );
  const savedRejeitados =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-Chk1`)
      : true;
  const [rejeitados, setRejeitados] = useState(
    savedRejeitados ? savedRejeitados : true
  );
  const savedDesistentes =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-Chk2`)
      : true;
  const [desistentes, setDesistentes] = useState(
    savedDesistentes ? savedDesistentes : true
  );
  const savedItemsPag =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-itm1`)
      : "";
  const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 5);
  const savedInscritos =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-Chk3`)
      : true;
  const [inscritos, setInscritos] = useState(
    savedInscritos ? savedInscritos : true
  );
  const savedAnalise =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-Chk4`)
      : true;
  const [analise, setAnalise] = useState(savedAnalise ? savedAnalise : true);
  const savedSearchTerm =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Componente-gerirTurma-${params.id_curso}-srch`)
      : "";
  const [searchTerm, setSearchTerm] = useState(
    savedSearchTerm ? savedSearchTerm : ""
  );
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [sendMail, setSendMail] = useState("");

  //Data atual
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();

  //Filtros data
  const datemin = new Date(0, 0, 1);
  const dateMax = new Date(year - 18, month, day);
  const dateCcMax = new Date(year + 10, month, day);
  const dateToday = new Date(year, month, day);

  function limparPercursos() {
    fetch(`${process.env.REACT_APP_API_URL}/percurso/limparPercursos`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparFormandos() {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/limparFormandos`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparFormadores() {
    fetch(`${process.env.REACT_APP_API_URL}/formadores/limparFormadores`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparDisciplinas() {
    fetch(`${process.env.REACT_APP_API_URL}/ufcds/limparDisciplinas`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparCoordenadores() {
    fetch(
      `${process.env.REACT_APP_API_URL}/coordenadores/limparCoordenadores`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
      }
    ).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparEntFinanciadoras() {
    fetch(
      `${process.env.REACT_APP_API_URL}/entFinanciadora/limparEntFinanciadoras`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
      }
    ).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparEntFormadoras() {
    fetch(`${process.env.REACT_APP_API_URL}/entFormadora/limparEntFormadoras`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparModulos() {
    fetch(`${process.env.REACT_APP_API_URL}/modulos/limparModulos`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparOperacoes() {
    fetch(`${process.env.REACT_APP_API_URL}/operacao/limparOperacoes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparTemplates() {
    fetch(`${process.env.REACT_APP_API_URL}/templates/limparTemplates`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparTestes() {
    fetch(`${process.env.REACT_APP_API_URL}/testes/limparTestes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparUfcdsGlobals() {
    fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal/limparUfcdsGlobals`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparAdmins() {
    fetch(`${process.env.REACT_APP_API_URL}/admins/limparAdmins`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  function limparEventos() {
    fetch(`${process.env.REACT_APP_API_URL}/calendar/limparEventos`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }

  //filtra dados
  function filterData(data) {
    return data.filter(
      (item) =>
        item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.employmentSituation
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.academicDegree.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  //Atribuir numero de páginas
  function paginacaoTabelas(data) {
    if (data.length < itemsPag) {
      setNumPaginas(1);
    } else if (data.length % itemsPag === 0) {
      setNumPaginas(data.length / itemsPag);
    } else {
      setNumPaginas(Math.ceil(data.length / itemsPag));
    }
    if (paginaAtual > nPaginas) {
      setPaginaAtual(nPaginas);
    }
    updatePagination(data); // n é o state por causa das tabelas
  }

  //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
  const handleItemsPerPageChange = (newItemsPag) => {
    const newNumPaginas = nPaginas;

    const newPaginaAtual =
      paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;
    setItemsPag(newItemsPag);
    setNumPaginas(newNumPaginas);
    setPaginaAtual(newPaginaAtual);

    updatePagination(dataToDisplay);
  };

  //aumentar página atual
  function increasePagination() {
    if (paginaAtual < nPaginas) {
      setPaginaAtual((prevIndex) => prevIndex + 1);
      updatePagination(dataToDisplay);
    }
  }

  //diminuir página atual
  function decreasePagination() {
    if (paginaAtual > 1) {
      setPaginaAtual((prevIndex) => prevIndex - 1);
      updatePagination(dataToDisplay);
    }
  }

  //Define que dados mostrar consoante a página em que está
  function updatePagination(data) {
    let maxIndex = paginaAtual * itemsPag;
    let minIndex;
    if (paginaAtual === 1) {
      minIndex = 0;
    } else {
      minIndex = (paginaAtual - 1) * itemsPag;
      maxIndex = paginaAtual * itemsPag;
    }
    setDataToDisplay(data.slice(minIndex, maxIndex));
  }

  let i = 0;
  useEffect(() => {
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-Pag1`, paginaAtual);
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-Chk1`, rejeitados);
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-Chk2`, desistentes);
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-Chk3`, inscritos);
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-Chk4`, analise);
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-itm1`, itemsPag);
    Cookies.set(`Componente-gerirTurma-${params.id_curso}-srch`, searchTerm);
    getPercurso();
    getFormandosValidos();
    handleSearch();
    if (!admin && token !== "admin2") {
      getUser();
    }
    if (!percursoList) {
      getPercursos();
    }
    i++;
  }, [
    cont,
    paginaAtual,
    nPaginas,
    rejeitados,
    desistentes,
    itemsPag,
    inscritos,
    analise,
    sendMail,
    searchTerm,
    selected,
  ]);
  const handleSearch = async () => {
    await getTurma(
      paginaAtual,
      itemsPag,
      searchTerm,
      inscritos,
      desistentes,
      analise,
      rejeitados
    );
  };
  async function downloadFile(url) {
    if (url) {
      try {
        const newWindow = window.open(url, "_blank"); // Open a new browser page
        if (newWindow) {
          newWindow.focus(); // Ensure focus on the new window
          newWindow.opener = null; // Set opener to null for security reasons
        } else {
          throw new Error(
            "Failed to open new window. Please check your browser settings."
          );
        }
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else {
      setLoading(false);
    }
  }

  //Abre modal
  const handleOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };
  //Abre modal de comentar
  const handleOpenComentar = () => {
    setModalComentarIsOpen(!modalComentarIsOpen);
  };
  //Abre modal de enviar email
  const handleOpenMail = () => {
    setModalMailIsOpen(!modalMailIsOpen);
  };
  //Cria folha de desitencia
  async function gerarFolhaDesistencia() {
    if (!idFormando) {
      console.error("No formando ID provided.");
      return;
    }

    try {
      setLoading(true);
      // Generate withdrawal sheet
      const percursoResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/percurso/FolhadeDesistencia/${params.id_curso}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
        }
      );

      if (!percursoResponse.ok) {
        throw new Error("Failed to generate withdrawal sheet.");
      }

      const percursoResult = await percursoResponse.json();

      // Download the generated sheet
      await downloadFile(percursoResult);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }

  async function duplicarTurma() {
    if (selected) {
      setLoading(true); // Start loading immediately
      try {
        // Proceed with copying each selectedCheckboxFormandos
        const promises = selectedCheckboxesFormandos.map(async (element) => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/formandos`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: localStorage.getItem(token),
                },
                method: "POST",
                body: JSON.stringify({
                  nif: element.nif,
                  niss: element.niss,
                  documentType: element.documentType,
                  fullName: element.fullName,
                  gender: element.gender,
                  birthDate: element.birthDate,
                  nationality: element.nationality,
                  address: element.address,
                  postalCode: element.postalCode,
                  cellNumber: element.cellNumber,
                  email: element.email,
                  nameEmployer: element.nameEmployer,
                  academicDegree: element.academicDegree,
                  employmentSituation: element.employmentSituation,
                  employmentSituationDate: element.employmentSituationDate,
                  nifEmployer: element.nifEmployer,
                  CQEP: element.CQEP,
                  originCQEP: element.originCQEP,
                  district: element.district,
                  county: element.county,
                  origemInscricao: element.origemInscricao,
                  beneficiarySS: element.beneficiarySS,
                  job: element.job,
                  idNumber: element.idNumber,
                  idExpiration: element.idExpiration,
                  parish: element.parish,
                  locality: element.locality,
                  dataAuthorization: element.dataAuthorization,
                  dataAuthorization2: element.dataAuthorization2,
                  centroQualifica: element.centroQualifica,
                  percurso: selected,
                  cc: element.cc_path,
                  dce: element.dce_path,
                  rv: element.rv_path,
                  ch: element.ch_path,
                  csp: element.csp_path,
                  ibancomp: element.ibancomp_path,
                  cm: element.cm_path,
                  outros2: element.outros_path,
                  sendMail: false,
                }),
              }
            );

            if (!response.ok) {
              throw new Error(`Failed to copy data for ${element.fullName}`);
            }
          } catch (error) {
            console.error("Error copying individual formando:", error);
          }
        });

        await Promise.all(promises); // Wait for all requests to complete

        toastSuccess("Turma transferida com sucesso");
        setCont(cont + 1);
      } catch (error) {
        console.error("Error transferring turma:", error);
        toastError("Failed to transfer turma"); // Optional error toast
      } finally {
        setLoading(false); // End loading after all processes
      }
    }
  }

  async function getTurma(
    currentPage,
    itemsPerPage,
    searchTerm,
    inscritos,
    desistentes,
    analise,
    rejeitados
  ) {
    try {
      const query = new URLSearchParams({
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        searchTerm: searchTerm,
        valid: inscritos,
        desistentes: desistentes,
        candidatos: analise,
        rejeitados: rejeitados,
      });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/${params.id_curso}/getTurma?${query}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      const totalItems = result.totalItems;
      const totalPages = Math.ceil(totalItems / result.itemsPerPage);
      setNumPaginas(result.itemsPerPage);
      // If
      // Save the data that comes from the API and define the total of pages and the total of items
      if (paginaAtual > totalPages) {
        setPaginaAtual(totalPages > 0 ? totalPages : 1);
      } else {
        setNumPaginas(totalPages);
      }
      let inscricaoNormal = [];
      let inscricaoExtra = [];
      result.data.forEach((element) => {
        if (!element.extraNum) {
          inscricaoNormal.push(element);
        } else {
          inscricaoExtra.push(element);
        }
      });
      const sortedInscricaoNormal = inscricaoNormal.sort((a, b) => {
        return (
          new Date(a.dateInscricao).getTime() -
          new Date(b.dateInscricao).getTime()
        );
      });

      const sortedInscricaoExtra = inscricaoExtra.sort((a, b) => {
        return (
          new Date(a.dateInscricao).getTime() -
          new Date(b.dateInscricao).getTime()
        );
      });

      const combinedResult = [
        ...sortedInscricaoNormal,
        ...sortedInscricaoExtra,
      ];
      let candidatos = await Promise.all(
        combinedResult.map((element) => fetchCandidaturas(element, token))
      );
      setData(candidatos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError("Erro ao obter os dados, por favor tente mais tarde.");
    }
  }

  //Cancelar candidatura
  function cancelarCandidatura(_id, sendMail) {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}/cancelar`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      body: JSON.stringify({
        sendMail: sendMail === "true"
      }),
    }).then((result) => {
      result.json().then((resp) => {
        setCont(cont + 1);
      });
    });
  }
  //Recuperar formando que estava invalido
  function recuperarInscrito(_id, sendMail) {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/formandos/${_id}/recuperarFormando`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
      }
    ).then((result) => {
      result.json().then((resp) => {
        setLoading(false);
        setCont(cont + 1);
      });
    });
  }
  //Cancelar inscrição
  async function desistirInscrito() {
    setLoading(true);
    if (desistenciaFile && dataDesis) {
      let desistenciaResult = "";
      const desistenciaFilePath = desistenciaFile;
      const options = {
        method: "POST",
        headers: { Accept: "application/json" },
        credentials: "same-origin",
      };

      let desistenciaFileForm = new FormData();
      desistenciaFileForm.append("desistencia", desistenciaFilePath);
      options.body = desistenciaFileForm;

      try {
        const desistenciaResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/desistencia`,
          options
        );
        const desistenciaResult1 = await desistenciaResponse.json();
        desistenciaResult = desistenciaResult1;
        // Handle the desistenciaResult as needed here
      } catch (error) {
        console.error("Error while submitting desistencia:", error);
        // Handle errors here
      }

      const desistencia = {
        dataDesistencia: dataDesis,
        desistenciaFile: desistenciaResult,
        idCurso: params.id_curso,
      };

      await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/${idFormando}/desistir`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          body: JSON.stringify({
            desistencia: desistencia,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Network response was not ok.");
          }
        })
        .then((result) => {
          setLoading(false);
          toastSuccess("Desistencia efetuada com sucesso.");
          setModalIsOpen(false);
          setCont(cont + 1);
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    } else {
      setLoading(false);
      toastError("Preencha todos os campos.");
    }
  }
  //Obtem candidaturas
  const fetchCandidaturas = async (element, token) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/formandos/${element.nif}/nCandidaturas`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "GET",
      }
    );
    const result = await response.json();
    return { ...element, nCandidaturas: result };
  };
  //Obtem candidatos

  //Valida candidaturas
  async function validarCandidatura(_id, sendEmail) {
    try {
      // Ensure token and percurso are defined
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }
  
      if (!percurso || !percurso.ufcds || !Array.isArray(percurso.ufcds)) {
        console.error("Invalid percurso data.");
        return;
      }
  
      // Validate candidatura
      const validateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/${_id}/validar`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          body: JSON.stringify({
            sendMail: sendEmail === "true",
          }),
        }
      );
  
      if (!validateResponse.ok) {
        console.error("Error validating candidatura:", await validateResponse.text());
        return;
      }
  
      // Process each UFCD in percurso.ufcds
      for (const ufcd of percurso.ufcds) {
        try {
          const ufcdResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/percurso/formandos`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: localStorage.getItem(token),
              },
              body: JSON.stringify({
                formandos: _id,
                ufcdId: ufcd,
                sendMail: sendEmail === "true",
              }),
            }
          );
  
          if (ufcdResponse.ok) {
            const ufcdData = await ufcdResponse.json();
            setCont(cont + 1); // Update the counter if response is successful
          } else {
            console.error(
              `Error processing UFCD (ID: ${ufcd}):`,
              await ufcdResponse.text()
            );
          }
        } catch (error) {
          console.error(`Error in UFCD request for ID: ${ufcd}`, error);
        }
      }
    } catch (error) {
      console.error("Error in validarCandidatura:", error);
    }
  }
  //Recupera candidaturas
  function recuperarCandidatura(_id, sendMail) {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}/recuperar`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      body: JSON.stringify({
        sendMail: sendMail === true
      }),
    }).then((result) => {
      result.json().then((resp) => {
        setLoading(false);
        setCont(cont + 1);
      });
    });
  }
  //Rejeita candidaturas
  function rejeitarCandidatura(_id, motivo, sendMail) {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}/rejeitar`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      body: JSON.stringify({
        motivo: motivo,
      }),
    }).then((result) => {
      result.json().then((resp) => {});
    });
    setCont(cont + 1);
  }
  function alterarMotivoRejeicao(_id, motivo) {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}/motivo`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      body: JSON.stringify({
        motivo: motivo,
      }),
    }).then((result) => {
      result.json().then((resp) => {});
    });
    setCont(cont + 1);
  }
  async function removerCandidatura(_id) {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((result) => {
      result.json().then((resp) => {});
    });
    setCont(cont + 1);
  }

  //Obtem percurso
  function getPercurso() {
    fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        setPercurso(result);
        setLoading(false);
      });
  }
  //Obtem formandos validos
  function getFormandosValidos() {
    fetch(
      `${process.env.REACT_APP_API_URL}/formandos/${params.id_curso}/formandosValidados`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        let inscricaoNormal = [];
        let inscricaoExtra = [];
        result.forEach((element) => {
          if (!element.extraNum) {
            inscricaoNormal.push(element);
          } else inscricaoExtra.push(element);
        });
        const sortedInscricaoNormal = inscricaoNormal.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName);
        });
        const sortedInscricaoExtra =
          inscricaoExtra &&
          inscricaoExtra.sort((a, b) => {
            return (
              new Date(a.dateInscricao).getTime() -
              new Date(b.dateInscricao).getTime()
            );
          });

        const combinedResult = [
          ...sortedInscricaoNormal,
          ...sortedInscricaoExtra,
        ];
        setData2(combinedResult);
        setLoading(false);
      });
  }
  //Obtem percursos
  function getPercursos() {
    fetch(`${process.env.REACT_APP_API_URL}/percurso/getCursosDropdown`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      body: JSON.stringify({
        admin: token === "admin1" && admin,
        coord: token === "coord" && params.id_candidatura,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((result) => {
        setPercursoList(result);
        setCont(cont + 1);
      });
  }
  //comentar
  function comentar(id) {
    let comentario = document.getElementById("inputComentario").value;
    let comentarioCQ = document.getElementById("inputComentarioCQ").value;
    if (comentario !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/formandos/${id}/comentario`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "PATCH",
        body: JSON.stringify({
          id: id,
          comentario: comentario,
          comentarioCQ: comentarioCQ,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          setCont(cont + 1);
        });
      setModalComentarIsOpen({ open: false, comentario: "", id: "" });
      toastSuccess("Comentario Adicionado.");
    } else toastError("Comentario não pode estar em branco.");
  }
  function alterarRVCC(id, RVCC) {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${id}/RVCC`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      method: "PATCH",
      body: JSON.stringify({
        RVCC: RVCC,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setCont(cont + 1);
      });
  }
  function getUser() {
    if (token === "admin1") {
      fetch(
        `${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`,
        {
          headers: {
            authorization: localStorage.getItem("admin1"),
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setAdmin(result);
            setCont(cont + 1);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    } else if (token === "coord") {
      fetch(
        `${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}`,
        {
          headers: {
            authorization: localStorage.getItem("coord"),
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setAdmin(result);
            setCont(cont + 1);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    }
  }
  function alterarEncCQ(id, encCQ) {
    fetch(`${process.env.REACT_APP_API_URL}/formandos/${id}/encaminhamentoCQ`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      method: "PATCH",
      body: JSON.stringify({
        encCQ: encCQ,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setCont(cont + 1);
      });
  }
  //Transforma os dados excel em dados json(que o site pode ler)
  function convertExcelToJson(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        // Explicitly specify the character encoding as 'utf-8'
        const workbook = read(data, { type: "array", encoding: "utf-8" });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = utils.sheet_to_csv(worksheet);

        csvtojson()
          .fromString(csvData)
          .then((jsonData) => {
            resolve(jsonData);
          })
          .catch((error) => {
            reject(error);
          });
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });
  }
  //Alterar ficheiro
  const handleFileChangeLocal = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  //Confirma que todos os ficheiros estao preenchidos
  function areAllValuesNotEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== "") {
        return false; // Found a non-empty string, return false
      }
    }
    return true; // No non-empty strings found
  }
  function parseDate(dateString) {
    // Replace all occurrences of "/" with ","
    const formattedDateString = dateString.replace(/[\/-]/g, ",");
    // Split the formatted string into an array
    const dateArray = formattedDateString.split(",");

    // Create a Date object
    const parsedDate = new Date(
      `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
    );

    return parsedDate;
  }
  async function criarAlunos() {
    setLoading(true);
    let verif = 0;
    if (file) {
      convertExcelToJson(file)
        .then((data) => {
          let erro = [];
          data.forEach((element) => {
            let checkcc;
            if (element.NomeCompleto !== "") {
              let checkEmail = element.Email.includes("@" && ".");
              if (
                /* element.Genero !== "" && */ element.DataNascimento !== "" &&
                element.Ntelemovel !== "" &&
                element.Email !== "" &&
                element.Morada !== "" &&
                element.CodigoPostal !== "" &&
                element.TipoDeDocumento !== "" &&
                element.NumeroDoDocumento !== "" &&
                element.nif !== "" &&
                element.Concelho !== "" &&
                element.Distrito !== "" &&
                element.IniciativaInscrição !== "" &&
                /*  element.ProvemCentroQualifica !== "" &&  */ element.GrauAcademico !==
                  "" &&
                element.SituacaoLaboral !== "" &&
                element.AutorizacaoDados1 !== "" &&
                element.AutorizacaoDados2 !==
                  "" /*  && element.NaturalidadeDistrito !== "" && element.NaturalidadeConcelho !== "" */
              ) {
                const datenow = new Date(parseDate(element.DataNascimento));
                if (
                  datenow.getTime() < dateMax.getTime() &&
                  datenow.getTime() > datemin.getTime()
                ) {
                  if (
                    isNumber(element.Ntelemovel) &&
                    !(
                      element.Ntelemovel.length > 13 ||
                      element.Ntelemovel.length < 9 ||
                      element.Ntelemovel < 0
                    )
                  ) {
                    if (containsPostalCode(element.CodigoPostal)) {
                      const datenow = new Date(parseDate(element.ValidadeDoc));
                      if (
                        datenow.getTime() > dateToday.getTime() &&
                        datenow.getTime() < dateCcMax.getTime()
                      ) {
                        check_nif_number(element.nif);
                        var checkniss = false;
                        function check_niss_number() {
                          const LEN_NISS = 11;
                          const table = [29, 23, 19, 17, 13, 11, 7, 5, 3, 2];
                          function sumLists(a, b) {
                            return a
                              .map((n, i) => n * b[i])
                              .reduce((acc, val) => acc + val, 0);
                          }
                          // verificar tamanho do número passado
                          if (element.niss.length !== LEN_NISS) {
                            return false;
                          }
                          // verificar validade do carácter inicial do NISS
                          if (!["1", "2"].includes(element.niss[0])) {
                            return false;
                          }
                          // converter número para lista de inteiros
                          const nissList = element.niss.split("").map(Number);
                          // verificar soma de controlo
                          if (
                            nissList[LEN_NISS - 1] ===
                            9 - (sumLists(table, nissList.slice(0, -1)) % 10)
                          ) {
                            checkniss = true;
                          }
                        }
                        check_niss_number(element.niss);
                        /*  if ((ExtractWord(element.ProvemCentroQualifica) === "Sim" && element.NomeCentroQualifica !== "") || ExtractWord(element.ProvemCentroQualifica) === "Não") { */
                        if (
                          ExtractWord(element.SituacaoLaboral).includes(
                            "Desempregados à procura de novo emprego"
                          ) ||
                          ExtractWord(element.SituacaoLaboral) ===
                            "Desempregados à procura do 1º emprego" ||
                          ExtractWord(element.SituacaoLaboral) ===
                            "Inativos - A frequentarem ações de educação ou formação" ||
                          ExtractWord(element.SituacaoLaboral) ===
                            "Inativos - Outros"
                        ) {
                          if (
                            ExtractWord(element.SituacaoLaboral) ===
                              "Desempregados à procura do 1º emprego" ||
                            ExtractWord(element.SituacaoLaboral) !==
                              "Desempregados à procura do 1º emprego" /*  && element.DataDesemprego !== "" */ /* && element.BeneficiarioPrestacaoSocial !== "" */
                          ) {
                            /* element.DataDesemprego.replace("-", ',')
                                                         const datenow = new Date(element.DataDesemprego)
                                                          if (datenow.getTime() >= dateToday.getTime()) {
                                                             const errorMessage = `O formando ${element.NomeCompleto} tem a data de desemprego incorreta!`;
                                                             erro.push(errorMessage);
                                                             toastError(errorMessage);
                                                             setCont(prevCont => prevCont + 1);
                                                         } else { */

                            if (
                              ExtractWord(element.AutorizacaoDados1) === "Sim"
                            ) {
                              if (
                                ExtractWord(element.AutorizacaoDados2) ===
                                  "Sim" ||
                                (ExtractWord(element.AutorizacaoDados2) ===
                                  "Não" &&
                                  ExtractWord(
                                    element.AutorizacaoCentroQualifica
                                  ) === "Sim")
                              ) {
                                if (checkEmail === true) {
                                  fetch(
                                    `${process.env.REACT_APP_API_URL}/formandos`,
                                    {
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                      method: "POST",
                                      body: JSON.stringify({
                                        nif: element.nif,
                                        niss: element.niss,
                                        documentType: ExtractWord(
                                          element.TipoDeDocumento
                                        ),
                                        fullName: CapitalizarNomes(
                                          element.NomeCompleto
                                        ),
                                        gender:
                                          element.Genero &&
                                          ExtractWord(element.Genero),
                                        birthDate: element.DataNascimento,
                                        nationality: Nacoes(
                                          element.Nacionalidade
                                        ),
                                        district: element.Distrito,
                                        county: element.Concelho,
                                        address: element.Morada,
                                        postalCode: element.CodigoPostal,
                                        cellNumber: element.Ntelemovel,
                                        email: element.Email,
                                        nameEmployer:
                                          element.EntidadeEmpregadora,
                                        academicDegree: ExtractWord(
                                          element.GrauAcademico
                                        ),
                                        employmentSituation: ExtractWord(
                                          element.SituacaoLaboral
                                        ),
                                        employmentSituationDate:
                                          element.DataSituacaoLaboral,
                                        nifEmployer: element.nifEmpresa,
                                        origemInscricao:
                                          element.IniciativaInscrição,
                                        CQEP:
                                          element.ProvemCentroQualifica &&
                                          ExtractWord(
                                            element.ProvemCentroQualifica
                                          ),
                                        originCQEP: element.NomeCentroQualifica,
                                        beneficiarySS:
                                          element.BeneficiarioPrestacaoSocial &&
                                          ExtractWord(
                                            element.BeneficiarioPrestacaoSocial
                                          ),
                                        job: element.Profissao,
                                        idNumber: element.NumeroDoDocumento,
                                        idExpiration: element.ValidadeDoc,
                                        parish:
                                          element.NaturalidadeDistrito +
                                          " - " +
                                          element.NaturalidadeConcelho,
                                        locality: element.LocalidadeResidencia,
                                        dataAuthorization:
                                          ExtractWord(
                                            element.AutorizacaoDados1
                                          ) === "Sim"
                                            ? true
                                            : false,
                                        dataAuthorization2:
                                          ExtractWord(
                                            element.AutorizacaoDados2
                                          ) === "Sim"
                                            ? true
                                            : false,
                                        centroQualifica:
                                          element.centroQualifica &&
                                          ExtractWord(
                                            element.AutorizacaoCentroQualifica
                                          ) === "Sim"
                                            ? true
                                            : false,
                                        percurso: params.id_curso,
                                        percursoName: percurso.name,
                                        sendMail: sendMail === "true",
                                      }),
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      toastSuccess(
                                        `O formando ${element.NomeCompleto} foi criado com sucesso`
                                      );
                                      setCont(cont + 1);
                                      setModal(false);
                                      setSendMail("");
                                    });
                                } else {
                                  const errorMessage = `O formando ${element.NomeCompleto} tem email incorreto`;
                                  erro.push(errorMessage);
                                  toastError(errorMessage);
                                  setCont((prevCont) => prevCont + 1);
                                }
                              } else {
                                const errorMessage = `O formando ${element.NomeCompleto} para submeter tem que aceitar o CentroQualifica caso não tenha aceitado a Autorização2.`;
                                erro.push(errorMessage);
                                toastError(errorMessage);
                                setCont((prevCont) => prevCont + 1);
                              }
                            } else {
                              const errorMessage = `O formando ${element.NomeCompleto} não pode submeter sem a Autorização1.`;
                              erro.push(errorMessage);
                              toastError(errorMessage);
                              setCont((prevCont) => prevCont + 1);
                            }
                            /* } */
                          } else {
                            const errorMessage = `O formando ${element.NomeCompleto} não preencheu todos os campos necessários.`;
                            erro.push(errorMessage);
                            toastError(errorMessage);
                            setCont((prevCont) => prevCont + 1);
                          }
                        } else {
                          if (
                            element.Profissao !==
                            "" /*&&  element.nifEmpresa !== "" */
                          ) {
                            /*   check_nif_number(element.nifEmpresa) */
                            /*    if (checkniss === true && check_nif_number(element.nif)) { */
                            if (
                              ExtractWord(element.AutorizacaoDados1) === "Sim"
                            ) {
                              if (
                                ExtractWord(element.AutorizacaoDados2) ===
                                  "Sim" ||
                                (ExtractWord(element.AutorizacaoDados2) ===
                                  "Não" &&
                                  ExtractWord(
                                    element.AutorizacaoCentroQualifica
                                  ) === "Sim")
                              ) {
                                if (checkEmail === true) {
                                  fetch(
                                    `${process.env.REACT_APP_API_URL}/formandos`,
                                    {
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                      method: "POST",
                                      body: JSON.stringify({
                                        nif: element.nif,
                                        niss: element.niss,
                                        documentType: ExtractWord(
                                          element.TipoDeDocumento
                                        ),
                                        fullName: CapitalizarNomes(
                                          element.NomeCompleto
                                        ),
                                        gender:
                                          element.Genero &&
                                          ExtractWord(element.Genero),
                                        birthDate: element.DataNascimento,
                                        nationality: Nacoes(
                                          element.Nacionalidade
                                        ),
                                        address: element.Morada,
                                        postalCode: element.CodigoPostal,
                                        district: element.Distrito,
                                        county: element.Concelho,
                                        cellNumber: element.Ntelemovel,
                                        email: element.Email,
                                        academicDegree: ExtractWord(
                                          element.GrauAcademico
                                        ),
                                        employmentSituation: ExtractWord(
                                          element.SituacaoLaboral
                                        ),
                                        employmentSituationDate:
                                          element.DataSituacaoLaboral,
                                        nifEmployer: element.nifEmpresa,
                                        nameEmployer:
                                          element.EntidadeEmpregadora,
                                        origemInscricao:
                                          element.IniciativaInscrição,
                                        CQEP:
                                          element.ProvemCentroQualifica &&
                                          ExtractWord(
                                            element.ProvemCentroQualifica
                                          ),
                                        originCQEP: element.NomeCentroQualifica,
                                        beneficiarySS:
                                          element.BeneficiarioPrestacaoSocial &&
                                          ExtractWord(
                                            element.BeneficiarioPrestacaoSocial
                                          ),
                                        job: element.Profissao,
                                        idNumber: element.NumeroDoDocumento,
                                        idExpiration: element.ValidadeDoc,
                                        parish:
                                          element.NaturalidadeDistrito +
                                          " - " +
                                          element.NaturalidadeConcelho,
                                        locality: element.LocalidadeResidencia,
                                        dataAuthorization:
                                          ExtractWord(
                                            element.AutorizacaoDados1
                                          ) === "Sim"
                                            ? true
                                            : false,
                                        dataAuthorization2:
                                          ExtractWord(
                                            element.AutorizacaoDados2
                                          ) === "Sim"
                                            ? true
                                            : false,
                                        centroQualifica:
                                          element.centroQualifica &&
                                          ExtractWord(
                                            element.AutorizacaoCentroQualifica
                                          ) === "Sim"
                                            ? true
                                            : false,
                                        percurso: params.id_curso,
                                        sendMail: sendMail === "true",
                                      }),
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      toastSuccess(
                                        `O formando ${element.NomeCompleto} foi criado com sucesso`
                                      );
                                      setCont(cont + 1);
                                      setModal(false);
                                      setSendMail("");
                                    });
                                } else {
                                  const errorMessage = `O formando ${element.NomeCompleto} tem email incorreto`;
                                  erro.push(errorMessage);
                                  toastError(errorMessage);
                                  setCont((prevCont) => prevCont + 1);
                                }
                              } else {
                                const errorMessage = `O formando ${element.NomeCompleto} para submeter tem que aceitar o CentroQualifica caso não tenha aceitado a Autorização2.`;
                                erro.push(errorMessage);
                                toastError(errorMessage);
                                setCont((prevCont) => prevCont + 1);
                              }
                            } else {
                              const errorMessage = `O formando ${element.NomeCompleto} não pode submeter sem a Autorização1.`;
                              erro.push(errorMessage);
                              toastError(errorMessage);
                              setCont((prevCont) => prevCont + 1);
                            }
                            /*  } else {
                                                             const errorMessage = `O formando ${element.NomeCompleto} tem o número de NIF inválido.`;
                                                             erro.push(errorMessage);
                                                             toastError(errorMessage);
                                                             setCont(prevCont => prevCont + 1);
                                                         } */
                          } else {
                            const errorMessage = `O formando ${element.NomeCompleto} não preenchou a profissão e/ou Nif da empresa.`;
                            erro.push(errorMessage);
                            toastError(errorMessage);
                            setCont((prevCont) => prevCont + 1);
                          }
                        }
                        /*   } else {
                                                      const errorMessage = `O formando ${element.NomeCompleto} não preencheu o Nome do Centro Qualifica.`;
                                                      erro.push(errorMessage);
                                                      toastError(errorMessage);
                                                      setCont(prevCont => prevCont + 1);
                                                  } */
                        /*   } else {
                                                      const errorMessage = `O formando ${element.NomeCompleto} contém algum documento incorreto.`;
                                                      erro.push(errorMessage);
                                                      toastError(errorMessage);
                                                      setCont(prevCont => prevCont + 1);
                                                  } */
                      } else {
                        const errorMessage = `O formando ${element.NomeCompleto} contém a validade do documento inválida.`;
                        erro.push(errorMessage);
                        toastError(errorMessage);
                        setCont((prevCont) => prevCont + 1);
                      }
                    } else {
                      const errorMessage = `O formando ${element.NomeCompleto} contém código Postal incorreto.`;
                      erro.push(errorMessage);
                      toastError(errorMessage);
                      setCont((prevCont) => prevCont + 1);
                    }
                    /*  } else {
                                             const errorMessage = `O formando ${element.NomeCompleto} contém números em campos que apenas aceitam letras.`;
                                             erro.push(errorMessage);
                                             toastError(errorMessage);
                                             setCont(prevCont => prevCont + 1);
                                         } */
                  } else {
                    const errorMessage = `O formando ${element.NomeCompleto} contém o Nº de telemóvel incorreto.`;
                    erro.push(errorMessage);
                    toastError(errorMessage);
                    setCont((prevCont) => prevCont + 1);
                  }
                } else {
                  const errorMessage = `O formando ${element.NomeCompleto} contém a data incorreta.`;
                  erro.push(errorMessage);
                  toastError(errorMessage);
                  setCont((prevCont) => prevCont + 1);
                }
              } else {
                const errorMessage = `O formando ${element.NomeCompleto} não contém os dados necessários.`;
                erro.push(errorMessage);
                toastError(errorMessage);
                setCont((prevCont) => prevCont + 1);
              }

              setErrorLeitura(erro);
            }
            if (areAllValuesNotEmpty(element)) {
              verif++;
            }
          });
          if (verif === data.length) {
            toastError("Documento vazio.");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toastError("Documento inválido.");
        });
    }
  }
  //Passa para a ultima pagina
  function ultimaPagina() {
    if (paginaAtual !== nPaginas) {
      setPaginaAtual(nPaginas);
      updatePagination(dataToDisplay);
    }
  }
  //Passa para a 1a pagina
  function primeiraPagina() {
    if (paginaAtual !== 1) {
      setPaginaAtual(1);
      updatePagination(dataToDisplay);
    }
  }

  //Download do ficheiro excel
  function downloadFileExcel() {
    const fileUrl = process.env.PUBLIC_URL + "/CriarTurma.xlsx"; // Update the file path accordingly

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "ModeloCriarTurma.xlsx"; // Specify the desired filename for the download

    // Programmatically trigger the download
    link.click();
  }
  //Envia email personalizado
  function enviarMailPersonalizado() {
    let divErro = document.getElementById("erro");

    if (
      document.getElementById("assunto").value &&
      document.getElementById("corpo").value
    ) {
      let email = document.getElementById("email").value;
      let assunto = document.getElementById("assunto").value;
      let corpo = document.getElementById("corpo").value;

      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/formandos/sendEmail`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          assunto: assunto,
          corpo: corpo,
        }),
      })
        .then((res) => res.json())
        .then((result) => {});
      setModalMailIsOpen(false);
      toastSuccess("E-Mail enviado com sucesso.");
      setLoading(false);
    } else {
      divErro.innerHTML = "Preencha todos os campos do e-mail.";
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;

    return formattedDay + "-" + formattedMonth + "-" + year;
  }

  function selectAllFormandos(e, data) {
    // Selecionar todos os pontos do tópico 0
    let checkbox = document.getElementById("selectALL");
    let array0 = [];

    if (e.target.checked === true) {
      data.forEach((element) => {
        array0.push(element);
      });
      setSelectedCheckboxesFormandos(array0); /* 
            setDataFilterCandidatos(array0.filter((candidatos) => candidatos.valid === "Por Validar"))
            setDataFilterValidados(array0.filter((candidatos) => candidatos.valid === "Validado"))
 */
    } else {
      setSelectedCheckboxesFormandos([]);
      setDataFilterCandidatos([]);
      setDataFilterValidados([]);
      checkbox.checked = false;
    }
  }

  //Função que obtem box checked
  const selectFormandos = (e, formando) => {
    const { checked } = e.target;
    const formandoData = formando; // Assuming object data is in dataset attributes

    setSelectedCheckboxesFormandos((prevCheckboxes) => {
      const updatedCheckboxes = checked
        ? [...prevCheckboxes, formandoData] // Add entire formando object
        : prevCheckboxes.filter((item) => item._id !== formandoData._id); // Remove by unique _id

      // Now use updatedCheckboxes for the filtering
      const candidatos = updatedCheckboxes.filter(
        (candidato) => candidato.valid === "Por Validar"
      );
      setDataFilterCandidatos(candidatos);

      const validados = updatedCheckboxes.filter(
        (candidato) => candidato.valid === "Validado"
      );
      setDataFilterValidados(validados);

      return updatedCheckboxes;
    });
  };

  if (loading)
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
        <div className="loading"></div>
      </div>
    );

  return (
    <>
      <main
        className={
          token === "coord"
            ? "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10"
            : "min-h-screen"
        }
      >
        {token !== "coord" && (
          <div className="w-full">
            {token === "admin1" && <NavbarAdmin1 currentPage={type} />}
            {token === "admin2" && <NavbarAdmin2 currentPage={type} />}
          </div>
        )}

        {token === "coord" && (
          <div className="h-full sm:col-span-2 md:bg-secundary-color">
            <NavbarCoordenador currentPage={"turmas"} />
          </div>
        )}
        <div
          className={
            token === "coord"
              ? "relative h-full col-span-5 mx-4 xl:col-span-8"
              : "w-full"
          }
        >
          <button
            onClick={() => navigate(-1)}
            className={
              token !== "coord"
                ? "back-button-div-noNavbar"
                : "back-button-noNavbar"
            }
          >
            <img
              className="back-button-img"
              alt="Back"
              src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
            />
          </button>
          {token === "coord" && <Header sideMenu={"true"} />}
          <div className="flex-grow mt-8">
            <h1 className="mx-12 title">Turma - {percurso.name}</h1>
            {CheckTypeMarketing() === false &&
              (CheckTypeAdmin() === true || token === "admin2") && (
                <div className="flex flex-col items-center mt-5 ">
                  <h2 className="mb-1 subtitle">Submeter alunos por excel</h2>
                  <div className="flex items-center">
                    <h3 className="mr-1 text-text-main-color">
                      Utilize este Modelo:
                    </h3>
                    <button
                      className="p-1 text-2xl button-geral"
                      id="downloadButton"
                      onClick={downloadFileExcel}
                    >
                      <PiMicrosoftExcelLogoFill />
                    </button>
                  </div>
                  <div className="flex items-center">
                    <h3 className="mr-1 text-text-main-color">
                      Caso deseja inscrever alunos por excel, submeta aqui:
                    </h3>
                    <Modal
                      isOpen={modal}
                      className="fixed inset-0 flex items-center justify-center"
                    >
                      <div
                        className="absolute w-full h-full"
                        onClick={() => setModal(false)}
                      ></div>
                      <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                        <div>
                          <h1 className="subtitle">
                            Deseja enviar email informativo?
                          </h1>
                          <div className="flex justify-center">
                            <select
                              onWheel={(e) => e.target.blur()}
                              id="sendMail"
                              className="selector w-24 ml-1"
                              defaultValue={sendMail ? sendMail : ""}
                              onChange={(e) => setSendMail(e.target.value)}
                            >
                              <option value={""} disabled>
                                Selecione uma opção
                              </option>
                              <option value={"false"}>Não</option>
                              <option value={"true"}>Sim</option>
                            </select>
                          </div>
                        </div>
                        <button
                          className="absolute top-1.5 right-1.5"
                          onClick={() => {
                            setModal(false);
                            setSendMail("");
                          }}
                        >
                          <AiOutlineCloseCircle
                            size={24}
                            className="hover:text-text-error"
                          />
                        </button>
                        <button
                          className={
                            sendMail
                              ? "button-confirmar mt-3"
                              : "button-disabled mt-3"
                          }
                          onClick={sendMail && criarAlunos}
                        >
                          Confirmar
                        </button>
                      </div>
                    </Modal>
                    <button
                      className={`p-1 text-2xl button-geral ${
                        (file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file?.type === "application/vnd.ms-excel") && "bg-text-correct"
                      }`}
                      id="downloadButton"
                      onClick={() => {
                        document.getElementById("fileInput").click();
                      }}
                    >
                      <FaFileUpload />
                      <FileUploader
                      inputClassName={"hidden"}
                      inputId={"fileInput"}
                      inputType={"file"}
                      inputAccept={".xls,.xlsx"}
                      onChangeSpecial={handleFileChangeLocal}
                      hiddenFormatosPermitidos={true}
                      allowedTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]}
                      />
                    </button>
                    {errorLeitura.length > 0 && (
                      <>
                        {errorLeitura.map((item, index) => (
                          <div className="flex flex-col" key={index}>
                            {item}
                          </div>
                        ))}
                      </>
                    )}
                    {jsonData && <pre>{JSON.stringify(jsonData, null, 2)}</pre>}
                  </div>
                </div>
              )}
            {CheckTypeMarketing() === false &&
              (CheckTypeAdmin() === true || token === "admin2") && (
                <>
                  {file ? (
                    <button
                      className="mt-2 button-geral"
                      onClick={() => setModal(true)}
                    >
                      Submeter
                    </button>
                  ) : (
                    <button className="mt-2 button-disabled">Submeter</button>
                  )}
                </>
              )}
            {CheckTypeMarketing() === false &&
              (CheckTypeAdmin() === true || token === "admin2") && (
                <div className="mt-4">
                  <h1 className="subtitle">
                    Transferir formandos para outro curso
                  </h1>
                  <select
                    defaultValue={""}
                    onChange={(e) => setSelected(e.target.value)}
                    className="selector2 flex m-auto w-56"
                  >
                    <option value="" disabled>
                      Selecione um curso
                    </option>
                    {percursoList && percursoList.length > 0 ? (
                      percursoList.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name} - {item.locality} - Edição nº
                          {item.edicoes}
                        </option>
                      ))
                    ) : (
                      <option disabled>Sem opções</option>
                    )}
                  </select>
                  {selected ? (
                    <button
                      className="mt-2 button-geral"
                      onClick={() => duplicarTurma()}
                    >
                      Submeter
                    </button>
                  ) : (
                    <button className="mt-2 button-disabled">Submeter</button>
                  )}
                </div>
              )}
            <div className="grid grid-cols-1 gap-10 mt-3">
              <div>
                <h1 className="subtitle">Lista de Formandos</h1>
                <div className="search-div">
                  <label className="search-input-label">Pesquisar:</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg
                        className="w-4 h-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      placeholder="ex: Nome"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                  </div>
                </div>
                <div className="flex flex-col grid-col-2 justify-center my-2 gap-4 lg:flex-row">
                  <div className="flex items-center justify-center">
                    <h3 className="mr-1 text-text-main-color">
                      Mostrar Candidaturas em Análise
                    </h3>
                    <input
                      className="check-box"
                      type="checkbox"
                      name="validados"
                      id="validados"
                      defaultChecked={analise === "false" ? false : true}
                      onClick={(e) => {
                        setAnalise(e.target.checked.toString());
                        setCont(cont + 1);
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <h3 className="mr-1 text-text-main-color">
                      Mostrar Selecionados
                    </h3>
                    <input
                      className="check-box"
                      type="checkbox"
                      name="validados"
                      id="validados"
                      defaultChecked={inscritos === "false" ? false : true}
                      onClick={(e) => {
                        setInscritos(e.target.checked.toString());
                        setCont(cont + 1);
                      }}
                    />
                  </div>
                  <div className=" flex items-center justify-center">
                    <h3 className="mr-1 text-text-main-color">
                      Mostrar Rejeitados
                    </h3>
                    <input
                      className="check-box"
                      type="checkbox"
                      name="validados"
                      id="validados"
                      defaultChecked={rejeitados === "false" ? false : true}
                      onClick={(e) => {
                        setRejeitados(e.target.checked.toString());
                        setCont(cont + 1);
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <h3 className="mr-1 text-text-main-color">
                      Mostrar Desistentes
                    </h3>
                    <input
                      className="check-box"
                      type="checkbox"
                      name="validados"
                      id="validados"
                      defaultChecked={desistentes === "false" ? false : true}
                      onClick={(e) => {
                        setDesistentes(e.target.checked.toString());
                        setCont(cont + 1);
                      }}
                    />
                  </div>
                </div>
                {data && data.length > 0 ? (
                  <>
                    <div className="flex justify-center items-center mt-2 mb-1">
                      <label className="text-text-main-color">
                        Dados por página:{" "}
                      </label>
                      <select
                        onWheel={(e) => e.target.blur()}
                        className="selector w-14 ml-1"
                        value={itemsPag}
                        onChange={(e) =>
                          handleItemsPerPageChange(parseInt(e.target.value))
                        }
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </select>
                    </div>
                    <div className="table-container">
                      <table className="my-table">
                        <thead className="bg-main-color">
                          <tr className="text-white h-14">
                            <th className="p-2 min-w-[100px] border border-white">
                              Selecionar
                            </th>
                            <th className="p-2 min-w-[100px] border border-white">
                              Data de Inscrição
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Nº
                            </th>
                            <th className="p-2 min-w-[150px] border border-white">
                              Nome Completo
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Nº Candidaturas
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Localidade
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Habilitações
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Situação face ao Emprego
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Encaminhamento RVCC
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Encaminhamento CQ (FMC/EFA)
                            </th>
                            <th className="p-2 min-w-[80px] border border-white">
                              Validação da Documentação
                            </th>
                            {(CheckTypeAdmin() === true ||
                              token === "admin2") && (
                              <th className="p-2 min-w-[80px] border border-white">
                                Seleção
                              </th>
                            )}
                            <th className="p-2 min-w-[80px] border border-white">
                              Estado da Inscrição
                            </th>
                            {(CheckTypeAdmin() === true ||
                              token === "admin2") && (
                              <th className="p-2 min-w-[80px] border border-white">
                                Ações
                              </th>
                            )}
                          </tr>
                        </thead>

                        <tbody className="text-text-main-color">
                          {data.map((item, index) => (
                            <tr key={index}>
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td className="border border-white ">
                                  <input
                                    className="check-box"
                                    type="checkbox"
                                    value={item._id}
                                    onChange={(e) => selectFormandos(e, item)}
                                    checked={selectedCheckboxesFormandos.some(
                                      (list) => list._id === item._id
                                    )}
                                  />
                                </td>
                              ) : (
                                <td className="bg-red-300 border border-white">
                                  {" "}
                                  <input
                                    className="check-box"
                                    type="checkbox"
                                    value={item._id}
                                    onChange={(e) => selectFormandos(e, item)}
                                    checked={selectedCheckboxesFormandos.some(
                                      (list) => list._id === item._id
                                    )}
                                  />
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td className="border border-white ">
                                  {formatDate(item.createdAt)}
                                </td>
                              ) : (
                                <td className="bg-red-300 border border-white">
                                  {formatDate(item.createdAt)}
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td className="border border-white ">
                                  {index + 1 + itemsPag * (paginaAtual - 1)}
                                </td>
                              ) : (
                                <td className="bg-red-300 border border-white">
                                  {index + 1 + itemsPag * (paginaAtual - 1)}
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td
                                  onClick={() => navigate(route + item._id)}
                                  className="border border-white row-click"
                                >
                                  {item.fullName}
                                </td>
                              ) : (
                                <td
                                  onClick={() => navigate(route + item._id)}
                                  className="bg-red-300 border border-white hover:backdrop-brightness-50 row-click"
                                >
                                  {item.fullName}
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td
                                  onClick={() =>
                                    navigate(route + item._id + "/candidaturas")
                                  }
                                  className="border border-white row-click"
                                >
                                  {item.nCandidaturas}
                                </td>
                              ) : (
                                <td
                                  onClick={() =>
                                    navigate(route + item._id + "/candidaturas")
                                  }
                                  className="bg-red-300 border border-white row-click"
                                >
                                  {item.nCandidaturas}
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td
                                  onClick={() => navigate(route + item._id)}
                                  className="border border-white row-click"
                                >
                                  {item.locality}
                                </td>
                              ) : (
                                <td
                                  onClick={() => navigate(route + item._id)}
                                  className="bg-red-300 border border-white hover:backdrop-brightness-50 row-click"
                                >
                                  {item.locality}
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td className="border border-white ">
                                  {item.academicDegree}
                                </td>
                              ) : (
                                <td className="bg-red-300 border border-white">
                                  {item.academicDegree}
                                </td>
                              )}
                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td className="border border-white ">
                                  {item.employmentSituation}
                                </td>
                              ) : (
                                <td className="bg-red-300 border border-white">
                                  {item.employmentSituation}
                                </td>
                              )}
                              {CheckTypeTORVC() === true ||
                              token === "coord" ? (
                                <td
                                  className={
                                    item.valid === "Por Validar" ||
                                    item.valid === "Validado"
                                      ? "border border-white items-center"
                                      : "bg-red-300 border border-white"
                                  }
                                >
                                  <>
                                    {item.RVCC === "Validado" ? (
                                      <div className="flex gap-2 justify-center">
                                        <FaCircleCheck
                                          className=" text-green-600"
                                          size={20}
                                        />
                                        <button
                                          className="tooltip"
                                          onClick={() =>
                                            alterarRVCC(item._id, "")
                                          }
                                          data-tip={"Refazer"}
                                        >
                                          <FaUndo className="text-yellow-600" />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {item.RVCC === "Invalidado" ? (
                                          <div className="flex gap-2 justify-center">
                                            <FaCircleXmark
                                              className=" text-red-600"
                                              size={20}
                                            />
                                            <button
                                              className="tooltip"
                                              onClick={() =>
                                                alterarRVCC(item._id, "")
                                              }
                                              data-tip={"Refazer"}
                                            >
                                              <FaUndo className="text-yellow-600" />
                                            </button>
                                          </div>
                                        ) : (
                                          <>
                                            <button
                                              className="tooltip"
                                              onClick={() =>
                                                alterarRVCC(
                                                  item._id,
                                                  "Validado"
                                                )
                                              }
                                              data-tip={"Validar"}
                                            >
                                              <FaCheck className="text-green-600 mx-1" />
                                            </button>
                                            <button
                                              className="tooltip"
                                              onClick={() =>
                                                alterarRVCC(
                                                  item._id,
                                                  "Invalidado"
                                                )
                                              }
                                              data-tip={"Rejeitar"}
                                            >
                                              <FaX className="text-red-600 mx-1" />
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                </td>
                              ) : (
                                <td
                                  className={
                                    item.valid === "Por Validar" ||
                                    item.valid === "Validado"
                                      ? "border border-white items-center"
                                      : "bg-red-300 border border-white"
                                  }
                                >
                                  {item.RVCC === "Validado" ? (
                                    <FaCircleCheck
                                      className="m-auto text-green-600"
                                      size={20}
                                    />
                                  ) : (
                                    <>
                                      {item.RVCC === "Invalidado" ? (
                                        <FaCircleXmark
                                          className="m-auto text-red-600 "
                                          size={20}
                                        />
                                      ) : (
                                        <FaQuestionCircle
                                          className="m-auto text-text-main-color"
                                          size={20}
                                        />
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                              {CheckTypeTORVC() === true ||
                              token === "coord" ? (
                                <td
                                  className={
                                    item.valid === "Por Validar" ||
                                    item.valid === "Validado"
                                      ? "border border-white items-center"
                                      : "bg-red-300 border border-white"
                                  }
                                >
                                  {item.encCQ === "Validado" ? (
                                    <div className="flex gap-2 justify-center">
                                      <FaCircleCheck
                                        className="text-green-600"
                                        size={20}
                                      />
                                      <button
                                        className="tooltip"
                                        onClick={() =>
                                          alterarEncCQ(item._id, "")
                                        }
                                        data-tip={"Refazer"}
                                      >
                                        <FaUndo className="text-yellow-600" />
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      {item.encCQ === "Invalidado" ? (
                                        <div className="flex gap-2 justify-center">
                                          <FaCircleXmark
                                            className="text-red-600"
                                            size={20}
                                          />
                                          <button
                                            className="tooltip"
                                            onClick={() =>
                                              alterarEncCQ(item._id, "")
                                            }
                                            data-tip={"Refazer"}
                                          >
                                            <FaUndo className="text-yellow-600" />
                                          </button>
                                        </div>
                                      ) : (
                                        <>
                                          <button
                                            className="tooltip"
                                            onClick={() =>
                                              alterarEncCQ(item._id, "Validado")
                                            }
                                            data-tip={"Validar"}
                                          >
                                            <FaCheck className="text-green-600 mx-1" />
                                          </button>
                                          <button
                                            className="tooltip"
                                            onClick={() =>
                                              alterarEncCQ(
                                                item._id,
                                                "Invalidado"
                                              )
                                            }
                                            data-tip={"Rejeitar"}
                                          >
                                            <FaX className="text-red-600 mx-1" />
                                          </button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              ) : (
                                <td
                                  className={
                                    item.valid === "Por Validar" ||
                                    item.valid === "Validado"
                                      ? "border border-white items-center"
                                      : "bg-red-300 border border-white"
                                  }
                                >
                                  {item.encCQ === "Validado" ? (
                                    <FaCircleCheck
                                      className="m-auto text-green-600"
                                      size={20}
                                    />
                                  ) : (
                                    <>
                                      {item.encCQ === "Invalidado" ? (
                                        <FaCircleXmark
                                          className="m-auto text-red-600"
                                          size={20}
                                        />
                                      ) : (
                                        <FaQuestionCircle
                                          className="m-auto text-text-main-color"
                                          size={20}
                                        />
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                              <td
                                className={
                                  item.valid === "Por Validar" ||
                                  item.valid === "Validado"
                                    ? "border border-white"
                                    : "bg-red-300 border border-white"
                                }
                              >
                                {item.aprovDocs[0] ? (
                                  item.aprovDocs[0].cc !== "" &&
                                  item.aprovDocs[0].ch !== "" &&
                                  item.aprovDocs[0].csp !== "" &&
                                  item.aprovDocs[0].cm !== "" &&
                                  ((percurso.modalidade ===
                                    "Cursos de Educação e Formação de Adultos" &&
                                    item.aprovDocs[0].iban !== "") ||
                                    percurso.modalidade !==
                                      "Cursos de Educação e Formação de Adultos") ? (
                                    item.aprovDocs[0].cc === "true" &&
                                    item.aprovDocs[0].ch === "true" &&
                                    item.aprovDocs[0].csp === "true" &&
                                    item.aprovDocs[0].cm === "true" &&
                                    ((percurso.modalidade ===
                                      "Cursos de Educação e Formação de Adultos" &&
                                      item.aprovDocs[0].iban === "true") ||
                                      percurso.modalidade !==
                                        "Cursos de Educação e Formação de Adultos") ? (
                                      <FaCircleCheck
                                        className="m-auto text-green-600"
                                        size={20}
                                      />
                                    ) : (
                                      <FaCircleXmark
                                        className="m-auto text-red-600"
                                        size={20}
                                      />
                                    )
                                  ) : item.cc_path &&
                                    item.cm_path &&
                                    item.ch_path &&
                                    item.csp_path &&
                                    item.ibancomp_path ? (
                                    <FaQuestionCircle
                                      className="m-auto text-yellow-600"
                                      size={20}
                                    />
                                  ) : (
                                    <FaQuestionCircle
                                      className="m-auto text-text-main-color"
                                      size={20}
                                    />
                                  )
                                ) : item.cc_path &&
                                  item.cm_path &&
                                  item.ch_path &&
                                  item.csp_path &&
                                  item.ibancomp_path ? (
                                  <FaQuestionCircle
                                    className="m-auto text-yellow-600"
                                    size={20}
                                  />
                                ) : (
                                  <FaQuestionCircle
                                    className="m-auto text-text-main-color"
                                    size={20}
                                  />
                                )}
                              </td>
                              {(CheckTypeAdmin() === true ||
                                token === "admin2") && (
                                <>
                                  {item.valid === "Por Validar" ? (
                                    <td className="w-40 items-center text-xl border border-white">
                                      <div className="m-auto">
                                        {CheckTypeMarketing() === false && (
                                          <ConfirmInscricao
                                            css={
                                              "text-text-correct hover:brightness-75 tooltip"
                                            }
                                            icon={<FaUserCheck size={20} />}
                                            title={"Aceitar"}
                                            message={
                                              "Deseja aceitar a candidatura ? (Antes de aceitar verifique o perfil do formando, se a aceitar, este ficará ligado às disciplinas deste curso.)"
                                            }
                                            confirm={validarCandidatura}
                                            id={item._id}
                                            type={"aceitar"}
                                            token={token}
                                            tooltip={"Aceitar Candidatura"}
                                          ></ConfirmInscricao>
                                        )}
                                        {CheckTypeMarketing() === false && (
                                          <ConfirmToasterReason
                                            css={
                                              "text-text-error ml-1 hover:brightness-75 tooltip"
                                            }
                                            icon={<FaUserTimes size={20} />}
                                            title={"Rejeitar"}
                                            message={
                                              "Deseja rejeitar a candidatura ? (Se a rejeitar perderá o acesso à esta.)"
                                            }
                                            confirm={rejeitarCandidatura}
                                            id={item._id}
                                            tooltip={"Rejeitar Candidatura"}
                                            type={"rejeitar"}
                                          />
                                        )}
                                        <button
                                          className="ml-1 text-main-color"
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content={
                                            (CheckTypeAdmin() === false &&
                                              item.comentario) ||
                                            ((CheckTypeAdmin() === true ||
                                              token === "admin2") &&
                                              item.comentarioCQ)
                                              ? "Comentário"
                                              : "Comentar"
                                          }
                                          data-tooltip-place="top"
                                          onClick={() => {
                                            setModalComentarIsOpen({
                                              open: true,
                                              comentario: item.comentario
                                                ? item.comentario
                                                : "",
                                              id: item._id,
                                            });
                                          }}
                                        >
                                          {item.comentario ||
                                          item.comentarioCQ ? (
                                            <BiSolidCommentEdit />
                                          ) : (
                                            <BiCommentEdit />
                                          )}
                                        </button>
                                        {CheckTypeMarketing() === false && (
                                          <ConfirmToaster
                                            css={
                                              "text-text-error hover:brightness-75 tooltip"
                                            }
                                            icon={<MdDeleteForever size={20} />}
                                            title={"Remover"}
                                            message={
                                              "Deseja remover a candidatura ? (Se a remover esta não é recuperável.)"
                                            }
                                            confirm={removerCandidatura}
                                            id={item._id}
                                            type={"remover"}
                                            tooltip={"Remover Candidatura"}
                                          ></ConfirmToaster>
                                        )}
                                      </div>
                                    </td>
                                  ) : (
                                    <>
                                      {item.valid === "Validado" ? (
                                        <td className="items-center text-xl w-40">
                                          <button
                                            className="ml-1 text-main-color m-auto"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={
                                              (CheckTypeAdmin() === false &&
                                                item.comentario) ||
                                              ((CheckTypeAdmin() === true ||
                                                token === "admin2") &&
                                                item.comentarioCQ)
                                                ? "Comentário"
                                                : "Comentar"
                                            }
                                            data-tooltip-place="top"
                                            onClick={() => {
                                              setModalComentarIsOpen({
                                                open: true,
                                                comentario: item.comentario
                                                  ? item.comentario
                                                  : "",
                                                id: item._id,
                                              });
                                            }}
                                          >
                                            {item.comentario ? (
                                              <BiSolidCommentEdit />
                                            ) : (
                                              <BiCommentEdit />
                                            )}
                                          </button>
                                        </td>
                                      ) : (
                                        <td className="items-center text-xl bg-red-300 w-40">
                                          <div className="m-auto">
                                            {CheckTypeMarketing() === false && (
                                              <>
                                                {item.valid === "Excluido" && (
                                                  <ConfirmToaster
                                                    css={
                                                      "text-text-correct hover:brightness-75 tooltip"
                                                    }
                                                    icon={
                                                      <BsArrowCounterclockwise />
                                                    }
                                                    title={"Recuperar"}
                                                    message={
                                                      "Deseja recuperar a candidatura ? "
                                                    }
                                                    confirm={
                                                      recuperarCandidatura
                                                    }
                                                    id={item._id}
                                                    type={"recuperar"}
                                                    tooltip={
                                                      "Recuperar Candidatura"
                                                    }
                                                  ></ConfirmToaster>
                                                )}
                                              </>
                                            )}
                                            <button
                                              className="ml-1 text-main-color"
                                              data-tooltip-id="tooltip"
                                              data-tooltip-content={
                                                (CheckTypeAdmin() === false &&
                                                  item.comentario) ||
                                                ((CheckTypeAdmin() === true ||
                                                  token === "admin2") &&
                                                  item.comentarioCQ)
                                                  ? "Comentário"
                                                  : "Comentar"
                                              }
                                              data-tooltip-place="top"
                                              onClick={() => {
                                                setModalComentarIsOpen({
                                                  open: true,
                                                  comentario: item.comentario
                                                    ? item.comentario
                                                    : "",
                                                  id: item._id,
                                                });
                                              }}
                                            >
                                              {item.comentario ? (
                                                <BiSolidCommentEdit />
                                              ) : (
                                                <BiCommentEdit />
                                              )}
                                            </button>
                                            {CheckTypeMarketing() === false && (
                                              <ConfirmToaster
                                                css={
                                                  "text-text-error hover:brightness-75 tooltip"
                                                }
                                                icon={
                                                  <MdDeleteForever size={20} />
                                                }
                                                title={"Remover"}
                                                message={
                                                  "Deseja remover a candidatura ? (Se a remover esta não é recuperável.)"
                                                }
                                                confirm={removerCandidatura}
                                                id={item._id}
                                                type={"remover"}
                                                tooltip={"Remover Candidatura"}
                                              ></ConfirmToaster>
                                            )}
                                          </div>
                                        </td>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {item.valid === "Por Validar" ||
                              item.valid === "Validado" ? (
                                <td className="border border-white w-40">
                                  {item.valid === "Validado"
                                    ? "Selecionado"
                                    : "Em Análise"}
                                </td>
                              ) : (
                                <td
                                  className="bg-red-300 border border-white w-40 h-full tooltip table-cell"
                                  data-tip={
                                    item.motivo && "Motivo: " + item.motivo
                                  }
                                >
                                  {item.valid}
                                  {CheckTypeMarketing() === false && (
                                    <ConfirmToasterReason
                                      css={
                                        "text-yellow-600 ml-1 hover:brightness-75 tooltip"
                                      }
                                      icon={<BiSolidCommentEdit size={20} />}
                                      title={"Alterar Motivo"}
                                      message={
                                        "Deseja alterar o motivo da rejeição da candidatura?"
                                      }
                                      motivoInicial={item.motivo}
                                      confirm={alterarMotivoRejeicao}
                                      id={item._id}
                                      type={"motivo"}
                                    />
                                  )}
                                </td>
                              )}

                              {(CheckTypeAdmin() === true ||
                                token === "admin2") && (
                                <>
                                  {item.valid === "Validado" ? (
                                    <td className="items-center w-40 text-xl border border-white">
                                      <div className="m-auto">
                                        {CheckTypeMarketing() === false && (
                                          <ConfirmToaster
                                            css={
                                              "text-text-error hover:brightness-75 tooltip"
                                            }
                                            icon={<BsFillArrowLeftCircleFill />}
                                            title={"Anular"}
                                            message={
                                              "Deseja anular a Inscrição? (Se a anular, ela voltará para a lista em análise.)"
                                            }
                                            confirm={cancelarCandidatura}
                                            id={item._id}
                                            type={"anular"}
                                            tooltip={"Anular Inscrição"}
                                          ></ConfirmToaster>
                                        )}
                                        {CheckTypeMarketing() === false && (
                                          <button
                                            className="ml-1 text-text-error"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content="Desistir"
                                            data-tooltip-place="top"
                                            onClick={() => {
                                              setModalIsOpen(true);
                                              setIdFormando(item._id);
                                            }}
                                          >
                                            <FaUserSlash />
                                          </button>
                                        )}
                                        {CheckTypeMarketing() === false && (
                                          <ModalEnvioContrato
                                            tipo={"formando"}
                                            data={item}
                                            token={token}
                                          />
                                        )}
                                        {CheckTypeMarketing() === false && (
                                          <button
                                            className="ml-1 text-main-color"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={
                                              "Enviar E-Mail Personalizado"
                                            }
                                            data-tooltip-place="top"
                                            onClick={() => {
                                              setModalMailIsOpen({
                                                open: true,
                                                email: item.email,
                                              });
                                            }}
                                          >
                                            <MdEmail />
                                          </button>
                                        )}
                                        <button
                                          className="ml-1 text-main-color"
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content={
                                            (CheckTypeAdmin() === false &&
                                              item.comentario) ||
                                            ((CheckTypeAdmin() === true ||
                                              token === "admin2") &&
                                              item.comentarioCQ)
                                              ? "Comentário"
                                              : "Comentar"
                                          }
                                          data-tooltip-place="top"
                                          onClick={() => {
                                            setModalComentarIsOpen({
                                              open: true,
                                              comentario: item.comentario
                                                ? item.comentario
                                                : "",
                                              id: item._id,
                                            });
                                          }}
                                        >
                                          {item.comentario ? (
                                            <BiSolidCommentEdit />
                                          ) : (
                                            <BiCommentEdit />
                                          )}
                                        </button>
                                      </div>
                                      <Modal
                                        isOpen={modalIsOpen}
                                        className="fixed inset-0 flex items-center justify-center"
                                      >
                                        <div
                                          className="absolute w-full h-full"
                                          onClick={handleOpen}
                                        ></div>
                                        <div className="relative flex flex-col items-center px-10 shadow-2xl bg-secundary-color py-7 rounded-2xl">
                                          <div>
                                            <h1 className="title">
                                              Dados do Formulário
                                            </h1>
                                          </div>
                                          <div>
                                            <p className="p-1 font-bold text-text-second-color">
                                              Data de Desistência:
                                            </p>
                                            <input
                                              className="input-field"
                                              type="date"
                                              defaultValue={dataDesis}
                                              onChange={(e) =>
                                                setDataDesis(e.target.value)
                                              }
                                            />
                                            <div className="flex">
                                              <p className="p-1 font-bold text-text-second-color">
                                                Download do Template de
                                                Desistência:
                                              </p>
                                              <button
                                                type="button"
                                                className="button-geral"
                                                onClick={async () =>
                                                  await gerarFolhaDesistencia()
                                                }
                                              >
                                                <FiDownload />
                                              </button>
                                            </div>
                                            <p className="p-1 font-bold text-text-second-color">
                                              Upload da Folha de Desistência:
                                            </p>
                                            <FileUploader
                                            inputClassName={"input-file"}
                                            inputType={"file"}
                                            inputAccept={".pdf"}
                                            onChangeSpecial={(e) => setDesistenciaFile(e.target.files[0])}
                                            allowedTypes={["application/pdf"]}
                                            />
                                            <div className="mt-3">
                                              <p className="font-bold third-title">
                                                Legenda:
                                              </p>
                                              <div className="max-w-sm text-text-main-color">
                                                É necessário a data para o
                                                download do template e para o
                                                upload da folha de desistência
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex flex-row justify-center w-full mt-5">
                                            <button
                                              className="button-confirmar"
                                              onClick={() => desistirInscrito()}
                                            >
                                              Confirmar
                                            </button>
                                            <button
                                              className="absolute top-1.5 right-1.5"
                                              onClick={handleOpen}
                                            >
                                              <AiOutlineCloseCircle
                                                size={24}
                                                className="hover:text-text-error"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </Modal>
                                    </td>
                                  ) : CheckTypeMarketing() === false ? (
                                    <td className="text-xl w-40 bg-red-300 border border-white">
                                      {item.valid === "Por Validar" ||
                                      item.valid === "Excluido" ? (
                                        <div className="text-base">N.A</div>
                                      ) : (
                                        <ConfirmToaster
                                          css={
                                            "text-text-correct flex items-center justify-center w-full hover:brightness-75 tooltip"
                                          }
                                          icon={<BsArrowCounterclockwise />}
                                          title={"Recuperar Inscrição"}
                                          message={
                                            "Deseja recuperar o Formando ? "
                                          }
                                          confirm={recuperarInscrito}
                                          id={item._id}
                                          type={"recuperarInscrição"}
                                          tooltip={"Recuperar Inscrição"}
                                        ></ConfirmToaster>
                                      )}
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="flex items-center justify-center mt-4 mb-8">
                      <button
                        className={
                          paginaAtual > 1
                            ? "button-geral mr-1"
                            : "button-disabled mr-1"
                        }
                        onClick={() => primeiraPagina()}
                      >
                        <AiOutlineDoubleLeft />
                      </button>
                      <button
                        className={
                          paginaAtual > 1
                            ? "button-geral mr-1"
                            : "button-disabled mr-1"
                        }
                        onClick={() => decreasePagination()}
                      >
                        <AiOutlineLeft />
                      </button>
                      <p className="text-main-color">
                        Página {paginaAtual} - {nPaginas}
                      </p>
                      <button
                        className={
                          paginaAtual < nPaginas
                            ? "button-geral ml-1"
                            : "button-disabled ml-1"
                        }
                        onClick={() => increasePagination()}
                      >
                        <AiOutlineRight />
                      </button>
                      <button
                        className={
                          paginaAtual < nPaginas
                            ? "button-geral ml-1"
                            : "button-disabled ml-1"
                        }
                        onClick={() => ultimaPagina()}
                      >
                        <AiOutlineDoubleRight />
                      </button>
                    </div>
                  </>
                ) : (
                  <p className="third-title">Não existem resultados</p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1 justify-center items-center mb-8">
              {data && data.length > 0 ? (
                <div>
                  <CSVGeneratorFormandos data={data2}></CSVGeneratorFormandos>
                </div>
              ) : (
                <button className="my-4 button-disabled w-72">
                  Exportar Turma para Moodle
                </button>
              )}
              {data2 && data2.length > 0 ? (
                <button
                  className="button-geral w-72"
                  onClick={() => ExcelFormandos(data2)}
                >
                  Exportar Template Execução Física
                </button>
              ) : (
                <button className="button-disabled  w-72">
                  Exportar Template Execução Física
                </button>
              )}
              {dataFilter && dataFilter.length > 0 ? (
                <button
                  className="mb-4 button-geral  w-72"
                  onClick={() => ExcelFormandosSimples(dataFilter)}
                >
                  Exportar Turma
                </button>
              ) : (
                <button className="mb-4 button-disabled  w-72">
                  Exportar Turma
                </button>
              )}
              {selectedCheckboxesFormandos &&
              selectedCheckboxesFormandos.length > 0 &&
              dataFilterCandidatos.length > 0 ? (
                <>
                  {CheckTypeMarketing() === false && (
                    <ConfirmInscricao
                      css={"mb-4 button-geral  w-72"}
                      icon={
                        <>
                          Aceitar Candidaturas Selecionadas (
                          {dataFilterCandidatos.length})
                        </>
                      }
                      title={"Aceitar Candidaturas"}
                      message={
                        "Deseja aceitar as candidaturas ? (Antes de aceitar verifique o perfil do formando, se a aceitar, este ficará ligado às disciplinas deste curso.)"
                      }
                      confirm={validarCandidatura}
                      data={dataFilterCandidatos}
                      type={"aceitar"}
                      token={token}
                    ></ConfirmInscricao>
                  )}
                </>
              ) : (
                <button className="mb-4 button-disabled  w-72">
                  Aceitar Candidaturas Selecionadas
                </button>
              )}
              {/*  {selectedCheckboxesFormandos && selectedCheckboxesFormandos.length > 0 && dataFilterCandidatos.length > 0 ?
                                <>{CheckTypeMarketing() === false && <ConfirmToasterReason css={"mb-4 button-geral  w-72"} icon={<>Rejeitar Candidaturas Selecionadas ({dataFilterCandidatos.length})</>} title={"Rejeitar Candidaturas"} message={"Deseja rejeitar as candidaturas ? (Se a rejeitar perderá o acesso à esta.)"} confirm={rejeitarCandidatura} data={dataFilterCandidatos} type={"rejeitar"} token={token} />}</> : <>
                                    <button className="mb-4 button-disabled  w-72" >Rejeitar Candidaturas Selecionadas</button></>} */}
              {selectedCheckboxesFormandos &&
              selectedCheckboxesFormandos.length > 0 &&
              dataFilterValidados.length > 0 ? (
                <>
                  {" "}
                  <ConfirmToaster
                    css={"mb-4 button-geral  w-72"}
                    icon={
                      <>Recurperar Inscrição ({dataFilterValidados.length})</>
                    }
                    title={"Recuperar Inscrição"}
                    message={"Deseja recuperar os Formandos? "}
                    confirm={recuperarInscrito}
                    type={"recuperarInscrição"}
                    data={dataFilterValidados}
                  ></ConfirmToaster>
                </>
              ) : (
                <button className="mb-4 button-disabled  w-72">
                  Rejeitar Candidaturas Selecionadas
                </button>
              )}
            </div>
         {/*    <button className="mb-4 button-geral  w-72" onClick={limparAdmins}>
              Limpar Admins
            </button>
            <button className="mb-4 button-geral  w-72" onClick={limparEventos}>
              Limpar Eventos
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparCoordenadores}
            >
              Limpar Coordenadores
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparEntFinanciadoras}
            >
              Limpar EntFinanciadoras
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparEntFormadoras}
            >
              Limpar EntFormadoras
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparFormadores}
            >
              Limpar Formadores
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparFormandos}
            >
              Limpar Formandos
            </button>
            <button className="mb-4 button-geral  w-72" onClick={limparModulos}>
              Limpar Modulos
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparOperacoes}
            >
              Limpar Operações
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparPercursos}
            >
              Limpar Percursos
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparTemplates}
            >
              Limpar Templates
            </button>
            <button className="mb-4 button-geral  w-72" onClick={limparTestes}>
              Limpar Testes
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparDisciplinas}
            >
              Limpar Ufcds
            </button>
            <button
              className="mb-4 button-geral  w-72"
              onClick={limparUfcdsGlobals}
            >
              Limpar UfcdsGlobals
            </button> */}
          </div>
        </div>
      </main>
      <Footer />
      <Modal
        isOpen={modalComentarIsOpen.open}
        className="flex items-center justify-center min-h-screen"
      >
        <div
          className="absolute w-full h-full"
          onClick={handleOpenComentar}
        ></div>
        <div className="flex flex-col items-center px-10 overflow-auto shadow-2xl bg-secundary-color py-7 rounded-2xl min-w-[70%] relative">
          <h1 className="title">Comentar</h1>
          <div className="flex flex-col w-full px-10">
            <h2>Coordenador:</h2>
            <textarea
              disabled={CheckTypeAdmin() === false && true}
              className="w-full mt-3 input-field min-h-8"
              placeholder={"Comentário do Coordenador"}
              defaultValue={modalComentarIsOpen.comentario}
              id="inputComentario"
              type="text"
              rows={10}
            />
          </div>
          <div id="erro" className="text-center text-text-error"></div>
          <div className="flex flex-col w-full px-10">
            <h2>Centro Qualifica:</h2>
            <textarea
              disabled={
                (CheckTypeAdmin() === true || token === "admin2") &&
                CheckTypeMarketing() === false &&
                true
              }
              className="w-full mt-3 input-field min-h-8"
              placeholder={"Comentário do Centro Qualifica"}
              defaultValue={modalComentarIsOpen.comentarioCQ}
              id="inputComentarioCQ"
              type="text"
              rows={10}
            />
          </div>
          <div id="erro" className="text-center text-text-error"></div>
          <button
            className="mt-4 button-confirmar"
            onClick={() => comentar(modalComentarIsOpen.id)}
          >
            Enviar
          </button>

          <button
            className="absolute top-1.5 right-1.5"
            onClick={() =>
              setModalComentarIsOpen({
                open: false,
                comentario: "",
                comentarioCQ: "",
                id: "",
              })
            }
          >
            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalMailIsOpen.open}
        className="flex items-center justify-center min-h-screen"
      >
        <div className="absolute w-full h-full" onClick={handleOpenMail}></div>
        <div className="flex flex-col items-center px-10 overflow-auto shadow-2xl bg-secundary-color py-7 rounded-2xl min-w-[70%] relative">
          <h1 className="title">Enviar E-Mail</h1>

          <div className="flex flex-col w-full px-10">
            <input
              className="w-full input-field-disabled"
              id="email"
              type="text"
              disabled
              value={modalMailIsOpen.email}
            />
            <input
              className="w-full mt-3 input-field"
              type="text"
              id="assunto"
              placeholder="Assunto"
            />
            <textarea
              className="w-full mt-3 input-field min-h-8"
              id="corpo"
              type="text"
              rows={10}
              placeholder="Corpo do E-Mail"
            />
          </div>
          <div id="erro" className="text-center text-text-error"></div>
          <button
            className="mt-4 button-confirmar"
            onClick={enviarMailPersonalizado}
          >
            Enviar
          </button>
          <button
            className="absolute top-1.5 right-1.5"
            onClick={() => setModalMailIsOpen({ open: false, email: "" })}
          >
            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
          </button>
        </div>
      </Modal>
      <Tooltip id="my-tooltip" />
      <Tooltip id="tooltip" />
    </>
  );
}

export default Candidaturas;
