import React, { useEffect, useState } from "react";
import { StyleFormandoGreenSkillsJobs } from "../Modal/stylesContratos/styled-contrato-formando-green-skills-jobs";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import SepararData from "../FormChecks/formatDates/splitDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormandoEDigital({ data, token, setOpen }) {
  // const to handle the date
  const { dia, mes, ano } = SepararData(data.percurso[0].dateBegin);


  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadingEnvio, setLoadingEnvio] = React.useState(false);
  const [loadingDownload, setLoadingDownload] = React.useState(false);

  const [valores, setValores] = useState({
    numeroContrato: data?.dadosContrato?.numeroContrato ?? "",
    //cargoRepresentanteLegal: data?.dadosContrato?.cargoRepresentanteLegal ?? "",
  });

  // Function to send the contract by email
  async function enviarContratoFormando() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormandoNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = React.useState({
    inputNumeroContrato: valores?.numeroContrato ?? "",
    //inputCargoRepresentanteLegal: valores?.cargoRepresentanteLegal ?? "",
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = React.useState({
    inputNumeroContrato: false,
    //inputCargoRepresentanteLegal: false,
  });

  useEffect(() => {
    valores.dia = dia;
    valores.mes = mes;
    valores.ano = ano;
  }, [valores, dia, mes, ano]);

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {
      if (key !== "inputNumeroContrato" && inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Function to adjust the width of the input field
  const adjustWidth = (e) => {
    e.target.style.width = `${e.target.value.length + 1}ch`;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <StyleFormandoGreenSkillsJobs>
            <div className="body">
              <p>
                <h2>
                  <b>
                    CONTRATO DE FORMAÇÃO Nº{" "}
                    <input
                      className="input-field-contract-number"
                      type="text"
                      name="inputNumeroContrato"
                      defaultValue={valores.numeroContrato}
                      placeholder="0000"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          numeroContrato: e.target.value,
                        });
                        handleInputChangeLocal(e);
                      }}
                      style={{
                        border: inputErrors.inputNumeroContrato
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />
                  </b>
                </h2>
              </p>
              <br />
              <br />
              <br />
              <p>
                Entre {data.percurso[0].entFormadora[0].name}, com o número de
                Pessoa Coletiva {data.percurso[0].entFormadora[0].nif},
                legalmente representado por{" "}
                {data.percurso[0].entFormadora[0].representanteLegal} na
                qualidade de{" "}
                {data.percurso[0].entFormadora[0].cargoRepresentanteLegal ??
                  "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputCargoRepresentanteLegal"
                  defaultValue={valores.cargoRepresentanteLegal}
                  placeholder="Cargo"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      cargoRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    border: inputErrors.inputCargoRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                    width: `${
                      valores.cargoRepresentanteLegal.length > 0
                        ? valores.cargoRepresentanteLegal.length + 2
                        : 10
                    }ch`,
                  }}
                /> */}
                , com poderes para o ato, adiante designada por entidade ou
                primeiro outorgante e {data.fullName}, portador do documento de
                identificação n.º {data.idNumber}, válido até{" "}
                {AlterarFormato(data.idExpiration)}, residente em {data.address}
                , concelho de {data.county}, distrito de {data.district},
                adiante designado por formando ou segundo outorgante, é
                celebrado o presente Contrato de Formação, o qual se rege pelas
                seguintes cláusulas:
              </p>
              <br />
              <br />
              <br />
              <p>
                <h2>
                  <b>Cláusula 1.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Objeto do contrato</b>
                </h2>
              </p>
              <br />
              <p>
                1. O primeiro outorgante compromete-se a proporcionar ao segundo
                outorgante, a ação ou o percurso de formação profissional de
                acordo com as seguintes UFCD/UC do CNQ e/ou módulos de formação
                extra-CNQ:
              </p>
              <table className="ufcds">
                <th>
                  <b>
                    Código e denominação da UFCD ou módulo de formação extra-CNQ
                  </b>
                </th>
                <th>
                  <b>Nível</b>
                </th>
                <th>
                  <b>Carga horária (h)</b>
                </th>
                <th>
                  <b>Regime de formação (presencial, misto ou a distância)</b>
                </th>
                <tbody>
                  {data.ufcds.map((ufcd) => (
                    <tr key={ufcd._id}>
                      <td>
                        {ufcd.codeUfcd} - {ufcd.name}
                      </td>
                      <td>{data.percurso[0].nivel}</td>
                      <td>{ufcd.cargaHoraria}</td>
                      <td>{ufcd.formato}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <p>
                2. Esta ação/este percurso de formação profissional
                desenvolve-se no âmbito da Medida “Formação Emprego + Digital”,
                de acordo com a legislação e demais documentos normativos em
                vigor.
              </p>
              <br />
              <p>
                3. Nos termos do n.º 3 do art.º 4.º do Decreto-Lei n.º 242/88,
                de 7 de julho, o presente contrato não gera nem titula relações
                de trabalho subordinado e caduca com a conclusão da ação de
                formação para que foi celebrado.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 2.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Local, duração e horário</b>
                </h2>
              </p>
              <br />
              <p>
                1. O processo formativo é assegurado pelo primeiro outorgante
                decorrendo a formação nas instalações localizadas em{" "}
                {data.percurso[0].address}, {data.percurso[0].locality}, ou
                noutras por ele indicadas, podendo também decorrer em regime,
                para além do regime presencial, em regime misto ou a distância.
              </p>
              <br />
              <p>
                2. A formação tem a duração de {data.percurso[0].duration}{" "}
                horas, com início em{" "}
                {AlterarFormato(data.percurso[0].dateBegin)}, terminando em{" "}
                {AlterarFormato(data.percurso[0].dateEnd)} e decorre de acordo
                com os horários que vierem a ser fixados pelo primeiro
                outorgante.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 3.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Direitos do formando</b>
                </h2>
              </p>
              <br />
              <p>
                1. O segundo outorgante terá direito a exigir do primeiro
                outorgante o cumprimento dos deveres previstos na cláusula 5.ª
                do presente contrato.
              </p>
              <br />
              <p>2. O segundo outorgante tem direito a:</p>
              <br />
              <span className="alinea">
                <p>
                  a) Receber a formação com base nas UFCD/UC do CNQ e/ou módulos
                  de formação extra-CNQ, nas metodologias e processos de
                  trabalho, tendo em conta o regime de formação adotado
                  (presencial e/ou misto);
                </p>
                <p>
                  b) Beneficiar dos apoios sociais que lhe possam ser
                  concedidos, de acordo com a legislação e demais documentos
                  normativos em vigor para este Medida, nos termos do documento
                  em anexo, que é parte integrante deste contrato;
                </p>
                <p>
                  c) Beneficiar de um seguro contra acidentes ocorridos durante
                  e por causa das atividades de formação, sendo o seguro de
                  acidentes de trabalho da entidade empregadora ou seguro de
                  acidentes pessoais específico desta ação/deste percurso de
                  formação profissional, consoante a formação se desenvolva
                  dentro ou fora do horário normal de trabalho do formando,
                  independentemente do regime de formação;
                </p>
                <p>
                  d) Obter gratuitamente, no final da ação/do percurso de
                  formação profissional um Certificado de Qualificações e/ou de
                  Formação Profissional e ver registadas no Passaporte Qualifica
                  as respetivas competências adquiridas e certificadas, nos
                  termos da legislação e demais documentos normativos
                  aplicáveis;
                </p>
                <p>
                  e) Receber informação, orientação profissional e apoio social
                  no decurso da ação/do percurso de formação profissional;
                </p>
                <p>
                  f) Recusar a realização de actividades que não se insiram no
                  objeto da ação/do percurso de formação profissional.
                </p>
              </span>
              <br />
              <p>
                3. Para além dos direitos referidos nos números anteriores, o
                segundo outorgante beneficia do disposto no Regulamento do
                Formando em vigor no início da formação.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 4.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Deveres do formando</b>
                </h2>
              </p>
              <br />
              <p>São deveres do segundo outorgante:</p>
              <br />
              <span className="alinea">
                <p>
                  a) Frequentar com assiduidade e pontualidade a ação de
                  formação, visando adquirir os conhecimentos teóricos e
                  práticos que lhe forem ministrados, em respeito do Regulamento
                  Interno em vigor;
                </p>
                <p>
                  b) Tratar com urbanidade o primeiro outorgante, seus
                  representantes, trabalhadores e colaboradores;
                </p>
                <p>
                  c) Guardar lealdade ao primeiro outorgante, e seus
                  representantes, nomeadamente não transmitindo para o exterior
                  informações sobre o equipamento e processos de fabrico de que
                  tome conhecimento por ocasião da ação de formação;
                </p>
                <p>
                  d) Utilizar com cuidado e zelar pela boa conservação dos
                  equipamentos e demais bens que lhe sejam confiados para
                  efeitos de formação;
                </p>
                <p>
                  e) Suportar os custos de substituição ou reparação dos
                  equipamentos e materiais que utilizar no período de formação,
                  fornecidos pelo primeiro outorgante e seus representantes,
                  sempre que os danos produzidos resultem de comportamento
                  doloso ou gravemente negligente;
                </p>
                <p>
                  f) Cumprir os demais deveres emergentes do contrato de
                  formação;
                </p>
                <p>
                  g) Conhecer e cumprir as normas e procedimentos instituídos no
                  Regulamento do Formando, em vigor à data de início da
                  formação.
                </p>
                <p>
                  h) Inscrever-se na Academia Portugal Digital{" "}
                  <a
                    href="https://academiaportugaldigital.pt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (https://academiaportugaldigital.pt/)
                  </a>{" "}
                  e realizar diagnóstico e a avaliação de competências digitais
                  no início e no final da ação/do percurso de formação
                  profissional, respetivamente.
                </p>
              </span>
              <br />
              <p>
                <h2>
                  <b>Cláusula 5.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Deveres da entidade</b>
                </h2>
              </p>
              <br />
              <p>São deveres do primeiro outorgante:</p>
              <br />
              <span className="alinea">
                <p>
                  a) Assegurar a formação programada com respeito pela
                  legislação e regulamentação em vigor, pelas condições de
                  aprovação da ação de formação e pelo regime de concessão dos
                  apoios a que o formando tenha direito;
                </p>
                <p>
                  b) Facultar ao formando o acesso aos benefícios e equipamentos
                  sociais que sejam ~ compatíveis com a ação/percurso de
                  formação profissional frequentada/o;
                </p>
                <p>
                  c) Respeitar e fazer respeitar as condições de higiene e
                  segurança no trabalho;
                </p>
                <p>
                  d) Não exigir ao formando tarefas não compreendidas no objeto
                  da ação/do percurso de formação profissional;
                </p>
                <p>e) Cumprir os termos do presente contrato;</p>
                <p>
                  f) Disponibilizar o Regulamento do Formando em vigor, à data
                  de início da formação;
                </p>
                <p>
                  g) Celebrar um contrato de seguro de acidentes pessoais
                  ocorridos durante e por causa das atividades da formação, se
                  esta decorrer fora do horário normal de trabalho do formando;
                </p>
                <p>
                  h) Passar gratuitamente ao formando, no final da ação/do
                  percurso de formação profissional, um Certificado de
                  Qualificações e/ou de Formação Profissional, nos termos da
                  legislação e demais documentos normativos aplicáveis.
                </p>
              </span>
              <br />
              <p>
                <h2>
                  <b>Cláusula 6.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Faltas</b>
                </h2>
              </p>
              <br />
              <p>
                Às faltas aplica-se o disposto no Regulamento do Formando em
                vigor à data de início da formação, bem como o definido no
                Regulamento Específico da Medida.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 7.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Alterações supervenientes</b>
                </h2>
              </p>
              <br />
              <p>
                1. Quando, por razões alheias à sua vontade e a si não
                imputáveis, o primeiro outorgante não puder cumprir
                integralmente o plano de formação e ou o término definido no n.º
                2 da cláusula 2.ª, poderá proceder aos necessários ajustamentos,
                devendo sempre comunicar por escrito tal facto ao formando.
              </p>
              <br />
              <p>
                2. As alterações mencionadas no ponto anterior, pelos motivos
                referidos, não conferem ao formando direito a qualquer
                indemnização.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 8.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Cessação do contrato</b>
                </h2>
              </p>
              <br />
              <p>
                1. O contrato pode cessar por revogação, por rescisão de uma das
                partes ou por caducidade.
              </p>
              <br />
              <p>
                2. A rescisão por justa causa, por qualquer das partes, tem que
                ser comunicada à outra, por documento escrito ou carta
                registada, devendo dela constar o(s) respectivo(s) motivo(s).
              </p>
              <br />
              <p>
                3. O contrato de formação caduca quando se verificar a
                impossibilidade superveniente, absoluta e definitiva, do segundo
                outorgante frequentar a ação de formação ou de o primeiro
                outorgante lha proporcionar.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 9.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Financiamento</b>
                </h2>
              </p>
              <br />
              <p>
                Esta ação de formação é financiada pelo PRR, no âmbito do
                INVESTIMENTO TD-C16-I01 – EMPRESAS 4.0: CAPACITAÇÃO DIGITAL DAS
                EMPRESAS Medida 02 – “Emprego + Digital 2025”, assumindo o IEFP,
                I.P. a qualidade de beneficiário final nos termos da Orientação
                Técnica N.º 02/C16-i01/2022.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 10.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Proteção de dados pessoais - RGPD</b>
                </h2>
              </p>
              <br />
              <p>
                1. No âmbito da formação objeto do presente contrato, e em
                cumprimento do Regulamento Geral sobre Proteção de Dados (RGPD),
                o Segundo Outorgante autoriza que os seus dados, fornecidos ao
                Primeiro Outorgante, sejam também utilizados para partilha, nos
                casos aplicáveis, em suporte digital e/ou físico, com:
              </p>
              <span className="alinea">
                <p>
                  a) Estruturas oficiais responsáveis pelo financiamento da
                  ação/do percurso de formação profissional objeto deste
                  contrato;
                </p>
                <p>
                  b) Entidades com competência no tratamento de eventuais
                  reclamações exaradas pelo Segundo Outorgante e/ou por
                  terceiros.
                </p>
              </span>
              <p>
                2. No caso da ação de formação se realizar em regime à distância
                ou misto, atendendo às particularidades de cada ação, o primeiro
                outorgante poderá, designadamente, proceder a:
              </p>
              <span className="alinea">
                <p>
                  a) Gravação de atividades e interações desenvolvidas nas
                  plataformas à distância, como evidência da assiduidade de cada
                  formando e de participação para efeitos de avaliação da ação
                  de formação;
                </p>
                <p>
                  b) Utilização de áreas de trabalho contributivas para partilha
                  de conteúdos formativos (ex.: OneDrive, Sharepoint);
                </p>
                <p>
                  c) Utilização de sistemas de videoconferência e de partilha de
                  ficheiros de conteúdos formativos (ex.: Moodle e Microsoft
                  Teams);
                </p>
                <p>
                  d) Utilização de sistemas de messaging e de partilha de
                  ficheiros de conteúdos formativos (ex.: Microsoft Teams).
                </p>
              </span>
              <p>
                3. O primeiro outorgante garante a não transmissibilidade dos
                dados pessoais sensíveis, a máxima confidencialidade e a sua
                conservação pelo período de tempo necessário às finalidades de
                interesse público, nos termos previstos na Portaria n.º
                182/2020, de 4 de agosto (Portaria de Conservação Arquivística),
                assegurando que os dados pessoais recolhidos são acessíveis
                apenas a pessoas devidamente autorizadas e exclusivamente para
                cumprimento das finalidades de interesse público referidas nesta
                cláusula.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula 11.ª</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>Legislação aplicável</b>
                </h2>
              </p>
              <br />
              <p>
                Ao presente contrato, em tudo o que for omisso, aplicar-se-á o
                disposto no Decreto-Lei n.º 242/88, de 7 de julho e demais
                legislação complementar.
              </p>
              <br />
              <br />
              <br />
              <br />
              <p>
                O presente contrato é feito em duplicado e assinado por ambos os
                outorgantes, destinando-se o original, ao primeiro outorgante e
                a cópia ao segundo outorgante.
              </p>
              <br />
              <br />
              <br />
              <p>
                {data.percurso[0].locality},{" "}
                {AlterarFormato(data.percurso[0].dateBegin)}
              </p>
              <br />
              <br />
              <div
                className="page-break-after"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>O PRIMEIRO OUTORGANTE</h4>
                  <div
                    style={{
                      minHeight: "130px",
                      borderBottom: "1px solid black",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ margin: "5px auto" }}
                      id="img"
                      className="img"
                      alt="assinatura"
                      src={data.percurso[0].entFormadora[0].assinatura}
                      height="70"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>O SEGUNDO OUTORGANTE</h4>
                  <div
                    style={{
                      minHeight: "130px",
                      borderBottom: "1px solid black",
                    }}
                  ></div>
                </div>
              </div>
              <br />
              <h2>
                {/* <span style={{ backgroundColor: "rgb(255, 235, 205)" }}>
                  Os contratos são assinados digitalmente, com assinatura
                  qualificada no caso do primeiro outorgante.
                </span> */}
              </h2>
              <br />
              <br />
              <br />
              <div>
                <h1>
                  <b>Anexo</b>
                </h1>
                <br />
                <br />
                <p>
                  Eu, {data.fullName}, declaro que tomei conhecimento que,
                  sempre que a formação decorra em horário pós-laboral e a
                  formação diária registar 3 ou mais horas de assiduidade,
                  deverei receber subsídio de alimentação, em montante
                  equivalente ao atribuído à Administração Pública, portanto
                  6€/dia.
                </p>
                <br />
                <p>
                  Eu, {data.fullName}, declaro que autorizo a{" "}
                  {data.percurso[0].entFormadora[0].name} a gravar imagens e som
                  nas sessões síncronas, assim como a tratar os meus dados
                  pessoais, nos termos do Regulamento Geral sobre a Proteção de
                  Dados (RGPD), no âmbito da minha participação na formação
                  indicada no presente contrato.
                </p>
                <br />
                <br />
                <p>
                  {dia} de {mes} de {ano}
                </p>
                <br />
                <br />
                <div className="flex justify-center flex-col items-center">
                  <h4 style={{ textAlign: "center" }}>O/a Formando/a</h4>
                  <br />
                  <br />
                  <br />
                  <div className="signatureLine"></div>
                </div>
              </div>
              <div className="flex justify-center w-full">
                  <ConfirmToaster
                  css={"mx-auto mt-4 button-geral"}
                  icon={"Enviar Contrato"}
                  title={"Enviar Contrato"}
                  message={
                    "Você confirma que o número do contrato está correto?"
                  }
                  confirm={enviarContratoFormando}
                />
                <ConfirmToaster
                  css={"mx-auto mt-4 button-geral"}
                  icon={"Download Contrato"}
                  title={"Download Contrato"}
                  message={
                    "Você confirma que o número do contrato está correto?"
                  }
                  confirm={enviarContratoFormandoNoEmail}
                />
              </div>
            </div>
          </StyleFormandoGreenSkillsJobs>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
