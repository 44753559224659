import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Header from "../../../components/Header";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import Footer from "../../../components/Footer";
import { BsPencilFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import Modal from 'react-modal';
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import NavbarCoordenador from "../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import Cookies from "js-cookie";
import { IoMdRemoveCircle } from "react-icons/io";
import containsPostalCode from "../../../components/FormChecks/containsPostalCode/containsPostalCode";
import isNumber from "../../../components/isNumber/isNumber";
import FileUploader from "../../../utils/validationFileUploader";
export default function Trainer() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(0);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [cont, setCont] = useState(0);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(false);
    const [numDoc, setNumDoc] = useState("")
    const [outrosDocs, setOutrosDocs] = useState([])
    const [alterarFiles, setAlterarFiles] = useState(0);// Se 1 = Alterar , Se 2 = adicionar  , Se 0 = nada
    const [alterar, setAlterar] = useState(false);
    const [verIntegracaoSistema, setVerIntegracaoSistema] = useState(false);
    const [irs, setIrs] = useState(null)
    const [iva, setIva] = useState(null)




    //Paginação tabelas
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenador-profile-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coordenadores-profile-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    document.data = data
    const dateToday = new Date(year, month, day)

    const params = useParams();


    useEffect(() => {
        Cookies.set("Coordenador-profile-Pag", paginaAtual);
        Cookies.set(`Coordenadores-profile-itm1`, itemsPag)
        getCoordenadores()
    }, [cont, paginaAtual, nPaginas, itemsPag]);

    /*Atribuir número de páginas à tabela 
    caso seja preciso alterar o número de registos por página aumentar o 10 para o número pretendido*/
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    //Dar update à paginação das tabelas
    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //Aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //Diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    const handleNameChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        if (!updatedOutrosDocs[index]) {
            updatedOutrosDocs[index] = {
                name: '',
                file: null
            };
        }
        updatedOutrosDocs[index].name = e.target.value;
        setOutrosDocs(updatedOutrosDocs);
    };

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    const handleFileChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        updatedOutrosDocs[index].file = e.target.files[0];
        setOutrosDocs(updatedOutrosDocs);
    };

    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }

    //Buscar o número de cursos que estão finalizados
    function getCoordenadores() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setIrs(result.irs);
                    setIva(result.iva)
                    paginacaoTabelas(result.percurso); // Dar update à paginação 
                    let contador = 0
                    result.percurso.forEach(cont => {
                        if (cont.fechoAdmin === false) {
                            contador++
                        }
                    });
                    setData2(contador);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    //Função para verificar se uma string só contém números


    function alterarConfirmar() {
        setAlterar(true)
    }

    //Função para alterar os dados do perfil
    function alterarPerfil() {
        let cellNumber = document.getElementById('cellNumber').value;
        if (data.tipo === false) {
            if (isNumber(cellNumber) !== "" && document.getElementById('email').value !== "" && document.getElementById('address').value !== "" && document.getElementById('locality').value !== "" && document.getElementById('postalCode').value !== "" && document.getElementById('county').value !== "" && document.getElementById('iva').value !== "" && document.getElementById('irs').value !== "" && document.getElementById("name").value !== "" && document.getElementById("nif").value != "") {
                if (!cellNumber.length > 13 || !cellNumber.length < 9 || !cellNumber < 0) {
                    let cont = 0
                    function verifyEmail() {
                        for (var i = 0; i < data2.length; i++) {
                            if (data2[i].email === document.getElementById('email').value) {
                                if (document.getElementById('email').defaultValue === document.getElementById('email').value);
                                else cont++;
                            }
                        }
                    }
                    verifyEmail()
                    // Verificar se o email tem "@" e o "."
                    let checkEmail = document.getElementById('email').value.includes("@" && ".")
                    if (checkEmail === true && cont === 0) {
                        if (document.getElementById('locality').value) {
                            if (containsPostalCode(document.getElementById('postalCode').value)) {
                                if (onlyString(document.getElementById('county').value)) {
                                    if (onlyString(document.getElementById('name').value)) {
                                        const dateCC = new Date(document.getElementById('ccExpiration').value)
                                        if (dateCC.getTime() > dateToday.getTime()) {
                                            if (check_nif_number(document.getElementById('nif').value)) {
                                                fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}/alterarPerfilCoordenador`,
                                                    {
                                                        body: JSON.stringify({
                                                            fullName: document.getElementById('name').value,
                                                            ccNumber: data.tipo === false && document.getElementById('ccNumber').value,
                                                            ccExpiration: data.tipo === false && document.getElementById('ccExpiration').value,
                                                            cellNumber: document.getElementById('cellNumber').value,
                                                            nationality: data.tipo === false && document.getElementById('nationality').value,
                                                            nif: document.getElementById('nif').value,
                                                            email: document.getElementById('email').value,
                                                            address: data.tipo === false && document.getElementById('address').value,
                                                            locality: data.tipo === false && document.getElementById('locality').value,
                                                            postalCode: data.tipo === false && document.getElementById('postalCode').value,
                                                            county: data.tipo === false && document.getElementById('county').value,
                                                            irs: data.tipo === false && document.getElementById('irs').value,
                                                            iva: data.tipo === false && document.getElementById('iva').value,
                                                        }),
                                                        method: "PATCH",
                                                        headers: {
                                                            'Accept': 'application/json',
                                                            'Content-Type': 'application/json',
                                                            'authorization': localStorage.getItem("coord")
                                                        }
                                                    }).then((result) => {
                                                        result.json().then((resp) => {
                                                            if (resp === "Error Email") {
                                                                toastError("Email já existente.")
                                                                setAlterar(false);
                                                            } else {
                                                                setAlterar(false);
                                                                toastSuccess('Dados alterados com sucesso.')
                                                                getCoordenadores();
                                                            }
                                                        })
                                                    })
                                            } else toastError("Número de nif inválido.")
                                        } else toastError("Data do CC caducada.")
                                    } else toastError("Nome Inválido")
                                } else toastError("Concelho Inválido")
                            } else toastError("Código Postal Inválido")
                        } else toastError("Localidade inválida")
                    } else toastError("Email Inválido ou Existente")
                } else toastError("Número Inválido")
            } else toastError("Dados não inseridos")
        } else {
            if (isNumber(cellNumber) !== "" && document.getElementById('email').value !== "" && document.getElementById("name").value !== "" && document.getElementById("nif").value != "") {
                if (!cellNumber.length > 13 || !cellNumber.length < 9 || !cellNumber < 0) {
                    let cont = 0
                    function verifyEmail() {
                        for (var i = 0; i < data2.length; i++) {
                            if (data2[i].email === document.getElementById('email').value) {
                                if (document.getElementById('email').defaultValue === document.getElementById('email').value);
                                else cont++;
                            }
                        }
                    }
                    verifyEmail()
                    // Verificar se o email tem "@" e o "."
                    let checkEmail = document.getElementById('email').value.includes("@" && ".")
                    if (checkEmail === true && cont === 0) {

                        if (onlyString(document.getElementById('name').value)) {
                            if (check_nif_number(document.getElementById('nif').value)) {
                                fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}/alterarPerfilCoordenador`,
                                    {
                                        body: JSON.stringify({
                                            fullName: document.getElementById('name').value,
                                            ccNumber: data.tipo === false && document.getElementById('ccNumber').value,
                                            ccExpiration: data.tipo === false && document.getElementById('ccExpiration').value,
                                            cellNumber: document.getElementById('cellNumber').value,
                                            nationality: data.tipo === false && document.getElementById('nationality').value,
                                            nif: document.getElementById('nif').value,
                                            emailAntigo: data.email,
                                            email: data.email !== document.getElementById('email').value && document.getElementById('email').value,
                                            address: data.tipo === false && document.getElementById('address').value,
                                            locality: data.tipo === false && document.getElementById('locality').value,
                                            postalCode: data.tipo === false && document.getElementById('postalCode').value,
                                            county: data.tipo === false && document.getElementById('county').value,
                                            irs: data.tipo === false && document.getElementById('irs').value,
                                            iva: data.tipo === false && document.getElementById('iva').value,
                                        }),
                                        method: "PATCH",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'authorization': localStorage.getItem("coord")
                                        }
                                    }).then((result) => {
                                        result.json().then((resp) => {
                                            if (resp === "Error Email") {
                                                toastError("Email já existente.")
                                                setAlterar(false);
                                            } else {
                                                setAlterar(false);
                                                toastSuccess('Dados alterados com sucesso.')
                                                getCoordenadores();
                                            }
                                        })
                                    })
                            } else toastError("Número de nif inválido.")
                        } else toastError("Nome Inválido")
                    } else toastError("Email Inválido ou Existente")
                } else toastError("Número Inválido")
            } else toastError("Dados não inseridos")
        }
    }


    //Alterar foto de perfil
    const handleFileUpload = async (event) => {
        setLoading(true);
        const selectedFile = event.target.files[0];
        let ccForm = new FormData();
        ccForm.append('logo', selectedFile);

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin",
            body: ccForm
        };

        try {
            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
            const logoResult = await logoResponse.json();
            await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}/photo`,
                {
                    body: JSON.stringify({
                        photo: logoResult,
                    }),
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem("coord")
                    }
                })

            setAlterar(false);
            toastSuccess('Foto adicionada com sucesso!');
            setCont(cont + 1)
        } catch (error) {
            console.error('Error uploading image:', error);
            setLoading(false);
        }
        await setLoading(false); // Set loading back to false after the upload is complete
    };

    async function eliminarOutroDocumento(index) {
        setLoading(true)
        await fetch(`${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}/deleteOtherDoc`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("user")
            },
            method: 'PATCH',
            body: JSON.stringify({
                index: index
            })
        })
            .then(res => res.json())
            .then((result) => {
                setLoading(false)
                toastSuccess("Ficheiro eliminado com sucesso")
                setCont(cont + 1)
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    //Permite o coordenador inserir ficheiros
    //Submete ficheiros
    async function submeterFicheiros() {
        setLoading(true)
        let form = document.getElementById("form")
        let formDataInfo = new FormData()

        const cc_path = document.getElementById("cc") && document.getElementById("cc").value ? document.getElementById("cc") : ""
        const ch_path = document.getElementById("ch") && document.getElementById("ch").value ? document.getElementById("ch") : ""
        const ccp_path = document.getElementById("ccp") && document.getElementById("ccp").value ? document.getElementById("ccp") : ""
        const cv_path = document.getElementById("cv") && document.getElementById("cv").value ? document.getElementById("cv") : ""

        function createOptions() {
            return {
                method: "POST",
                headers: { "Accept": "application/json" },
                credentials: "same-origin"
            };
        }

        const promises = [];

        if (cc_path) {
            const cc = form.cc.files[0];
            let ccForm = new FormData();
            ccForm.append('cc', cc);
            const ccOptions = createOptions(); // Create separate options object
            ccOptions.body = ccForm;
            const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, ccOptions);
            const ccResult = await ccResponse.json();
            formDataInfo.append('cc', ccResult);
            promises.push(ccResponse);
        }

        if (ch_path) {
            const ch = form.ch.files[0];
            let chForm = new FormData();
            chForm.append('ch', ch);
            const chOptions = createOptions(); // Create separate options object
            chOptions.body = chForm;
            const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, chOptions);
            const chResult = await chResponse.json();
            formDataInfo.append('ch', chResult);
            promises.push(chResponse);
        }

        if (ccp_path) {
            const ccp = form.ccp.files[0]
            let ccpForm = new FormData()
            ccpForm.append('ccp', ccp)
            const ccpOptions = createOptions(); // Create separate options object
            ccpOptions.body = ccpForm;
            const ccpcompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mccp`, ccpOptions)
            const ccpcompResult = await ccpcompResponse.json()
            formDataInfo.append('ccp', ccpcompResult)
            promises.push(ccpcompResponse);
        }

        //CV permite multiplos ficheiros
        if (cv_path) {
            const cvFiles = Array.from(cv_path.files);
            const cvResults = [];
            for (const cv of cvFiles) {
                if (cv !== undefined) {
                    let cvForm = new FormData();
                    cvForm.append(`cv`, cv);
                    const cvOptions = createOptions(); // Create separate options object
                    cvOptions.body = cvForm;
                    const cvResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcv`, cvOptions);
                    const cvResult = await cvResponse.json();
                    promises.push(cvResponse);
                    cvResults.push(cvResult);
                }
            }
            formDataInfo.append(`cv`, cvResults);
        }

        let arrayObject = [];
        for (let i = 0; i < numDoc; i++) {
            const outro = outrosDocs[i].file;
            if (outro) {
                let outroForm = new FormData();
                outroForm.append('outro', outro);
                const outroOptions = createOptions(); // Create separate options object
                outroOptions.body = outroForm;
                const outroResponse = await fetch(`${process.env.REACT_APP_API_URL}/outro`, outroOptions);
                const outroResult = await outroResponse.json();
                const object = {}
                object.name = outrosDocs[i].name;
                object.file = outroResult;
                arrayObject[i] = object;
            }
        }

        if (arrayObject.length === 1) {
            formDataInfo.append("outros", JSON.stringify(arrayObject));
        } else formDataInfo.append("outros", JSON.stringify(arrayObject));

        try {
            const responses = await Promise.all(promises);

            // Check if all promises were successful
            if (responses.every(response => response.ok)) {
                // All requests succeeded
                // Process the responses and append data to formDataInfo

                // Then send the PATCH request
                const patchResponse = await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${data._id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem("coord")
                    },
                    method: 'PATCH',
                    body: formDataToJson(formDataInfo)
                });

                if (patchResponse.ok) {
                    toastSuccess("Ficheiros submetidos com sucesso!");
                    setAlterarFiles(0);
                    setLoading(false);
                    setAlterar(false);
                    setCont(cont + 1);
                } else {
                    toastError("Erro ao submeter os ficheiros");
                    setLoading(false);
                }
            } else {
                // At least one request failed
                toastError("Erro ao submeter os ficheiros");
                setLoading(false);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            toastError("Erro ao submeter os ficheiros");
            setLoading(false);
        }
    }

    async function eliminarCV(value) {
        setLoading(true)
        await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}/deleteCV`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("coord")
            },
            method: 'PATCH',
            body: JSON.stringify({
                value: value
            })
        })
            .then(res => res.json())
            .then((result) => {
                setLoading(false)
                toastSuccess("Ficheiro eliminado com sucesso")
                setCont(cont + 1)
            })
    }

    async function eliminarOutroDocumento(index) {
        setLoading(true)
        await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}/deleteOtherDoc`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("coord")
            },
            method: 'PATCH',
            body: JSON.stringify({
                index: index
            })
        })
            .then(res => res.json())
            .then((result) => {
                setLoading(false)
                toastSuccess("Ficheiro eliminado com sucesso")
                setCont(cont + 1)
            })
    }

    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"perfil"} />
                </div>

                <div className="h-full col-span-5 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <div className="mx-10 mt-8">
                        <div className="items-center sm:flex lg:ml-24">
                            <div className="relative transition cursor-pointer group hover:opacity-80" onClick={() => { document.getElementById('fileInput').click(); }}>
                                <img alt="profile" className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === '' ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" />
                                <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                    <BsPencilFill className="text-black" />
                                </div>
                                <FileUploader
                                inputId={"fileInput"}
                                inputType={"file"}
                                inputAccept={".png, .jpeg, .jpg"}
                                inputStyle={{ display: 'none' }}
                                onChangeSpecial={handleFileUpload}
                                allowedTypes={["image/png", "image/jpeg", "image/jpg"]}
                                />
                            </div>

                            <div className='mx-auto mt-2 sm:ml-16 '>
                                {alterar === true ?
                                    <input id="name" name="name" className="w-4/5 input-field" placeholder={data.fullName} defaultValue={data.fullName} required /> :
                                    <h1 className="text-2xl font-bold text-text-main-color">{data.fullName}</h1>
                                }
                                <div className="justify-around mt-2 font-medium sm:flex text-text-second-color">
                                    <div className="px-1">
                                        <p>Percursos em atividade</p>
                                        {data2 && data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos finalizados</p>
                                        {data && data.percurso.length - data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos total</p>
                                        {data && data.percurso.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-6">
                            <hr />
                        </div>
                        <div className="mb-5 lg:ml-24">
                            <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                            <div className="my-1 mb-2 text-center sm:text-left">
                                {data.fullName && data.cellNumber && data.email && data.nif && data.cc_path && data.cv_path && data.ccp_path && data.ch_path ? <div className="text-green-700">Perfil preenchido </div> : <div className="text-red-700">Dados do perfil por preencher</div>}
                                <div className="grid mt-4 sm:grid-cols-2">
                                    <div>
                                        {data.tipo === false && <>
                                            <div className="text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Número do documento de identificação:</div>
                                                <div className="mt-2 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="ccNumber" name="email" className="w-4/5 input-field" placeholder={data.ccNumber} defaultValue={data.ccNumber} required></input> : data.ccNumber}
                                                </div>
                                            </div>

                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Data de validade do documento de identificação:</div>
                                                <div className="mt-2 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="ccExpiration" name="ccExpiration" className="w-4/5 input-field" type="date" placeholder={data.ccExpiration} defaultValue={data.ccExpiration} required></input> : data.ccExpiration}
                                                </div>
                                            </div>
                                        </>}
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                            <div className="mt-2 text-lg text-text-main-color">
                                                {alterar === true ? <input id="nif" name="nif" className="w-4/5 input-field" placeholder={data.nif} minLength={9} maxLength={14} defaultValue={data.nif} required></input> : data.nif}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Número de telefone:</div>
                                            <div className="mt-2 text-lg text-text-main-color">
                                                {alterar === true ? <input id="cellNumber" name="cellNumber" className="w-4/5 input-field" placeholder={data.cellNumber} minLength={9} maxLength={14} defaultValue={data.cellNumber} required></input> : data.cellNumber}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="email" name="email" className="w-4/5 input-field" placeholder={data.email} defaultValue={data.email} required></input> : data.email}
                                            </div>
                                        </div>
                                        {data.tipo === false && <>
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Iva:</div>
                                                {alterar === true ? (
                                                    <select onWheel={(e) => e.target.blur()} className="selector" id="iva" name="iva" value={iva} onChange={(e) => { setIva(e.target.value) }} required>
                                                        <option value="" disabled>Selecione a sua opção</option>
                                                        <option value="1" >Sim</option>
                                                        <option value="2">Não</option>
                                                    </select>
                                                ) : data.iva ? (
                                                    <div className="mt-1 text-lg text-text-main-color">
                                                        {data.iva === 1 ? "Sim" : "Não"}
                                                    </div>
                                                ) : (
                                                    <div className="input-error-profile" />
                                                )}
                                            </div>

                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Irs:</div>
                                                {alterar === true ? (
                                                    <select onWheel={(e) => e.target.blur()} className="selector" id="irs" name="irs" value={irs} onChange={(e) => { setIrs(e.target.value) }} required>
                                                        <option value="" disabled>Selecione a sua opção</option>
                                                        <option value="1" >Sim</option>
                                                        <option value="2">Não</option>
                                                    </select>
                                                ) : data.iva ? (
                                                    <div className="mt-1 text-lg text-text-main-color">
                                                        {data.irs === 1 ? "Sim" : "Não"}
                                                    </div>
                                                ) : (
                                                    <div className="input-error-profile" />
                                                )}
                                            </div>
                                        </>}
                                    </div>
                                    <div>
                                        {data.tipo === false && <>
                                            <div className="text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Morada:</div>
                                                <div className="mt-2 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="address" name="address" className="w-4/5 input-field" placeholder={data.address} defaultValue={data.address} required></input> : data.address}
                                                </div>
                                            </div>
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Nacionalidade:</div>
                                                <div className="mt-2 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="nationality" name="nationality" className="w-4/5 input-field" placeholder={data.nationality} defaultValue={data.nationality} required></input> : data.nationality}
                                                </div>
                                            </div>
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                                <div className="mt-1 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="locality" name="locality" className="w-4/5 input-field" placeholder={data.locality} defaultValue={data.locality} required></input> : data.locality}
                                                </div>
                                            </div>
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Concelho</div>
                                                <div className="mt-1 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="county" name="county" className="w-4/5 input-field" placeholder={data.county} defaultValue={data.county} required></input> : data.county}
                                                </div>
                                            </div>
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Código Postal:</div>
                                                <div className="mt-2 text-lg text-text-main-color">
                                                    {alterar === true ? <input id="postalCode" className="w-4/5 input-field" name="postalCode" placeholder={data.postalCode} defaultValue={data.postalCode} required></input> : data.postalCode}
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex justify-center mt-3 sm:justify-normal">
                                            {alterar === true ? <button className="mr-5 button-cancelar" onClick={() => { setAlterar(false) }}>Cancelar</button> : <button className="button-geral" onClick={alterarConfirmar}>Alterar Dados</button>}
                                            {alterar === true && <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Alterar Dados"} message={"Deseja confirmar as alterações?"} confirm={alterarPerfil} tooltip={"Alterar Perfil"} />}
                                        </div>
                                        <div className="flex justify-center mt-3 sm:justify-normal">
                                            <Link className="mr-5 button-geral" to={"/MenuCoordenador/Profile/"+ params.id_candidatura +"/changepassword"}>Alterar Password</Link>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-8 text-3xl font-bold text-center text-text-main-color">Ficheiros</h3>
                                <div className="mt-3">
                                    <div className="text-center subtitle">Ficheiros de Identificação</div>
                                    <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                                        {data.cc_path ?
                                            <div className="flex justify-center">
                                                <a href={data.cc_path} target="_blank" rel="noreferrer" download>
                                                    <button className="button-files">Documento de Identificação </button>
                                                </a>
                                            </div>
                                            :
                                            <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Documento de Identificação </button>
                                            </div>
                                        }
                                        {data.cv_path && data.cv_path.length > 0 ?
                                            <div className="flex justify-center">
                                                <button className="button-files" onClick={() => setModal(true)}>Curriculum Vitae</button>
                                            </div>
                                            :
                                            <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Curriculum Vitae</button>
                                            </div>
                                        }
                                        {data.ch_path ?
                                            <div className="flex justify-center">
                                                <a href={data.ch_path} target="_blank" rel="noreferrer" download>
                                                    <button className="button-files"> Certificado de Habilitações</button>
                                                </a>
                                            </div> :
                                            <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty"> Certificado de Habilitações</button>
                                            </div>}
                                        {data.ccp_path ?
                                            <div className="flex justify-center">
                                                <a href={data.ccp_path} target="_blank" rel="noreferrer" download>
                                                    <button className="button-files"> Certificação de Competências Pedagógicas</button>
                                                </a>
                                            </div> :
                                            <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty"> Certificação de Competências Pedagógicas</button>
                                            </div>}
                                    </div>
                                    {data.outros_path.length > 0 &&
                                        <>
                                            <div className="text-center subtitle">Outros Ficheiros</div>
                                            <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                                                {data.outros_path.map((value, index) => (
                                                    <div key={index} className="relative flex justify-center">
                                                        <button className="relative flex items-center justify-between p-0 button-files">
                                                            <a href={value.file} target="_blank" rel="noreferrer" download className="flex-grow p-2 sm:p-4">
                                                                {value.name}
                                                            </a>
                                                            <ConfirmToaster
                                                                css="absolute top-0.5 right-0.5 text-cancel-color hover:brightness-75 tooltip"
                                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                                title="Remover Ficheiro"
                                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                                confirm={() => eliminarOutroDocumento(index)}
                                                                id={value.index}
                                                                type="remover"
                                                                tooltip="Eliminar Ficheiro"
                                                            />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                </div>

                                {alterarFiles === 0 ?
                                    <div className="flex justify-center my-3">
                                        <button className="m-1 button-geral" onClick={() => setAlterarFiles(1)}>
                                            Alterar Ficheiros de Identificação
                                        </button>
                                        <button className="m-1 button-geral sm:ml-3" onClick={() => setAlterarFiles(2)}>
                                            Adicionar Outros Ficheiros
                                        </button>
                                        <button className="m-1 button-geral sm:ml-3" onClick={() => setVerIntegracaoSistema(!verIntegracaoSistema)}>
                                            Ver Integração com o Sistema
                                        </button>
                                    </div> : ""}

                                {alterarFiles === 1 ?
                                    <div>
                                        <form id="form">
                                            <h1 className="mt-8 text-center title">Insira os seguintes ficheiros</h1>
                                            <div>
                                                <div className="flex flex-col items-center">
                                                    <div className="text-center label-input">Documento de Identificação: </div>
                                                    <FileUploader
                                                    inputClassName={"input-file"}
                                                    inputType={"file"}
                                                    inputId={"cc"}
                                                    inputName={"cc"}
                                                    inputAccept={".pdf"}
                                                    allowedTypes={["application/pdf"]}
                                                    />
                                                </div>
                                                <div className="flex flex-col items-center mt-2">
                                                    <div className="text-center label-input" htmlFor="cv">Curriculum Vitae: </div>
                                                    <FileUploader
                                                    inputClassName={"input-file"}
                                                    inputMultiple={true}
                                                    inputType={"file"}
                                                    inputId={"cv"}
                                                    inputName={"cv"}
                                                    inputAccept={".pdf"}
                                                    allowedTypes={["application/pdf"]}
                                                    />
                                                </div>
                                                <div className="flex flex-col items-center mt-2">
                                                    <div className="text-center label-input" htmlFor="ch">Certificado de Habilitações: </div>
                                                    <FileUploader
                                                    inputClassName={"input-file"}
                                                    inputType={"file"}
                                                    inputId={"ch"}
                                                    inputName={"ch"}
                                                    inputAccept={".pdf"}
                                                    allowedTypes={["application/pdf"]}
                                                    />
                                                </div>
                                                <div className="flex flex-col items-center mt-2">
                                                    <div className="text-center label-input" htmlFor="ccp">Certificado de Competências Pedagógicas: </div>
                                                    <FileUploader
                                                    inputClassName={"input-file"}
                                                    inputType={"file"}
                                                    inputId={"ccp"}
                                                    inputName={"ccp"}
                                                    inputAccept={".pdf"}
                                                    allowedTypes={["application/pdf"]}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <p className="text-center text-text-error">*Formato suportado .pdf</p>
                                        <div className="flex justify-center mt-3">
                                            <button className="mr-5 button-cancelar" onClick={() => { setAlterarFiles(0) }}>Cancelar</button>
                                            <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Confirmar"} message={"Deseja confirmar as alterações?"} confirm={submeterFicheiros} tooltip={"Submeter Ficheiros"}></ConfirmToaster>
                                        </div>
                                    </div>
                                    : ""}

                                {alterarFiles === 2 ?
                                    <div>
                                        <h1 className="mt-8 text-center title">Adicionar Ficheiros</h1>
                                        <div className="flex flex-col justify-center mb-2">
                                            <label className="text-center text-text-main-color">Quantos outros documentos deseja inserir:</label>
                                            <input className="w-1/3 mx-auto input-field" type="number" value={numDoc} onChange={(e) => { setNumDoc(e.target.value) }}></input>
                                        </div>
                                        <div className="flex flex-col items-center w-full pt-4 overflow-y-auto rounded-lg max-h-156 bg-gray-50">
                                            {Array.from({ length: numDoc }).map((_, index) => (
                                                <div key={index} className="w-4/5 my-2">
                                                    <h4 className="mb-1 text-left uppercase text-main-color">Documento {index + 1}</h4>
                                                    <div className="flex w-full text-left">
                                                        <label className="w-2/5 text-text-main-color" htmlFor={`fullName${index}`}>Nome do documento:</label>
                                                        <input className="w-3/5 input-field" type="text" id={`fullName${index}`} name={`fullName${index}`} value={outrosDocs[index]?.name} onChange={(e) => handleNameChange(e, index)} />
                                                    </div>
                                                    <div className="flex w-full mt-2 mb-3 text-left">
                                                        <label className="w-2/5 text-text-main-color" htmlFor={`outro${index}`}>Ficheiro:</label>
                                                        <FileUploader
                                                        inputClassName={"w-3/5 input-file"}
                                                        inputType={"file"}
                                                        inputId={`outro${index}`}
                                                        inputName={`outro${index}`}
                                                        inputAccept={".pdf"}
                                                        allowedTypes={["application/pdf"]}
                                                        onChangeSpecial={(e) => handleFileChange(e, index)}
                                                        />
                                                    </div>
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                        <p className="text-center text-text-error">*Formato suportado .pdf</p>
                                        <div className="flex justify-center mt-3">
                                            <button className="mr-5 button-cancelar" onClick={() => { setAlterarFiles(0) }}>Cancelar</button>
                                            <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Confirmar"} message={"Deseja confirmar as alterações?"} confirm={submeterFicheiros} tooltip={"Submeter Ficheiros"}></ConfirmToaster>
                                        </div>
                                    </div>
                                    : ""}
                            </div>

                            {verIntegracaoSistema === true ?
                                <div>
                                    <h1 className="mt-8 subtitle">Interação com o Sistema</h1>
                                    {data && data.percurso.length > 0 ? <>
                                        <div className="flex justify-center items-center mb-1">
                                            <label className="text-text-main-color">Dados por página: </label>
                                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                                value={itemsPag}
                                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </div>
                                        <div className="table-container">
                                            <table className="my-table">

                                                <thead className="bg-main-color">
                                                    <tr className="text-white h-14">
                                                        <th className="p-2 min-w-[80px] border border-white">Curso</th>
                                                        <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                                    </tr>
                                                </thead>
                                                {data.percurso.length > 0 && (
                                                    <tbody className="text-text-main-color">
                                                        {dataToDisplay.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="border border-white">{item.name}</td>
                                                                <td className="border border-white">{!item.fechoCoordenador === true ? "Ativo" : "Inativo"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                        <div className="flex items-center justify-center mt-4 mb-8">
                                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                                <AiOutlineDoubleLeft />
                                            </button>
                                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                                <AiOutlineLeft />
                                            </button>
                                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                                <AiOutlineRight />
                                            </button>
                                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                                <AiOutlineDoubleRight />
                                            </button>
                                        </div> </> : <p className="third-title">Não existem dados</p>}
                                </div> : ""}
                        </div>
                    </div>
                </div>

                <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center">
                    <div className="absolute w-full h-full" onClick={() => setModal(false)}></div>
                    <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                        <div>
                            <h1 className="subtitle">Currículos</h1>
                        </div>
                        <div className="modal-content">
                            {data.cv_path && <>
                                {data.cv_path.map((value, index) => (
                                    <div key={value}>
                                        <div className="flex items-center m-1.5">
                                            <span className="mr-2 text-text-main-color">CV - {index + 1}:</span>
                                            <a className="mr-2 button-geral" href={value} target="_blank" rel="noreferrer" download>
                                                <FiDownload />
                                            </a>
                                            <button className="button-cancelar w-fit" onClick={() => { eliminarCV(value) }}>
                                                <IoMdRemoveCircle />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </>}
                        </div>
                        <button className="absolute top-1.5 right-1.5" onClick={() => setModal(false)}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </Modal>
            </main>
            <Footer />
        </>
    );
}