import React, { Component } from 'react';
import Papa from 'papaparse';
import toastError from '../Toast/toastError';

class CSVGeneratorDisciplinas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: '', // Manage categoryId in state
    };
  }

  async populateCSVFormandos() {
    const { data } = this.props;
    const { categoryId } = this.state;

    if (!categoryId) {
      toastError('Por favor, insira o ID da categoria!');
      return;
    }

    try {
      // Parse the CSV template
      const templateCSV = await this.fetchCSVTemplate('./moodleCSV/carregarDisciplinas.csv');
      const parsedTemplate = Papa.parse(templateCSV, { header: true, skipEmptyLines: true });

      // Generate data
      const dataToInsert = this.generateData(data);

      // Add data below the headers
      const populatedCSV = this.addRowsToCSV(parsedTemplate, dataToInsert);

      // Trigger CSV download
      this.downloadCSV(populatedCSV);
    } catch (error) {
      console.error('Error reading or processing CSV template:', error);
    }
  }

  generateData(data) {
    const array = [];
    const { categoryId } = this.state;
    data.forEach((element) => {
      // Combine the courses into a single object
      array.push([
        element.codeMoodle,
        element.name,
        element.percurso[0]?.name || '',
        categoryId // Use optional chaining to handle missing percurso
      ]);
    });

    return array;
  }

  // Adds rows to the CSV
  addRowsToCSV(parsedTemplate, dataToInsert) {
    const headerRow = Papa.unparse([parsedTemplate.meta.fields]); // Reconstruct the header row

    // Join data rows with newline characters
    const dataRows = dataToInsert.map((row) => Papa.unparse([row], { skipEmptyLines: true })).join('\n');

    // Concatenate the header row and data rows
    return `${headerRow}\n${dataRows}`;
  }

  downloadCSV(csvData) {
    // Create a Blob with UTF-8 encoding and a BOM for proper special character handling
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]); // BOM for UTF-8
    const blob = new Blob([bom, csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async fetchCSVTemplate(templatePath) {
    try {
      const response = await fetch(templatePath);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV template: ${response.status} ${response.statusText}`);
      }
      const csvText = await response.text();

      // Ensure the CSV text includes a BOM for special character compatibility
      return csvText.charCodeAt(0) === 0xFEFF ? csvText : '\uFEFF' + csvText;
    } catch (error) {
      throw new Error(`Error fetching CSV template: ${error.message}`);
    }
  }

  render() {
    const { categoryId } = this.state;

    return (
      <div className='flex flex-col'>
        <div className='subtitle'>Exportar para o Moodle</div>
        <div>
        <input
          type="number"
          min={1}
          className="input-field"
          placeholder="ID da Categoria"
          value={categoryId}
          onChange={(e) => this.setState({ categoryId: e.target.value })}
        />
        </div>
        <div>
        <button
          className={categoryId ? 'mt-5 button-geral' : 'mt-5 button-disabled'}
          onClick={() => this.populateCSVFormandos()}
        >
          Exportar Disciplinas para CSV
        </button>
        </div>
        <p className="mb-10 text-text-error">É necessário inserir o ID da categoria</p>
      </div>
    );
  }
}

export default CSVGeneratorDisciplinas;
