import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import { useParams, Link, useNavigate } from 'react-router-dom'
import Footer from "../../../../../components/Footer";
import toastSuccess from "../../../../../components/Toast/toastSuccess";
import ExcelGenerator from "../../../../../components/Excel/cronogramaDisciplina/index";
import NavbarFormador from "../../../../../components/Navbars/NavbarFormador/navbarFormador";
import { FiDownload } from "react-icons/fi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
import { FaEye, FaRegListAlt } from "react-icons/fa";
import toastError from "../../../../../components/Toast/toastError";
import DetermineCourseStatus from "../../../../../components/FormChecks/determinarCourseStatus/determineCourseStatus";

export default function UFCDsListed() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [percursosList, setPercursosList] = useState([])
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinas-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [error, setError] = useState(null);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinas-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-disciplinas-itm1`) : ""
    const savedFechoFormadorSelected = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-disciplinas-itm2`) : ""
    const [fechoFormadorSelected, setFechoFormadorSelected] = useState(savedFechoFormadorSelected ? savedFechoFormadorSelected : null);
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const [savedStatusOfCourses, setSavedStatusOfCourses] = useState(
        Cookies.get("statusOfCourses")
    );
    const [statusOfCourses, setStatusOfCourses] = useState(
        savedStatusOfCourses ? savedStatusOfCourses : ""
    );
    const [savedPercursos, setSavedPercursos] = useState(
        Cookies.get("percursos")
    );
    const [percursos, setPercursos] = useState(
        savedPercursos ? savedPercursos : ""
    );
    const [entidadesFormadorasList, setEntidadesFormadorasList] = useState([]);
    const [savedEntidadesFormadoras, setSavedEntidadesFormadoras] = useState(
        Cookies.get("EntidadeFormadora")
    );
    const [entidadesFormadoras, setEntidadesFormadoras] = useState(
        savedEntidadesFormadoras ? savedEntidadesFormadoras : ""
    );

    const params = useParams();
    const navigate = useNavigate()
    document.data = data

    useEffect(() => {
        Cookies.set("Formadores-disciplinas-Pag", paginaAtual);
        Cookies.set("Formadores-disciplinas-1", searchTerm);
        Cookies.set(`Formadores-disciplinas-itm1`, itemsPag);
        Cookies.set(`Formadores-disciplinas-itm2`, fechoFormadorSelected);
        Cookies.set("statusOfCourses", statusOfCourses);
        Cookies.set("percursos", percursos);
        handleSearch()
        getPercursos()
    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag, statusOfCourses, percursos, fechoFormadorSelected, entidadesFormadoras]);



    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    const handleSearch = async () => {
        await getUfcds(
            paginaAtual,
            itemsPag,
            searchTerm,
            statusOfCourses,
            percursos,
            entidadesFormadoras,
            fechoFormadorSelected
        );
    };
    async function getUfcds(
        currentPage,
        itemsPerPage,
        searchTerm,
        statusOfCourses,
        percurso,
        entidadesFormadoras,
        fechoFormadorSelected
    ) {
        try {
            const query = new URLSearchParams({
                currentPage: currentPage,
                itemsPerPage: itemsPerPage,
                searchTerm: searchTerm,
                statusOfCourses: statusOfCourses,
                percurso: percurso,
                entidadesFormadoras: entidadesFormadoras,
                fechoFormadorSelected: fechoFormadorSelected
            });

            // Fetch courses with pagination
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/ufcds/trainer/atividade/${params.id_candidatura}?${query}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        authorization: localStorage.getItem('user'),
                    },
                    method: "GET",
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            const totalItems = result.totalItems;
            const totalPages = Math.ceil(totalItems / result.itemsPerPage);

            // Save the data that comes from the API and define the total of pages and the total of items
            if (paginaAtual > totalPages) {
                setPaginaAtual(totalPages > 0 ? totalPages : 1);
            } else {
                setNumPaginas(totalPages);
            }
            setData(result.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError("Erro ao obter os dados, por favor tente mais tarde.");
        }
    }


    //Recebe as ufcds associadas a um formador ativas
    function getPercursos() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/trainer/${params.id_candidatura}`,
            {
                headers: {
                    'authorization': localStorage.getItem('user')
                }
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setPercursosList(result.percursos);
                    setEntidadesFormadorasList(result.entidades);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"ufcdsAtual"} />
                </div>
                <div className="h-full col-span-5 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <h1 className="title">Lista de disciplinas</h1>
                    <div className="search-div">
                        <label className="search-input-label"> Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="search" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
                        </div>
                    </div>
                    <div className="flex justify-center flex-row">
                        <div className="mr-5 flex flex-col">
                            <label className="text-text-second-color" htmlFor="templateCheck">
                                Percursos{" "}
                            </label>
                            {percursosList && percursosList.length > 0 && (
                                <select
                                    defaultValue={percursos}
                                    onWheel={(e) => e.target.blur()}
                                    className="mx-auto w-52 search-selector"
                                    name="sel"
                                    id="seletorOp"
                                    onChange={(e) => {
                                        setPercursos(e.target.value);
                                    }}
                                >
                                    <option value="">Todas</option>
                                    {percursosList.map((item, index) => (
                                        <option value={item._id} key={index}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="mr-5 flex flex-col">
                            <label className="text-text-second-color" htmlFor="templateCheck">
                                Entidade Formadora{" "}
                            </label>
                            {entidadesFormadorasList && entidadesFormadorasList.length > 0 && (
                                <select
                                    defaultValue={entidadesFormadoras}
                                    onWheel={(e) => e.target.blur()}
                                    className="mx-auto w-52 search-selector"
                                    name="sel3"
                                    id="seletorOp3"
                                    onChange={(e) => {
                                        setEntidadesFormadoras(e.target.value);
                                    }}
                                >
                                    <option value="">Todas</option>
                                    {entidadesFormadorasList.map((item, index) => (
                                        <option value={item._id} key={index}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="mr-5 flex flex-col">
                            <label className="text-text-second-color" htmlFor="templateCheck">
                                Estado da Disciplina{" "}
                            </label>
                            <select
                                defaultValue={statusOfCourses && statusOfCourses}
                                onWheel={(e) => e.target.blur()}
                                className="mx-auto w-52 search-selector"
                                name="sel"
                                id="seletorOp"
                                onChange={(e) => {
                                    setStatusOfCourses(e.target.value);
                                }}
                            >
                                <option value="">Todos</option>
                                <option value={"A decorrer"}>A decorrer</option>
                                <option value={"Terminado"}>Terminado</option>
                                <option value={"Planeado"}>Planeado</option>
                            </select>
                        </div>
                        <div className="mr-5 flex flex-col">
                            <label className="text-text-second-color" htmlFor="templateCheck">
                                Estado da Pauta{" "}
                            </label>
                            <select
                                defaultValue={fechoFormadorSelected && fechoFormadorSelected}
                                onWheel={(e) => e.target.blur()}
                                className="mx-auto w-52 search-selector"
                                name="sel2"
                                id="seletorOp2"
                                onChange={(e) => {
                                    setFechoFormadorSelected(e.target.value);
                                }}
                            >
                                <option value={null}>Todos</option>
                                <option value={"false"}>Por Fechar</option>
                                <option value={"true"}>Aguardar o fecho do Coordenador</option>
                            </select>
                        </div>
                        </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        {data && data.length > 0 ? <> <div className="mx-auto table-containerSideMenu">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[60px] border border-white">Código da disciplina</th>
                                        <th className="p-2 min-w-[60px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[60px] border border-white">Nome do Percurso</th>
                                        <th className="p-2 min-w-[60px] border border-white">Entidade Formadora</th>
                                        <th className="p-2 min-w-[60px] border border-white">Duração</th>
                                        <th className="p-2 min-w-[60px] border border-white">Data início</th>
                                        <th className="p-2 min-w-[60px] border border-white">Data final</th>
                                        <th className="p-2 min-w-[60px] border border-white">Estado da Disciplina</th>
                                        <th className="p-2 min-w-[60px] border border-white">Gerir Eventos</th>
                                        <th className="p-2 min-w-[60px] border border-white">Estado da Pauta</th>
                                        <th className="p-2 min-w-[60px] border border-white">Pauta</th>
                                        <th className="p-2 min-w-[60px] border border-white">Avaliação de Satisfação</th>
                                        <th className="p-2 min-w-[60px] border border-white pr-2">Exportar Cronograma</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>{item.codeUfcd}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>{item.name}</td>
                                                <td onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + item.percurso[0]._id)} className={`border border-white cursor-pointer ${item.fechoFormador ? "bg-indigo-300" : " "} row-click hover:text-white hover:underline`}>{item.percurso[0] ? item.percurso[0].name : "Sem curso"}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>{item.percurso[0] && item.percurso[0].entFormadora[0] && item.percurso[0].entFormadora[0].name}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>{item.cargaHoraria}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`} type="date">{AlterarFormato(item.dateBegin)}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`} type="date">{AlterarFormato(item.dateEnd)}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`} >{DetermineCourseStatus(
                                                    item.dateBegin,
                                                    item.dateEnd
                                                )}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>
                                                    <button className="button-geral" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/UFCDsListed/ativas/modulos/" + item._id)}>Marcar Eventos</button>
                                                </td>

                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`} >{item.fechoFormador ? "Aguardar Coordenador" : "Por Fechar"}</td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>
                                                    <button className="button-geral" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + item.percurso[0]._id + "/Ufcds/" + item._id + "/pauta")}><FaEye className=" text-white" /></button>
                                                </td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>
                                                    <button onClick={() => navigate(`/menutrainers/${params.id_candidatura}/cursos/${params.id_curso}/Ufcds/${item._id}/FormAvaliacao/formador/${params.id_candidatura}`)} className={(!item.avaliacaoSatisfacaoFormador && item.avaliacaoSatisfacaoFormador.length !== 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Avaliação"}>
                                                        <FaRegListAlt className={(item.avaliacaoSatisfacaoFormador && item.avaliacaoSatisfacaoFormador.length === 0) ? "p-2 text-main-color hover:text-text-second-color m-auto" : "p-2 text-green-600 m-auto"} size={50} />
                                                    </button>
                                                </td>
                                                <td className={`border border-white ${item.fechoFormador ? "bg-indigo-300" : ""}`}>
                                                    <button className="button-geral" onClick={() => ExcelGenerator(item)}><FiDownload /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
            </main>
            <Footer />
        </>
    );
}