import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import toastError from "../../../components/Toast/toastError";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { read, utils } from 'xlsx';
import csvtojson from 'csvtojson';
import Options from "../../../components/FormChecks/areaCodigoFormacao/areaCodigoFormacao";
import { IoMdRemoveCircle } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import toastNotify from "../../../components/Toast/toastNotify";
import Cookies from "js-cookie";
import FileUploader from "../../../utils/validationFileUploader";

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-templates-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [escolha, setEscolha] = useState("");
    const [ufcdsGlobais, setUfcdsGlobais] = useState([])
    const [ufcdsSelecionadas, setUfcdsSelecionadas] = useState([]);
    const [cont, setCont] = useState(0)
    const [jsonData, setJsonData] = useState(null);
    const [file, setFile] = useState(null);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-templates-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const [ufcdGlobaisNaoInseridas, setUfcdGlobaisNaoInseridas] = useState([]);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-templates-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    const params = useParams();
    const navigate = useNavigate();

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('templates')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate(`/menuadmin/${params.id_candidatura}/templates/${item._id}`)
        }
    };

    useEffect(() => {
        Cookies.set("Admin-templates-1", searchTerm);
        Cookies.set("Admin-templates-Pag", paginaAtual);

        Cookies.set(`Admin-templates-itm1`, itemsPag)
        getTemplates()
        getUfcdsGlobais()
        if (cont) {
            addUfcdGlobalButton()
        }
    }, [loading, cont, paginaAtual, nPaginas, escolha, searchTerm, itemsPag]);


    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }


    // Mapa das UFCDs Modelo selecionadas
    function RenderList() {
        return (
            <>
                {ufcdsSelecionadas.map((item, index) =>
                    <div key={index} className="flex items-center justify-between mt-3 text-text-main-color" >
                        <p className="mr-2">{item.codeUfcd} - {item.name}</p>
                        <button className="button-cancelar" type="button" onClick={(e) => { rejeitarUfcd(item.id) }}>
                            Remover
                        </button>
                    </div>)}
            </>
        )
    }

    function getTemplates() {
        fetch(`${process.env.REACT_APP_API_URL}/templates`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(filterData(result));
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                    setLoading(false);
                }
            );
    }


    function getUfcdsGlobais() {
        const datalist = document.getElementById('ufcds-globais-por-selecionar');

        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then(response => response.json())
            .then(result => {
                const idJaUsados = ufcdsSelecionadas.map((item) => item.codeUfcd);
                const todosIds = result.map((item) => item.codeUfcd);
                const diffArray = [];
                // Check elements from todosIds
                for (let i = 0; i < todosIds.length; i++) {
                    if (diffArray.length <= todosIds.length) {
                        if (!idJaUsados.includes(todosIds[i])) {
                            diffArray.push(result[i]);
                        }
                    }

                }
                const uniqueArray = [...new Set(diffArray.map(JSON.stringify))].map(JSON.parse);
                setUfcdGlobaisNaoInseridas(uniqueArray)

                if (uniqueArray.length > 0 && datalist) {// Construir o Seletor
                    uniqueArray.forEach(ufcd => {
                        const option = document.createElement('option');
                        option.value = ufcd.codeUfcd + " - " + ufcd.name;
                        option.setAttribute('data-id', ufcd._id); // Use data-id attribute for _id
                        option.setAttribute('data-code', ufcd.codeUfcd);
                        option.setAttribute('data-name', ufcd.name);
                        option.label = ufcd.codeUfcd + " - " + ufcd.name; // Set label attribute to categorias.name
                        datalist.appendChild(option);
                    });
                }
            });
        if (datalist) {
            // Clear existing options
            datalist.innerHTML = '';
        }
    }
    //rejeitar ufcds selecionadas
    function rejeitarUfcd(id) {
        const guardar = []
        ufcdsSelecionadas.forEach(ufcds => {
            guardar.push(ufcds.id)
        });
        const index = guardar.indexOf(id)
        if (index !== -1) {
            ufcdsSelecionadas.splice(index, 1);
            setCont(cont + 1)
        }
    }

    function addUfcdGlobalButton(el, dl) {
        if (el) {
            var opSelected = dl.querySelector(`[ value="${el.value}"]`);
            var option = document.createElement("option");
            option.value = opSelected.value;
            option.text = opSelected.getAttribute('data-id');
            option.code = opSelected.getAttribute('data-code');
            option.name = opSelected.getAttribute('data-name');
            if (!ufcdsSelecionadas.some(e => e.id === option.text)) {
                ufcdsSelecionadas.push({ id: option.text, name: option.name, codeUfcd: option.code })
                setCont(cont + 1)
            }
        }
    }

    function submeter() {
        let template = {
            name: document.querySelector("#name").value.trim(),
            duracao: document.querySelector("#duracao").value.trim(),
            nivel: document.querySelector("#nivel").value.trim(),
            area: document.querySelector("#area").value.trim(),
            ufcdsGlobal: ufcdsSelecionadas,
        }

        if (!template.ufcdsGlobal || !template.name) {
            toastError("Alguns dos campos não foram inseridos")
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/templates`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                body: JSON.stringify(template)
            })
            .then(res => res.json())
            .then(
                (res) => {
                    toastSuccess('Curso modelo inserido com sucesso!')
                    setCont(cont + 1)
                    let array = []
                    setUfcdsGlobais(array)
                },
                (error) => {
                    console.error(error)
                    return
                }
            )
        setUfcdsSelecionadas([])
        document.getElementById("name").value = ""
    }

    function submeterExcel() {
        if (file) {
            convertExcelToJson(file)
                .then((data) => {
                    let template = {
                        name: document.querySelector("#name").value.trim(),
                        duracao: document.querySelector("#duracao").value.trim(),
                        nivel: document.querySelector("#nivel").value.trim(),
                        area: document.querySelector("#area").value.trim(),
                        ufcdsGlobal: data,
                    }
                    if (!template.ufcdsGlobal || !template.name) {
                        toastError("Alguns dos campos não foram inseridos")
                        return;
                    }
                    fetch(`${process.env.REACT_APP_API_URL}/templates/excel`,
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                            body: JSON.stringify(template)
                        })
                        .then(res => res.json())
                        .then(
                            (res) => {
                                if (res.error.length > 0) {
                                    if (res.error.length > 10) {
                                        toastError("Confirme se está a submeter o documento certo.")
                                    } else {
                                        res.error.forEach(element => {
                                            toastError(element)
                                        });
                                        if (res.succed.length > 0) {

                                            toastSuccess(`Foram criadas ${res.succed.length} com sucesso`)

                                        }
                                        toastNotify('O Curso modelo foi criado de forma incompleta!')
                                    }
                                }
                                else {
                                    if (res.succed.length > 0) {
                                        toastSuccess('Curso modelo inserido com sucesso!')
                                        let array = []
                                        setUfcdsGlobais(array)
                                    }
                                }
                                setCont(cont + 1)
                            },
                            (error) => {
                                console.error(error)
                                return
                            }
                        )
                    setUfcdsSelecionadas([])
                })
        }
    }

    //Elimina uma ufcd pelo seu id
    function deleteUfcd(_id) {

        fetch(`${process.env.REACT_APP_API_URL}/templates/${_id}`, {
            method: 'DELETE',
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Curso modelo removido com sucesso!')
                getTemplates()
            })
        })
    }

    //Download do ficheiro
    function downloadFile() {
        const fileUrl = process.env.PUBLIC_URL + '/Disciplinas.xlsx'; // Update the file path accordingly

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'ModeloCriarCurso.xlsx'; // Specify the desired filename for the download

        // Programmatically trigger the download
        link.click();
    }


    function convertExcelToJson(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const csvData = utils.sheet_to_csv(worksheet);

                csvtojson()
                    .fromString(csvData)
                    .then((jsonData) => {
                        // Filter rows with values in columns A, B, C, or D
                        const filteredData = jsonData.filter(row =>
                            row.Codigo || row.Nome || row.Carga || row.Formato
                        );
                        resolve(filteredData);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsArrayBuffer(file);
        });
    }
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"criarCursosModelo"} />
                </div>
                <div className="flex-grow my-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>

                    <h1 className="mt-3 sm:mx-16 sm:mt-0 title">Cursos modelo</h1>
                    <h2 className="subtitle">Lista de Cursos modelo</h2>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label">Pesquisar:</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>

                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">
                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Duração</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nível</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área</th>
                                        <th className="p-2 min-w-[80px] border border-white">Remover</th>
                                    </tr>
                                </thead>
                                {dataToDisplay.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                                <td className="border border-white templates">{item.name}</td>
                                                <td className="border border-white templates">{item.duracao}</td>
                                                <td className="border border-white templates">{item.nivel}</td>
                                                <td className="border border-white templates">{item.area}</td>
                                                <td className="border border-white">
                                                    <ConfirmToaster css={"button-geral hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Eliminar"} message={"Deseja eliminar o template ?"} confirm={() => deleteUfcd(item._id)}></ConfirmToaster>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody></tbody>
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}



                    <div className="flex flex-col justify-center w-1/2 mx-auto">
                        <h2 className="mt-4 subtitle">Adicionar Curso Modelo</h2>

                        <div className="flex flex-col">
                            <label className="label-input">Nome:</label>
                            <input type="text" id="name" name="name" className="w-full input-field" required />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className="label-input">Duração:</label>
                            <input type="text" id="duracao" name="duracao" className="w-full input-field" required />
                        </div>

                        <div className="flex flex-col mt-3">
                            <label className="label-input">Área:</label>
                            <select onWheel={(e) => e.target.blur()} className="selector" name="area" id="area" defaultValue="">
                                <option value="" disabled>Selecione uma Área de Formação</option>
                                {Options.map((item) => (
                                    <option key={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className="label-input">Nível:</label>
                            <select onWheel={(e) => e.target.blur()} className="selector" type="text" id="nivel" name="nivel" defaultValue="" required>
                                <option value={""} disabled  > Selecionar a nível</option>
                                <option value="2">2</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="outro">Outro</option>
                            </select>
                        </div>

                        <div className="flex flex-col my-3">
                            <label className="label-input">Método de Inserção da lista de Disciplinas:</label>
                            <select onWheel={(e) => e.target.blur()} name="typeSubmit" id="typeSubmit" className="selector" defaultValue="0" onChange={(e) => setEscolha(e.target.value)}>
                                <option value="0" disabled>Selecione como pretente enviar as Disciplinas</option>
                                <option value="1">Excel</option>
                                <option value="2">Selecionando as pretendidas</option>
                            </select>
                        </div>

                        {escolha === "1" ?
                            <div className="mt-1">
                                <div className="flex items-center justify-center mb-1">
                                    <h3 className="mr-1 text-text-main-color">Download de Modelo excel</h3>
                                    <button className="button-geral" onClick={() => downloadFile()}><FiDownload /></button>
                                </div>
                                <div>
                                    <FileUploader
                                        inputClassName={"input-file"}
                                        inputType={"file"}
                                        inputAccept={".xls,.xlsx"}
                                        onChangeSpecial={handleFileChange}
                                        allowedTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]}
                                        />
                                </div>
                                <div className="my-6">
                                    <button className="button-geral" onClick={() => submeterExcel()}>Submeter</button>
                                </div>

                                {jsonData && <pre>{JSON.stringify(jsonData, null, 2)}</pre>}
                            </div> : <>{escolha === "2" && <div>
                                <div className="flex flex-col mt-3">
                                    <p className="label-input">Disciplinas:
                                        <input
                                            onChange={(e) => {

                                                const input = e.target;
                                                const datalist = document.getElementById('ufcds-globais-por-selecionar');
                                                const optionExists = Array.from(datalist.options).some((option) => option.value === input.value);

                                                if (optionExists) {
                                                    addUfcdGlobalButton(input, datalist);
                                                    input.value = "";
                                                }
                                            }}
                                            className="w-full input-field"
                                            list="ufcds-globais-por-selecionar"
                                        />
                                    </p>
                                    <datalist id="ufcds-globais-por-selecionar"></datalist>
                                </div>
                                <div>
                                    {ufcdsSelecionadas.length > 0 && <h4 className="mt-3 third-title">Lista de disciplinas</h4>}
                                    <RenderList />
                                </div>
                                <button className="my-6 button-geral" onClick={submeter} type="button">Submeter</button>
                            </div>}</>}
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default ShowUfcd;
