import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import toastSuccess from "../Toast/toastSuccess";
import { AiOutlineCloseCircle } from "react-icons/ai";
import refreshPage from "../Refresh";
import FileUploader from "../../utils/validationFileUploader";

export default function ModalUploadUFCD({ id_curso, topicoTitulo, topicoAPI, icon, user, tooltip }) {
    const [open, setOpen] = React.useState(false);
    const [nome, setNome] = React.useState("");
    const [file, setFile] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [erro, setErro] = useState("")
    const maxSizeInBytes = 4 * 1024 * 1024 // 1 MB

    useEffect(() => {

    }, [nome, file, open, loading, erro])
    const handleOpen = () => {
        setErro("")
        setOpen(!open);
    }
    //Posta no cloudinary
    async function cloudinaryFicheiro() {
        const date = new Date();
        if (!nome) {
            console.error("Nome do documento é necessário");
            setErro("Nome do documento é necessário")
            setLoading(false);
            return;
        }
        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }
        if (document.getElementById("ficheiro") && document.getElementById("ficheiro").value) {
            let fileSize = document.getElementById("ficheiro").files[0].size;
            if (fileSize < maxSizeInBytes) {
                setLoading(true)
                const ficheiro = document.getElementById("ficheiro").files[0]
                if ((ficheiro.name.includes(".xlsx") === true) || (ficheiro.name.includes(".csv") === true)) {
                    let ficheiroForm = new FormData()
                    ficheiroForm.append('excel', ficheiro)
                    options.body = ficheiroForm
                    const ficheiroResponse = await fetch(`${process.env.REACT_APP_API_URL}/cronograma/especifico`, options)
                    const ficheiroResult = await ficheiroResponse.json()
                    await fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id_curso}/DTP`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(user)
                        },
                        method: 'PATCH',
                        body: JSON.stringify({
                            topicoAPI: topicoAPI,
                            nome: nome,
                            file: ficheiroResult,
                            date: date,
                            idUtilizador: localStorage.getItem("id")
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            setLoading(false)
                            toastSuccess("Ficheiro Uploaded com sucesso")
                            setOpen(!open)
                        })
                        .finally(refreshPage())
                }
                else {
                    const ficheiro = document.getElementById("ficheiro").files[0]
                    let ficheiroForm = new FormData()
                    ficheiroForm.append('ficheiro', ficheiro)
                    options.body = ficheiroForm
                    const ficheiroResponse = await fetch(`${process.env.REACT_APP_API_URL}/ficheiro`, options)
                    const ficheiroResult = await ficheiroResponse.json()
                    await fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id_curso}/DTP`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(user)
                        },
                        method: 'PATCH',
                        body: JSON.stringify({
                            topicoAPI: topicoAPI,
                            nome: nome,
                            file: ficheiroResult,
                            date: date,
                            idUtilizador: localStorage.getItem("id")
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            setLoading(false)
                            toastSuccess("Ficheiro Uploaded com sucesso")
                            setOpen(!open)
                        })
                        .finally(refreshPage())
                }
            } else setErro("O tamanho do ficheiro não pode ultrapassar 4MB")
        } else setErro("É necessário adicionar ficheiro")

    }

    return (
        <>
            <button className="mr-1 text-xl tooltip text-text-main-color" onClick={handleOpen} variant="gradient" data-tip={tooltip}>{icon}</button>
            <Dialog className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato" open={open} handler={handleOpen}>
                <div className="flex items-center justify-center h-screen">
                    <div className="absolute w-full h-full" onClick={handleOpen}></div>
                    <div className="relative z-50 p-2 rounded-lg shadow-lg max-h-128 w-128 overflow-auto bg-secundary-color shadow-gray-600">
                        <div className="flex flex-col justify-center my-2">
                            <h4 className="mx-4 text-center small-title">{topicoTitulo}</h4>
                            {!loading ?
                                <DialogBody>
                                    <div className="flex w-full text-left">
                                        <label className="w-2/5 text-text-main-color" htmlFor={`fullName`}>Nome do documento:</label>
                                        <input className="w-3/5 input-field" type="text" id={`fullName`} name={`fullName`} onChange={(e) => setNome(e.target.value)} required />
                                    </div>
                                    <div className="flex w-full mt-2 mb-3 text-left">
                                        <label className="w-2/5 text-text-main-color" htmlFor={`outro`}>Ficheiro:</label>
                                        <FileUploader
                                            inputClassName={"w-3/5 input-file"}
                                            inputType={"file"}
                                            inputId={"ficheiro"}
                                            inputName={"ficheiro"}
                                            inputAccept={".csv, .xlsx, .pdf"}
                                            allowedTypes={["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf"]}
                                            />
                                    </div>
                                    {erro && <div className="flex justify-center text-text-error mb-1">{erro}</div>}
                                    <div className="flex justify-center w-full">
                                        <button className="button-geral" onClick={cloudinaryFicheiro}>Submeter</button>
                                    </div>
                                </DialogBody>
                                :
                                <DialogBody>
                                    <div className="flex items-center justify-center w-full h-full bg-secundary-color">
                                        <div className="loading"></div>
                                    </div>
                                </DialogBody>
                            }
                            <hr />
                        </div>

                        <button className="absolute top-1.5 right-1.5" onClick={handleOpen}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}