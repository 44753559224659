import React, { useState, useEffect } from "react";
import { ContratoFormador } from "../Modal/stylesContratos/styledFormador";
import SepararData from "../FormChecks/formatDates/splitDates";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormadorNaoFinanciado({
  data,
  token,
  formador,
  tipo,
  setOpen,
}) {
  // This const is for finding the "dadosContrato" object in the array of objects
  // Verify if the formador._id is equal to the id in the array of objects
  const index =
    formador && tipo === "formador"
      ? data.dadosContrato.findIndex((item) => item.id === formador._id)
      : 0;
  const contrato = data?.dadosContrato[index]?.dadosContrato;

  // const to handle the date
  const { dia, mes, ano } = SepararData(data.dateBegin);

  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [valores, setValores] = useState({
    valorHoraFormador: contrato?.valorHoraFormador ?? "",
    valorHorasLecionadasFormador: contrato?.valorHorasLecionadasFormador ?? "",
  });

  // Function to send the contract by email
  async function enviarContratoFormador() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormadorNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = useState({
    inputValorHoraFormador: valores.valorHoraFormador,
    inputValorHorasLecionadasFormador: valores.valorHorasLecionadasFormador,
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = useState({
    inputValorHoraFormador: false,
    inputValorHorasLecionadasFormador: false,
  });

  useEffect(() => {
    valores.dia = dia;
    valores.mes = mes;
    valores.ano = ano;
  }, [valores, dia, mes, ano]);

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {

      if (
        key === "inputValorHorasLecionadasFormador" &&
        data?.formadores?.length < 2
      ) {
        continue; // Ignore this input validation if there is only one formador
      }
      
      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Function to adjust the width of the input field
  const adjustWidth = (e) => {
    e.target.style.width = `${e.target.value.length + 1}ch`;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <ContratoFormador>
            <div className="body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    src={data.percurso[0].entFormadora[0].logotipo}
                    className="h-20"
                    alt="logo"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
                <div>
                  <h1
                    style={{
                      fontSize: "16px",
                      paddingLeft: "15px",
                      marginTop: "-5px",
                    }}
                  >
                    Contrato de Prestação de Serviços de Formação - Formadores/as externos/as
                  </h1>
                </div>
                <div>
                  {data.percurso[0].entFinanciadora &&
                    data.percurso[0].entFinanciadora.length > 0 &&
                    data.percurso[0].entFinanciadora[0].logotipo1 && (
                      <img
                        src={data.percurso[0].entFinanciadora[0].logotipo1}
                        className="h-20"
                        alt="logo"
                        style={{ marginRight: "20px" }}
                      />
                    )}
                </div>
              </div>
              <div className="page1"></div>
              <div>
                <br />
                  <p>
                    <b>ENTRE:</b>
                  </p>
                  <p>
                    <b>PRIMEIRO OUTORGANTE:</b>
                  </p>
                  <p>
                    <b>{data.percurso[0].entFormadora[0].name}</b>, NIPC {data.percurso[0].entFormadora[0].nif}, com
                    sede em {data.percurso[0].entFormadora[0].morada},{" "}
                    {data.percurso[0].entFormadora[0].postalCode},{" "}
                    {data.percurso[0].entFormadora[0].localidade},
                    nesse ato representado pelo/a{" "}
                    {data.percurso[0].entFormadora[0].representanteLegal}, na qualidade de {data.percurso[0].entFormadora[0].cargoRepresentanteLegal}.
                  </p>
                  <p>
                    E o <b>SEGUNDO OUTORGANTE</b>:
                  </p>
                  <p>
                    {formador.fullName} titular do Cartão de Cidadão nº {formador.ccNumber}, válido até{" "}
                    {formador.ccExpiration} com residência em {formador.address}, {formador.postalCode}, {formador.locality}.
                  </p>
                  <br />
                  <p>
                  É nesta data livremente Outorgado um contrato de prestação de serviços no âmbito da formação, o qual se rege pelas seguintes cláusulas:
                  </p>
                </div>
                <div>
                  <h2>Cláusula 1ª</h2>
                  <p>
                    1. O <b>SEGUNDO OUTORGANTE</b> é contratado como PRESTADOR DE SERVIÇOS para o
                    exercício da função de <b>FORMADOR/A</b> para o Curso de{" "}
                    {data.percurso[0].name}, nos módulos de {data.name}, com a duração de{" "}
                    <b>{data && data.formadores && data.formadores.length < 2 ? (data.cargaHoraria) :  (<input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorHorasLecionadasFormador"
                      defaultValue={valores.valorHorasLecionadasFormador}
                      placeholder="5"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorHorasLecionadasFormador: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorHorasLecionadasFormador.length > 0
                            ? valores.valorHorasLecionadasFormador.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorHorasLecionadasFormador
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />)}</b> horas.
                  </p>
                  
                <div>
                  <p>
                    2. O local de realização do curso é:
                  </p>
                  <table className="realizacaocurso">
                    <tbody>
                      <tr>
                        <th>Local:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].locality
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Morada:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].address
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Código de Postal:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].postalCode
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Data de início prevista:</th>
                        <td>{AlterarFormato(data.dateBegin)}</td>
                      </tr>
                      <tr>
                        <th>Data de fim prevista:</th>
                        <td>{AlterarFormato(data.dateEnd)}</td>
                      </tr>
                      <tr>
                      <th>Plataforma LMS:</th>
                        <td>
                          {data.formato === "Online" ? (
                            <a href={data?.percurso[0]?.moodle[0]?.name}>
                              {data?.percurso[0]?.moodle[0]?.name}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                  3. O horário e data das sessões poderá ser alterado de comum acordo com a <b>PRIMEIRA OUTORGANTE</b>, desde que respeite o período de funcionamento por ela definido, a carga horária semanal e a disponibilidade dos/as formandos/as.
                  </p>
                  <p>
                  4. O valor da prestação de serviço para esta ação de formação é de <b><input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorHoraFormador"
                      defaultValue={valores.valorHoraFormador}
                      placeholder="10,00"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorHoraFormador: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorHoraFormador.length > 0
                            ? valores.valorHoraFormador.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorHoraFormador
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    /></b>{" "}
                    € por cada hora de formação lecionada. Aos valores referidos acresce IVA (se devido) à taxa legal em vigor.
                  </p>
                </div>
              </div>
              <div>
                <h2>Cláusula 2ª</h2>
                
                  <p>
                  1. O <b>SEGUNDO OUTORGANTE</b> compromete-se assim a prestar apoio pedagógico-didático ao <b>PRIMEIRO OUTORGANTE</b>.
                  </p>
                  <p>
                  2. As condições da prestação do serviço são as seguintes:
                  </p>
                  <ol className="tipoa">
                    <li>Participar na preparação, desenvolvimento e acompanhamento do processo formativo, designadamente nas tarefas de elaboração de materiais didáticos e de provas de avaliação;</li>
                    <li>Ministrar a matéria a lecionar de acordo com os conteúdos programáticos estabelecidos contratualmente entre o <b>PRIMEIRO OUTORGANTE</b> e o <b>SEGUNDO OUTORGANTE</b>.</li>
                    <li>Participar em atividades de formação complementar dos/as formandos/as e noutras de natureza interdisciplinar, em reuniões técnicas por área de formação, e na análise, reflexão e validação das técnicas e métodos de formação.</li>
                    <li>
                    Utilizar a estratégia pedagógica utilizada de acordo com o estabelecido contratualmente entre o <b>PRIMEIRO OUTORGANTE</b> e o <b>SEGUNDO OUTORGANTE</b>.
                    </li>
                    <li>
                    Contactar em primeira instância o <b>PRIMEIRO OUTORGANTE</b> sempre que surja algum imprevisto em relação ao que estava acordado, preenchendo simultaneamente a FICHA DE OCORRÊNCIAS, constante do Dossier Técnico-Pedagógico.
                    </li>
                    <li>
                    Entregar/enviar para o <b>PRIMEIRO OUTORGANTE</b> todo o material pedagógico que seja adicionalmente entregue aos formandos/as (ou produzido por estes/as), assumindo toda e qualquer responsabilidade de direitos de autor desse material.
                    </li>
                    <li>
                    Assinar e preencher planos de sessão (quando aplicável), folhas de sumário, bem como verificar a assiduidade dos/as formandos/as nas respetivas folhas de presença e plataforma Moodle (quando aplicável);
                    </li>
                    <li>
                    Cumprir todas as obrigações respeitantes à avaliação da ação e à avaliação de cada formando/a.
                    </li>
                    <li>
                    Sujeitar-se a processos de avaliação do seu desempenho a realizar pela primeira outorgante. 
                    </li>
                    <li>
                    Entregar/enviar, no final da ação, o Dossier Pedagógico, devidamente preenchido e organizado.
                    </li>
                  </ol>
                  <br />
                  <p>
                  3. O <b>SEGUNDO OUTORGANTE</b> compromete-se ainda a entregar toda a documentação necessária para instrução do seu processo individual, de acordo com as instruções de serviço anexas a este contrato.
                  </p>
                
              </div>
              <div>
                <h2>Cláusula 3ª</h2>
                <p>
                O <b>SEGUNDO OUTORGANTE</b> deverá enviar/manter atualizados os dados relativos ao seu ficheiro pessoal, nomeadamente:
                </p>
                <ol className="tipoa">
                      <li>
                        <i>Curriculum Vitae;</i>
                      </li>
                      <li>
                        Cópia do Cartão de Cidadão;
                      </li>
                      <li>
                        Cópia do Número de Identificação Bancária;
                      </li>
                      <li>
                        Cópia dos Certificados comprovativos das Habilitações Literárias;
                      </li>
                      <li>
                        Cópia do Certificado Competências Profissionais (CCP) ou comprovativo de isenção de CCP (ao abrigo do n.º 2, do artigo 2.º, da Portaria n.º 214/2011, de 30 de maio).
                      </li>
                </ol>
              </div>
              <div>
                <h2>Cláusula 4ª</h2>
                <p>
                1. O presente contrato não confere ao <b>SEGUNDO OUTORGANTE</b> a qualidade de trabalhador/a do <b>PRIMEIRO OUTORGANTE</b>.
                </p>
                 <p>
                 2. Em consequência do disposto no nº 1, o <b>SEGUNDO OUTORGANTE</b> não tem direito a férias, subsídio de férias ou Natal, subsídio de refeição ou quaisquer outros subsídios ou prestações complementares, sendo que é da sua exclusiva responsabilidade a sua situação perante a Administração Fiscal e Segurança Social.
                  </p>       
              </div>
              <div>
                <h2>Cláusula 5ª</h2>
                <p>
                1. O <b>SEGUNDO OUTORGANTE</b> emitirá a fatura correspondente ao valor estipulado nos termos deste contrato, cumprindo as obrigações fiscais aplicáveis aos rendimentos provenientes de trabalho independente.
                </p>
                <p>
                2. O <b>PRIMEIRO OUTORGANTE</b> efetuará o pagamento das respetivas importâncias até ao máximo de 60 dias após a emissão da fatura. 
                </p>
              </div>
              <div>
                <h2>Cláusula 6ª</h2>
                <p>
                1. No caso de não cumprimento, por parte do/a segundo/a outorgante, das cláusulas deste contrato, poderá a primeira outorgante acionar os competentes mecanismos judiciais com vista à indemnização pelos danos e prejuízos eventualmente ocorridos.
                </p>
                <p>
                2. Estabelece-se, desde já, que o não cumprimento do número total de horas, referido na cláusula 1.ª ou das obrigações previstas na cláusula 2.ª deste contrato implica pagamento das horas efetivamente realizadas.
                </p>
              </div>
              <div>
                <h2>Cláusula 7ª</h2>
                <p>
                O primeiro outorgante atuará no respeito das normas nacionais de proteção de dados pessoais (relativos ao/à segundo/a outorgante), sendo que os mesmos apenas poderão ser divulgados para efeitos de:
                </p>
                <ol className="tipoa">
                      <li>
                      Divulgação das ações de Formação;
                      </li>
                      <li>
                      Auditorias de controlo por parte de entidades competentes e/ou eventual auscultação por parte do sistema de certificação da DGERT.
                      </li>
                </ol>
                      <p>Os Outorgantes comprometem-se a respeitar o Regulamento Geral de Proteção de Dados (RGPD) em vigor e demais legislações nacionais aplicáveis aos dados pessoais.</p>
              </div>
              <div style={{ breakInside: "avoid" }}>
                <h2>Cláusula 8ª</h2>
                <p>
                  1. O presente contrato pode cessar, sem necessidade de aviso
                  prévio quando os <b>OUTORGANTES</b> não cumpram as obrigações
                  nele assumidas.
                </p>
                <p>
                2. O presente contrato entra em vigor com a data das assinaturas e vigorará durante o período estabelecido para a execução dos módulos de formação agora contratualizados e descritos na cláusula 1ª.
                </p>
              </div>
              <div>
              <br />
              <br />
                  <p>
                    {data.percurso[0].locality}, {AlterarFormato(data.dateBegin)}
                    </p>      
            <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O PRIMEIRO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt="assinatura"
                        style={{ margin: "5px auto" }}
                        id="img"
                        className="img"
                        src={data.percurso[0].entFormadora[0].assinatura}
                        height="70"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O SEGUNDO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                      }}
                    ></div>
                  </div>             
                </div>
                <div>
                  <p>
                  A informação por si disponibilizada não será partilhada com terceiros e será utilizada apenas para os fins diretamente relacionados com o curso a que vai ministrar formação.
                  </p>
                  <p>
                  Caso não autorize que os seus dados pessoais sejam facultados ao DGERT – Direcção-Geral do Emprego e das Relações de Trabalho, Entidade Acreditadora de entidades formadoras, para vir a ser auscultado sobre a qualidade da formação em que colaborou, assinale com um “X”. <span style={{ fontSize: "24px" }}>&#x2610;</span>
                  </p>
                  <p>
                  Caso não autorize a utilização dos seus dados pessoais para ações de divulgação a realizar pela {data.percurso[0].entFormadora[0].name}, assinale com um “X”. <span style={{ fontSize: "24px" }}>&#x2610;</span>
                  </p>
                </div>
              </div>
              {data.percurso[0].entFinanciadora &&
                data.percurso[0].entFinanciadora.length > 0 &&
                data.percurso[0].entFinanciadora[0].logotipo2 && (
                  <div>
                    <img
                      src={data.percurso[0].entFinanciadora[0].logotipo2}
                      className="h-20"
                      alt="logo"
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                )}
            </div>
            <div className="flex justify-center w-full">
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Enviar Contrato"}
                title={"Enviar Contrato"}
                message={"Confirma o envio do contrato?"}
                confirm={enviarContratoFormador}
              />
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Download Contrato"}
                title={"Download Contrato"}
                message={"Confirma o download do contrato?"}
                confirm={enviarContratoFormadorNoEmail}
              />
            </div>
          </ContratoFormador>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
