import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../Footer";
import FileUploader from "../../utils/validationFileUploader";
import toastSuccess from "../Toast/toastSuccess";
import toastError from "../Toast/toastError";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import { FiDownload } from "react-icons/fi";
import check_nif_number from "../FormChecks/checkNif/checkNIf";
import { BsPencilFill } from "react-icons/bs";

function EntFormadoraIndividual(token) {
    const [data, setData] = useState(null);
    const [cont, setCont] = useState(0)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [name, setName] = useState("");
    const [gestorFormacao, setGestorFormacao] = useState("");
    const [localidade, setLocalidade] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [morada, setMorada] = useState("");
    const [distrito, setDistrito] = useState("");
    const [concelho, setConcelho] = useState("");
    const [nif, setNif] = useState("");

    const [codDGERT, setCodDGERT] = useState("");
    const [representanteLegal, setRepresentanteLegal] = useState("");
    const [nifRepresentanteLegal, setNifRepresentanteLegal] = useState("");
    const [ccRepresentanteLegal, setCcRepresentanteLegal] = useState("");
    const [validadeCcRepresentanteLegal, setValidadeCcRepresentanteLegal] = useState("");
    const [cargoRepresentanteLegal, setCargoRepresentanteLegal] = useState("");
    const [moradaRepresentanteLegal, setMoradaRepresentanteLegal] = useState("");
    const [localidadeRepresentanteLegal, setLocalidadeRepresentanteLegal] = useState("");
    const [postalCodeRepresentanteLegal, setPostalCodeRepresentanteLegal] = useState("");
    const [distritoRepresentanteLegal, setDistritoRepresentanteLegal] = useState("");
    const [concelhoRepresentanteLegal, setConcelhoRepresentanteLegal] = useState("");

    const [imageHeightLogo, setimageHeightLogo] = useState(0);
    const [newCertificado, setNewCertificado] = useState(null);
    const [newAssinatura, setNewAssinatura] = useState(null);

    const [alterar, setAltetar] = useState(false);

    const params = useParams();
    const image = new Image();

    useEffect(() => {
        getEntFormadora()
    }, [cont]);

    function getEntFormadora() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFormadora/` + params.id_item, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setLoading(false)
            })
    }

    const handleFileChangeLogo = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeightLogo(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileUpload = async (event) => {
        await handleFileChangeLogo(event);
        if (imageHeightLogo <= 240) {
            setLoading(true);
            const selectedFile = event.target.files[0];
            let ccForm = new FormData();
            ccForm.append('logo', selectedFile);

            const options = {
                method: "POST",
                headers: { "Accept": "application/json" },
                credentials: "same-origin",
                body: ccForm
            };

            try {
                const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
                const logoResult = await logoResponse.json();

                await fetch(`${process.env.REACT_APP_API_URL}/EntFormadora/${params.id_item}/logo`, {
                    body: JSON.stringify({
                        logotipo: logoResult,
                    }),
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    }
                });

                toastSuccess('Logotipo adicionado com sucesso!');
                setCont(cont + 1)
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
            }
        } else toastError('A altura da imagem deve ser menor ou igual a 240 píxeis!');
    };


    const atualizarEntFormadora = async () => {
        if (check_nif_number(document.getElementById("nif").value)&& check_nif_number(document.getElementById("nifRL").value)) {
            setLoading(true);

            const options = {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    'authorization': localStorage.getItem(token)
                },
                credentials: "same-origin"
            }
            let certificadoResults
            let assinaturaResults
            if (newCertificado) {
                const certificadoForm = new FormData();
                certificadoForm.append('certificado', newCertificado);
                options.body = certificadoForm;
                const certificadoResponse = await fetch(`${process.env.REACT_APP_API_URL}/certificado`, options);
                const certificadoResult = await certificadoResponse.json();
                certificadoResults =(certificadoResult);
            }

            if (newAssinatura) {
                const assinaturaForm = new FormData();
                assinaturaForm.append('assinatura', newAssinatura);
                options.body = assinaturaForm;
                const assinaturaResponse = await fetch(`${process.env.REACT_APP_API_URL}/assinatura`, options);
                const assinaturaResult = await assinaturaResponse.json();
                assinaturaResults = (assinaturaResult);
            }

            fetch(`${process.env.REACT_APP_API_URL}/EntFormadora/` + params.id_item, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
                body: JSON.stringify({
                    name: name ? name : data.name,
                    codDGERT: codDGERT ? codDGERT : data.codDGERT,
                    representanteLegal: representanteLegal ? representanteLegal : data.representanteLegal,
                    gestorFormacao: gestorFormacao ? gestorFormacao : data.gestorFormacao,
                    localidade: localidade ? localidade : data.localidade,
                    postalCode: postalCode ? postalCode : data.postalCode,
                    morada: morada ? morada : data.morada,
                    nif: nif ? nif : data.nif,
                    distrito: distrito ? distrito : data.distrito ? data.distrito : "",
                    concelho: concelho ? concelho : data.concelho ? data.concelho : "",
                    nifRepresentanteLegal: nifRepresentanteLegal ? nifRepresentanteLegal : data.nifRepresentanteLegal ? data.nifRepresentanteLegal : "",
                    ccRepresentanteLegal: ccRepresentanteLegal ? ccRepresentanteLegal : data.ccRepresentanteLegal ? data.ccRepresentanteLegal : "",
                    validadeCcRepresentanteLegal: validadeCcRepresentanteLegal ? validadeCcRepresentanteLegal : data.validadeCcRepresentanteLegal ? data.validadeCcRepresentanteLegal : "",
                    cargoRepresentanteLegal: cargoRepresentanteLegal ? cargoRepresentanteLegal : data.cargoRepresentanteLegal ? data.cargoRepresentanteLegal : "",
                    moradaRepresentanteLegal: moradaRepresentanteLegal ? moradaRepresentanteLegal : data.moradaRepresentanteLegal ? data.moradaRepresentanteLegal : "",
                    postalCodeRepresentanteLegal: postalCodeRepresentanteLegal ? postalCodeRepresentanteLegal : data.postalCodeRepresentanteLegal ? data.postalCodeRepresentanteLegal : "",
                    distritoRepresentanteLegal: distritoRepresentanteLegal ? distritoRepresentanteLegal : data.distritoRepresentanteLegal ? data.distritoRepresentanteLegal : "",
                    concelhoRepresentanteLegal: concelhoRepresentanteLegal ? concelhoRepresentanteLegal : data.concelhoRepresentanteLegal ? data.concelhoRepresentanteLegal : "",
                    localidadeRepresentanteLegal: localidadeRepresentanteLegal ? localidadeRepresentanteLegal : data.localidadeRepresentanteLegal ? data.localidadeRepresentanteLegal : "",
                    certificado: certificadoResults ? certificadoResults : data.certificado,
                    assinatura: assinaturaResults ? assinaturaResults : data.assinatura,
                })
            })
                .then(res => res.json())
                .then((result) => {
                    toastSuccess("Entidade atualizada com sucesso.")
                    setAltetar(false)
                    setLoading(false);
                    setCont(cont + 1)
                });
        } else {
            toastError("Nif inválido");
        }
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                {token === "admin1" ? <><div className="w-full">
                    <NavbarAdmin1 currentPage={"gerirEntidadeTraining"} />
                </div>
                    <Link to={'/menuadmin/' + params.id_candidatura + "/entFormadoras"} className="mt-5 back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link></> :
                    <><div className="w-full">
                        <NavbarAdmin2 currentPage={"gerirEntidadeTraining"} />
                    </div>
                        <Link to={"/menuSuperAdmin/entFormadora"} className="mt-5 back-button-div-noNavbar">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </Link></>
                }
                <div className="flex-grow mx-5 mt-20 sm:mt-8 sm:mx-0">
                    <div>
                        <h1 className="title">Entidade Formadora - {data.name}</h1>
                        <div className={token === "admin2"?"relative mx-auto transition cursor-pointer group hover:opacity-80 w-fit":"relative mx-auto transition"} onClick={() => { token === "admin2" &&document.getElementById('fileInput').click(); }} >
                            <img className="h-32 mx-auto" alt="Logo" src={data.logotipo} />
                           {token === "admin2" && <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                <BsPencilFill className="text-black" />
                            </div>}
                            {token === "admin2" &&
                            <FileUploader
                                inputAccept={".png, .jpeg, .jpg"}
                                inputStyle={{ display: 'none' }}
                                inputType={"file"}
                                inputId={"fileInput"}
                                onChangeSpecial={handleFileUpload}
                                allowedTypes={[
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                ]}
                            />         
                            }
                        </div>
                        <div className="flex flex-col items-center my-4 ">
                            {alterar === false ?
                                <div className="grid grid-cols-1 mx-auto my-4 sm:grid-cols-2 gap-x-6">
                                    <div className="text-left">
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Gestor de Formação:</h3>
                                            <span className="third-title">{data.gestorFormacao}</span>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input"> Código DGERT: </h3>
                                            <span className="third-title">{data.codDGERT}</span>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Morada:</h3>
                                            <span className="third-title">{data.morada}</span>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Localidade:</h3>
                                            <span className="third-title">{data.localidade}</span>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Distrito:</h3>
                                            {data.distrito? <span className="third-title">{data.distrito}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Concelho:</h3>
                                            {data.concelho ?<span className="third-title">{data.concelho}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Código Postal:</h3>
                                            <span className="third-title">{data.postalCode}</span>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Número de identificação de pessoa coletiva:</h3>
                                            <span className="third-title">{data.nif}</span>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Representante Legal:</h3>
                                            <span className="third-title">{data.representanteLegal}</span>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Nif do Representante Legal:</h3>
                                            {data.nifRepresentanteLegal ?<span className="third-title">{data.nifRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">CC do Representante Legal:</h3>
                                            {data.ccRepresentanteLegal ?<span className="third-title">{data.ccRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Validade do CC do Representante Legal:</h3>
                                            {data.validadeCcRepresentanteLegal ?<span className="third-title">{data.validadeCcRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Cargo do Representante Legal:</h3>
                                            {data.cargoRepresentanteLegal ?<span className="third-title">{data.cargoRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Morada Atual do Representante Legal:</h3>
                                            {data.moradaRepresentanteLegal ?<span className="third-title">{data.moradaRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Código Postal do Representante Legal:</h3>
                                            {data.postalCodeRepresentanteLegal ? <span className="third-title">{data.postalCodeRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Freguesia da Naturalidade do Representante Legal:</h3>
                                            {data.localidadeRepresentanteLegal ?<span className="third-title">{data.localidadeRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Distrito da Naturalidade do Representante Legal:</h3>
                                            {data.distritoRepresentanteLegal ?<span className="third-title">{data.distritoRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="label-input">Concelho da Naturalidade do Representante Legal:</h3>
                                            {data.concelhoRepresentanteLegal ?<span className="third-title">{data.concelhoRepresentanteLegal}</span>:<div className="input-error-profile" />}
                                        </div>
                                        <div className="flex items-center">
                                            <h3 className="mb-0 mr-1 third-title">Certificado DGERT:</h3>
                                            <a className="button-geral" target="_blank" href={data.certificado} download={"CertificadoDGERT" + data.codDGERT} ><FiDownload /> </a>
                                        </div>
                                        <div className="flex items-center">
                                            <h3 className="mb-0 mr-1 third-title">Assinatura:</h3>
                                            <a className="button-geral" target="_blank" href={data.assinatura} download={"Assinatura" + data.assinatura} ><FiDownload /> </a>
                                        </div>
                                    </div>
                                </div> :
                                <div className="grid grid-cols-1 mx-auto my-4 sm:grid-cols-2 gap-x-6">
                                    <div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Nome:</h3>
                                            <input className="input-field-long" defaultValue={data.name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Gestor de Formação:</h3>
                                            <input className="input-field-long" defaultValue={data.gestorFormacao} onChange={(e) => setGestorFormacao(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="text-left third-title">Código DGERT:</h3>
                                            <input className="input-field-long" defaultValue={data.codDGERT} onChange={(e) => setCodDGERT(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Morada:</h3>
                                            <input className="input-field-long" defaultValue={data.morada} onChange={(e) => setMorada(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Localidade:</h3>
                                            <input className="input-field-long" defaultValue={data.localidade} onChange={(e) => setLocalidade(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="mt-2 text-left third-title">Distrito:</h3>
                                            <input className="input-field-long" defaultValue={data.distrito} onChange={(e) => setDistrito(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="mt-2 text-left third-title">Concelho:</h3>
                                            <input className="input-field-long" defaultValue={data.concelho} onChange={(e) => setConcelho(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title lg:mt-0">Código Postal:</h3>
                                            <input className="input-field-long" defaultValue={data.postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Número de identificação de pessoa coletiva:</h3>
                                            <input className="input-field-long" defaultValue={data.nif} id="nif" onChange={(e) => setNif(e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.representanteLegal} onChange={(e) => setRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">NIF do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.nifRepresentanteLegal && data.nifRepresentanteLegal} id="nifRL" onChange={(e) => setNifRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">CC do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.ccRepresentanteLegal && data.ccRepresentanteLegal} id="ccRL" onChange={(e) => setCcRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Validade do CC do Representante Legal:</h3>
                                            <input className="input-field-long" type="date"  min={new Date().toISOString().split('T')[0]} defaultValue={data.validadeCcRepresentanteLegal && data.validadeCcRepresentanteLegal/* .toISOString().split('T')[0] */} id="validadeCcRL" onChange={(e) => setValidadeCcRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Cargo do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.cargoRepresentanteLegal && data.cargoRepresentanteLegal} id="cargoRL" onChange={(e) => setCargoRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Morada do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.moradaRepresentanteLegal && data.moradaRepresentanteLegal} onChange={(e) => setMoradaRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Freguesia do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.localidadeRepresentanteLegal && data.localidadeRepresentanteLegal} onChange={(e) => setLocalidadeRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="mt-2 text-left third-title">Distrito do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.distritoRepresentanteLegal && data.distritoRepresentanteLegal} onChange={(e) => setDistritoRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="mt-2 text-left third-title">Concelho do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.concelhoRepresentanteLegal && data.concelhoRepresentanteLegal} onChange={(e) => setConcelhoRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <h3 className="mt-2 text-left third-title">Código Postal do Representante Legal:</h3>
                                            <input className="input-field-long" defaultValue={data.postalCodeRepresentanteLegal && data.postalCodeRepresentanteLegal} onChange={(e) => setPostalCodeRepresentanteLegal(e.target.value)} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Certificado DGERT:</h3>
                                            <FileUploader
                                            inputClassName={"input-file"}
                                            inputAccept={".pdf"}
                                            inputType={"file"}
                                            inputId={"ficheiro"}
                                            inputName={"ficheiro"}
                                            allowedTypes={[
                                            "application/pdf",
                                            ]}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <h3 className="mt-2 text-left third-title">Assinatura:</h3>
                                            <FileUploader
                                            inputClassName={"input-file"}
                                            inputAccept={".png, .jpeg, .jpg"}
                                            inputType={"file"}
                                            inputId={"ficheiro"}
                                            inputName={"ficheiro"} 
                                            allowedTypes={[
                                            "image/png",
                                            "image/jpeg",
                                            "image/jpg",
                                            ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                   {token ==="admin2"&& <div className="flex justify-center mb-8">
                        {alterar === false ?
                            <button className="button-geral" onClick={() => setAltetar(true)}>Alterar</button> :
                            <button className="mr-3 button-cancelar" onClick={() => setAltetar(false)}>Cancelar</button>}
                        {alterar === true &&
                            <button className="button-confirmar" onClick={atualizarEntFormadora}>Confirmar</button>
                        }
                    </div>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default EntFormadoraIndividual