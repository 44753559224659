import React, { useState, useEffect, useRef } from "react";
import { MenuFormando } from "./styled";
import { useParams, useNavigate } from 'react-router-dom'
import Footer from "../Footer";
import Header from "../Header";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import Modal from 'react-modal'
import 'react-toastify/dist/ReactToastify.css';
import toastError from "../Toast/toastError";
import NavbarFormador from "../Navbars/NavbarFormador/navbarFormador";
import EventBox from "../eventBox/eventBox";
import toastSuccess from "../Toast/toastSuccess";
import { AiOutlineCloseCircle } from "react-icons/ai";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { BsClipboard2Check } from "react-icons/bs";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import VerificaHorario from "../FormChecks/verifyHorario/verifyHorario";
Modal.setAppElement('#root');

function MarcarEventos(token, rote) {
    const [loading, setLoading] = useState(true);
    const [data2, setData2] = useState(null);
    const [formadorEscolhido, setFormadorEscolhido] = useState(null);
    const [formadoresList, setFormadoresList] = useState([]);
    const [error, setError] = useState(null);
    const [percurso, setPercurso] = useState("");
    const [ufcd, setUfcd] = useState("");
    const [ufcdSelecionada, setUfcdSelecionada] = useState("");
    const [info, setInfo] = useState("");
    const [date, setDate] = useState("");
    const [tipo, setTipo] = useState("");
    const [startEvent, setStartEvent] = useState("");
    const [tituloEvent, setTituloEvent] = useState("");
    const [sessao, setSessao] = useState("");
    const [cont, setCont] = useState(0);
    const [eventoOpen, setEventoOpen] = useState(false);
    const [duration, setDuration] = useState("");
    const [horaInicio, setHoraInicio] = useState("");
    const [formato, setFormato] = useState("");
    let evento = []
    const [data, setData] = useState(null);
    const idArray = []
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [modal2Option, setModal2Option] = useState("");
    const [eventBoxPosition, setEventBoxPosition] = useState({ left: 0, top: 0 });
    const [eventInfo, setEventInfo] = useState(null);
    const eventBoxRef = useRef(null);
    const [buttonClassCheck, setButtonClassCheck] = useState(false);
    const [buttonClassCheckRemove, setButtonClassCheckRemove] = useState(false);


    const [events, setEvents] = useState([]);
    let array = []
    const params = useParams();
    const navigate = useNavigate();

    //Recebe todos os eventos
    function getEvento() {
        for (let i = 0; i < idArray.length; i++) {
            fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcds/${idArray[i].id}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        array[i] = result
                        array[i].forEach(element => {
                            const eventExists = events.some(event => event._id === element._id);
                            if (!eventExists) {
                                setEvents(prevState => [...prevState, element])
                            }
                        });
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );
        };
    }
    function getUfcd() {
        if (params.id_ufcd) {
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setPercurso(result.percurso[0]._id)
                        /* if (result) {
                            result.forEach(ufcds => {
                                idArray.push({ id: ufcds._id, name: ufcds.name })
                            });
                        }
                        getEvento() */
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                    }
                );
        }
        else setPercurso(params.id_curso)
    }

    function getUFCDName(id) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    const disciplina = result.codeUfcd + " - " + result.name
                    setUfcdSelecionada(disciplina)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            ); 
    }

    function getUfcdsPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${percurso}/percurso`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result) {
                        result.forEach(ufcds => {
                            idArray.push({ id: ufcds._id, name: ufcds.name })
                        });
                    }
                    getEvento()
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    //Cria um novo evento
    async function novo() {
        const titulo = document.getElementById('titulo').value;
        let startInput = document.getElementById('start').value;
        let durationInput = parseFloat(document.getElementById('duration').value);
        startInput = startInput + "T" + horaInicio + "Z"; // Interpret input as UTC
        let start = new Date(startInput);
        let nSessao =document.getElementById('nSessao').value
        const seletor = document.getElementById('seletor').value;
        let end = new Date(start.getTime() + (durationInput * (1000 * 60 * 60))); // Cria uma end date que adiciona + 1 hora ao start date

        if (!titulo && isNaN(titulo)) {
            toastError("Escreva um titulo");
            return;
        }

        // Impede prosseguir sem selecionar uma data inicial
        if (!start && isNaN(start)) {
            toastError("Selecione uma data inicial");
            return;
        }

        if (!duration) {
            toastError("Insira a duração do evento");
            return;
        }
        start = new Date(start.getTime());
        end = new Date(end.getTime());

        // Converte as horas em milisegundos
        const startTime = start.getTime();
        const endTime = end.getTime();

        // Verifica as horas para não acontecer overlap.
        for (let event of events) {
            // Converte as horas em milisegundos
            const eventStartTime = new Date(event.start).getTime();
            const eventEndTime = new Date(event.end).getTime();
            if ((startTime >= eventStartTime && startTime < eventEndTime) ||
                (endTime > eventStartTime && endTime <= eventEndTime) ||
                (startTime <= eventStartTime && endTime >= eventEndTime)) {
                toastError('O novo evento sobrepõe-se a um existente. Por favor, corrija.');
                return;
            }
        }

        evento.push({
            start: start.toISOString(),
            end: end.toISOString(),
        });

        if (seletor) {
            fetch(`${process.env.REACT_APP_API_URL}/calendar/${document.getElementById('seletor').value}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                body: JSON.stringify({
                    title: `${data.formato === "Misto" ? (tipo === "Presencial" ? "P" : "VC") : (data.formato === "Presencial" ? "P" : "VC")}${sessao } - ${titulo}`,
                    sessao: sessao,
                    evento: evento,
                    id_ufcds: (document.getElementById('seletor').value),
                    color: data.color,
                    id_formador: token === "user" ? params.id_candidatura : formadorEscolhido,
                    tipo: formato === "Misto" ? document.getElementById('seletor2').value : formato
                }),
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        limparModal()
                        setModalIsOpen(false)
                        setCont(cont + 1)
                        toastSuccess("Evento criado com Sucesso")

                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );
        }
    }

    function addTimeToDate(dateString, timeToAdd) {
        const initialDate = new Date(dateString);

        // Split the timeToAdd into hours and minutes
        const [hours, minutes] = timeToAdd.split(':');

        // Set the time on the initialDate
        initialDate.setUTCHours(hours);
        initialDate.setUTCMinutes(minutes);

        return initialDate;
    }

// Function to adjust a date to Portugal timezone
function formatToCustomString(date) {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get parts of the date
    const dayOfWeek = weekdays[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours() - VerificaHorario( new Date(date) );
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const timezoneOffset = date.getTimezoneOffset() / -60; // Convert timezone offset to positive value

    // Construct the formatted string
    const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}:${seconds} GMT${timezoneOffset > 0 ? '+' : ''}${timezoneOffset}00 (Hora de verão da Europa Ocidental)`;
    return formattedDate;
}

    async function alterarEvento(id) {
        let titulo = tituloEvent
            ? tituloEvent
            : getSubstringAfterDash(info.event._def.title);

        let startInput = startEvent
            ? startEvent
            : formatToCustomString(info.event._instance.range.start);
        let durationInput = duration
            ? parseFloat(duration)
            : parseFloat(subtractTimes((info.event._instance.range.start),(info.event._instance.range.end)).toString());
        let nSessao = (sessao || sessao === "0") ? sessao : info.event._def.extendedProps.sessao;
        let hourInput = horaInicio ? horaInicio : getFormattedHour2(formatToCustomString(info.event._instance.range.start)).toString()
            startInput = addTimeToDate(startInput, hourInput);
        let start = new Date(startInput);
        let end = new Date(start.getTime() + (durationInput * (1000 * 60 * 60))); // Cria uma end date que adiciona + 1 hora ao start date
        if (!titulo && isNaN(titulo)) {
            toastError("Escreva um titulo");
            return;
        }

        // Impede prosseguir sem selecionar uma data inicial
        if (!start && isNaN(start)) {
            toastError("Selecione uma data inicial");
            return;
        }

        if (!durationInput) {
            toastError("Insira a duração do evento");
            return;
        }
        start = new Date(start.getTime());
        end = new Date(end.getTime());
        // Converte as horas em milisegundos
        const startTime = start.getTime();
        const endTime = end.getTime();

        // Verifica as horas para não acontecer overlap.
        for (let event of events) {
            if (event._id !== id) {
                // Converte as horas em milisegundos
                const eventStartTime = new Date(event.start).getTime();
                const eventEndTime = new Date(event.end).getTime();
                if ((startTime >= eventStartTime && startTime < eventEndTime) ||
                    (endTime > eventStartTime && endTime <= eventEndTime) ||
                    (startTime <= eventStartTime && endTime >= eventEndTime)) {
                    toastError('O novo evento sobrepõe-se a um existente. Por favor, corrija.');
                    return;
                }
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/calendar/${id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            body: JSON.stringify({
                title: `${data.formato === "Misto" ? (tipo === "Presencial" ? "P" : "VC") : (data.formato === "Presencial" ? "P" : "VC")}${sessao? sessao : nSessao} - ${titulo}`,
                sessao: nSessao,
                start: start.toISOString(),
                end: end.toISOString(),
                id_formador: formadorEscolhido && formadorEscolhido,
                color: data.color,
                tipo: formato === "Misto" ? document.getElementById('seletor2').value : data.formato
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    limparModal()
                    setModalIsOpen2(false)
                    setCont(cont + 1)
                    toastSuccess("Evento criado com Sucesso")
                    setEvents([])
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );

    }

    function limparModal() {
        if (ufcd) {
            setButtonClassCheck(false)
            setUfcd("")
            setTipo("")
            evento = []
            document.getElementById('titulo').value = ""
            document.getElementById('start').value = ""
        }
        setHoraInicio("")
        setDuration("")
        setSessao("")
        setStartEvent("")
        setTituloEvent("")
        setFormadorEscolhido("")
    }

    function removerEvento(id) {
        if (id) {
            fetch(`${process.env.REACT_APP_API_URL}/calendar/${id}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setModalIsOpen2(false)
                        setButtonClassCheckRemove(false)
                        setEventoOpen(false)
                        const updatedEvents = events.filter(event => event._id !== id);
                        setEvents(updatedEvents)
                        toastSuccess("Evento removido com Sucesso")
                        setCont(cont + 1)
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );
        } else toastError("Selecione o evento a remover.")
    }

    //Função de paragem consuante os milisegundos inseridos
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    //Cria um novo evento
    async function novoEvento() {
        await ufcdID(document.getElementById('seletor').value)
        await sleep(300)
        await novo()
    }

    function UFCDsFormador() {
        if (token === "user") {
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/${percurso}/percurso/${params.id_candidatura}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
                .then(response => response.json())
                .then(result => {
                    setData2(result)
                });
        }
        else {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/${percurso}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
                .then(response => response.json())
                .then(result => {
                    setData2(result.ufcds)
                });
        }
    }

    function tipoEvento(evento) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${evento}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then(response => response.json())
            .then(result => {
                setFormato(result.formato)
            });
    }

    function Ufcds() {
        const seletor = document.getElementById('ufcds')

        if (seletor) {
            seletor.innerHTML = '<option value="" selected disabled >Selecionar UFCD</option>';
            fetch(`${process.env.REACT_APP_API_URL}/calendar/formador/${params.id_candidatura}/${percurso}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
                .then(response => response.json())
                .then(result => {
                    // Construir o Seletor
                    result.forEach(ufcd => {
                        const option = document.createElement('option');
                        option.value = ufcd._id;
                        option.innerHTML = ufcd.name ?? "vazio";
                        seletor.appendChild(option);
                    });
                });
        }
    }

    function Eventos(e) {
        const seletor = document.getElementById('evento')
        if (seletor) {
            seletor.innerHTML = '<option value="" disabled selected>Selecionar o Evento</option>';
            fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcds/${e}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
                .then(response => response.json())
                .then(result => {
                    // Construir o Seletor
                    result.forEach(ufcd => {
                        const option = document.createElement('option');
                        option.value = ufcd._id;
                        option.innerHTML = ufcd.title ?? "vazio";
                        seletor.appendChild(option);
                    });
                });
        }
    }
    //Recebe ufcds associadas ao calendario
    function ufcdID(id) {
        fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcd/${id}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result)
                    if (result.formadores.length === 1) {
                        setFormadorEscolhido(result.formadores[0]._id)
                    }
                    else {
                        const sortedData = result.formadores.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
                        setFormadoresList(sortedData)
                    }
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function getFormattedDate(date) {
        if (!date) {
            return ''; // Return an empty string or any default value you prefer when date is null or undefined
        }

        const dateObj = new Date(date);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function getFormattedHour(date) {
        if (!date) {
            return ''; // Return an empty string or any default value you prefer when date is null or undefined
        }
        const dateObj = new Date(date);
        const hours = dateObj.getUTCHours();
        const min = dateObj.getUTCMinutes();
        return `${hours < 10 ? '0' : ''}${hours}:${min < 10 ? '0' : ''}${min}`;
    }
    function getFormattedHour2(date) {
        if (!date) {
            return ''; // Return an empty string or any default value you prefer when date is null or undefined
        }
        const dateObj = new Date(date);
        const hours = dateObj.getHours()
        const min = dateObj.getMinutes()
        return `${hours < 10 ? '0' : ''}${hours}:${min < 10 ? '0' : ''}${min}`;
    }
    const subtractTimes = (dateBegin, dateEnd) => {
        const time1 = getFormattedHour(dateBegin)
        const time2 = getFormattedHour(dateEnd)
        const [hour1, min1] = time1.split(':').map(Number);
        const [hour2, min2] = time2.split(':').map(Number);

        let resultHour = hour2 - hour1;
        let resultMin = min2 - min1;

        if (resultMin < 0) {
            resultHour -= 1;
            resultMin += 60;
        }
        return (`${resultHour}${resultMin === 30 ? '.5' : ''}`);
    };

    const handleEventMouseEnter = (arg) => {
        if (arg.view.type === 'dayGridMonth' && !eventInfo) {
            // When the cursor enters an event and the event box is not already open,
            // show the event box and set the event information
            setEventBoxPosition({ left: arg.jsEvent.pageX, top: arg.jsEvent.pageY });
            setEventInfo(arg.event);
        }
    };

    const handleEventMouseLeave = () => {
        setEventInfo(null);
    };

    const handleLinkClick = (info) => {
        info.jsEvent.preventDefault();
        window.open(`/#/Presencas/${info.event._def.extendedProps.ufcds[0]}/${info.event._def.extendedProps._id}`, '_blank');
    };

    function setModalTrue(date) {
        setDate(getFormattedDate(date.date))
        setModalIsOpen(true)
    }
    function setModalTrue2() {
        setModalIsOpen2(true)
    }

    async function setEventoTrue(e) {
        await setEventoOpen(true)
        await Eventos(e)
    }

    function getButtonClassAddEvento() {
        const seletor = document.getElementById('seletor')
        if (seletor.value) {
            setButtonClassCheck(true)
        } else setButtonClassCheck(false)
    }

    function getButtonClassRemoveEvento() {
        const seletor = document.getElementById('ufcds')
        if (seletor.value) {
            setButtonClassCheckRemove(true)
        } else setButtonClassCheckRemove(false)
    }


    const getSubstringAfterDash = (title) => {
        const indexOfDash = title.indexOf('-');
        return title.substring(indexOfDash + 1).trim()

    };

    useEffect(() => {
        if (percurso) {
            getUfcdsPercurso()
            UFCDsFormador()
            Ufcds()
        }
        else getUfcd()
    }, [loading, cont, buttonClassCheck, percurso, date, modal2Option, info, sessao]);

    return (
        <>
            <main className={token.includes("admin") ? "min-h-screen" : "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10"}>

                {(token === "user" || token === "coord") ? <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    {token === "user" && <NavbarFormador currentPage={rote} />}
                    {token === "coord" && <NavbarCoordenador currentPage={rote} />}
                </div> :
                    <div className="w-full">
                        {token === "admin1" && <NavbarAdmin1 currentPage={rote} />}
                        {token === "admin2" && <NavbarAdmin2 currentPage={rote} />}
                    </div>}

                <div className={token.includes("coord") ? "relative w-full h-full col-span-5 xl:col-span-8" : ""}>
                    <div className={token.includes("admin") ? "back-button-div-noNavbar" : "back-button-noNavbar"}>
                        <button onClick={() => navigate(-1)} className="flex w-8">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                    </div>
                    {!token.includes("admin") && <Header sideMenu={"true"} />}
                    <MenuFormando>
                        <div className="mt-8">
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                locale={ptLocale}
                                timeZone="UTC"
                                themeSystem="standard"
                                aspectRatio="1.5"
                                initialView={'dayGridMonth'}
                                headerToolbar={{
                                    left: 'dayGridMonth,timeGridWeek,timeGridDay',
                                    center: "title",
                                    end: 'today prev,next',
                                }}
                                dateClick={(dateClickInfo) => setModalTrue(dateClickInfo)}
                                eventClick={function (info) {
                                    info.jsEvent.preventDefault();
                                    setModalIsOpen2(true)
                                    getUFCDName(info.event._def.extendedProps.ufcds[0])
                                    setInfo(info)
                                    ufcdID(info.event._def.extendedProps.ufcds[0])
                                    setModal2Option("")
                                }}
                                events={events}
                                eventMouseEnter={handleEventMouseEnter}
                                eventMouseLeave={handleEventMouseLeave} />
                            {eventInfo && (
                                <EventBox
                                    ref={eventBoxRef}
                                    position={eventBoxPosition}
                                    eventInfo={eventInfo}
                                    onClose={handleEventMouseLeave}
                                    onLinkClick={handleLinkClick} />)}
                        </div>
                        <div className="modal2" style={{ display: modalIsOpen ? "block" : "none" }}>
                            {/* <UFCDsFormador /> */}
                            <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                                <div className="flex items-center justify-center h-screen">
                                    <div className="relative z-50 p-2 rounded-lg shadow-lg max-h-128 xl:w-128 bg-secundary-color shadow-gray-600">
                                        <div className="flex flex-col justify-center my-2">
                                            <h2 className="subtitle">Novo Evento</h2>
                                            <div className="flex flex-col modal2-body">
                                                <div className="flex flex-row items-center my-2">
                                                    <label className="w-24 mr-1 text-start label-input">Disciplina</label>
                                                    <select onWheel={(e) => e.target.blur()} className="selector w-60" name="seletor" id="seletor" value={ufcd ? ufcd : ""} onChange={(e) => { setUfcd(e.target.value); tipoEvento(e.target.value); ufcdID(e.target.value); getButtonClassAddEvento(); }}>
                                                        <option value="" disabled>Selecionar UFCD</option>
                                                        {data2 && <>
                                                            {
                                                                data2.map((item, index) => (
                                                                    <option key={index} value={item._id} >{item.name}</option>
                                                                ))
                                                            }
                                                        </>
                                                        }
                                                    </select>
                                                </div>
                                                {ufcd && data &&
                                                    <>{data.formato === "Misto" &&
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 label-input">Tipo de Evento:</label>
                                                            <select onWheel={(e) => e.target.blur()} className="selector w-60" name="seletor2" id="seletor2" defaultValue={tipo} onChange={(e) => { setTipo(e.target.value); getButtonClassAddEvento() }}>
                                                                <option value="">Selecione um Formato</option>
                                                                <option value="Online">Online</option>
                                                                <option value="Presencial">Presencial</option>
                                                            </select>
                                                        </div>
                                                    }
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Nº da Sessão</label>
                                                            <select onWheel={(e) => e.target.blur()} type="text" className="selector w-60" id="nSessao" name="nSessao" defaultValue={sessao ? sessao : ""} onChange={(e) => { setSessao(e.target.value); getButtonClassAddEvento() }} required>
                                                                <option value="" disabled>Selecione o número da sessão</option>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="13">13</option>
                                                                <option value="14">14</option>
                                                                <option value="15">15</option>
                                                                <option value="16">16</option>
                                                                <option value="17">17</option>
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                            </select>
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Título</label>
                                                            <div className="flex">
                                                                <input className="w-10 input-field disabled" name="titulo1" id="titulo1" disabled value={`${data.formato === "Misto" ? (tipo === "Presencial" ? "P" : "VC") : (data.formato === "Presencial" ? "P" : "VC")}${sessao}`} />
                                                                <div className="mx-1">-</div>
                                                                <input className="w-55 input-field" name="titulo" id="titulo" placeholder="Título do Evento" onChange={getButtonClassAddEvento} />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Data Início</label>
                                                            {data && <input type="date" max={getFormattedDate(data.dateEnd)} defaultValue={date} onChange={getButtonClassAddEvento} className="w-60 input-field" name="start" id="start" />}
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Hora de Início</label>
                                                            <select onWheel={(e) => e.target.blur()} type="text" className="selector w-60" id="hora" name="hora" value={horaInicio} onChange={(e) => { setHoraInicio(e.target.value); getButtonClassAddEvento() }} required>
                                                                <option value={""} disabled> Selecionar a duração</option>
                                                                <option value="08:00">08:00h</option>
                                                                <option value="08:30">08:30h</option>
                                                                <option value="09:00">09:00h</option>
                                                                <option value="09:30">09:30h</option>
                                                                <option value="10:00">10:00h</option>
                                                                <option value="10:30">10:30h</option>
                                                                <option value="11:00">11:00h</option>
                                                                <option value="11:30">11:30h</option>
                                                                <option value="12:00">12:00h</option>
                                                                <option value="12:30">12:30h</option>
                                                                <option value="13:00">13:00h</option>
                                                                <option value="13:30">13:30h</option>
                                                                <option value="14:00">14:00h</option>
                                                                <option value="14:30">14:30h</option>
                                                                <option value="15:00">15:00h</option>
                                                                <option value="15:30">15:30h</option>
                                                                <option value="16:00">16:00h</option>
                                                                <option value="16:30">16:30h</option>
                                                                <option value="17:00">17:00h</option>
                                                                <option value="17:30">17:30h</option>
                                                                <option value="18:00">18:00h</option>
                                                                <option value="18:30">18:30h</option>
                                                                <option value="19:00">19:00h</option>
                                                                <option value="19:30">19:30h</option>
                                                                <option value="20:00">20:00h</option>
                                                                <option value="20:30">20:30h</option>
                                                                <option value="21:00">21:00h</option>
                                                                <option value="21:30">21:30h</option>
                                                                <option value="22:00">22:00h</option>
                                                                <option value="22:30">22:30h</option>
                                                                <option value="22:00">23:00h</option>
                                                            </select>
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Duração</label>
                                                            <select onWheel={(e) => e.target.blur()} type="text" className="selector w-60" id="duration" name="duration" value={duration} onChange={(e) => { setDuration(e.target.value); getButtonClassAddEvento(); }} required>
                                                                <option value={""} disabled>Selecionar a duração</option>
                                                                <option value="1">1h</option>
                                                                <option value="1.5">1:30h</option>
                                                                <option value="2">2h</option>
                                                                <option value="2.5">2:30h</option>
                                                                <option value="3">3h</option>
                                                                <option value="3.5">3h:30</option>
                                                                <option value="4">4h</option>
                                                                <option value="4.5">4h:30</option>
                                                                <option value="5">5h</option>
                                                            </select>
                                                        </div>

                                                        {token != "user" && data.formadores.length > 1 && formadoresList && <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Formador</label>
                                                            <select
                                                                onWheel={(e) => e.target.blur()}
                                                                className="selector w-60"
                                                                defaultValue={""}
                                                                onChange={(e) => setFormadorEscolhido(e.target.value)}
                                                            >
                                                                <option value="" disabled>Selecione uma opção</option>
                                                                {formadoresList.map((value) => (
                                                                    <option value={value._id} key={value._id}>
                                                                        {value.fullName}
                                                                    </option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                        }</>}
                                                {(formadoresList || formadorEscolhido) && <div className="flex justify-center m-4">
                                                    <button className={buttonClassCheck&& sessao && horaInicio && duration &&  (formadoresList.length >= 1 ||formadorEscolhido )? "button-geral" : "button-disabled tooltip"} data-tip={formadoresList.length === 0 ||  !formadorEscolhido  ? "Sem Formador Associado": "Dados por Preencher"} onClick={()=> {if( (formadoresList.length >= 1 ||formadorEscolhido ) && sessao && horaInicio && duration  )novoEvento()}}>Associar</button>
                                                </div>}
                                                <button className="absolute top-1.5 right-1.5" onClick={() => { limparModal(); setModalIsOpen(false) }}>
                                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal2" style={{ display: modalIsOpen2 ? "block" : "none" }}>
                            <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                                <div className="flex items-center justify-center h-screen">
                                    <div className="relative z-50 p-2 rounded-lg shadow-lg max-h-128 xl:w-128 bg-secundary-color shadow-gray-600 min-w-[40%] min-h-[60%]">
                                        {modalIsOpen2 && modal2Option !== "" && <div className="flex flex-row gap-2 justify-center items-center">
                                            <button onClick={() => window.open(`/#/Presencas/${info.event._def.extendedProps.ufcds[0]}/${info.event._def.extendedProps._id}`, '_blank')} className="tooltip" data-tip={"Gerir Presenças"}><BsClipboard2Check className=" px-2 text-main-color hover:text-text-second-color" size={60} /></button>
                                            <button onClick={() => setModal2Option("alterar")} className="tooltip" data-tip={"Alterar Evento"}><FiEdit className={modal2Option === "alterar" ? "p-2 text-third-color tooltip" : "p-2 text-main-color hover:text-text-second-color "} size={60} /></button>
                                            <button onClick={() => setModal2Option("remover")} className="tooltip" data-tip={"Remover Evento"}><FiTrash2 className={modal2Option === "remover" ? "p-2 text-third-color" : "p-2 text-main-color hover:text-text-second-color"} size={60} /></button>
                                        </div>}
                                        {modalIsOpen2 && modal2Option !== "" && <hr className="bg-main-color my-2 h-1" />}
                                        {modalIsOpen2 && modal2Option === "" &&
                                            <div>
                                                {info &&  <h2 className="subtitle">{ufcdSelecionada}</h2>}
                                                <h2 className="subtitle">{info.event._def.title}</h2>
                                                <div className="flex flex-col justify-center items-center">
                                                    <button onClick={() => window.open(`/#/Presencas/${info.event._def.extendedProps.ufcds[0]}/${info.event._def.extendedProps._id}`, '_blank')} className="tooltip contents justify-center" data-tip={"Gerir Presenças"}><BsClipboard2Check className=" py-2 text-main-color hover:text-text-second-color" size={100} /> <span className="subtitle text-lg">Gerir Presenças</span></button>
                                                    <button onClick={() => setModal2Option("alterar")} className="tooltip contents justify-center" data-tip={"Alterar Evento"}><FiEdit className={modal2Option === "alterar" ? "py-2 text-third-color tooltip" : "py-2 text-main-color hover:text-text-second-color "} size={100} /><span className="subtitle text-lg">Alterar Evento</span></button>
                                                    <button onClick={() => setModal2Option("remover")} className="toolttooltip contents justify-centerip" data-tip={"Remover Evento"}><FiTrash2 className={modal2Option === "remover" ? "py-2 text-third-color" : "py-2 text-main-color hover:text-text-second-color"} size={100} /><span className="subtitle text-lg">Remover Evento</span></button>
                                                </div>

                                            </div>
                                        }

                                        {modal2Option === "alterar" && <div >
                                            <div className="flex flex-col justify-center my-2">
                                                <h2 className="subtitle">Alterar Evento</h2>
                                                <div className="flex flex-col modal2-body">
                                                    <>{formato === "Misto" ?
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1  text-left  label-input">Tipo de Evento:</label>
                                                            <select onWheel={(e) => e.target.blur()} className="selector w-60" name="seletor2" id="seletor2" defaultValue={tipo} onChange={(e) => { setTipo(e.target.value); getButtonClassAddEvento() }}>
                                                                <option value="">Selecione um Formato</option>
                                                                <option value="Online">Online</option>
                                                                <option value="Presencial">Presencial</option>
                                                            </select>
                                                        </div> : <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1  text-left  label-input">Tipo de Evento:</label>
                                                            <select onWheel={(e) => e.target.blur()} className="selector w-60 disabled" disabled name="seletor2" id="seletor2" defaultValue={data.formato}>
                                                                <option value="">Selecione um Formato</option>
                                                                <option value="Online">Online</option>
                                                                <option value="Presencial">Presencial</option>
                                                            </select>
                                                        </div>
                                                    }
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Nº da Sessão</label>
                                                            <select onWheel={(e) => e.target.blur()} type="text" className="selector w-60" id="nSessao" name="nSessao" defaultValue={info.event._def.extendedProps.sessao ? info.event._def.extendedProps.sessao : ""} onChange={(e) => setSessao(e.target.value)} required>
                                                                <option value="" disabled>Selecione o número da sessão</option>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="13">13</option>
                                                                <option value="14">14</option>
                                                                <option value="15">15</option>
                                                                <option value="16">16</option>
                                                                <option value="17">17</option>
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                            </select>
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Título</label>
                                                            <div className="flex">
                                                                <input className="w-10 input-field disabled" name="titulo1" id="titulo1" disabled value={`${data.formato === "Misto" ? (tipo === "Presencial" ? "P" : "VC") : (data.formato === "Presencial" ? "P" : "VC")}${sessao ? sessao : (info.event._def.extendedProps.sessao ? info.event._def.extendedProps.sessao : "")}`} />
                                                                <div className="mx-1">-</div>
                                                                <input className="w-55 input-field" name="titulo" id="titulo" placeholder="Título do Evento" onChange={(e) => setTituloEvent(e.target.value)} defaultValue={getSubstringAfterDash(info.event._def.title)} />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Data Início</label>
                                                            <input type="date" max={getFormattedDate(data.dateEnd)} defaultValue={getFormattedDate(info.event._instance.range.start)} onChange={(e) => setStartEvent(e.target.value)} className="w-60 input-field" name="start" id="start" />
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Hora de Início</label>
                                                            <select onWheel={(e) => e.target.blur()} type="text" className="selector w-60" id="hora" name="hora" defaultValue={getFormattedHour(info.event._instance.range.start).toString()} onChange={(e) => { setHoraInicio(e.target.value) }} required>
                                                                <option value={""} disabled> Selecionar a duração</option>
                                                                <option value="08:00">08:00h</option>
                                                                <option value="08:30">08:30h</option>
                                                                <option value="09:00">09:00h</option>
                                                                <option value="09:30">09:30h</option>
                                                                <option value="10:00">10:00h</option>
                                                                <option value="10:30">10:30h</option>
                                                                <option value="11:00">11:00h</option>
                                                                <option value="11:30">11:30h</option>
                                                                <option value="12:30">12:30h</option>
                                                                <option value="13:00">13:00h</option>
                                                                <option value="13:30">13:30h</option>
                                                                <option value="14:00">14:00h</option>
                                                                <option value="14:30">14:30h</option>
                                                                <option value="15:00">15:00h</option>
                                                                <option value="15:30">15:30h</option>
                                                                <option value="16:00">16:00h</option>
                                                                <option value="16:30">16:30h</option>
                                                                <option value="17:00">17:00h</option>
                                                                <option value="17:30">17:30h</option>
                                                                <option value="18:00">18:00h</option>
                                                                <option value="18:30">18:30h</option>
                                                                <option value="19:00">19:00h</option>
                                                                <option value="19:30">19:30h</option>
                                                                <option value="20:00">20:00h</option>
                                                                <option value="20:30">20:30h</option>
                                                                <option value="21:00">21:00h</option>
                                                                <option value="21:30">21:30h</option>
                                                                <option value="22:00">22:00h</option>
                                                                <option value="22:30">22:30h</option>
                                                                <option value="22:00">23:00h</option>
                                                            </select>
                                                        </div>
                                                        <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Duração</label>
                                                            <select onWheel={(e) => e.target.blur()} type="text" className="selector w-60" id="duration" name="duration" defaultValue={subtractTimes(info.event._instance.range.start, info.event._instance.range.end).toString()} onChange={(e) => { setDuration(e.target.value) }} required>
                                                                <option value={""} disabled>Selecionar a duração</option>
                                                                <option value="1">1h</option>
                                                                <option value="1.5">1:30h</option>
                                                                <option value="2">2h</option>
                                                                <option value="2.5">2:30h</option>
                                                                <option value="3">3h</option>
                                                                <option value="3.5">3h:30</option>
                                                                <option value="4">4h</option>
                                                                <option value="4.5">4h:30</option>
                                                                <option value="5">5h</option>
                                                            </select>
                                                        </div>
                                                        {token != "user" && data.formadores.length > 1 && <div className="flex flex-row items-center my-2">
                                                            <label className="w-24 mr-1 text-left label-input">Formador</label>
                                                            <select
                                                                onWheel={(e) => e.target.blur()}
                                                                className="selector w-60"
                                                                defaultValue={info.event._def.extendedProps.formadores[0] ? info.event._def.extendedProps.formadores[0] : ""}
                                                                onChange={(e) => setFormadorEscolhido(e.target.value)}
                                                            >
                                                                <option value="" disabled>Selecione uma opção</option>
                                                                {formadoresList.map((value) => (
                                                                    <option value={value._id} key={value._id}>
                                                                        {value.fullName}
                                                                    </option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                        }</>
                                                    <div className="flex justify-center m-4">
                                                        <button className={"button-geral"} onClick={() => alterarEvento(info.event._def.extendedProps._id)}>Alterar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {modal2Option === "remover" &&
                                            <div className="flex flex-col mt-20">
                                                <div className="flex flex-col justify-center my-2">
                                                    <h2 className="subtitle">Remover Evento</h2>
                                                </div>

                                                <div className="flex flex-col items-center my-2">
                                                    <p>Tem a certeza que deseja eliminar este evento?</p>
                                                    <p className="text-red-400 text-sm">Ao fazê-lo, perderá o acesso ao mesmo.</p>
                                                </div>


                                                <div className="flex justify-center m-4">
                                                    <button className={"button-geral"} onClick={(e) => removerEvento(info.event._def.extendedProps._id)}>Remover</button>
                                                </div>

                                            </div>}
                                        <button className="absolute top-1.5 right-1.5" onClick={(e) => setModalIsOpen2(false)}>
                                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MenuFormando >
                </div >
            </main >
            <Footer />
        </>
    );
}

export default MarcarEventos;