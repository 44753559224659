import { useState, useEffect } from 'react';

export default function DateTimeDifference(start, end) {
        // Calculate the difference in milliseconds
        const a = new Date(start);
        const b = new Date(end);

        const differenceInMilliseconds = b - a;

        // Convert milliseconds to hours
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

        return differenceInHours
}