import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import toastError from "../../../../components/Toast/toastError";
import onlyString from "../../../../components/FormChecks/onlyString/onlyString";
import containsPostalCode from "../../../../components/FormChecks/containsPostalCode/containsPostalCode";
import NavbarFormador from "../../../../components/Navbars/NavbarFormador/navbarFormador";
import { BsPencilFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import ConfirmToaster from "../../../../components/Toast/toastConfirmChoice";
import Modal from 'react-modal';
import { IoMdRemoveCircle } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import check_nif_number from "../../../../components/FormChecks/checkNif/checkNIf";
import CountryDropdown from "../../../../components/FormChecks/countryDropdown/countryDropdown";
import FileUploader from "../../../../utils/validationFileUploader";

export default function Trainer() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [numDoc, setNumDoc] = useState("");
    const [nome, setNome] = useState("")
    const [modal, setModal] = useState(false);
    const [outrosDocs, setOutrosDocs] = useState([])
    const [alterarFiles, setAlterarFiles] = useState(0);// Se 1 = Alterar , Se 2 = adicionar  , Se 0 = nada
    const [alterar, setAlterar] = useState(false);
    const [cont, setCont] = useState(0);
    const [teachingQualification, setTeachingQualification] = useState("")
    const [nationality, setNationality] = useState("")
    const [academicDegree, setAcademicDegree] = useState("")
    const [parishCounty, setParishCounty] = useState("")
    const [parishDistrict, setParishDistrict] = useState("")

    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    document.data = data
    const countyNaturality = data && data.parish.split(/[-\\/]/)[1];
    const districtNaturality = data && data.parish.split(/[-\\/]/)[0];
    const dateToday = new Date(year, month, day);

    const params = useParams();

    useEffect(() => {
      getFormadores(); // eslint-disable-next-line
    }, [cont, teachingQualification, alterar]);

    function alterarConfirmar() {
      setAlterar(true);
    }

    //transforma data em json
    function formDataToJson(formData) {
      var object = {};
      formData.forEach((value, key) => (object[key] = value));
      return JSON.stringify(object);
    }

    const handleNameChange = (e, index) => {
      const updatedOutrosDocs = [...outrosDocs];
      if (!updatedOutrosDocs[index]) {
        updatedOutrosDocs[index] = {
          name: "",
          file: null,
        };
      }
      updatedOutrosDocs[index].name = e.target.value;
      setOutrosDocs(updatedOutrosDocs);
    };

    const handleFileChange = (e, index) => {
      const updatedOutrosDocs = [...outrosDocs];
      updatedOutrosDocs[index].file = e.target.files[0];
      setOutrosDocs(updatedOutrosDocs);
    };

    const splitString = (string) => {
      const [string1, string2] = string.split(" - ");
      setParishDistrict(string1);
      setParishCounty(string2);
    };

    //Recebe um formador pelo seu id
    function getFormadores() {
      fetch(
        `${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}`,
        {
          headers: {
            authorization: localStorage.getItem("user"),
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setData(result);
            if (!teachingQualification) {
              setTeachingQualification(result.teachingQualification);
            }
            if (!academicDegree) {
              setAcademicDegree(result.academicDegree);
            }
            splitString(result.parish);
            getCandidaturas();
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
            setError(error);
          }
        );
    }

    //Recebe todos os formadores
    //Obtem candidaturas
    function getCandidaturas() {
      fetch(`${process.env.REACT_APP_API_URL}/formadores`, {
        headers: {
          authorization: localStorage.getItem("user"),
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setData2(result);
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
            setError(error);
          }
        );
    }

    //Da update da db de dados atualizados pelo utilizador
    function alterarPerfil() {
      let cellNumber = document.getElementById("cellNumber").value;
      if (
        cellNumber !== "" &&
        document.getElementById("email").value !== "" &&
        document.getElementById("address").value !== "" &&
        document.getElementById("locality").value !== "" &&
        document.getElementById("postalCode").value !== "" &&
        document.getElementById("county").value !== ""
      ) {
        if (
          !cellNumber.length > 13 ||
          !cellNumber.length < 9 ||
          !cellNumber < 0
        ) {
          let cont = 0;
          function verifyEmail() {
            for (var i = 0; i < data2.length; i++) {
              if (data2[i].email === document.getElementById("email").value) {
                if (
                  document.getElementById("email").defaultValue ===
                  document.getElementById("email").value
                );
                else cont++;
              }
            }
          }
          verifyEmail();
          // Verificar se o email tem "@" e o "."
          let checkEmail = document
            .getElementById("email")
            .value.includes("@" && ".");
          if (checkEmail === true && cont === 0) {
            if (document.getElementById("locality").value) {
              if (
                containsPostalCode(document.getElementById("postalCode").value)
              ) {
                if (onlyString(document.getElementById("county").value)) {
                  if (onlyString(document.getElementById("name").value)) {
                    const dateCC = new Date(
                      document.getElementById("ccExpiration").value
                    );
                    if (dateCC.getTime() > dateToday.getTime()) {
                      const birthDate = new Date(
                        document.getElementById("birthDate").value
                      );
                      if (birthDate.getTime() < dateToday.getTime()) {
                        if (
                          check_nif_number(document.getElementById("nif").value)
                        ) {
                          if (
                            document.getElementById("teachingQualification")
                              .value === "2" ||
                            (document.getElementById("teachingQualification")
                              .value === "1" &&
                              document.getElementById("recruitmentGroup")
                                .value !== "")
                          ) {
                            fetch(
                              `${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}/alterarPerfil`,
                              {
                                body: JSON.stringify({
                                  fullName:
                                    document.getElementById("name").value,
                                  ccNumber:
                                    document.getElementById("ccNumber").value,
                                  ccExpiration:
                                    document.getElementById("ccExpiration")
                                      .value,
                                  birthDate:
                                    document.getElementById("birthDate").value,
                                  nationality: nationality
                                    ? nationality
                                    : data.nationality,
                                  nif: document.getElementById("nif").value,
                                  parishNaturality: document.getElementById('parishNaturality').value,
                                  parish:
                                    parishCounty && parishDistrict
                                      ? parishDistrict + " - " + parishCounty
                                      : data.parish,
                                  courseDesignation:
                                    document.getElementById("courseDesignation")
                                      .value,
                                  teachingQualification:
                                    document.getElementById(
                                      "teachingQualification"
                                    ).value,
                                  conclusionYear:
                                    document.getElementById("conclusionYear") &&
                                    document.getElementById("conclusionYear")
                                      .value,
                                  cellNumber:
                                    document.getElementById("cellNumber").value,
                                  email: document.getElementById("email").value,
                                  academicDegree: academicDegree
                                    ? academicDegree
                                    : data.academicDegree,
                                  address:
                                    document.getElementById("address").value,
                                  locality:
                                    document.getElementById("locality").value,
                                  postalCode:
                                    document.getElementById("postalCode").value,
                                  county:
                                    document.getElementById("county").value,
                                  iban: document.getElementById("iban").value
                                    ? document.getElementById("iban").value
                                    : "",
                                  recruitmentGroup:
                                    document.getElementById(
                                      "teachingQualification"
                                    ).value === "1"
                                      ? document.getElementById(
                                          "recruitmentGroup"
                                        ).value
                                      : "",
                                  cap:
                                    document.getElementById("cap") &&
                                    document.getElementById("cap").value,
                                  professionalCertification:
                                    document.getElementById(
                                      "professionalCertification"
                                    ) &&
                                    document.getElementById(
                                      "professionalCertification"
                                    ).value,
                                  iva: document.getElementById("iva").value,
                                  irs: document.getElementById("irs").value,
                                  office:
                                    document.getElementById("office").value,
                                }),
                                method: "PATCH",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  authorization: localStorage.getItem("user"),
                                },
                              }
                            )
                              .then((res) => res.json())
                              .then((result) => {
                                if (result === "Error Email") {
                                  toastError("Email já existente.");
                                  setAlterar(false);
                                } else {
                                  setAlterar(false);
                                  toastSuccess("Dados alterados com sucesso!");
                                  setCont(cont + 1);
                                }
                              });
                          } else
                            toastError(
                              "Grupo de recrutamento em falta ou habilitação para docência errado"
                            );
                        } else toastError("Número de nif inválido.");
                      } else toastError("Data de Nascimento Inválida.");
                    } else toastError("Data do CC caducada.");
                  } else toastError("Nome Inválido");
                } else toastError("Concelho Inválido");
              } else toastError("Código Postal Inválido");
            } else toastError("Localidade inválida");
          } else toastError("Email Inválido ou Existente");
        } else toastError("Número Inválido");
      } else toastError("Dados não inseridos");
    }

    const handlePhotoUpload = async (event) => {
      setLoading(true); // Set loading to true before starting the upload

      const selectedFile = event.target.files[0];
      let ccForm = new FormData();
      ccForm.append("logo", selectedFile);

      const options = {
        method: "POST",
        headers: { Accept: "application/json" },
        credentials: "same-origin",
        body: ccForm,
      };

      try {
        const logoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/logo`,
          options
        );
        const logoResult = await logoResponse.json();
        await fetch(
          `${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}/photo`,
          {
            body: JSON.stringify({
              photo: logoResult,
            }),
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: localStorage.getItem("user"),
            },
          }
        );

        setAlterar(false);
        toastSuccess("Foto adicionada com sucesso!");
        setCont(cont + 1);
        setLoading(false);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false); // Make sure loading is set back to false even if an error occurs
        // Handle the error as needed
      }
    };

    //Submete ficheiros
    async function submeterFicheiros() {
      setLoading(true);
      let form = document.getElementById("form");
      let formDataInfo = new FormData();

      const cc_path =
        document.getElementById("cc") && document.getElementById("cc").value
          ? document.getElementById("cc")
          : "";
      const ch_path =
        document.getElementById("ch") && document.getElementById("ch").value
          ? document.getElementById("ch")
          : "";
      const ccp_path =
        document.getElementById("ccp") && document.getElementById("ccp").value
          ? document.getElementById("ccp")
          : "";
      const cv_path =
        document.getElementById("cv") && document.getElementById("cv").value
          ? document.getElementById("cv")
          : "";
      const ibancomp_path =
        document.getElementById("ibanDoc") &&
        document.getElementById("ibanDoc").value
          ? document.getElementById("ibanDoc")
          : "";

      function createOptions() {
        return {
          method: "POST",
          headers: { Accept: "application/json" },
          credentials: "same-origin",
        };
      }

      const promises = [];
      if (cc_path) {
        const cc = form.cc.files[0];
        let ccForm = new FormData();
        ccForm.append("cc", cc);
        const ccOptions = createOptions(); // Create separate options object
        ccOptions.body = ccForm;
        const ccResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/mcc`,
          ccOptions
        );
        const ccResult = await ccResponse.json();
        formDataInfo.append("cc", ccResult);
        promises.push(ccResponse);
      }

      if (ibancomp_path) {
        const ibanDoc = form.ibanDoc.files[0];
        let IbanForm = new FormData();
        IbanForm.append("ibancomp", ibanDoc);
        const ccOptions = createOptions(); // Create separate options object
        ccOptions.body = IbanForm;
        const IbanResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/mibancomp`,
          ccOptions
        );
        const IbanResult = await IbanResponse.json();
        formDataInfo.append("ibancomp", IbanResult);
        promises.push(IbanResponse);
      }

      if (ch_path) {
        const ch = form.ch.files[0];
        let chForm = new FormData();
        chForm.append("ch", ch);
        const chOptions = createOptions(); // Create separate options object
        chOptions.body = chForm;
        const chResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/mch`,
          chOptions
        );
        const chResult = await chResponse.json();
        formDataInfo.append("ch", chResult);
        promises.push(chResponse);
      }

      if (ccp_path) {
        const ccp = form.ccp.files[0];
        let ccpForm = new FormData();
        ccpForm.append("ccp", ccp);
        const ccpOptions = createOptions(); // Create separate options object
        ccpOptions.body = ccpForm;
        const ccpcompResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/mccp`,
          ccpOptions
        );
        const ccpcompResult = await ccpcompResponse.json();
        formDataInfo.append("ccp", ccpcompResult);
        promises.push(ccpcompResponse);
      }

      //CV permite multiplos ficheiros
      if (cv_path) {
        const cvFiles = Array.from(cv_path.files);
        const cvResults = [];
        for (const cv of cvFiles) {
          if (cv !== undefined) {
            let cvForm = new FormData();
            cvForm.append(`cv`, cv);
            const cvOptions = createOptions(); // Create separate options object
            cvOptions.body = cvForm;
            const cvResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/mcv`,
              cvOptions
            );
            const cvResult = await cvResponse.json();
            promises.push(cvResponse);
            cvResults.push(cvResult);
          }
        }
        formDataInfo.append(`cv`, cvResults);
      }

      let arrayObject = [];
      for (let i = 0; i < numDoc; i++) {
        const outro = outrosDocs[i].file;
        if (outro) {
          let outroForm = new FormData();
          outroForm.append("outro", outro);
          const outroOptions = createOptions(); // Create separate options object
          outroOptions.body = outroForm;
          const outroResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/outro`,
            outroOptions
          );
          const outroResult = await outroResponse.json();
          const object = {};
          object.name = outrosDocs[i].name;
          object.file = outroResult;
          arrayObject[i] = object;
        }
      }

      if (arrayObject.length === 1) {
        formDataInfo.append("outros", JSON.stringify(arrayObject));
      } else formDataInfo.append("outros", JSON.stringify(arrayObject));

      try {
        const responses = await Promise.all(promises);

        // Check if all promises were successful
        if (responses.every((response) => response.ok)) {
          // All requests succeeded
          // Process the responses and append data to formDataInfo

          // Then send the PATCH request
          const patchResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: localStorage.getItem("user"),
              },
              method: "PATCH",
              body: formDataToJson(formDataInfo),
            }
          );

          if (patchResponse.ok) {
            toastSuccess("Ficheiros submetidos com sucesso!");
            setAlterarFiles(0);
            setLoading(false);
            setAlterar(false);
            setCont(cont + 1);
          } else {
            toastError("Erro ao submeter os ficheiros");
            setLoading(false);
          }
        } else {
          // At least one request failed
          toastError("Erro ao submeter os ficheiros");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        toastError("Erro ao submeter os ficheiros");
        setLoading(false);
      }
    }

    async function eliminarCV(value) {
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}/deleteCV`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("user"),
          },
          method: "PATCH",
          body: JSON.stringify({
            value: value,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          toastSuccess("Ficheiro eliminado com sucesso");
          setCont(cont + 1);
        });
    }

    async function eliminarOutroDocumento(index) {
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}/deleteOtherDoc`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("user"),
          },
          method: "PATCH",
          body: JSON.stringify({
            index: index,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          toastSuccess("Ficheiro eliminado com sucesso");
          setCont(cont + 1);
        });
    }

    //Altera nacionalidade
    const handleNationalityChange = (selectedNationality) => {
      setNationality(selectedNationality);
    };

    if (loading)
      return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
          <div className="loading"></div>
        </div>
      );
    if (error) return "Error!";

    return (
      <>
        <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
          <div className="h-full sm:col-span-2 md:bg-secundary-color">
            <NavbarFormador currentPage={"perfil"} />
          </div>

          <div className="h-full col-span-5 xl:col-span-8">
            <Header sideMenu={"true"} />
            <div className="mx-10 mt-8">
              <div className="flex flex-col items-center lg:ml-24">
                <div className="relative transition cursor-pointer group hover:opacity-80">
                  <img
                    alt="Profile"
                    className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0"
                    src={
                      data.photo === undefined || data.photo === ""
                        ? "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                        : data.photo
                    }
                    height="120px"
                    onClick={() => {
                      document.getElementById("fileInput").click();
                    }}
                  />
                  <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                    <BsPencilFill className="text-black" />
                  </div>
                  <FileUploader
                  inputId={"fileInput"}
                  inputType={"file"}
                  inputAccept={".png, .jpeg, .jpg"}
                  allowedTypes={["image/png", "image/jpeg", "image/jpg"]}
                  inputStyle={{ display: "none" }}
                  onChangeSpecial={handlePhotoUpload}
                  />
                </div>
                <div className="flex items-center mt-2 ml-5">
                  {alterar === true ? (
                    <input
                      id="name"
                      name="name"
                      className="w-72 input-field"
                      placeholder={data.fullName}
                      defaultValue={data.fullName}
                      required
                    />
                  ) : (
                    <h1 className="text-2xl font-bold text-text-main-color">
                      {data.fullName}
                    </h1>
                  )}
                </div>
              </div>
              <div className="my-6">
                <hr />
              </div>
              <div className="mb-5 lg:ml-24">
                <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">
                  Dados Pessoais
                </h2>
                <div className="my-1 mb-2 text-center sm:text-left">
                  {data.fullName &&
                  data.cellNumber &&
                  data.nationality &&
                  data.email &&
                  data.iva &&
                  data.irs &&
                  data.office &&
                  data.nif &&
                  data.address &&
                  data.birthDate &&
                  data.cap &&
                  ((data.teachingQualification === "1" &&
                    data.recruitmentGroup) ||
                    data.teachingQualification) &&
                  data.courseDesignation &&
                  data.parish &&
                  data.parishNaturality &&
                  data.conclusionYear &&
                  data.locality &&
                  data.academicDegree &&
                  data.postalCode &&
                  data.county &&
                  data.ccNumber &&
                  data.ccExpiration &&
                  data.profileValid === true &&
                  data.cc_path &&
                  data.cv_path &&
                  data.ccp_path &&
                  data.ch_path ? (
                    <div className="text-green-700">Perfil preenchido </div>
                  ) : (
                    <div className="text-red-700">
                      Dados do perfil por preencher
                    </div>
                  )}
                </div>
                <div className="grid mt-4 sm:grid-cols-2">
                  <div>
                    <div className="text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Data de nascimento:
                      </div>
                      {data.birthDate ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="birthDate"
                              className="w-4/5 input-field"
                              type="date"
                              name="birthDate"
                              placeholder={data.birthDate}
                              defaultValue={data.birthDate}
                              required
                            ></input>
                          ) : (
                            data.birthDate
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Número de telefone:{" "}
                      </div>
                      {data.cellNumber ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="cellNumber"
                              name="cellNumber"
                              className="w-4/5 input-field"
                              placeholder={data.cellNumber}
                              minLength={9}
                              maxLength={14}
                              defaultValue={data.cellNumber}
                              required
                            ></input>
                          ) : (
                            data.cellNumber
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        E-mail:
                      </div>
                      {data.email ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {" "}
                          {alterar === true ? (
                            <input
                              id="email"
                              name="email"
                              className="w-4/5 input-field"
                              placeholder={data.email}
                              defaultValue={data.email}
                              required
                            ></input>
                          ) : (
                            data.email
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Morada:
                      </div>
                      {data.address ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="address"
                              name="address"
                              className="w-4/5 input-field"
                              placeholder={data.address}
                              defaultValue={data.address}
                              required
                            ></input>
                          ) : (
                            data.address
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Concelho:
                      </div>
                      {data.county ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {" "}
                          {alterar === true ? (
                            <input
                              id="county"
                              name="county"
                              className="w-4/5 input-field"
                              placeholder={data.county}
                              defaultValue={data.county}
                              required
                            ></input>
                          ) : (
                            data.county
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Localidade:
                      </div>
                      {data.locality ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {" "}
                          {alterar === true ? (
                            <input
                              id="locality"
                              name="locality"
                              className="w-4/5 input-field"
                              placeholder={data.locality}
                              defaultValue={data.locality}
                              required
                            ></input>
                          ) : (
                            data.locality
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Código Postal:
                      </div>
                      {data.locality ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="postalCode"
                              className="w-4/5 input-field"
                              name="postalCode"
                              placeholder={data.postalCode}
                              defaultValue={data.postalCode}
                              required
                            ></input>
                          ) : (
                            data.postalCode
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Número do Documento de Identificação:
                      </div>
                      {data.ccNumber ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="ccNumber"
                              name="email"
                              className="w-4/5 input-field"
                              placeholder={data.ccNumber}
                              defaultValue={data.ccNumber}
                              required
                            ></input>
                          ) : (
                            data.ccNumber
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Validade do Documento de Identificação:
                      </div>
                      {data.ccExpiration ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="ccExpiration"
                              name="ccExpiration"
                              className="w-4/5 input-field"
                              type="date"
                              placeholder={data.ccExpiration}
                              defaultValue={data.ccExpiration}
                              required
                            ></input>
                          ) : (
                            data.ccExpiration
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Freguesia (Naturalidade):
                      </div>
                      {alterar === true ? (
                        <input
                          id="parishNaturality"
                          name="parishNaturality"
                          className="w-4/5 input-field"
                          placeholder={data.parishNaturality}
                          defaultValue={data.parishNaturality}
                          required
                        />
                      ) : (
                        <>
                          {data.parishNaturality ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {data.parishNaturality}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </>
                      )}
                    </div>

                    {alterar === false ? (
                      <>
                        <div className="mt-4 text-center sm:text-left">
                          <div className="text-xs uppercase text-text-second-color opacity-90">
                            Concelho (Naturalidade):
                          </div>
                          {data.parish ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {alterar === true ? (
                                <input
                                  id="parish"
                                  name="parish"
                                  className="w-4/5 input-field"
                                  placeholder={countyNaturality}
                                  defaultValue={countyNaturality}
                                  required
                                />
                              ) : (
                                countyNaturality
                              )}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </div>{" "}
                        <div className="mt-4 text-center sm:text-left">
                          <div className="text-xs uppercase text-text-second-color opacity-90">
                            Distrito (Naturalidade):
                          </div>
                          {data.parish ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {alterar === true ? (
                                <input
                                  id="parish"
                                  name="parish"
                                  className="w-4/5 input-field"
                                  placeholder={districtNaturality}
                                  defaultValue={districtNaturality}
                                  required
                                />
                              ) : (
                                districtNaturality
                              )}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-4 text-center sm:text-left">
                          <div className="text-xs uppercase text-text-second-color opacity-90">
                            Concelho (Naturalidade):
                          </div>
                          <div className="mt-1 text-lg text-text-main-color">
                            <input
                              id="parish"
                              name="parish"
                              className="w-4/5 input-field"
                              placeholder={parishCounty}
                              defaultValue={parishCounty}
                              onChange={(e) => setParishCounty(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="mt-4 text-center sm:text-left">
                          <div className="text-xs uppercase text-text-second-color opacity-90">
                            Distrito (Naturalidade):
                          </div>
                          <div className="mt-1 text-lg text-text-main-color">
                            <input
                              id="parish"
                              name="parish"
                              className="w-4/5 input-field"
                              placeholder={parishDistrict}
                              defaultValue={parishDistrict}
                              onChange={(e) =>
                                setParishDistrict(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Nacionalidade:
                      </div>
                      {alterar === true ? (
                        <div className="w-4/5">
                          <CountryDropdown
                            defaultValue={data.nationality}
                            onChange={handleNationalityChange}
                          />
                        </div>
                      ) : (
                        <>
                          {data.nationality ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {data.nationality}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        NIF:
                      </div>
                      {data.nif ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="nif"
                              name="nif"
                              className="w-4/5 input-field"
                              placeholder={data.nif}
                              minLength={9}
                              maxLength={14}
                              defaultValue={data.nif}
                              required
                            ></input>
                          ) : (
                            data.nif
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Grau Académico:
                      </div>
                      {alterar === true ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          <select
                            onWheel={(e) => e.target.blur()}
                            className="w-4/5 text-lg selector"
                            id="academicDegree"
                            name="academicDegree"
                            defaultValue={data.academicDegree}
                            onChange={(e) => {
                              setAcademicDegree(e.target.value);
                            }}
                            required
                          >
                            <option value="" disabled>
                              Selecione a sua opção
                            </option>
                            <option value="Não sabe ler nem escrever">
                              Não sabe ler nem escrever
                            </option>
                            <option value="Menos de 4 anos escolaridade">
                              Menos de 4 anos escolaridade
                            </option>
                            <option value="1º ciclo (4º ano)">
                              1º ciclo (4º ano)
                            </option>
                            <option value="2º ciclo (6º ano)">
                              2º ciclo (6º ano)
                            </option>
                            <option value="3º ciclo (9º ano)">
                              3º ciclo (9º ano)
                            </option>
                            <option value="Ensino Secundário">
                              Ensino Secundário
                            </option>
                            <option value="Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)">
                              Ensino Pós-Secundário não superior (ex CET Cursos
                              de Especialização Tecnológica)
                            </option>
                            <option value="Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)">
                              Cursos superiores de curta duração (ex TeSP Cursos
                              Técnicos Superiores Profissionais)
                            </option>
                            <option value="Bacharelato">Bacharelato</option>
                            <option value="Licenciatura">Licenciatura</option>
                            <option value="Licenciatura pré-Bolonha">
                              Licenciatura pré-Bolonha
                            </option>
                            <option value="Mestrado">Mestrado</option>
                            <option value="Mestrado Pré-Bolonha">
                              Mestrado Pré-Bolonha
                            </option>
                            <option value="Doutoramento">Doutoramento</option>
                          </select>
                        </div>
                      ) : (
                        <>
                          {data.academicDegree ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {data.academicDegree}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </>
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Designação do Curso:
                      </div>
                      {data.courseDesignation ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="courseDesignation"
                              name="courseDesignation"
                              className="w-4/5 input-field"
                              placeholder={data.courseDesignation}
                              defaultValue={data.courseDesignation}
                              required
                            ></input>
                          ) : (
                            data.courseDesignation
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    {(!academicDegree
                      ? data.academicDegree !== "Não sabe ler nem escrever"
                      : academicDegree !== "Não sabe ler nem escrever") && (
                      <div className="mt-4 text-center sm:text-left">
                        <div className="text-xs uppercase text-text-second-color opacity-90">
                          Ano de Conclusão:
                        </div>
                        {data.conclusionYear ? (
                          <div className="mt-1 text-lg text-text-main-color">
                            {alterar === true ? (
                              <input
                                id="conclusionYear"
                                name="conclusionYear"
                                className="w-4/5 input-field"
                                placeholder={data.conclusionYear}
                                defaultValue={data.conclusionYear}
                                required
                              ></input>
                            ) : (
                              data.conclusionYear
                            )}
                          </div>
                        ) : (
                          <div className="input-error-profile" />
                        )}
                      </div>
                    )}
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Qualificação para docência:
                      </div>
                      {data.teachingQualification ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <select
                              onWheel={(e) => e.target.blur()}
                              className="w-4/5 text-lg selector"
                              id="teachingQualification"
                              name="teachingQualification"
                              defaultValue={data.teachingQualification}
                              onChange={(e) =>
                                setTeachingQualification(e.target.value)
                              }
                              required
                            >
                              <option value="" disabled>
                                Selecione a sua opção
                              </option>
                              <option value="1">Sim</option>
                              <option value="2">Não</option>
                            </select>
                          ) : data.teachingQualification === 1 ? (
                            "Sim"
                          ) : (
                            "Não"
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    {(!teachingQualification
                      ? data.teachingQualification === 1
                      : teachingQualification == 1) && (
                      <div className="mt-4 text-center sm:text-left">
                        <div className="text-xs uppercase text-text-second-color opacity-90">
                          Grupo de Recrutamento:
                        </div>
                        {alterar === true ? (
                          <div className="mt-1 text-lg text-text-main-color">
                            <input
                              id="recruitmentGroup"
                              name="recruitmentGroup"
                              className="w-4/5 text-lg input-field"
                              placeholder={data.recruitmentGroup}
                              defaultValue={data.recruitmentGroup}
                              required
                            ></input>
                          </div>
                        ) : (
                          <>
                            {data.recruitmentGroup ? (
                              <div className="mt-1 text-lg text-text-main-color">
                                {data.recruitmentGroup}
                              </div>
                            ) : (
                              <div className="input-error-profile" />
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {
                      <div className="mt-4 text-center sm:text-left">
                        <div className="text-xs uppercase text-text-second-color opacity-90">
                          Número de CAP/ CCP:
                        </div>
                        {alterar === true ? (
                          <input
                            id="cap"
                            name="cap"
                            className="w-4/5 text-lg input-field"
                            placeholder={data.cap}
                            defaultValue={data.cap}
                            required
                          ></input>
                        ) : (
                          <>
                            {data.cap ? (
                              <div className="mt-1 text-lg text-text-main-color">
                                {data.cap}{" "}
                              </div>
                            ) : (
                              <div className="input-error-profile" />
                            )}
                          </>
                        )}
                      </div>
                    }
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Certificação Profissional:
                      </div>
                      {alterar === true ? (
                        <input
                          id="professionalCertification"
                          name="professionalCertification"
                          className="w-4/5 input-field"
                          placeholder={data.professionalCertification}
                          defaultValue={data.professionalCertification}
                          required
                        />
                      ) : (
                        <>
                          {data.professionalCertification ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {data.professionalCertification}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </>
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        IVA:
                      </div>
                      {data.iva ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <select
                              onWheel={(e) => e.target.blur()}
                              className="w-4/5 text-lg selector"
                              id="iva"
                              name="iva"
                              defaultValue={data.iva}
                              required
                            >
                              <option value="" disabled>
                                Selecione a sua opção
                              </option>
                              <option value="1">Sim</option>
                              <option value="2">Não</option>
                            </select>
                          ) : data.iva === 1 ? (
                            "Sim"
                          ) : (
                            "Não"
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        IRS:
                      </div>
                      {data.irs ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {" "}
                          {alterar === true ? (
                            <select
                              onWheel={(e) => e.target.blur()}
                              className="w-4/5 text-lg selector"
                              id="irs"
                              name="irs"
                              defaultValue={data.irs}
                              required
                            >
                              <option value="" disabled>
                                Selecione a sua opção
                              </option>
                              <option value="1">Sim</option>
                              <option value="2">Não</option>
                            </select>
                          ) : data.irs === 1 ? (
                            "Sim"
                          ) : (
                            "Não"
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        IBAN:
                      </div>
                      {alterar === true ? (
                        <input
                          id="iban"
                          name="iban"
                          className="w-4/5 text-lg input-field"
                          placeholder={data.iban && data.iban}
                          defaultValue={data.iban && data.iban}
                          required
                        />
                      ) : (
                        <>
                          {data.iban ? (
                            <div className="mt-1 text-lg text-text-main-color">
                              {data.iban}
                            </div>
                          ) : (
                            <div className="input-error-profile" />
                          )}
                        </>
                      )}
                    </div>
                    <div className="mt-4 text-center sm:text-left">
                      <div className="text-xs uppercase text-text-second-color opacity-90">
                        Profissão (formador ou outra):
                      </div>
                      {data.office ? (
                        <div className="mt-1 text-lg text-text-main-color">
                          {alterar === true ? (
                            <input
                              id="office"
                              name="office"
                              className="w-4/5 input-field"
                              placeholder={data.office}
                              defaultValue={data.office}
                              required
                            ></input>
                          ) : (
                            data.office
                          )}
                        </div>
                      ) : (
                        <div className="input-error-profile" />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center mt-3 sm:justify-normal">
                    {alterar === true ? (
                      <button
                        className="mr-5 button-cancelar"
                        onClick={() => {
                          setAlterar(false);
                          setTeachingQualification(data.teachingQualification);
                          setAcademicDegree(data.academicDegree);
                        }}
                      >
                        Cancelar
                      </button>
                    ) : (
                      <button
                        className="button-geral"
                        onClick={alterarConfirmar}
                      >
                        Alterar Dados
                      </button>
                    )}
                    {alterar === true && (
                      <ConfirmToaster
                        css={"button-confirmar"}
                        icon={"Confirmar"}
                        title={"Alterar Dados"}
                        message={"Deseja confirmar as alterações?"}
                        confirm={alterarPerfil}
                        tooltip={"Alterar Perfil"}
                      ></ConfirmToaster>
                    )}
                  </div>
                </div>

                <h3 className="mt-8 text-3xl font-bold text-center text-text-main-color">
                  Ficheiros
                </h3>
                <div className="mt-3">
                  <div className="text-center subtitle">
                    Ficheiros de Identificação
                  </div>
                  <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                    {data.cc_path ? (
                      <a
                        href={data.cc_path}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <button className="button-files">
                          Documento de Identificação{" "}
                        </button>
                      </a>
                    ) : (
                      <div className="tooltip" data-tip="Sem Documento">
                        <button className="button-files-empty">
                          Documento de Identificação{" "}
                        </button>
                      </div>
                    )}
                    {data.cv_path && data.cv_path.length > 0 ? (
                      <div className="flex justify-center">
                        <button
                          className="button-files"
                          onClick={() => setModal(true)}
                        >
                          Curriculum Vitae
                        </button>
                      </div>
                    ) : (
                      <div
                        className="flex justify-center tooltip"
                        data-tip="Sem Documento"
                      >
                        <button className="button-files-empty">
                          Curriculum Vitae
                        </button>
                      </div>
                    )}
                    {data.ch_path ? (
                      <a
                        href={data.ch_path}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <button className="button-files">
                          {" "}
                          Certificado de Habilitações
                        </button>
                      </a>
                    ) : (
                      <div className="tooltip" data-tip="Sem Documento">
                        <button className="button-files-empty">
                          {" "}
                          Certificado de Habilitações
                        </button>
                      </div>
                    )}
                    {data.ccp_path ? (
                      <a
                        href={data.ccp_path}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <button className="button-files">
                          {" "}
                          Certificação de Competências Pedagógicas
                        </button>
                      </a>
                    ) : (
                      <div className="tooltip" data-tip="Sem Documento">
                        <button className="button-files-empty">
                          {" "}
                          Certificação de Competências Pedagógicas
                        </button>
                      </div>
                    )}
                    {data.ibancomp_path ? (
                      <a
                        href={data.ibancomp_path}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <button className="button-files"> IBAN </button>
                      </a>
                    ) : (
                      <div className="tooltip" data-tip="Sem Documento">
                        <button className="button-files-empty"> IBAN </button>
                      </div>
                    )}
                  </div>
                  {data.outros_path.length > 0 && (
                    <>
                      <div className="text-center subtitle">
                        Outros Ficheiros
                      </div>
                      <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                        {data.outros_path.map((value, index) => (
                          <div
                            key={index}
                            className="relative flex justify-center"
                          >
                            <button className="relative flex items-center justify-between p-0 button-files">
                              <a
                                href={value.file}
                                target="_blank"
                                rel="noreferrer"
                                download
                                className="flex-grow p-2 px-3 sm:py-4"
                              >
                                {value.name}
                              </a>
                              <ConfirmToaster
                                css="absolute top-0.5 right-0.5 text-cancel-color hover:brightness-75 tooltip"
                                icon={
                                  <AiOutlineCloseCircle
                                    size={18}
                                    className="hover:text-text-error"
                                  />
                                }
                                title="Remover Ficheiro"
                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                confirm={() => eliminarOutroDocumento(index)}
                                id={value.index}
                                type="remover"
                                tooltip="Eliminar Ficheiro"
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>

                {alterarFiles === 0 ? (
                  <div className="flex justify-center my-3">
                    <button
                      className="m-1 button-geral"
                      onClick={() => setAlterarFiles(1)}
                    >
                      Alterar Ficheiros de Identificação
                    </button>
                    <button
                      className="m-1 button-geral sm:ml-3"
                      onClick={() => setAlterarFiles(2)}
                    >
                      Adicionar Outros Ficheiros
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {alterarFiles === 1 ? (
                  <div>
                    <form id="form">
                      <h1 className="mt-8 text-center title">
                        Insira os seguintes ficheiros
                      </h1>
                      <div>
                        <div className="flex flex-col items-center">
                          <div className="text-center label-input">
                            Documento de Identificação:{" "}
                          </div>
                          <FileUploader
                          inputClassName={"input-file"}
                          inputType={"file"}
                          inputId={"cc"}
                          inputName={"cc"}
                          inputAccept={".pdf"}
                          allowedTypes={["application/pdf"]}
                          />
                        </div>
                        <div className="flex flex-col items-center mt-2">
                          <div className="text-center label-input" htmlFor="cv">
                            Curriculum Vitae:{" "}
                          </div>
                          <FileUploader
                          inputClassName={"input-file"}
                          inputMultiple={true}
                          inputType={"file"}
                          inputId={"cv"}
                          inputName={"cv"}
                          inputAccept={".pdf"}
                          allowedTypes={["application/pdf"]}
                          />
                        </div>
                        <div className="flex flex-col items-center mt-2">
                          <div className="text-center label-input" htmlFor="ch">
                            Certificado de Habilitações:{" "}
                          </div>
                          <FileUploader
                          inputClassName={"input-file"}
                          inputType={"file"}
                          inputId={"ch"}
                          inputName={"ch"}
                          inputAccept={".pdf"}
                          allowedTypes={["application/pdf"]}
                          />
                        </div>
                        <div className="flex flex-col items-center mt-2">
                          <div
                            className="text-center label-input"
                            htmlFor="ccp"
                          >
                            Certificado de Competências Pedagógicas:{" "}
                          </div>
                          <FileUploader
                          inputClassName={"input-file"}
                          inputType={"file"}
                          inputId={"ccp"}
                          inputName={"ccp"}
                          inputAccept={".pdf"}
                          allowedTypes={["application/pdf"]}
                          />
                        </div>
                        <div className="flex flex-col items-center mt-2">
                          <div className="text-center label-input">
                            Comprovativo de IBAN:{" "}
                          </div>
                          <FileUploader
                          inputClassName={"input-file"}
                          inputType={"file"}
                          inputId={"ibanDoc"}
                          inputName={"ibanDoc"}
                          inputAccept={".pdf"}
                          allowedTypes={["application/pdf"]}
                          />
                        </div>
                      </div>
                    </form>
                    <p className="text-center text-text-error">
                      *Formato suportado .pdf
                    </p>
                    <div className="flex justify-center mt-3">
                      <button
                        className="mr-5 button-cancelar"
                        onClick={() => {
                          setAlterarFiles(0);
                        }}
                      >
                        Cancelar
                      </button>
                      <ConfirmToaster
                        css={"button-confirmar"}
                        icon={"Confirmar"}
                        title={"Confirmar"}
                        message={"Deseja confirmar as alterações?"}
                        confirm={submeterFicheiros}
                        tooltip={"Submeter Ficheiros"}
                      ></ConfirmToaster>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {alterarFiles === 2 ? (
                  <div>
                    <h1 className="mt-8 text-center title">
                      Adicionar Ficheiros
                    </h1>
                    <div className="flex flex-col justify-center mb-2">
                      <label className="text-center text-text-main-color">
                        Quantos outros documentos deseja inserir:
                      </label>
                      <input
                        className="w-1/3 mx-auto input-field"
                        type="number"
                        value={numDoc}
                        onChange={(e) => {
                          setNumDoc(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="flex flex-col items-center w-full pt-4 overflow-y-auto rounded-lg max-h-156 bg-gray-50">
                      {Array.from({ length: numDoc }).map((_, index) => (
                        <div key={index} className="w-4/5 my-2">
                          <h4 className="mb-1 text-left uppercase text-main-color">
                            Documento {index + 1}
                          </h4>
                          <div className="flex w-full text-left">
                            <label
                              className="w-2/5 text-text-main-color"
                              htmlFor={`fullName${index}`}
                            >
                              Nome do documento:
                            </label>
                            <input
                              className="w-3/5 input-field"
                              type="text"
                              id={`fullName${index}`}
                              name={`fullName${index}`}
                              value={outrosDocs[index]?.name}
                              onChange={(e) => handleNameChange(e, index)}
                            />
                          </div>
                          <div className="flex w-full mt-2 mb-3 text-left">
                            <label
                              className="w-2/5 text-text-main-color"
                              htmlFor={`outro${index}`}
                            >
                              Ficheiro:
                            </label>
                            <FileUploader
                            inputClassName={"w-3/5 input-file"}
                            inputType={"file"}
                            inputId={`outro${index}`}
                            inputName={`outro${index}`}
                            inputAccept={".pdf"}
                            allowedTypes={["application/pdf"]}
                            onChangeSpecial={(e) => handleFileChange(e, index)}
                            />
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                    <p className="text-center text-text-error">
                      *Formato suportado .pdf
                    </p>
                    <div className="flex justify-center mt-3">
                      <button
                        className="mr-5 button-cancelar"
                        onClick={() => {
                          setAlterarFiles(0);
                        }}
                      >
                        Cancelar
                      </button>
                      <ConfirmToaster
                        css={"button-confirmar"}
                        icon={"Confirmar"}
                        title={"Confirmar"}
                        message={"Deseja confirmar as alterações?"}
                        confirm={submeterFicheiros}
                        tooltip={"Submeter Ficheiros"}
                      ></ConfirmToaster>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <Modal
                  isOpen={modal}
                  className="fixed inset-0 flex items-center justify-center"
                >
                  <div
                    className="absolute w-full h-full"
                    onClick={() => setModal(false)}
                  ></div>
                  <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                    <button
                      className="absolute top-1.5 right-1.5"
                      onClick={() => setModal(false)}
                    >
                      <AiOutlineCloseCircle
                        size={24}
                        className="hover:text-text-error"
                      />
                    </button>
                    <div>
                      <h1 className="subtitle">Currículos</h1>
                    </div>
                    <div className="modal-content">
                      {data.cv_path && (
                        <>
                          {data.cv_path.map((value, index) => (
                            <div key={index}>
                              <div className="flex items-center m-1.5">
                                <span className="mr-2 text-text-main-color">
                                  CV - {index + 1}:
                                </span>
                                <a
                                  className="mr-2 button-geral"
                                  href={value}
                                  target="_blank"
                                  rel="noreferrer"
                                  download
                                >
                                  <FiDownload />
                                </a>
                                <button
                                  className="button-cancelar w-fit"
                                  onClick={() => {
                                    eliminarCV(value);
                                  }}
                                >
                                  <IoMdRemoveCircle />
                                </button>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </>
    );
}



