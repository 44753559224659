import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import FileUploader from "../../../utils/validationFileUploader";

function EntFinanciadora() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFile1, setSelectedFile1] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [name, setName] = useState("");
    const [imageHeight1, setimageHeight1] = useState(0);
    const [imageHeight2, setimageHeight2] = useState(0);
    const image = new Image();
    const navigate = useNavigate()
    const params = useParams();

    useEffect(() => {
    }, [loading]);

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile1(file);
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeight1(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else setSelectedFile1(null);
    };

    const handleFileChange2 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile2(file);
            const reader = new FileReader();
            reader.onload = function (e) {

                image.onload = function () {
                    setimageHeight2(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else setSelectedFile1(null);
    };

    const handleSubmit = async () => {
        if (name !== "") {
            if (selectedFile1 && selectedFile2) {
                if ((selectedFile1.type === "image/png" || selectedFile1.type === "image/jpeg") && ((selectedFile2.type === "image/png" || selectedFile2.type === "image/jpeg"))) {
                    if (selectedFile1.size <= 200000 && (!selectedFile2 || selectedFile2.size <= 200000)) {
                        if (imageHeight1 <= 240 && (!selectedFile2 || imageHeight2 <= 240)) {
                            try {

                                const formData1 = new FormData();
                                const formData2 = new FormData();
                                const options = {
                                    method: "POST",
                                    headers: { "Accept": "application/json" },
                                    credentials: "same-origin"
                                }
                                let logo1Form = new FormData()
                                logo1Form.append('logo', selectedFile1)
                                options.body = logo1Form
                                const logo1Response = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options)
                                const logo1Result = await logo1Response.json()
                                formData1.append('logo', logo1Result)

                                let logo2Result = ""

                                if (selectedFile2) {
                                    let logo2Form = new FormData()
                                    logo2Form.append('logo', selectedFile2)
                                    options.body = logo2Form
                                    const logo2Response = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options)
                                    logo2Result = await logo2Response.json()
                                    formData2.append('logo', logo2Result)

                                }


                                fetch(`${process.env.REACT_APP_API_URL}/EntFinanciadora`,
                                    {
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',

                                        },
                                        method: 'POST',
                                        body: JSON.stringify({
                                            name: document.getElementById('name').value,
                                            logotipo1: logo1Result,
                                            logotipo2: logo2Result
                                        })
                                    })
                                    .then(res => res.json())
                                    .then((result) => {
                                        toastSuccess('Entidade financiadora adicionada com sucesso!')
                                        navigate('/MenuSuperAdmin')
                                    })

                            } catch (error) {
                                console.error('Error uploading file:', error);
                            }
                        } else toastError('A altura da imagem deve ser menor ou igual a 240 píxeis!');
                    } else toastError('Ficheiro demasiado grande!')
                } else toastError('Ficheiro deverá apenas ser um png ou jpeg!');;
            } else toastError('Selecione um logotipo!');
        } else toastError('A entidade financiadora têm de ter um nome!');
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"criarEntidadeFinanciadora"} />
                </div>
                <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex flex-col items-center flex-grow mt-20">
                    <h1 className="title">Adicionar Entidade Financiadora</h1>
                    <div className="w-9/20">
                        <div className="flex flex-col items-start">
                            <label className="label-input">Nome:</label>
                            <input id="name" className="w-full input-field" onChange={(e) => setName(e.target.value)}></input>
                        </div>
                        <div className="flex flex-col items-start mt-4">
                            <label className="label-input">Logotipo (Canto superior direito)*:</label>
                            <FileUploader
                                inputClassName={"max-w-[15rem] input-file"}
                                inputType={"file"}
                                inputAccept={".jpeg, .jpg, .png"}
                                onChangeSpecial={handleFileChange1}
                                allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
                                />
                        </div>
                        <div className="flex flex-col items-start mt-4">
                            <label className="label-input">Logotipo (centro inferior):</label>
                            <FileUploader
                                inputClassName={"max-w-[15rem] input-file"}
                                inputType={"file"}
                                inputAccept={".jpeg, .jpg, .png"}
                                onChangeSpecial={handleFileChange2}
                                allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
                                />
                        </div>
                        <button className="mt-4 button-geral" onClick={handleSubmit}>Adicionar</button>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default EntFinanciadora