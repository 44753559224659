import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../../../../components/Footer";
import Header from "../../../../../../components/Header";
import "react-toastify/dist/ReactToastify.css";
import { FaUserSlash } from "react-icons/fa";
import NavbarCoordenador from "../../../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import Modal from "react-modal";
import { FiDownload } from "react-icons/fi";
import ReactModal from "react-modal";
import toastError from "../../../../../../components/Toast/toastError";
import toastSuccess from "../../../../../../components/Toast/toastSuccess";
import {
  AiOutlineCloseCircle,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import Cookies from "js-cookie";
import { HiUserRemove } from "react-icons/hi";
import { PiStudentFill } from "react-icons/pi";
import { MdCancel } from "react-icons/md";
import FileUploader from "../../../../../../utils/validationFileUploader";

ReactModal.setAppElement("#root");

function Candidaturas() {
  const [data, setData] = useState(null);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataDesis, setDataDesis] = useState("");
  const [desistenciaFile, setDesistenciaFile] = useState("");

  const [cont, setCont] = useState(0);
  const [loading, setLoading] = useState(false);
  const [comentCheck, setComentCheck] = useState(false);
  const [idFormando, setIdFormando] = useState(null);
  const [error, setError] = useState(null);
  const [percurso, setPercurso] = useState(null);
  const savedPagina =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get("Coordenador-coursesIndUfcdPercTurmInd-Pag")
      : "";
  const [nPaginas, setNumPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(
    savedPagina ? parseInt(savedPagina) : 1
  );
  const savedItemsPag =
    localStorage.getItem("cookiesAccepted") === "true"
      ? Cookies.get(`Coordenadores-coursesIndUfcdPercTurmInd-itm1`)
      : "";
  const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10);
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    Cookies.set("Coordenador-coursesIndUfcdPercTurmInd-Pag", paginaAtual);
    Cookies.set(`Coordenadores-coursesIndUfcdPercTurmInd-itm1`, itemsPag);
    getFormadores();
    getPercurso();
  }, [loading, cont, paginaAtual, nPaginas, itemsPag]);

  //Atribuir numero de páginas
  function paginacaoTabelas(data) {
    if (data.length < itemsPag) {
      setNumPaginas(1);
    } else if (data.length % itemsPag === 0) {
      setNumPaginas(data.length / itemsPag);
    } else {
      setNumPaginas(Math.ceil(data.length / itemsPag));
    }
    if (paginaAtual > nPaginas) {
      setPaginaAtual(nPaginas);
    }
    updatePagination(data); // n é o state por causa das tabelas
  }

  //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
  //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
  const handleItemsPerPageChange = (newItemsPag) => {
    const newNumPaginas = Math.ceil(data.length / newItemsPag);

    const newPaginaAtual =
      paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

    setItemsPag(newItemsPag);
    setNumPaginas(newNumPaginas);
    setPaginaAtual(newPaginaAtual);

    updatePagination(dataToDisplay);
  };

  //Define que dados mostrar consoante a página em que está
  function updatePagination(data) {
    let maxIndex = paginaAtual * itemsPag;
    let minIndex;
    if (paginaAtual === 1) {
      minIndex = 0;
    } else {
      minIndex = (paginaAtual - 1) * itemsPag;
      maxIndex = paginaAtual * itemsPag;
    }
    setDataToDisplay(data.slice(minIndex, maxIndex));
  }

  //aumentar página atual
  function increasePagination() {
    if (paginaAtual < nPaginas) {
      setPaginaAtual((prevIndex) => prevIndex + 1);
      updatePagination(data);
    }
  }

  //diminuir página atual
  function decreasePagination() {
    if (paginaAtual > 1) {
      setPaginaAtual((prevIndex) => prevIndex - 1);
      updatePagination(data);
    }
  }
  async function submeterEquivalencia(id) {
    setLoading(true);
    // Secção - 10
    const equivalencia = {
      idUfcd: params.id_ufcd,
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/equivalencia/${params.id_ufcd}/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("coord"),
        },
        body: JSON.stringify({
          equivalencia: equivalencia,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((result) => {
        toastSuccess("Equivalencia efetuada com sucesso.");
        setModalIsOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }
  async function submeterNInscricao(id) {
    setLoading(true);
    // Secção - 10
    const nInscricao = {
      idUfcd: params.id_ufcd,
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/nInscricao/${params.id_ufcd}/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("coord"),
        },
        body: JSON.stringify({
          nInscricao: nInscricao,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((result) => {
        toastSuccess("Não inscrição efetuada com sucesso.");
        setModalIsOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }
  function getFormadores() {
    fetch(
      `${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/formandos/${params.id_ufcd}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("coord"),
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const sortedResult = result.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName);
        });
        setData(sortedResult);
        paginacaoTabelas(sortedResult);
        setCont(cont + 1);
      });
  }

  //Obtem percurso
  function getPercurso() {
    fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem("coord"),
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        setPercurso(result.name);
      });
  }

  //Download do ficheiro
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }
  async function gerarFolhaDesistencia() {
    await setLoading(true);
    if (idFormando) {
      try {
        const formandoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/formandos/${idFormando}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: localStorage.getItem("coord"),
            },
            method: "GET",
          }
        );

        const result = await formandoResponse.json();

        const percursoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/percurso/FolhadeDesistenciaFormando/${params.id_curso}/${result._id}/${params.id_ufcd}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: localStorage.getItem("coord"),
            },
            body: JSON.stringify({
              dataDesistencia: dataDesis,
            }),
          }
        );

        if (!percursoResponse.ok) {
          throw new Error("Network response was not ok.");
        }

        const percursoResult = await percursoResponse.json();

        // Handle the successful response
        await downloadFile(percursoResult.desistencia_path);
        await setLoading(false);
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    }
  }
  async function submeterDesistencia() {
    setLoading(true);
    // Secção - 10

    if (desistenciaFile) {
      let desistenciaResult = "";
      const desistenciaFilePath = desistenciaFile;
      const options = {
        method: "POST",
        headers: { Accept: "application/json" },
        credentials: "same-origin",
      };

      let desistenciaFileForm = new FormData();
      desistenciaFileForm.append("desistencia", desistenciaFilePath);
      options.body = desistenciaFileForm;

      try {
        const desistenciaResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/desistencia`,
          options
        );
        const desistenciaResult1 = await desistenciaResponse.json();
        desistenciaResult = desistenciaResult1;
        // Handle the desistenciaResult as needed here
      } catch (error) {
        console.error("Error while submitting desistencia:", error);
        // Handle errors here
      }
      const desistencia = {
        dataDesistencia: dataDesis,
        desistenciaFile: desistenciaResult,
        idUfcd: params.id_ufcd,
      };
      await fetch(
        `${process.env.REACT_APP_API_URL}/ufcds/desistencia/${params.id_ufcd}/${idFormando}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("coord"),
          },
          body: JSON.stringify({
            desistencia: desistencia,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Network response was not ok.");
          }
        })
        .then((result) => {
          toastSuccess("Desistencia efetuada com sucesso.");
          setModalIsOpen(false);
          setLoading(false);
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    } else toastError("Tente outra vez.");
  }
  async function cancelarEquivalencia(id) {
    setLoading(true);
    // Secção - 10
    const equivalencia = {
      idUfcd: params.id_ufcd,
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/equivalencia/${params.id_ufcd}/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("coord"),
        },
        body: JSON.stringify({
          equivalencia: equivalencia,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((result) => {
        toastSuccess("Equivalência cancelada com sucesso.");
        setModalIsOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }
  async function cancelarNInscrito(id) {
    setLoading(true);
    // Secção - 10
    const nInscricao = {
      idUfcd: params.id_ufcd,
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/nInscricao/${params.id_ufcd}/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("coord"),
        },
        body: JSON.stringify({
          nInscricao: nInscricao,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((result) => {
        toastSuccess("Não inscrição cancelado com sucesso.");
        setModalIsOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }
  async function cancelarDesistencia(id) {
    setLoading(true);
    // Secção - 10
    const desistencia = {
      idUfcd: params.id_ufcd,
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/desistencia/${params.id_ufcd}/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("coord"),
        },
        body: JSON.stringify({
          desistencia: desistencia,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((result) => {
        toastSuccess("Desistência cancelada com sucesso.");
        setModalIsOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }
  if (loading)
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
        <div className="loading"></div>
      </div>
    );
  if (error) return "Error!";
  //passa para a ultima página
  function ultimaPagina() {
    if (paginaAtual !== nPaginas) {
      setPaginaAtual(nPaginas);
      updatePagination(data);
    }
  }
  //passa para a primeira página
  function primeiraPagina() {
    if (paginaAtual !== 1) {
      setPaginaAtual(1);
      updatePagination(data);
    }
  }
  return (
    <>
      <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
        <div className="h-full sm:col-span-2 md:bg-secundary-color">
          <NavbarCoordenador currentPage={"cursos"} />
        </div>

        <div className="relative w-full h-full col-span-5 xl:col-span-8">
          <div className="mx-4 back-button-noNavbar">
            <Link
              to={"/menucoordenador/" + params.id_candidatura + "/disciplinas"}
              className="back-button-link"
            >
              <img
                className="back-button-img"
                alt="Back"
                src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
              />
            </Link>
          </div>
          <Header sideMenu={"true"} />
          <h1 className="mx-4 mt-8 title sm:mt-0">Turma - {percurso}</h1>

          {data && data.length > 0 ? (
            <>
              <div className="flex justify-center items-center mb-1">
                <label className="text-text-main-color">
                  Dados por página:{" "}
                </label>
                <select
                  onWheel={(e) => e.target.blur()}
                  className="selector w-14 ml-1"
                  value={itemsPag}
                  onChange={(e) =>
                    handleItemsPerPageChange(parseInt(e.target.value))
                  }
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <div className="table-containerSideMenu">
                <table className="my-table">
                  <thead className="bg-main-color">
                    <tr className="text-white h-14">
                      <th className="p-2 min-w-[80px] border border-white">
                        Número:
                      </th>
                      <th className="p-2 min-w-[150px] border border-white">
                        Nome Completo:
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Email
                      </th>
                      <th className="p-2 min-w-[150px] border border-white">
                        Estado
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  {data ? (
                    <tbody className="text-text-main-color">
                      {dataToDisplay.map((item, index) => (
                        <tr key={index}>
                          <td className="border border-white">{index + 1}</td>
                          <td className="border border-white">
                            {item.fullName}
                          </td>
                          <td className="border border-white">{item.email}</td>
                          <td className="border border-white">
                            {item.equivalencia.some(
                              (item) => item.idUfcd === params.id_ufcd
                            )
                              ? "Equivalência"
                              : item.desistencia.some(
                                  (item) => item.idUfcd === params.id_ufcd
                                )
                              ? "Desistente"
                              : item.nInscricao.some(
                                  (item) => item.idUfcd === params.id_ufcd
                                )
                              ? "Não Inscrito"
                              : "Ativo"}
                          </td>
                          <td className="border border-white">
                            <div className="flex">
                              {!item.desistencia.some(
                                (item) => item.idUfcd === params.id_ufcd
                              ) && (
                                <button
                                  className="ml-1 text-text-error"
                                  data-tooltip-id="tooltip"
                                  data-tooltip-content="Desistir"
                                  data-tooltip-place="top"
                                  onClick={() => {
                                    setComentCheck(!comentCheck);
                                    setModalIsOpen(true);
                                    setIdFormando(item._id);
                                  }}
                                >
                                  <FaUserSlash />
                                </button>
                              )}
                              {item.desistencia.some(
                                (item) => item.idUfcd === params.id_ufcd
                              ) && (
                                <button
                                  className="ml-1 text-yellow-600"
                                  data-tooltip-id="tooltip"
                                  data-tooltip-content="Cancelar desistência"
                                  data-tooltip-place="top"
                                  onClick={() => {
                                    cancelarDesistencia(item._id);
                                  }}
                                >
                                  <MdCancel />
                                </button>
                              )}
                              {!item.nInscricao.some(
                                (item) => item.idUfcd === params.id_ufcd
                              ) &&
                                !item.equivalencia.some(
                                  (item) => item.idUfcd === params.id_ufcd
                                ) && (
                                  <button
                                    className="ml-1 text-yellow-600"
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content="Submeter equilivalência"
                                    data-tooltip-place="top"
                                    onClick={() => {
                                      submeterEquivalencia(item._id);
                                    }}
                                  >
                                    <PiStudentFill />
                                  </button>
                                )}
                              {!item.nInscricao.some(
                                (item) => item.idUfcd === params.id_ufcd
                              ) &&
                                item.equivalencia.some(
                                  (item) => item.idUfcd === params.id_ufcd
                                ) && (
                                  <button
                                    className="ml-1 text-yellow-600"
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content="Cancelar equilivalência"
                                    data-tooltip-place="top"
                                    onClick={() => {
                                      cancelarEquivalencia(item._id);
                                    }}
                                  >
                                    <MdCancel />
                                  </button>
                                )}
                              {!item.equivalencia.some(
                                (item) => item.idUfcd === params.id_ufcd
                              ) &&
                                !item.nInscricao.some(
                                  (item) => item.idUfcd === params.id_ufcd
                                ) && (
                                  <button
                                    className="ml-1 text-yellow-600"
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content="Submeter não inscrição"
                                    data-tooltip-place="top"
                                    onClick={() => {
                                      submeterNInscricao(item._id);
                                    }}
                                  >
                                    <HiUserRemove />
                                  </button>
                                )}
                              {item.nInscricao.some(
                                (item) => item.idUfcd === params.id_ufcd
                              ) &&
                                !item.equivalencia.some(
                                  (item) => item.idUfcd === params.id_ufcd
                                ) && (
                                  <button
                                    className="ml-1 text-yellow-600"
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content="Cancelar não inscrição"
                                    data-tooltip-place="top"
                                    onClick={() => {
                                      cancelarNInscrito(item._id);
                                    }}
                                  >
                                    <MdCancel />
                                  </button>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody />
                  )}
                </table>
              </div>
              <div className="flex items-center justify-center mt-4 mb-8">
                <button
                  className={
                    paginaAtual > 1
                      ? "button-geral mr-1"
                      : "button-disabled mr-1"
                  }
                  onClick={() => primeiraPagina()}
                >
                  <AiOutlineDoubleLeft />
                </button>
                <button
                  className={
                    paginaAtual > 1
                      ? "button-geral mr-1"
                      : "button-disabled mr-1"
                  }
                  onClick={() => decreasePagination()}
                >
                  <AiOutlineLeft />
                </button>
                <p className="text-main-color">
                  Página {paginaAtual} - {nPaginas}
                </p>
                <button
                  className={
                    paginaAtual < nPaginas
                      ? "button-geral ml-1"
                      : "button-disabled ml-1"
                  }
                  onClick={() => increasePagination()}
                >
                  <AiOutlineRight />
                </button>
                <button
                  className={
                    paginaAtual < nPaginas
                      ? "button-geral ml-1"
                      : "button-disabled ml-1"
                  }
                  onClick={() => ultimaPagina()}
                >
                  <AiOutlineDoubleRight />
                </button>
              </div>{" "}
            </>
          ) : (
            <p className="third-title">Não existem dados</p>
          )}

          <Modal
            isOpen={modalIsOpen}
            className="fixed inset-0 flex items-center justify-center"
          >
            <div
              className="absolute w-full h-full"
              onClick={() => setModalIsOpen(false)}
            ></div>
            <div className="relative flex flex-col items-center px-10 shadow-2xl bg-secundary-color py-7 rounded-2xl">
              <div>
                <h1 className="title">Dados do Formulário</h1>
              </div>
              <div>
                <p className="p-1 font-bold text-text-second-color">
                  Data de Desistência:
                </p>
                <input
                  className="input-field"
                  type="date"
                  defaultValue={dataDesis}
                  onChange={(e) => setDataDesis(e.target.value)}
                />
                <div className="flex">
                  <p className="p-1 font-bold text-text-second-color">
                    Download do Template de Desistência:
                  </p>
                  <button
                    type="button"
                    className="button-geral"
                    onClick={() => gerarFolhaDesistencia()}
                  >
                    <FiDownload />
                  </button>
                </div>
                <p className="p-1 font-bold text-text-second-color">
                  Upload da Folha de Desistência:
                </p>
                <FileUploader
                  inputClassName={"input-file"}
                  inputType={"file"}
                  inputAccept={".pdf"}
                  onChangeSpecial={(e) => setDesistenciaFile(e.target.files[0])}
                  allowedTypes={["application/pdf"]}
                  />
                <div className="mt-3">
                  <p className="font-bold third-title">Legenda:</p>
                  <div className="max-w-sm text-text-main-color">
                    É necessário a data para o download do template e para o
                    upload da folha de desistência
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-center w-full mt-5">
                <button
                  className="button-confirmar"
                  onClick={() => submeterDesistencia()}
                >
                  Confirmar
                </button>
              </div>
              <button
                className="absolute top-1.5 right-1.5"
                onClick={() => setModalIsOpen(false)}
              >
                <AiOutlineCloseCircle
                  size={24}
                  className="hover:text-text-error"
                />
              </button>
            </div>
          </Modal>
        </div>
      </main>
      <Footer />
      <Tooltip id="tooltip" />
    </>
  );
}

export default Candidaturas;
