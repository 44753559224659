import React, { useState } from "react";
import { ContratoFormando } from "../Modal/stylesContratos/styledFormando";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormandoFMC({
  data,
  token,
  setOpen,
}) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadingEnvio, setLoadingEnvio] = React.useState(false);
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [valores, setValores] = useState({
    numeroApolice: data?.dadosContrato?.numeroApolice ?? "",
    seguradora: data?.dadosContrato?.seguradora ?? "",
    local: data?.percurso[0]?.locality ?? "",
    date: AlterarFormato(data?.percurso[0]?.dateBegin) ?? "",
  });

  // Function to send the contract by email
  async function enviarContratoFormando() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormandoNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = React.useState({
    inputNumeroApolice: valores?.numeroApolice ?? "",
    inputSeguradora: valores?.seguradora ?? "",
    inputDate: valores?.date ?? "",
    inputLocal: valores.local ?? "",
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = React.useState({
    inputNumeroApolice: false,
    inputSeguradora: false,
    inputDate: false,
    inputLocal: false,
  });

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {
      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Function to adjust the width of the input field
  const adjustWidth = (e) => {
    e.target.style.width = `${e.target.value.length + 1}ch`;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <ContratoFormando>
            <div className="body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    src={data.percurso[0].entFormadora[0].logotipo}
                    className="h-20"
                    alt="logo"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
                <div></div>
                <div>
                  {data.percurso[0].entFinanciadora &&
                    data.percurso[0].entFinanciadora.length > 0 &&
                    data.percurso[0].entFinanciadora[0].logotipo1 && (
                      <img
                        src={data.percurso[0].entFinanciadora[0].logotipo1}
                        className="h-20"
                        alt="logo"
                        style={{ marginLeft: "20px" }}
                      />
                    )}
                </div>
              </div>

              <div className="page1">
                <div className="content">
                  <div className="mt-4">
                    <br />
                    <h1>
                      <b>
                        Contrato de Formação
                      </b>
                    </h1>
                    <br />
                    <br />
                    <p>
                      Entre:
                      <br />
                      <b>{data.percurso[0].entFormadora[0].name}</b>, com sede
                      social em {data.percurso[0].entFormadora[0].morada},{" "}
                      {data.percurso[0].entFormadora[0].postalCode}{" "}
                      {data.percurso[0].entFormadora[0].distrito}, com o
                      Contribuinte nº {data.percurso[0].entFormadora[0].nif},
                      legítimo utilizador da marca comercial "ACADEMIA
                      COMENIUS", neste ato representada por{" "}
                      {data.percurso[0].entFormadora[0].representanteLegal},
                      adiante designada por <b>Primeiro Outorgante</b>, e
                    </p>
                    <table className="dados">
                      <tr>
                        <th>Nome Completo</th>
                        <td>{data.fullName}</td>
                      </tr>
                      <tr>
                        <th>NIF</th>
                        <td>{data.nif}</td>
                      </tr>
                      <tr>
                        <th>Cartão de Cidadão/Passaporte</th>
                        <td>{data.idNumber}</td>
                      </tr>
                      <tr>
                        <th>Morada</th>
                        <td>{data.address}</td>
                      </tr>
                      <tr>
                        <th>Código Postal</th>
                        <td>{data.postalCode}</td>
                      </tr>
                    </table>
                    <p>
                      , adiante designada por <b>Segundo Outorgante</b>, é
                      celebrado o presente contrato de formação, o
                      qual se rege pelo disposto nas seguintes cláusulas:
                    </p>
                  </div>
                  <div>
                    <br />
                    <h2>
                      Cláusula 1ª - Objeto do Contrato, local e cronograma
                    </h2>
                    <br />
                    <p>
                      1. O <b>Primeiro Outorgante</b> obriga-se a proporcionar
                      ao <b>Segundo Outorgante</b>, que aceita, o seguinte
                      curso:
                    </p>
                    <table class="dados">
                      <tr>
                        <th>Nome do curso</th>
                        <td>{data.percurso[0].name}</td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <p>
                      2. O curso em causa é composto pela(s) seguinte(s) Unidade(s) de
                      Formação de Curta Duração:
                    </p>

                    <table className="dados">
                      <tbody>
                        {data.ufcds.map((ufcd, index) => {
                          if (
                            ufcd.nInscricoes &&
                            !ufcd.nInscricoes.find(
                              (item) => item === data._id
                            ) &&
                            ufcd.equivalencias &&
                            !ufcd.equivalencias.find(
                              (item) => item === data._id
                            )
                          ) {
                            return (
                              <tr key={index}>
                                <th>UFCD {index + 1}</th>
                                <td>
                                  {ufcd.codeUfcd} - {ufcd.name}
                                </td>
                                <th
                                  style={{
                                    width: "100px",
                                    textAlign: "center",
                                  }}
                                >
                                  Carga Horária(h):
                                </th>
                                <td style={{ width: "100px" }}>
                                  {ufcd.cargaHoraria}
                                </td>
                              </tr>
                            );
                          }
                          return null; // If the condition is not met, return null to avoid rendering anything.
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <p>
                      3. O curso em causa é uma Ação de Formação desenvolvida no
                      âmbito da seguinte operação a cargo do{" "}
                      <b>Primeiro Outorgante</b>:
                    </p>
                    <table className="projeto">
                      <tr>
                        <th>Nº do Projeto:</th>
                        {data.percurso[0].entFinanciadora &&
                        data.percurso[0].entFinanciadora.length > 0 ? (
                          <td>
                            {data.percurso[0].operacao[0] &&
                              data.percurso[0].operacao[0].codigoOperacao}
                          </td>
                        ) : (
                          <td>N/A</td>
                        )}
                      </tr>
                    </table>
                  </div>

                  <div>
                    <p>
                      4. Quando a formação decorre (total ou parcialmente) no
                      formato presencial, o local de realização do curso é o
                      seguinte:
                    </p>
                    <table className="dados">
                      <tr>
                        <th>Local:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].locality
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Morada:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].address
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Código de Postal:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].postalCode
                            : "(Online)"}
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div>
                    <p>
                      5. Quando a formação decorre (total ou parcialmente) no
                      formato a distância, o curso realiza-se através da
                      seguinte plataforma:
                    </p>

                    <table className="dados">
                      <tr>
                        <th>Plataforma LMS:</th>
                        <td>
                          {data.percurso[0].moodle &&
                          data.percurso[0].moodle.length > 0 ? (
                            <a href={data.percurso[0].moodle[0].name}>
                              {data.percurso[0].moodle[0].name}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <p>
                      6. O cronograma da formação é facultado no início da Ação
                      de Formação, sendo que está compreendido entre as
                      seguintes datas:
                    </p>

                    <table className="dados">
                      <tr>
                        <th>Data de início prevista:</th>
                        <td>{AlterarFormato(data.percurso[0].dateBegin)}</td>
                      </tr>
                      <tr>
                        <th>Data de fim prevista:</th>
                        <td>{AlterarFormato(data.percurso[0].dateEnd)}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <br />
                  <h2>Cláusula 2ª - Direitos dos/as formandos/as</h2>
                  <br />
                  <p>
                    São direitos do <b>Segundo Outorgante</b>:
                  </p>
                  <ol>
                    <li>
                      1. Receber do <b>Primeiro Outorgante</b> os ensinamentos
                      de acordo com os objetivos de Ação de Formação e respetivo
                      Programa de Formação.
                    </li>
                    <li>
                      2. Garantir a proteção dos dados pessoais do/a Formando/a,
                      sendo tratados apenas para fins diretamente relacionados
                      com a ação de formação, conservando-os de forma segura nos
                      termos e prazos aplicáveis.
                    </li>
                    <li>
                      3. Receber do Primeiro Outorgante, no final de cada Ação
                      de Formação, em caso de aproveitamento, o Certificado de
                      Formação Profissional legalmente previsto. Este
                      certificado deverá ser emitido até 30 dias após a
                      conclusão do curso, caso não seja possível deverá ser
                      emitida uma declaração de conclusão do curso.
                    </li>
                    <li>
                      4. Ter acesso às instalações, equipamentos e materiais
                      compatíveis com a tipologia da ação de formação.
                    </li>
                    <li>
                      5. Beneficiar de um seguro de acidentes pessoal, com apólice n.º <input
                        className="input-field-contracts"
                        type="text"
                        name="inputNumeroApolice"
                        defaultValue={valores.numeroApolice}
                        placeholder="ex: 23445232343"
                        onChange={(e) => {
                          setValores({ ...valores, numeroApolice: e.target.value });
                          handleInputChangeLocal(e);
                          adjustWidth (e);
                        }}
                        style={{
                          border: inputErrors.inputNumeroApolice
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />, da seguradora <input
                      className="input-field-contracts"
                      type="text"
                      name="inputSeguradora"
                      defaultValue={valores.seguradora}
                      placeholder="ex: Fidelidade"
                      onChange={(e) => {
                        setValores({ ...valores, seguradora: e.target.value });
                        handleInputChangeLocal(e);
                        adjustWidth (e);
                      }}
                      style={{
                        border: inputErrors.inputSeguradora
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />.
                    </li>
                  </ol>
                </div>
                <br />
                <h2>Cláusula 3ª - Deveres gerais dos/as formandos/as</h2>
                <br />
                <p>
                  São deveres do <b>Segundo Outorgante</b>:
                </p>
                <ol>
                  <li>
                    1. Tratar com urbanidade o <b>Primeiro Outorgante</b> e seus
                    representantes.
                  </li>
                  <li>
                    2. Frequentar com assiduidade e pontualidade a Ação de
                    Formação, de acordo com as regras constantes nos
                    regulamentos de formação em vigor, nomeadamente o
                    Regulamento da Formação à Distância (
                    <a href="https://drive.google.com/drive/folders/1GBgijvoBmp04CpA6dvtPEgeCgP6KmeiB?usp=sharing">
                      https://drive.google.com/drive/folders/1GBgijvoBmp04CpA6dvtPEgeCgP6KmeiB?usp=sharing
                    </a>
                    ), quando esta decorre nesse formato.
                  </li>
                  <li>
                    3. Realizar as provas de avaliação de acordo com os métodos
                    e critérios constantes nos regulamentos da Ação de Formação.
                  </li>
                  <li>
                    4. Abster-se da prática de qualquer ato que possa causar
                    prejuízo ou descrédito do <b>Primeiro Outorgante</b>
                  </li>
                  <li>
                    5. Sempre que se aplique, zelar pela conservação e boa
                    utilização dos bens e instalações do{" "}
                    <b>Primeiro Outorgante</b> e entidades associadas ao
                    projeto, na Ação de Formação em causa.
                  </li>
                  <li>
                    6. Suportar os custos de substituição ou reparação dos
                    equipamentos e materiais fornecidos pelo
                    <b>Primeiro Outorgante</b>, ou seus representantes, sempre
                    que danificados por via de comportamento doloso ou
                    gravemente negligente.
                  </li>
                  <li>
                    7. O registo e qualquer inscrição é individual e
                    intransmissível devendo apenas ser utilizada pelo/
                    próprio/a.
                  </li>
                  <li>
                    8. Na sequência do ponto anterior, sempre que a formação
                    decorra no formato a distância, refere-se o seguinte:
                  </li>
                  <ol className="tipoa" type="a">
                    <li>
                      O Login (“Nome de Utilizador”) e a Password (“Senha de
                      Acesso”) apenas podem ser utilizados pelo formando/a.
                    </li>
                    <li>
                      Todos os materiais, vídeos, textos, imagens e outros
                      conteúdos disponibilizados ao/à formando/ destinam-se
                      única e exclusivamente ao usufruto do/a próprio/a e não
                      podem ser entregues a qualquer outro indivíduo ou
                      entidade.
                    </li>
                    <li>
                      O/a formando/a reconhece que todos os seus acessos e
                      movimentos dentro da <b>Plataforma LMS</b> (referida na
                      Cláusula 1a) são registados e que os mesmos serão usados
                      para efeitos de verificação de assiduidade.
                    </li>
                    <li>
                      O/a formando/a terá acesso aos conteúdos disponibilizados
                      na <b>Plataforma LMS</b> (referida na Cláusula 1ª) durante
                      o tempo determinado no cronograma da Ação de Formação em
                      que se inscreveu.
                    </li>
                    <li>
                      Após o término da Ação de Formação, na data definida e
                      comunicada no cronograma, o/a formando/a terá o prazo de 1
                      mês para continuar a aceder aos conteúdos disponibilizados
                      na <b>Plataforma LMS</b> (referida na Cláusula 1ª).
                    </li>
                    <li>
                      Para que o/a formando/a possa aceder aos conteúdos da Ação
                      de Formação em causa, deve garantir que tem os recursos
                      técnicos necessários, tais como computador, ligação à{" "}
                      <i>Internet</i>, e a aplicação Google MEET ou outra.
                    </li>
                  </ol>
                </ol>
              </div>
              <br />
              <h2>
                Cláusula 4ª - Assiduidade nas componentes de formação presencial
                ou a distância, quando síncronas
              </h2>
              <br />
              <ol>
                <li>
                  1. O conceito de “Presença” ou a “Falta” a uma sessão,
                  aplica-se apenas à formação presencial ou formação à distância
                  quando estas decorrem no formato síncrono, isto é, não se
                  aplica à formação à distância quando esta decorre no formato
                  assíncrono.
                </li>
                <li>
                  2. A “Presença” ou a “Falta” a uma sessão será registada
                  através da rubrica, ou não, da Folha de Presenças/Sumários (ou
                  outro método previsto pelo organismo financiador).
                </li>
                <li>
                  3. Entende-se por “Falta”, para efeitos do presente Contrato:
                </li>
                <ol className="tipoa" type="a">
                  <li>
                    A não comparência a uma sessão de formação presencial ou
                    formação a distância, quando esta decorre no formato
                    síncrona, na data e horário previsto no cronograma (ou
                    agendada pelo/a formador/a).
                  </li>
                  <li>
                    A comparência do/a formando/a a uma sessão de formação
                    presencial ou formação a distância, quando no formato
                    síncrona, com atraso superior a 15 minutos, é considerada
                    “Falta”.
                  </li>
                </ol>
                <li>
                  4. Especificamente nas componentes de formação a distância
                  síncrona, que se realizam no formato de videoconferência, o
                  formado obriga-se a estar com a câmara e microfone ligado, a
                  fim de poder intervir sempre que solicitado pelo/a formador/a,
                  assim como autoriza a gravação dessas sessões para partilha e
                  re-visionamento posterior, na plataforma do curso, apenas para
                  fins de aprendizagem ou auditoria.
                </li>
                <li>5. As faltas podem ser justificadas ou injustificadas.</li>
                <li>
                  6. São consideradas faltas justificadas, mediante apresentação
                  de comprovativo, as faltas da seguinte natureza:
                </li>
                <ol className="tipoa">
                  <li>Doença ou Acidente;</li>
                  <li>Falecimento de parentes próximos ou afins;</li>
                  <li>Casamento;</li>
                  <li>Maternidade ou paternidade;</li>
                  <li>Doença ou Acidente de familiar a cargo;</li>
                  <li>
                    Qualquer dever imposto por lei, que não admita adiamento;
                  </li>
                  <li>
                    Outros motivos comprovados e aceites pela Entidade
                    Formadora.
                  </li>
                </ol>
                <li>
                  7. As faltas justificadas, quando previsíveis devem ser
                  comunicadas à Coordenação Pedagógica, com a antecedência
                  mínima de dois dias.
                </li>
              </ol>
              <div>
                <br />
                <h2>
                  Cláusula 5ª - Assiduidade nas componentes de formação a
                  distância, quando assíncronas
                </h2>
                <br />
                <ol>
                  <li>
                    1. A assiduidade da componente à distância assíncrona é
                    verificada através da “carga de trabalho” e tempo de
                    trabalho registado pela <b>Plataforma LMS</b> (referida na
                    Cláusula 1ª) onde decorre a formação, sendo que corresponde
                    à realização (ou não) das atividades previstas no Roteiro
                    Pedagógico de cada uma das UFCD que fazem parte do Percurso
                    de Formação em causa, as quais são disponibilizadas na{" "}
                    <b>Plataforma LMS</b> (referida na Cláusula 1ª) e que
                    incluem:
                  </li>

                  <ol className="tipoa" type="a">
                    <li>
                      O visionamento e estudo dos materiais pedagógicos,
                      incluindo vídeo aulas, documentação de suporte, atividades
                      e outros materiais disponibilizados.
                    </li>
                    <li>
                      A realização das atividades previstas na plataforma e
                      outras tarefas determinadas pelo/a formador/a.
                    </li>
                  </ol>
                  <li>
                    2. Nas componentes da formação desenvolvida no formato à
                    distância, componente assíncrona, o/a formando/a está
                    obrigado à realização de 90% das atividades pedagógicas
                    previstas no Roteiro Pedagógico de cada uma das UFCD que
                    fazem parte do Percurso de Formação, independentemente do
                    que de seguida será referido na Cláusula 6ª.
                  </li>
                  <li>
                    3. O Roteiro Pedagógico da UFCD tem uma carga horária
                    associada a cada conteúdo, mas a assiduidade não está
                    diretamente relacionada com o cumprimento de um horário ou
                    um tempo de trabalho dedicado à atividade, mas sim à sua
                    realização com zelo e interesse de cada uma das componentes
                    previstas no Roteiro Pedagógico.
                  </li>
                  <li>
                    4. O controlo da realização ou não das atividades previstas
                    no Roteiro Pedagógico é efetuado de forma automática por
                    meio da <b>Plataforma LMS</b> (referida na Cláusula 1ª).
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 6ª - Aproveitamento</h2>
                <br />
                <ol>
                  <li>
                    1. O/a formando/a está obrigado à frequência de pelo menos
                    90% do total das horas presencias ou síncronas e/ou da carga
                    de trabalho assíncrona (quando aplicável o disposto na
                    Cláusula 5ª), da totalidade do Percurso de Formação e,
                    cumulativamente, a 50% da carga horária/carga de trabalho de
                    cada UFCD.
                  </li>
                  <li>
                    2. Durante o Percurso de Formação o/a formando/a será
                    submetido/a a um processo de avaliação sumativa a cada uma
                    das suas componentes, UFCD a UFCD, que irá determinar o seu
                    aproveitamento, ou não.
                  </li>
                  <li>
                    3. Caso o formando não cumpra o disposto no nº 1 e nº 2 da
                    presente Cláusula, à luz da Portaria nº 66/2022, para
                    alcançar os resultados mínimos de aprendizagem, o{" "}
                    <b>Primeiro Outorgante</b> deve desenvolver os mecanismos de
                    recuperação necessários ao cumprimento dos objetivos
                    inicialmente definidos, nomeadamente, disponibilizando os
                    materiais pedagógicos em formato assíncrono em momentos
                    diferentes dos inicialmente previstos no cronograma e a
                    possibilidade de realização das avaliações sumativas noutros
                    momentos para que alcance os resultados de aprendizagem
                    mínimos.
                  </li>
                  <li>
                    4. Desde que a Ação de Formação seja concluída “Com
                    Aproveitamento”, o <b>Segundo Outorgante</b>
                    receberá do <b>Primeiro Outorgante</b>, o respetivo
                    Certificado de Formação Profissional legalmente previsto.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>
                  Cláusula 7ª - Alteração do Percurso/Cronograma de Formação
                </h2>
                <br />
                <ol>
                  <li>
                    1. Quando, por razões alheias à sua vontade e a si não
                    imputáveis, o <b>Primeiro Outorgante</b> não puder cumprir
                    integralmente o Percurso/Cronograma de Formação previsto,
                    poderá proceder aos necessários ajustamentos, devendo sempre
                    comunicar tal facto ao <b>Segundo Outorgante</b>.
                  </li>
                  <li>
                    2. A alteração do Percurso/Cronograma de Formação pelos
                    motivos referidos no número anterior, não confere ao{" "}
                    <b>Segundo Outorgante</b> direito a qualquer indemnização.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 8ª - Casos Omissos</h2>
                <br />
                <ol>
                  <li>
                    1. Nos casos omissos, recorrer-se-á ao Regulamento de
                    Funcionamento da Formação e à Lei Geral.
                  </li>
                  <li>
                    2. As dúvidas suscitadas pela aplicação do presente contrato
                    serão resolvidas pelo <b>Primeiro Outorgante</b>, ou seus
                    representantes.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 9ª — Duração</h2>
                <br />
                <ol>
                  <li>
                    O presente contrato vigorará pelo período de duração de
                    formação, previsto na Cláusula 1ª.
                  </li>
                </ol>
              </div>

              <div>
                <br />
                <h2>Cláusula 10ª — Incumprimento</h2>
                <br />
                <ol>
                  <li>
                    1. Verificando-se o incumprimento pelo{" "}
                    <b>Segundo Outorgante</b> de qualquer das obrigações
                    assumidas no presente contrato, o <b>Primeiro Outorgante</b>{" "}
                    poderá proceder à rescisão imediata do presente contrato,
                    sem direito a qualquer indemnização ou compensação
                    pecuniária.
                  </li>
                  <li>
                    2. Os/as formandos/as que não tenham concluído a formação
                    por motivo de faltas relacionadas com a maternidade,
                    paternidade ou assistência à família, têm prioridade no
                    acesso a Ações de Formação que se iniciem imediatamente após
                    o termo do impedimento.
                  </li>
                </ol>
              </div>
              <div style={{ breakInside: "avoid" }}>
                <br />
                <h2>Cláusula 11ª — Legislação</h2>
                <br />
                <ol>
                  <p>
                  O presente Contrato de Formação regula-se nos termos do disposto na Portaria nº 325/2023, para efeitos de Co-financiamento pelo Fundo Social Europeu (FSE).
                  </p>
                  <br />
                  <p>
                    O presente contrato é feito em duplicado fincando cada uma
                    das partes na posse de um exemplar.
                  </p>
                </ol>
                <table className="localData">
                  <tr>
                    <th>Local:</th>
                    <td>
                      <input
                        className="w-full input-field"
                        type="text"
                        name="inputLocal"
                        defaultValue={valores.local}
                        placeholder="ex: Porto"
                        onChange={(e) => {
                          setValores({ ...valores, local: e.target.value });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputLocal
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Data:</th>
                    <td>
                      <input
                        className="w-full input-field"
                        type="text"
                        name="inputDate"
                        defaultValue={valores.date}
                        placeholder="12/12/2023"
                        onChange={(e) => {
                          setValores({
                            ...valores,
                            date: AlterarFormato(e.target.value),
                          });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputDate
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </td>
                  </tr>
                </table>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O PRIMEIRO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt="assinatura"
                        style={{ margin: "5px auto" }}
                        id="img"
                        className="img"
                        src={data.percurso[0].entFormadora[0].assinatura}
                        height="70"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O SEGUNDO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              {data.percurso[0].entFinanciadora &&
                data.percurso[0].entFinanciadora.length > 0 &&
                data.percurso[0].entFinanciadora[0].logotipo2 && (
                  <div>
                    <img
                      src={data.percurso[0].entFinanciadora[0].logotipo2}
                      className="h-20"
                      alt="logo"
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                )}
            </div>
            <div className="flex justify-center w-full">
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Enviar Contrato"}
                title={"Enviar Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormando}
              />
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Download Contrato"}
                title={"Download Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormandoNoEmail}
              />
            </div>
          </ContratoFormando>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
