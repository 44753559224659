import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import toastError from "../Toast/toastError";
import 'react-toastify/dist/ReactToastify.css';
import containsPostalCode from "../FormChecks/containsPostalCode/containsPostalCode";
import check_nif_number from "../FormChecks/checkNif/checkNIf";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import FileUploader  from "../../utils/validationFileUploader";

function CreateEntFormadora(token) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [name, setName] = useState("");
    const [gestorFormacao, setGestorFormacao] = useState("");
    const [morada, setMorada] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [localidade, setLocalidade] = useState("");
    const [distrito, setDistrito] = useState("");
    const [concelho, setConcelho] = useState("");
    const [nif, setNif] = useState("");

    const [representanteLegal, setrepresentanteLegal] = useState("");
    const [nifRepresentanteLegal, setNifRepresentanteLegal] = useState("");
    const [ccRepresentanteLegal, setCcRepresentanteLegal] = useState("");
    const [validadeCcRepresentanteLegal, setValidadeCcRepresentanteLegal] = useState("");
    const [cargoRepresentanteLegal, setCargoRepresentanteLegal] = useState("");
    const [moradaRepresentanteLegal, setMoradaRepresentanteLegal] = useState("");
    const [localidadeRepresentanteLegal, setLocalidadeRepresentanteLegal] = useState("");
    const [postalCodeRepresentanteLegal, setPostalCodeRepresentanteLegal] = useState("");
    const [distritoRepresentanteLegal, setDistritoRepresentanteLegal] = useState("");
    const [concelhoRepresentanteLegal, setConcelhoRepresentanteLegal] = useState("");

    const [codDGERT, setCodDGERT] = useState("");
    const [fileDGERT, setfileDGERT] = useState(null);
    const [fileAssinatura, setfileAssiantura] = useState(null);
    const [imageHeightLogo, setimageHeightLogo] = useState(0);
    const [imageHeightAssinatura, setimageHeightAssinatura] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const image = new Image();
    const params = useParams();
    const navigate = useNavigate();

    const handleFileChangeLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            const file = event.target.files[0];
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeightLogo(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else setSelectedFile(null);
    };

    const handleFileChangeAssinatura = (event) => {
        const file = event.target.files[0];
        if (file) {
            setfileAssiantura(file);
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeightAssinatura(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else setfileAssiantura(null);
    };

    const handleSubmit = async () => {
        if (name !== "" && representanteLegal !== "" && nifRepresentanteLegal !== "" && ccRepresentanteLegal !== "" && validadeCcRepresentanteLegal !== "" && cargoRepresentanteLegal !== "" && moradaRepresentanteLegal !== "" && localidadeRepresentanteLegal !== "" && distritoRepresentanteLegal !== "" && concelhoRepresentanteLegal !== "" && postalCodeRepresentanteLegal !== "" && distrito !== "" && concelho !== "" && codDGERT !== "" && nif !== "" && morada !== "" && localidade !== "" && postalCode !== "" && localidade !== "" && gestorFormacao !== "") {
            if (containsPostalCode(postalCode)) {
                if (check_nif_number(nif)) {
                    if (selectedFile !== null && fileDGERT !== null && fileAssinatura !== null) {
                        if (fileDGERT.type === "application/pdf") {
                            if (selectedFile.type === "image/png" || selectedFile.type === "image/jpeg" || fileAssinatura.type === "image/png" || fileAssinatura.type === "image/png") {
                                if (selectedFile.size <= 200000 || fileAssinatura.size <= 200000) {
                                    if (imageHeightLogo <= 240 || imageHeightAssinatura <= 240) {
                                        setLoading(true)
                                        try {
                                            const options = {
                                                method: "POST",
                                                headers: {
                                                    "Accept": "application/json",
                                                    'authorization': localStorage.getItem(token)
                                                },
                                                credentials: "same-origin"
                                            }
                                            let ccForm = new FormData()
                                            ccForm.append('logo', selectedFile)
                                            options.body = ccForm
                                            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options)
                                            const logoResult = await logoResponse.json()

                                            let certificadoForm = new FormData()
                                            certificadoForm.append('certificado', fileDGERT)
                                            options.body = certificadoForm
                                            const certificadoResponse = await fetch(`${process.env.REACT_APP_API_URL}/certificado`, options)
                                            const certificadoResult = await certificadoResponse.json()

                                            let assinaturaForm = new FormData()
                                            assinaturaForm.append('assinatura', fileAssinatura)
                                            options.body = assinaturaForm
                                            const assinaturaResponse = await fetch(`${process.env.REACT_APP_API_URL}/assinatura`, options)
                                            const assinaturaResult = await assinaturaResponse.json()

                                            fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`,
                                                {
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json',
                                                        'authorization': localStorage.getItem(token)
                                                    },
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        name: name,
                                                        logotipo: logoResult,
                                                        representanteLegal: representanteLegal,
                                                        gestorFormacao: gestorFormacao,
                                                        codDGERT: codDGERT,
                                                        localidade: localidade,
                                                        postalCode: postalCode,
                                                        morada: morada,
                                                        nif: nif,
                                                        distrito: distrito,
                                                        concelho: concelho,
                                                        nifRepresentanteLegal: nifRepresentanteLegal,
                                                        ccRepresentanteLegal: ccRepresentanteLegal,
                                                        validadeCcRepresentanteLegal: validadeCcRepresentanteLegal,
                                                        cargoRepresentanteLegal: cargoRepresentanteLegal,
                                                        moradaRepresentanteLegal: moradaRepresentanteLegal,
                                                        postalCodeRepresentanteLegal: postalCodeRepresentanteLegal,
                                                        distritoRepresentanteLegal: distritoRepresentanteLegal,
                                                        concelhoRepresentanteLegal: concelhoRepresentanteLegal,
                                                        localidadeRepresentanteLegal: localidadeRepresentanteLegal,
                                                        certificado: certificadoResult,
                                                        assinatura: assinaturaResult,
                                                    })
                                                })
                                                .then(res => res.json())
                                                .then((result) => {
                                                    setLoading(false)
                                                    toastSuccess('Entidade Formadora adicionada com sucesso!')
                                                    if (token === "admin1") navigate('/menuAdmin/' + params.id_candidatura)
                                                    else navigate('/menuSuperAdmin')
                                                })
                                        } catch (error) {
                                            setLoading(false);
                                            console.error('Error uploading file:', error);
                                        }
                                    } else toastError('A altura da imagem deve ser menor ou igual a 240 píxeis.');
                                } else toastError('Ficheiro demasiado grande.')
                            } else toastError('o logotipo apenas pode ser um png ou jpeg.');;
                        } else toastError('O certificado apenas pode ser um pdf.');;
                    } else toastError('Adicione todos os ficheiros.');
                } else toastError("Número de identificação de pessoa coletiva inválido.")
            } else toastError("O Código Postal é inválido. \nEx:1111-000")
        } else toastError('Preencha todos os campos obrigatórios.');
    };
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                {token === "admin1" ? <><div className="w-full">
                    <NavbarAdmin1 currentPage={"criarEntidadeTraining"} />
                </div>
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link></> : <><div className="w-full">
                        <NavbarAdmin2 currentPage={"criarEntidadeTraining"} />
                    </div>
                    <Link to={'/menuSuperAdmin/'} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link></>}
                <div className="flex-grow mt-20 sm:mt-8">
                    <h1 className="title">Criar entidade formadora</h1>
                    <div className="flex justify-center w-screen">
                        <div className="grid mx-2 sm:grid-cols-2 gap-x-16">
                            <div>
                                <div className="flex flex-col items-start mx-4 sm:mx-0">
                                    <label className="label-input">Nome*:</label>
                                    <input id="name" className="w-full input-field" onChange={(e) => setName(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="gestorFormacao">Gestor de Formação:*</label>
                                    <input type="text" className="w-full input-field" id="gestorFormacao" name="gestorFormacao" value={gestorFormacao} onChange={(e) => { setGestorFormacao(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Código DGERT*:</label>
                                    <input id="name" className="w-full input-field" onChange={(e) => setCodDGERT(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="address">Morada*:</label>
                                    <input type="text" className="w-full input-field" id="address" name="address" value={morada} onChange={(e) => { setMorada(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="locality">Localidade*:</label>
                                    <input type="text" className="w-full input-field" id="locality" name="locality" value={localidade} onChange={(e) => { setLocalidade(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="address">Distrito*:</label>
                                    <input type="text" className="w-full input-field" id="distrito" name="distrito" value={distrito} onChange={(e) => { setDistrito(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="locality">Concelho*:</label>
                                    <input type="text" className="w-full input-field" id="concelho" name="concelho" value={concelho} onChange={(e) => { setConcelho(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="postalCode">Código Postal:*</label>
                                    <input type="text" className="w-full input-field" id="postalCode" name="postalCode" pattern="[0-9]{4}[\-]?[0-9]{3}" value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="nif">Número de identificação de pessoa coletiva*:</label>
                                    <input type="text" className="w-full input-field" id="nif" name="nif" minLength={9} maxLength={9} value={nif} onChange={(e) => { setNif(e.target.value) }} required></input>
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col items-start mx-4 sm:mx-0">
                                    <label className="label-input">Representante Legal*:</label>
                                    <input id="name" className="w-full input-field" defaultValue={representanteLegal} onChange={(e) => setrepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">NIF do Representante Legal*:</label>
                                    <input id="nifRL" className="w-full input-field" defaultValue={nifRepresentanteLegal} onChange={(e) => setNifRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">CC do Representante Legal*:</label>
                                    <input id="ccRL" className="w-full input-field" defaultValue={ccRepresentanteLegal} onChange={(e) => setCcRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Validade do CC do Representante Legal*:</label>
                                    <input
                                        id="validadeCCRL"
                                        type="date"
                                        min={new Date().toISOString().split('T')[0]} // Formats today's date as YYYY-MM-DD
                                        className="w-full input-field"
                                        defaultValue={validadeCcRepresentanteLegal}
                                        onChange={(e) => setValidadeCcRepresentanteLegal(e.target.value)}
                                    />                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Cargo do Representante Legal*:</label>
                                    <input id="cargoRL" className="w-full input-field" defaultValue={cargoRepresentanteLegal} onChange={(e) => setCargoRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Morada Atual do Representante Legal*:</label>
                                    <input id="moradaRL" className="w-full input-field" defaultValue={moradaRepresentanteLegal} onChange={(e) => setMoradaRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Código Postal do Representante Legal*:</label>
                                    <input id="codigoPostalRL" className="w-full input-field" defaultValue={postalCodeRepresentanteLegal} onChange={(e) => setPostalCodeRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Freguesia da Naturalidade do Representante Legal*:</label>
                                    <input id="localidadeRL" className="w-full input-field" defaultValue={localidadeRepresentanteLegal} onChange={(e) => setLocalidadeRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Distrito da Naturalidade do Representante Legal*:</label>
                                    <input id="distritoRL" className="w-full input-field" defaultValue={distritoRepresentanteLegal} onChange={(e) => setDistritoRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Concelho da Naturalidade do Representante Legal*:</label>
                                    <input id="concelhoRL" className="w-full input-field" defaultValue={concelhoRepresentanteLegal} onChange={(e) => setConcelhoRepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Logotipo*:</label>
                                    <FileUploader
                                    inputClassName={"input-file"}
                                    inputAccept={".png,.jpeg,.jpg"}
                                    inputType={"file"}
                                    allowedTypes={[
                                    "image/jpeg",
                                    "image/png",
                                    "image/jpg",
                                    ]}
                                    onChangeSpecial={handleFileChangeLogo}
                                    />
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Assinatura*:</label>
                                    <FileUploader
                                    inputClassName={"input-file"}
                                    inputAccept={".png,.jpeg,.jpg"}
                                    inputType={"file"}
                                    allowedTypes={[
                                    "image/jpeg",
                                    "image/png",
                                    "image/jpg",
                                    ]}
                                    onChangeSpecial={handleFileChangeAssinatura}
                                    />
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Certificação*:</label>
                                    <FileUploader
                                    inputClassName={"input-file"}
                                    inputAccept={".pdf"}
                                    inputType={"file"}
                                    allowedTypes={[
                                    "application/pdf",
                                    ]}
                                    onChangeSpecial={(e) => setfileDGERT(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="mt-8 mb-5 button-geral" onClick={handleSubmit}>Adicionar</button>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default CreateEntFormadora