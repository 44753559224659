import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import DateTimeDifference from "../FormChecks/DateTimeDifference/dateTimeDiff";

function OperacaoIndividual(token) {
    const navigate = useNavigate();
    const [data, setData] = useState(null);

    const [admin, setAdmin] = useState("");
    const [adminType, setAdminType] = useState(null);

    const [entFinanciadora, setEntFinanciadora] = useState("");
    const [entFormadora, setEntFormadora] = useState("");

    const [entFinanciadoraList, setEntFinanciadoraList] = useState("");
    const [entFormadoraList, setEntFormadoraList] = useState("");

    const [diretExec, setDiretExec] = useState([]);
    const [gestorOpe, setGestorOpe] = useState([]);
    const [gestorFin, setGestorFin] = useState([]);
    const [diretDep, setDiretDep] = useState([]);

    const [formandosValidados, setFormandosValidados] = useState(null);
    const [formandosFinalizados, setFormandosFinalizados] = useState(null);

    const [formandosEncValidados, setEncValidados] = useState(null);
    const [formandosEncFinalizados, setEncFinalizados] = useState(null);

    const [alterarDados, setAlterarDados] = useState(-1);

    const [alterar, setAlterar] = useState(-1);

    const [contTotalFormandos, setContTotalFormandos] = useState(0);
    const [cont5e4Formandos, setCont5e4Formandos] = useState(0);

    const [contTotalFormadores, setContTotalFormadores] = useState(0);
    const [cont5e4Formadores, setCont5e4Formadores] = useState(0);

    const [contTotalCoordenadores, setContTotalCoordenadores] = useState(0);
    const [cont5e4Coordenadores, setCont5e4Coordenadores] = useState(0);

    const [formandosValidadosFiltrados, setFormandosValidadosFiltrados] = useState(null);
    const [formandosEncFiltrados, setFormandosEncFiltrados] = useState(null);

    const [volumeTotal, setVolumeTotal] = useState(null);
    const [taxaCertificacao, setTaxaCertificacao] = useState(null);

    const [volumeDates, setVolumeDates] = useState(null);
    const [volumeDatesCertificados, setVolumeDatesCertificados] = useState(null);
    const [taxaCertificacaoDates, setTaxaCertificacaoDates] = useState(null);

    const [formandosDesistentesDate, setFormandosDesistentesDate] = useState(null);

    const [desempenhoFormadores, setDesempenhoFormadores] = useState(null);
    const [desempenhoCoordenadores, setDesempenhoCoordenadores] = useState(null);

    const [percursos, setPercursos] = useState(null);
    const [dateBegin, setDateBegin] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    useEffect(() => {

        getOperacao()
        getAdmins()
        getPercursoOperacao()
        getEntFinanciadoras()
    }, [loading, alterarDados, dateBegin, dateEnd]);
    //Obtem percurso
    function getOperacao() {
        fetch(`${process.env.REACT_APP_API_URL}/operacao/${params.id_operacao}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setLoading(false)
                if (token === "admin1") {
                    fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
                        headers: {
                            "authorization": localStorage.getItem(token)
                        }
                    })
                        .then((res) => res.json())
                        .then(
                            (result) => {

                                setAdminType(result.nomeRole);
                                if (token === "admin1") {
                                    setEntFormadoraList(result.entFormadora)
                                }

                            },
                            (error) => {
                            }
                        );
                } else {
                    fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
                        headers: {
                            'authorization': localStorage.getItem(token)
                        },
                        method: 'GET',
                    })
                        .then(response => response.json())
                        .then(result => {

                            setEntFormadoraList(result)
                        });
                }
            })
    }

    function getEntFinanciadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFinanciadora`, {
            headers: {
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setEntFinanciadoraList(result)
            });
    }
    async function submeterDados() {
        try {
            await addAdmin();
            await submeterDadosPrv();

        } catch (error) {
            console.error('Erro ao submeter os dados:', error);
        } finally {
            setLoading(false);
            setAlterarDados(-1) // Não precisa de await aqui
        }
    }
    function addAdmin() {
        const gestorOperacao = document.getElementById("gestorOperacao") ? document.getElementById("gestorOperacao").value : null
        const gestorFinanceiro = document.getElementById("gestorFinanceiro") ? document.getElementById("gestorFinanceiro").value : null
        const diretorExecutivo = document.getElementById("diretorExecutivo") ? document.getElementById("diretorExecutivo").value : null
        const diretorDepartamento = document.getElementById("diretorDepartamento") ? document.getElementById("diretorDepartamento").value : null
        const entidadeFormadora = document.getElementById("entFormadora") ? document.getElementById("entFormadora").value : null
        const entidadeFinanciadora = document.getElementById("entFinanciadora") ? document.getElementById("entFinanciadora").value : null
        fetch(`${process.env.REACT_APP_API_URL}/operacao/associarAdmin/${params.id_operacao}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem(token) },
                body: JSON.stringify({
                    gestorOperacao: gestorOperacao,
                    gestorFinanceiro: gestorFinanceiro,
                    diretorExecutivo: diretorExecutivo,
                    diretorDepartamento: diretorDepartamento,
                    entFormadora: entidadeFormadora,
                    entFinanciadora: entidadeFinanciadora
                })
            })
            .then((res) => res.json())
            .then(
                (result) => {
                },
                (error) => {
                }
            );
    }
    function getAdmins() {
        fetch(`${process.env.REACT_APP_API_URL}/admins`, {
            headers: {
                "authorization": localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    const gestorOperacao = result.filter(admin => (admin.nomeRole === "Administrador - Gestor de Operação" || admin.nomeRole === "Entidade de Ligação" || admin.nomeRole === "Administrador - Diretor de Departamento"))
                    setGestorOpe(gestorOperacao)
                    const gestorFinanceiro = result.filter(admin => (admin.nomeRole === "Gestor Financeiro"))
                    setGestorFin(gestorFinanceiro)
                    const diretorDepartamento = result.filter(admin => (admin.nomeRole === "Administrador - Geral" || admin.nomeRole === "Administrador - Diretor de Departamento"))
                    setDiretDep(diretorDepartamento)
                    const diretorExec = result.filter(admin => (admin.nomeRole === "Administrador - Gestor de Operação" || admin.nomeRole === "Administrador - Geral" || admin.nomeRole === "Entidade de Ligação" || admin.nomeRole === "Administrador - Diretor de Departamento"))
                    setDiretExec(diretorExec)

                },
                (error) => {

                }
            );
    }

    function submeterDadosPrv() {
        const participantesPrev = document.getElementById("participantesPrev") && document.getElementById("participantesPrev").value
        const encaminhadosPrev = document.getElementById("encaminhadosPrev") && document.getElementById("encaminhadosPrev").value
        const taxaCerti = document.getElementById("taxaCerti") && document.getElementById("taxaCerti").value
        const volumeFormacao = document.getElementById("volumeFormacao") && document.getElementById("volumeFormacao").value
        const tipologia = document.getElementById("tipologia") && document.getElementById("tipologia").value
        const nometipologia = document.getElementById("nomeTipologia") && document.getElementById("nomeTipologia").value
        const codigoOperacao = document.getElementById("codigoOperacao") && document.getElementById("codigoOperacao").value

        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/operacao/${params.id_operacao}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem(token)
                },
                body: JSON.stringify({
                    participantesPrev: participantesPrev,
                    encaminhadosPrev: encaminhadosPrev,
                    taxaCerti: taxaCerti,
                    volumeFormacao: volumeFormacao,
                    tipologia: tipologia,
                    nomeOperacao: nometipologia,
                    codigoOperacao: codigoOperacao
                })
            })
            .then(res => res.json())
            .then(
                (res) => {
                    setLoading(false)
                    setAlterar(-1)
                    toastSuccess("Dados alterados com sucesso")
                },
                (error) => {
                    console.error(error)
                    setLoading(false)
                    return
                }
            )
    }

    function getFormandosCertificados(element, percurso) {
        let alunosCerti = 0;
        let volumeCerti = 0
        let volumeTotal = 0
        const formandos = element.formandos;
        const totalAulasSinc = element.eventos.reduce((total, event) => {
            return total + DateTimeDifference(event.start, event.end);
        }, 0);
        if (element.fechoCoordenador) {
            formandos.forEach((formando) => {
                if (formando.valid === "Validado" && formando.pauta && formando.pauta.length > 0) {
                    const index = formando.pauta.findIndex((item) => item.idUfcd === element._id.toString());
                    if (index !== -1) {
                        const pauta = formando.pauta[index];
                        const percentSincrona = (100 * parseInt(pauta.sincrona)) / parseInt(element.cargaHoraria);
                        const totalhoraspercent = (Math.floor((((pauta.assiduidade / 100) * (parseInt(element.cargaHoraria) - totalAulasSinc)) + pauta.sincrona) * 100) / parseInt(element.cargaHoraria))

                        if (element.formato === "Presencial" && pauta.notaFinal && pauta.notaFinal >= 9.5) {
                            if (percurso.operacao[0].tipologia === "Formação Empresarial Conjunta e Formação Ação" && pauta.sincrona >= 80) {
                                alunosCerti++;
                                volumeCerti = volumeCerti + pauta.sincrona
                                volumeTotal = volumeTotal + pauta.sincrona
                            } else if (percentSincrona >= 90) {
                                alunosCerti++;
                                volumeCerti = volumeCerti + pauta.sincrona
                                volumeTotal = volumeTotal + pauta.sincrona
                            } else volumeTotal = volumeTotal + pauta.sincrona
                        } else if (
                            element.formato !== "Presencial" &&
                            pauta.media &&
                            pauta.media - (pauta.forumTotal - pauta.forum) >= 9.5
                        ) {
                            if (percurso.operacao[0].tipologia === "Formação Empresarial Conjunta e Formação Ação" && totalhoraspercent >= 80) {
                                alunosCerti++;
                                volumeCerti = volumeCerti + pauta.sincrona + pauta.assincrona
                                volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                            } else if (totalhoraspercent >= 90) {
                                alunosCerti++;
                                volumeCerti = volumeCerti + pauta.sincrona + pauta.assincrona
                                volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                            } else volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                        }
                    }
                }
            });
        }

        return { alunosCerti: alunosCerti, totalAlunos: element.formandos.filter((item) => (item.valid === "Validado" || item.valid === "Desistiu")), volumeCerti: volumeCerti, volumeTotal: volumeTotal };
    }



    function getPercursoOperacao() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/operacao/${params.id_operacao}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setPercursos(result);

                const firstCourseDate = result
                    .map(element => new Date(element.dateBegin)) // Convert the dates to Date objects
                    .sort((a, b) => a - b) // Sort the dates in ascending order
                    .flat()[0]; // Get the first (earliest) date

                const dataInicio = document.getElementById("dataInicio") && new Date(document.getElementById("dataInicio").value);
                const dataFim = document.getElementById("dataFim") && new Date(document.getElementById("dataFim").value);
                const percursosFiltred = result
                    .filter(percursoFiltrado => {
                        const dateBegin = new Date(percursoFiltrado.dateBegin);
                        const dateEnd = new Date(percursoFiltrado.dateEnd);

                        // Check if the filtered "percurso" falls within the date range
                        return dataInicio <= dateBegin && dataFim >= dateEnd;
                    })
                if (!dateBegin) setDateBegin(firstCourseDate)
                // Indicadores
                {
                    //Formandos
                    {  // Collect all formandos that are valid from each element
                        const formandosValidados = result.map(element =>
                            element.formandos.filter((item) => item.valid === "Validado")
                        ).flat(); // Flatten the array since filter returns arrays

                        const formandosFinalizados = result
                            .filter(element => element.fechoAdmin === true) // or simply 'element.fechoAdmin' if it's a boolean
                            .map(element =>
                                element.formandos.filter((item) =>
                                    item.valid === "Validado" && item.pauta?.notafinal >= 10
                                )
                            )
                            .flat();

                        // Collect all formandos that are valid from each element
                        const formandosEncValidados = result.map(element =>
                            element.formandos.filter((item) => item.valid === "Validado" && item.encCQ === "Validado")
                        ).flat(); // Flatten the array since filter returns arrays


                        const formandosEncFinalizados = result
                            .filter(element => element.fechoAdmin === true) // or simply 'element.fechoAdmin' if it's a boolean
                            .map(element =>
                                element.formandos.filter((item) =>
                                    item.valid === "Validado" && item.encCQ === "Validado" && item.pauta?.notafinal >= 10
                                )
                            )
                            .flat();

                        setEncValidados(formandosEncValidados)

                        setEncFinalizados(formandosEncFinalizados)
                        // Update the state with the valid formandos
                        setFormandosValidados(formandosValidados);
                        setFormandosFinalizados(formandosFinalizados)
                    }
                    //Taxa Certificação
                    {
                        let totalFormandos = 0; // Total formandos across all percursos
                        let totalFormandosAprov = 0; // Total aprovados across all percursos

                        result.forEach(percurso => {
                            let percursoFormandos = 0;
                            let totalAlunos = 0;
                            percurso.ufcds.forEach(disciplina => {
                                if (disciplina.fechoCoordenador) {
                                    // Get data for this disciplina only once
                                    const formandosData = getFormandosCertificados(disciplina, percurso);
                                    percursoFormandos += formandosData.alunosCerti || 0;
                                    totalAlunos += formandosData.totalAlunos.length || 0;
                                }
                            });

                            // Accumulate results for this percurso into global totals
                            totalFormandosAprov += percursoFormandos;
                            totalFormandos += totalAlunos;

                        });

                        // Calculate and set the certification rate
                        const taxaCertificacao = totalFormandos > 0
                            ? Math.round((totalFormandosAprov * 100) / totalFormandos)
                            : 0;
                        setTaxaCertificacao(taxaCertificacao);
                    }

                    //Volume
                    {
                        let totalVolume = 0; // Total volume across all percursos
                        let totalFormandos = 0; // Total formandos across all percursos
                        result.forEach(percurso => {
                            let percursoVolume = 0;
                            let percursoFormandos = 0
                            percurso.ufcds.forEach(disciplina => {
                                const certificados = getFormandosCertificados(disciplina, percurso);
                                percursoVolume += certificados.volumeTotal; // Acumular volume para o percurso
                            });
                            // Accumulate the results for this percurso into the global totals
                            totalVolume += percursoVolume;
                            totalFormandos += percursoFormandos;
                        });
                        setVolumeTotal({ volume: totalVolume, formandos: totalFormandos })
                    }
                }
                // Análise
                {
                    //Forms de Satisfação dos Formandos
                    {
                        const resultados5e4AvaSatisTotal = percursosFiltred.map(percurso =>
                            // Filter the "percursoFiltrado" based on the date range
                            percurso.ufcds.map(element =>
                                element.avaliacaoSatisfacaoFormandosResults.length * 8
                            )
                        )
                            .flat(2) // Flatten nested arrays (double flattening because of nested maps)
                            .reduce((acc, curr) => acc + curr, 0); // Sum up the results
                        setContTotalFormandos(resultados5e4AvaSatisTotal)

                        const resultados5e4AvaSatisFormandos = percursosFiltred.map(percurso =>
                            // 
                            percurso.ufcds.map(percursoFiltrado =>
                                // Assuming 'avaliacaoSatisfacaoFormandosResults' is an array of objects
                                percursoFiltrado.avaliacaoSatisfacaoFormandosResults.map(criteriaObj => {
                                    // Extract all the criterion values from the object
                                    const criterios = Object.values(criteriaObj);
                                    // Filter for valid values
                                    return criterios.filter(criterio =>
                                        ["5 - Muito Bom", "4 - Bom", 4, 5].includes(criterio)
                                    ).length;
                                }).reduce((acc, curr) => acc + curr, 0) // Sum the filtered counts from each criteria object
                            )
                        )
                            .flat() // Flatten the array to remove any nested arrays
                            .reduce((acc, curr) => acc + curr, 0); // Sum up the counts
                        setCont5e4Formandos(resultados5e4AvaSatisFormandos)
                    }

                    //Forms de Satisfação dos Formadores
                    {
                        const resultados5e4AvaSatisTotal = percursosFiltred.map(percurso =>
                            // Filter the "percursoFiltrado" based on the date range
                            percurso.ufcds.map(element =>
                                element.avaliacaoSatisfacaoFormador.length * 8
                            )
                        )
                            .flat(2) // Flatten nested arrays (double flattening because of nested maps)
                            .reduce((acc, curr) => acc + curr, 0); // Sum up the results
                        setContTotalFormadores(resultados5e4AvaSatisTotal)

                        const resultados5e4AvaSatisFormadores = percursosFiltred.map(percurso =>
                            // 
                            percurso.ufcds.map(percursoFiltrado =>
                                // Assuming 'avaliacaoSatisfacaoFormandosResults' is an array of objects
                                percursoFiltrado.avaliacaoSatisfacaoFormador.map(criteriaObj => {
                                    // Extract all the criterion values from the object
                                    const criterios = Object.values(criteriaObj);
                                    // Filter for valid values
                                    return criterios.filter(criterio =>
                                        ["5 - Muito Bom", "4 - Bom", 4, 5].includes(criterio)
                                    ).length;
                                }).reduce((acc, curr) => acc + curr, 0) // Sum the filtered counts from each criteria object
                            )
                        )
                            .flat() // Flatten the array to remove any nested arrays
                            .reduce((acc, curr) => acc + curr, 0); // Sum up the counts
                        setCont5e4Formadores(resultados5e4AvaSatisFormadores)
                    }

                    //Forms de Satisfação dos Coordenadores
                    {
                        const resultados5e4AvaSatisTotal = percursosFiltred.map(percurso =>
                            // Filter the "percursoFiltrado" based on the date range
                            percurso.ufcds.map(element =>
                                element.avaliacaoSatisfacaoCoordenador.length * 8
                            )
                        )
                            .flat(2) // Flatten nested arrays (double flattening because of nested maps)
                            .reduce((acc, curr) => acc + curr, 0); // Sum up the results
                        setContTotalCoordenadores(resultados5e4AvaSatisTotal)

                        const resultados5e4AvaSatisCoordenadores = percursosFiltred.map(percurso =>
                            // 
                            percurso.ufcds.map(percursoFiltrado =>
                                // Assuming 'avaliacaoSatisfacaoFormandosResults' is an array of objects
                                percursoFiltrado.avaliacaoSatisfacaoCoordenador.map(criteriaObj => {
                                    // Extract all the criterion values from the object
                                    const criterios = Object.values(criteriaObj);
                                    // Filter for valid values
                                    return criterios.filter(criterio =>
                                        ["5 - Muito Bom", "4 - Bom", 4, 5].includes(criterio)
                                    ).length;
                                }).reduce((acc, curr) => acc + curr, 0) // Sum the filtered counts from each criteria object
                            )
                        )
                            .flat() // Flatten the array to remove any nested arrays
                            .reduce((acc, curr) => acc + curr, 0); // Sum up the counts
                        setCont5e4Coordenadores(resultados5e4AvaSatisCoordenadores)
                    }

                    //nºParticipantes
                    {
                        const formandosValidados = result
                            .map(element => element.formandos.filter(item => {
                                const inscricaoDate = item.dateInscricao ? new Date(item.dateInscricao) : new Date(element.dateBegin);
                                return item.valid === "Validado" &&
                                    inscricaoDate >= new Date(dataInicio) &&
                                    inscricaoDate <= new Date(dataFim);
                            }))
                            .flat(); // Flatten the array since filter returns arrays
                        setFormandosValidadosFiltrados(formandosValidados);
                    }

                    // nºEncaminhados
                    {
                        const formandosEncValidados = result
                            .map(element => element.formandos.filter(item => {
                                const inscricaoDate = item.dateInscricao ? new Date(item.dateInscricao) : new Date(element.dateBegin);
                                return item.valid === "Validado" &&
                                    item.encCQ === "Validado" &&
                                    inscricaoDate >= new Date(dataInicio) &&
                                    inscricaoDate <= new Date(dataFim);
                            }))
                            .flat(); // Flatten the array since filter returns arrays
                        setFormandosEncFiltrados(formandosEncValidados);
                    }

                    //Taxa Certificação
                    {
                        let totalFormandos = 0; // Total formandos across all percursos
                        let totalFormandosAprov = 0; // Total formandos across all percursos
                        percursosFiltred.forEach(percurso => {
                            let percursoFormandos = 0;
                            let totalAlunos = 0;
                            percurso.ufcds.forEach(disciplina => {
                                if (disciplina.fechoCoordenador) {
                                    // Get data for this disciplina only once
                                    const formandosData = getFormandosCertificados(disciplina, percurso);
                                    percursoFormandos += formandosData.alunosCerti || 0;
                                    totalAlunos += formandosData.totalAlunos.length || 0;
                                }
                            });

                            // Accumulate results for this percurso into global totals
                            totalFormandosAprov += percursoFormandos;
                            totalFormandos += totalAlunos;
                        });
                        // Calculate and set the certification rate
                        const taxaCertificacao = totalFormandos > 0
                            ? Math.round((totalFormandosAprov * 100) / totalFormandos)
                            : 0;
                        setTaxaCertificacaoDates(taxaCertificacao)
                    }

                    //Volume
                    {
                        let totalVolume = 0; // Total volume across all percursos
                        let totalVolumeCerti = 0; // Total formandos across all percursos
                        percursosFiltred.forEach(percurso => {
                            let percursoVolume = 0;
                            let percursoVolumeCertificados = 0
                            percurso.ufcds.forEach(disciplina => {
                                const certificados = getFormandosCertificados(disciplina, percurso);
                                percursoVolume += certificados.volumeTotal; // Acumular volume para o percurso
                                percursoVolumeCertificados += certificados.volumeCerti; // Acumular formandos certificados
                            });
                            totalVolume =totalVolume+ percursoVolume;
                            totalVolumeCerti = totalVolumeCerti +percursoVolumeCertificados
                        });
                        setVolumeDates({ volume: totalVolume,  volumeCerti: totalVolumeCerti})
                    }

                    //nº Desistentes
                    {
                        const formandosDesis = percursosFiltred
                            .map(element => element.formandos.filter(item => item.valid === "Desistiu"))
                            .flat(); // Flatten the array since filter returns arrays

                        const totalFormandos = percursosFiltred
                            .map(element => element.formandos.filter(item => item.valid !== "Por Validar"))
                            .flat(); // Flatten the array since filter returns arrays

                        if (formandosDesis.length > 0) {
                            if (totalFormandos.length > 0) { // Avoid division by zero
                                const percentageDesistentes = (formandosDesis.length * 100) / totalFormandos.length;
                                setFormandosDesistentesDate(parseInt(percentageDesistentes));
                            }
                        }
                    }

                    // Resultados avaliação dos Formadores
                    {
                        const resultadosAvaDesempenho = [];
                        percursosFiltred.forEach(percurso => {
                            // Iterate over `ufcds` in each `percurso`
                            percurso.ufcds.forEach(element => {
                                // Ensure `evaluation` has entries
                                if (element.evaluation.length > 0) {
                                    // Push `evaluationFinal` values into the array
                                    element.evaluation.forEach(avaliacao => {
                                        resultadosAvaDesempenho.push(avaliacao.evaluationFinal);
                                    });
                                }
                            });
                        });
                        // Calculate the average if there are values in the array
                        if (resultadosAvaDesempenho.length > 0) {
                            const total = resultadosAvaDesempenho.reduce((sum, value) => sum + value, 0);
                            const average = parseInt(total / resultadosAvaDesempenho.length);
                            setDesempenhoFormadores(average)
                        }
                    }

                    // Resultados avaliação dos Coordenadores/Mediadores
                    {
                        const resultadosAvaDesempenho = [];
                        percursosFiltred.forEach(percurso => {
                            // Iterate over `ufcds` in each `percurso`
                            if (percurso.evaluation) resultadosAvaDesempenho.push(percurso.evaluation)
                        });
                        // Calculate the average if there are values in the array
                        if (resultadosAvaDesempenho.length > 0) {
                            const total = resultadosAvaDesempenho.reduce((sum, value) => sum + value, 0);
                            const average = parseInt(total / resultadosAvaDesempenho.length);
                            setDesempenhoCoordenadores(average)
                        }
                    }
                }
                setLoading(false); // Ensure loading is set to false after data processing
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                setLoading(false); // Handle errors and stop loading
            });
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );

    const route = token === "admin1" ? '/MenuAdmin/' + params.id_candidatura : "/MenuSuperAdmin"
    return (
        <>
            {data && <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"manageOperacoesAdmin"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"manageOperacoesAdmin"} />}
                </div>
                <Link to={route + "/operacoes"} className="back-button-div-noNavbar md:mt-5">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                {data && <div className="grid mt-20 gap-x-10 gap-y-5 sm:grid-cols-3">
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Nome: </div>
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color"> {data.nomeTipologia}</div> : <input className="input-field" type="text" defaultValue={data.nomeTipologia} id="nomeTipologia" />}</div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Código: </div>
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color"> {data.codigoOperacao}</div> : <input className="input-field" type="text" defaultValue={data.codigoOperacao} id="codigoOperacao" />}</div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Tipologia: </div>
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color"> {data.tipologia && data.tipologia}</div> : <select className="selector" defaultValue={data.tipologia ? data.tipologia : ""} type="text" id="tipologia" >
                            <option value="" disabled>Selecione uma tipologia</option>
                            <option value="Cursos de Educação e Formação de Adultos">Cursos de Educação e Formação de Adultos</option>
                            <option value="Formação Modular Certificada">Formação Modular Certificada</option>
                            <option value="Português Língua de Acolhimento (PLA)">Português Língua de Acolhimento (PLA)</option>
                            <option value="Formação Não Financiada">Formação Não Financiada</option>
                            <option value="Cursos de Especialização Tecnológica">Cursos de Especialização Tecnológica</option>
                            <option value="Cursos Aprendizagem">Cursos Aprendizagem</option>
                            <option value="Programa Trabalhos & Competências Verdes / Green Skills & Jobs">Programa Trabalhos & Competências Verdes / Green Skills & Jobs</option>
                            <option value="Emprego + Digital">Emprego + Digital</option>
                            <option value="Projetos Locais Promotores de Qualificações de Nível B1/B2/B3">Projetos Locais Promotores de Qualificações de Nível B1/B2/B3</option><option value="Projetos Locais Promotores de Qualificações de Nível B1/B2/B3">Projetos Locais Promotores de Qualificações de Nível B1/B2/B3</option>
                            <option value="Formação Empresarial Conjunta e Formação-ação">Formação Empresarial Conjunta e Formação-ação</option>
                            <option value="Projetos Conjuntos de Formação Autónoma">Projetos Conjuntos de Formação Autónoma</option>
                            <option value="Centros Qualifica">Centros Qualifica</option>
                        </select>}</div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Gestor de Operação: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.gestorOperacao.length > 0 ? data.gestorOperacao.map((admin, index) => (
                            <span key={index}>{admin.fullName}{index < data.gestorOperacao.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="gestorOperacao" defaultValue={admin ? admin : data.gestorOperacao.length > 0 ? data.gestorOperacao[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                            <option disabled value="">Selecione um Gestor de Operação</option>
                            {gestorOpe.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.fullName}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Gestor Financeiro: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.gestorFinanceiro.length > 0 ? data.gestorFinanceiro.map((admin, index) => (
                            <span key={index}>{admin.fullName}{index < data.gestorFinanceiro.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="gestorFinanceiro" defaultValue={admin ? admin : data.gestorFinanceiro.length > 0 ? data.gestorFinanceiro[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                            <option disabled value="">Selecione um Gestor Financeiro</option>
                            {gestorFin.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.fullName}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Diretor Executivo: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.diretorExecutivo.length > 0 ? data.diretorExecutivo.map((admin, index) => (
                            <span key={index}>{admin.fullName}{index < data.diretorExecutivo.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="diretorExecutivo" defaultValue={admin ? admin : data.diretorExecutivo.length > 0 ? data.diretorExecutivo[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                            <option disabled value="">Selecione um Diretor Executivo</option>
                            {diretExec.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.fullName}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Diretor de Departamento: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.diretorDepartamento.length > 0 ? data.diretorDepartamento.map((admin, index) => (
                            <span key={index}>{admin.fullName}{index < data.diretorDepartamento.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="diretorDepartamento" defaultValue={admin ? admin : data.diretorDepartamento.length > 0 ? data.diretorDepartamento[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                            <option disabled value="">Selecione um Diretor de Departamento</option>
                            {diretDep.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.fullName}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Entidade Formadora: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.entFormadora.length > 0 ? data.entFormadora.map((admin, index) => (
                            <span key={index}>{admin.name}{index < data.entFormadora.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="entFormadora" defaultValue={entFormadora ? entFormadora : data.entFormadora.length > 0 ? data.entFormadora[0]._id : ""} onChange={(e) => setEntFormadora(e.target.value)}>
                            <option disabled value="">Selecione uma Entidade Formadora</option>
                            {entFormadoraList && entFormadoraList.length > 0 && entFormadoraList.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.name}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Entidade Financiadora: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.entFinanciadora.length > 0 ? data.entFinanciadora.map((admin, index) => (
                            <span key={index}>{admin.name}{index < data.entFinanciadora.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="entFinanciadora" defaultValue={entFinanciadora ? entFinanciadora : data.entFinanciadora.length > 0 ? data.entFinanciadora[0]._id : ""} onChange={(e) => setEntFinanciadora(e.target.value)}>
                            <option disabled value="">Selecione um Entidade Financiadora</option>
                            {entFinanciadoraList.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.name}</option>
                            ))}
                        </select>}
                    </div>
                </div>}
                {((token === "admin2") || (token === "admin1" && (adminType === "Administrador - Gestor de Operação" || adminType === "Administrador - Diretor de Departamento" || adminType === "Gestor Financeiro"))) && <div className="flex flex-row gap-3 mt-5">
                    <button className={alterarDados === -1 ? "button-geral" : "button-cancelar"} onClick={() => alterarDados === -1 ? setAlterarDados(1) : setAlterarDados(-1)}>{alterarDados === -1 ? "Alterar Dados" : "Cancelar"}</button>
                    {alterarDados !== -1 && <button className={"button-confirmar"} onClick={() => submeterDados()}>Confirmar</button>}
                </div>}
                {percursos && <>
                    <h1 className="mt-10 mb-5 subtitle">Cursos/Percursos Associados</h1>
                    <div className="table-containermax-w-lg">
                        <table className="my-table ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[150px] border border-white">Cursos/Percursos</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                {percursos.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border border-white">{item.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="table-containermax-w-lg">
                        <h1 className="mt-10 mb-5 subtitle">Indicadores</h1>
                        {alterar === -1 ? <button onClick={() => setAlterar(1)} className="button-geral">Alterar Dados</button> :
                            <button onClick={() => submeterDadosPrv()} className="button-geral">Submeter</button>}
                        <table className="my-table ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[150px] border border-white">Objetivos Contratualizados</th>
                                    <th className="p-2 min-w-[150px] border border-white">Previsto Candidatura</th>
                                    <th className="p-2 min-w-[150px] border border-white">Em Execução</th>
                                    <th className="p-2 min-w-[150px] border border-white">Finalizado</th>
                                    <th className="p-2 min-w-[150px] border border-white">Em Falta</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td className="border border-white">Nº Participantes</td>
                                    <td className="border border-white">{alterar === 1 ? <input
                                        className="input-field"
                                        placeholder={data.participantesPrev}
                                        defaultValue={data.participantesPrev}
                                        id={"participantesPrev"}
                                        type="text"
                                    /> : <>{data.participantesPrev && data.participantesPrev}</>}</td>
                                    <td className="border border-white">{formandosValidados ? formandosValidados.length : 0}</td>
                                    <td className="border border-white">{formandosFinalizados ? formandosFinalizados.length : 0}</td>
                                    <td className="border border-white">{data.participantesPrev && data.participantesPrev - (formandosValidados ? formandosValidados.length : 0) - (formandosFinalizados ? formandosFinalizados.length : 0)}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Nº Encaminhados</td>
                                    <td className="border border-white">{alterar === 1 ? <input
                                        className="input-field"
                                        placeholder={data.encaminhadosPrev}
                                        defaultValue={data.encaminhadosPrev}
                                        id={"encaminhadosPrev"}
                                        type="text"
                                    /> : <>{data.encaminhadosPrev && data.encaminhadosPrev}</>}</td>
                                    <td className="border border-white"> {formandosEncValidados ? formandosEncValidados.length : 0}</td>
                                    <td className="border border-white">{formandosEncFinalizados ? formandosEncFinalizados.length : 0}</td>
                                    <td className="border border-white">{data.encaminhadosPrev && data.encaminhadosPrev - (formandosEncValidados ? formandosEncValidados.length : 0) - (formandosEncFinalizados ? formandosEncFinalizados.length : 0)}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa Certificação</td>
                                    <td className="border border-white">{alterar === 1 ? <><input
                                        className="input-field"
                                        placeholder={data.taxaCerti}
                                        defaultValue={data.taxaCerti}
                                        id={"taxaCerti"}
                                        type="text"
                                    />%</> : <>{data.taxaCerti && data.taxaCerti}%</>}</td>
                                    <td className="border border-white">{taxaCertificacao}%</td>
                                    <td className="border border-white">{taxaCertificacao}%</td>
                                    <td className="border border-white">{data.taxaCerti && data.taxaCerti - taxaCertificacao}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Volume Formação</td>
                                    <td className="border border-white">{alterar === 1 ? <><input
                                        className="input-field"
                                        placeholder={data.volumeFormacao}
                                        defaultValue={data.volumeFormacao}
                                        id={"volumeFormacao"}
                                        type="text"
                                    />h</>: <>{data.volumeFormacao && data.volumeFormacao}h</>}</td>
                                    <td className="border border-white">{volumeTotal.volume}h</td>
                                    <td className="border border-white">{volumeTotal.volume}h</td>
                                    <td className="border border-white">{data.volumeFormacao ? data.volumeFormacao - volumeTotal.volume : 0}h</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-containermax-w-lg mb-10">
                        <h1 className="mt-10 mb-5 subtitle">Análise</h1>
                        <div className="flex col gap-3">
                            {dateBegin && <div>
                                <h2 className="label">Data Inicio:</h2>
                                <input id="dataInicio" type="date" className="input-field" defaultValue={new Date(dateBegin).toISOString().substring(0, 10)} onChange={(e) => setDateBegin((e.target.value))} />
                            </div>}
                            <div>
                                <h2 className="label">Data Fim:</h2>
                                <input id="dataFim" type="date" className="input-field" defaultValue={dateEnd ? new Date(dateEnd).toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10)} onChange={(e) => setDateEnd(new Date(e.target.value).toISOString().substring(0, 10))} />
                            </div>
                        </div>
                        <table className="my-table ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[150px] border border-white">Objetivos Operacionais</th>
                                    <th className="p-2 min-w-[150px] border border-white"></th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td className="border border-white">Avaliação Satisfação Formandos</td>
                                    <td className="border border-white"> {contTotalFormandos > 0 ? Math.round((cont5e4Formandos / contTotalFormandos) * 100) : 0}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Avaliação Satisfação Formadores</td>
                                    <td className="border border-white"> {contTotalFormadores > 0 ? Math.round((cont5e4Formadores / contTotalFormadores) * 100) : 0}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Avaliação Satisfação Coordenadores</td>
                                    <td className="border border-white"> {contTotalCoordenadores > 0 ? Math.round((cont5e4Coordenadores / contTotalCoordenadores) * 100) : 0}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Desempenho dos formadores</td>
                                    <td className="border border-white">{desempenhoFormadores ? desempenhoFormadores + "%" : "Sem registos"}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Desempenho coordenadores e mediadores</td>
                                    <td className="border border-white">{desempenhoCoordenadores ? desempenhoCoordenadores + "%" : "Sem registos"}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa de Desistência</td>
                                    <td className="border border-white">{formandosDesistentesDate ? formandosDesistentesDate : 0}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa de Assiduidade</td>
                                    <td className="border border-white">{formandosDesistentesDate ? 100 - formandosDesistentesDate : 100}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Avaliação de Impacto</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Nº Participantes</td>
                                    <td className="border border-white">{formandosValidadosFiltrados ? formandosValidadosFiltrados.length : 0}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Nº Encaminhados</td>
                                    <td className="border border-white">{formandosEncFiltrados ? formandosEncFiltrados.length : 0}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa de Certificados</td>
                                    <td className="border border-white">{taxaCertificacaoDates ? taxaCertificacaoDates : 0}%</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Volume Total de Formação</td>
                                    <td className="border border-white">{volumeDates ? volumeDates.volume  + "h" :"Sem registos"}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Volume de Certificados de Formação</td>
                                    <td className="border border-white">{volumeDates ? volumeDates.volumeCerti + "h" :"Sem registos" }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>}
            </main >}
            <Footer />
        </>
    );
}

export default OperacaoIndividual