import React from 'react';
import Papa from 'papaparse';

class CSVGeneratorFormandos extends React.Component {
  async populateCSVFormandos() {
    const { data } = this.props;
    try {
      // Fetch and parse the CSV template
      const templateCSV = await this.fetchCSVTemplate('./moodleCSV/carregarUtilizadores.csv');
      const parsedTemplate = Papa.parse(templateCSV, { header: true, skipEmptyLines: true });

      // Generate the data to insert
      const dataToInsert = this.generateData(data);

      // Add data to the CSV template
      const populatedCSV = this.addRowsToCSV(parsedTemplate, dataToInsert);

      // Trigger CSV download
      this.downloadCSV(populatedCSV);
    } catch (error) {
      console.error('Error reading or processing CSV template:', error);
    }
  }

  generateData(data) {
    const array = [];

    data.forEach((element) => {
      const nameParts = element.fullName.split(' ');
      const firstWord = nameParts[0];
      const lastWord = nameParts[nameParts.length - 1];
      const dateNow = new Date();
      const yearNow = dateNow.getFullYear();
      const password = `Formacao_${yearNow}`;
      const courses = element.ufcds.map((ufcd) => ufcd.codeMoodle);

      array.push([
        element.nif,
        password,
        firstWord,
        lastWord,
        element.email,
        ...courses,
      ]);
    });

    return array;
  }

  addRowsToCSV(parsedTemplate, dataToInsert) {
    const headerRow = Papa.unparse([parsedTemplate.meta.fields]); // Reconstruct the header row
    const dataRows = dataToInsert.map((row) => Papa.unparse([row])).join('\n'); // Process data rows
    return `${headerRow}\n${dataRows}`; // Combine header and data rows
  }

  downloadCSV(csvData) {
    // Create a Blob with UTF-8 encoding and a BOM for proper special character handling
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async fetchCSVTemplate(templatePath) {
    try {
      const response = await fetch(templatePath);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV template: ${response.status} ${response.statusText}`);
      }
      const csvText = await response.text();

      // Ensure the CSV text includes a BOM (useful for Excel compatibility)
      return csvText.charCodeAt(0) === 0xFEFF ? csvText : '\uFEFF' + csvText;
    } catch (error) {
      throw new Error(`Error fetching CSV template: ${error.message}`);
    }
  }

  render() {
    return (
      <div>
        <button className="mt-4 button-geral w-72" onClick={() => this.populateCSVFormandos()}>
          Exportar Turma para Moodle
        </button>
      </div>
    );
  }
}

export default CSVGeneratorFormandos;
