import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom"
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Modal from 'react-modal'
import toastSuccess from "../../../components/Toast/toastSuccess";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import toastError from "../../../components/Toast/toastError";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import containsPostalCode from "../../../components/FormChecks/containsPostalCode/containsPostalCode";
import CountryDropdown from "../../../components/FormChecks/countryDropdown/countryDropdown";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import isNumber from "../../../components/isNumber/isNumber";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CapitalizarNomes from "../../../components/capitalizeNames/capitalizeNames";
import FileUploader from "../../../utils/validationFileUploader";
Modal.setAppElement('#root');
let Next = process.env.PUBLIC_URL + "/Next.svg"
//Passos do formulario
//Passos do formulario
const steps = [
    "DADOS PESSOAIS",
    "REGISTO",
    "NACIONALIDADE",
    "RESIDÊNCIA",
    "IDENTIFICAÇÃO",
    "HABILITAÇÕES ACADÉMICAS",
    "CERTIFICAÇÃO PROFISSIONAL",
    "DADOS FISCAIS",
    "UTILIZAÇÃO DADOS PESSOAIS",
    "DOCUMENTOS"
];

const Candidatura = () => {
    //Mostrar pass
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
 // eslint-disable-next-line
    const [checkStep, setCheckStep] = useState({ section1: false, section2: false, section3: false, section4: false, section5: false, section6: false, section7: false, section8: false, section9: false })
    const [showPassword, setShowPassword] = useState(false)
    const [numDoc, setNumDoc] = useState([]);
    const [outrosDocs, setOutrosDocs] = useState([])
    const [loading, setLoading] = useState(false);
    const [checkPass, setCheckPass] = useState("")
    const [section, setSection] = useState(1);
    const navigate = useNavigate();
    const ref = useRef();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    //Filtros data
    const datemin = new Date(0, 0, 1)
    const dateMax = new Date(year - 18, month, day)
    const dateCcMax = new Date(year + 10, month, day)
    const dateToday = new Date(year, month, day)

    //Dados guardados
    // Secção - 1 - Dados Informartivos
    const [fullName, setFullName] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [cellNumber, setCellNumber] = useState("")

    //Secção - 2 - Dados Registo
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    //Secção - 3  - Dados Naturalidade
    const [parishD, setParishD] = useState("")
    const [parishN, setParishN] = useState("")
    const [parishNaturality, setParishNaturality] = useState("");
    const [nationality, setNationality] = useState("");

    //Secção - 4 -  Dados Morada
    const [locality, setLocality] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [county, setCounty] = useState("");

    //Secção - 5 -  Dados Identificativos
    const [ccNumber, setCcNumber] = useState("");
    const [ccExpiration, setCcExpiration] = useState("");
    const [nif, setNif] = useState("");

    //Secção - 6 - Dados Académicos
    const [academicDegree, setAcademicDegree] = useState("");
    const [courseDesignation, setCourseDesignation] = useState("");
    const [teachingQualification, setTeachingQualification] = useState("");
    const [conclusionYear, setConclusionYear] = useState("");
    const [recruitmentGroup, setRecruitmentGroup] = useState("");

    //Secção - 7 - Dados Profissionais
    const [office, setOffice] = useState("");
    const [cap, setCap] = useState("");
    const [professionalCertification, setProfessionalCertification] =
      useState("");

    //Secção - 8 - Dados Fiscais/Bancários
    const [iva, setIva] = useState("");
    const [irs, setIrs] = useState("");
    const [iban, setIban] = useState("");

    //Secção - 9 - Autorização
    const [dataAuthorization, setDataAuthorization] = useState(false);
    const [dataAuthorization2, setDataAuthorization2] = useState(false);

    //Secção - 10 - Documentos
    const [CC, setCC] = useState("");
    const [CV, setCV] = useState("");
    const [CCP, setCCP] = useState("");
    const [CH, setCH] = useState("");
    const [IBANDOC, setIBANDOC] = useState("");

    useEffect(() => {
      return () => {
        toast.dismiss();
      };
    }, [section, CC, CV, CCP, CH, IBANDOC]);

    //Proxima secção
    function sectionNext(step) {
      setSection(step);
    }
    //Secção anterior
    function sectionBack(step) {
      setSection(step);
    }

    //Animação fade
    function fade(step) {
      setTimeout(() => {
        document.querySelector("#main-form").classList.remove("fadeInLeft");
        document.querySelector("#main-form").classList.remove("fadeInRight");
        document.querySelector("#main-form").classList.add("fadeOutLeft");
      }, 0);
      setTimeout(() => {
        document.querySelector("#main-form").classList.remove("fadeOutLeft");
        sectionNext(step);
        document.querySelector("#main-form").classList.add("fadeInRight");
      }, 500);
    }

    //Animação fade reverse
    function fadeInRight(step) {
      setTimeout(() => {
        document.querySelector("#main-form").classList.remove("fadeInRight");
        document.querySelector("#main-form").classList.remove("fadeInLeft");
        document.querySelector("#main-form").classList.add("fadeOutRight");
      }, 0);
      setTimeout(() => {
        document.querySelector("#main-form").classList.remove("fadeOutRight");
        sectionBack(step);
        document.querySelector("#main-form").classList.add("fadeInLeft");
      }, 500);
    }

    //Função para mudar a cor caso ja tenha preenchido esse step
    const MultiStepForm = ({ steps, currentStep }) => {
      const percentage = (100 * (currentStep + 1)) / steps.length;

      /* Animação do react spring */
      const styles = useSpring({
        from: {
          "--value": 0,
        },
        to: {
          "--value": percentage,
        },
      });

      return (
        <>
          <div className="items-center justify-center hidden xl:flex">
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => {
                  handleNext(index + 1);
                }}
                className="flex items-center justify-center text-center"
              >
                <p
                  className={
                    index === currentStep
                      ? "step-active"
                      : checkStep[`section${index + 1}`] == true
                      ? "step-done"
                      : "step-inactive"
                  }
                >
                  {step}
                </p>
                {index !== steps.length - 1 && (
                  <i className="m-1 text-gray-600 fas fa-caret-right"></i>
                )}
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-center sm:flex-row xl:hidden">
            {/* Componente abaixo é deste site https://daisyui.com/components/radial-progress/  */}
            {/* Componente deste bibilioteca https://www.npmjs.com/package/react-spring */}
            <animated.div
              className="border radial-progress bg-secundary-color border-text-main-color text-main-color"
              style={styles}
            >
              {currentStep + 1} de {steps.length}
            </animated.div>
            <div className="flex flex-col items-center justify-center sm:ml-10 md:ml-32">
              <div className="text-text-second-color">{steps[currentStep]}</div>
              {steps[currentStep + 1] ? (
                <div className="text-text-second-color">
                  Próximo: {steps[currentStep + 1]}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      );
    };

    //Higienização e Continuação
    const handleNext = (newStep) => {
      if (section === 1) {
        if (fullName !== "" && birthDate !== "" && cellNumber !== "") {
          birthDate.replace("-", ",");
          const datenow = new Date(birthDate);
          if (onlyString(fullName)) {
            if (datenow.getTime() > dateMax.getTime()) {
              toastError("Data incorreta.");
              checkStep.section1 = false;
            } else if (datenow.getTime() < dateMax.getTime())
              if (datenow.getTime() > datemin.getTime()) {
                if (isNumber(cellNumber)) {
                  if (
                    cellNumber.length > 13 ||
                    cellNumber.length < 9 ||
                    cellNumber < 0
                  ) {
                    toastError(
                      "Nº de telemóvel inválido  ou com caracteres inválidos."
                    );
                    checkStep.section1 = false;
                  } else {
                    checkStep.section1 = true;
                    if (!newStep) {
                      fade(section + 1);
                    } else {
                      if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                          fade(newStep);
                        } else
                          toastError(
                            "Preencha todas as informações necessárias antes de avançar."
                          );
                      } else if (section > newStep) {
                        fadeInRight(newStep);
                      }
                    }
                  }
                } else {
                  toastError(
                    "Nº de telemóvel inválido ou com caracteres inválidos."
                  );
                  checkStep.section1 = false;
                }
              } else {
                toastError("Data incorreta.");
                checkStep.section1 = false;
              }
            else {
              toastError("Data incorreta.");
              checkStep.section1 = false;
            }
          } else {
            toastError("Nome inválido.");
            checkStep.section1 = false;
          }
        } else {
          toastError("Preencha todos os dados obrigatórios.");
          checkStep.section1 = false;
        }
      } else if (section === 2) {
        if (email !== "" && password !== "") {
          fetch(`${process.env.REACT_APP_API_URL}/formadores/emails/${email}`)
            .then((res) => res.json())
            .then(
              (result) => {
                // Verificar se o email tem "@" e o "."
                let checkEmail = email.includes("@" && ".");
                if (checkEmail === true && result === true) {
                  if (password.length >= 8 && password.length <= 20) {
                    // Verificar se tem Uppercase
                    function containsUppercase(str) {
                      return Boolean(str.match(/[A-Z]/));
                    }
                    // Verificar se tem Special caracters
                    function containsSpecial(str) {
                      return Boolean(
                        str.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)
                      );
                    }
                    var upperPass = containsUppercase(password);
                    var specialPass = containsSpecial(password);
                    if (specialPass === true) {
                      if (upperPass === true) {
                        if (password === checkPass) {
                          checkStep.section2 = true;
                          if (!newStep) {
                            fade(section + 1);
                          } else {
                            if (section < newStep) {
                              if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep);
                              } else
                                toastError(
                                  "Preencha todas as informações necessárias antes de avançar."
                                );
                            } else if (section > newStep) {
                              fadeInRight(newStep);
                            }
                          }
                        } else {
                          checkStep.section2 = false;
                          if (newStep && newStep < section) {
                            fadeInRight(newStep);
                          } else {
                            toastError("As Palavra-passe não são compatíveis.");
                          }
                        }
                      } else {
                        checkStep.section2 = false;
                        if (newStep && newStep < section) {
                          fadeInRight(newStep);
                        } else {
                          toastError(
                            "A Palavra-passe necessita de uma letra maiúscula."
                          );
                        }
                      }
                    } else {
                      toastError(
                        "A Palavra-passe necessita de um carácter especial."
                      );
                      checkStep.section2 = false;
                    }
                  } else {
                    checkStep.section2 = false;
                    if (newStep && newStep < section) {
                      fadeInRight(newStep);
                    } else {
                      toastError(`Não cumpre os requisitos [8-20] digitos.`);
                    }
                  }
                } else {
                  checkStep.section2 = false;
                  if (newStep && newStep < section) {
                    fadeInRight(newStep);
                  } else {
                    toastError("Email incorreto ou Email existente.");
                  }
                }
              },
              (error) => {
                console.error("Error fetching data: ", error);
              }
            );
        } else {
          checkStep.section2 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 3) {
        setNationality(document.getElementById("nationality").value);
        if (
          parishD !== "" &&
          document.getElementById("nationality").value !== "0"
        ) {
          if (
            parishN !== "" &&
            document.getElementById("nationality").value !== "0"
          ) {
            checkStep.section3 = true;
            if (!newStep) {
              fade(section + 1);
            } else {
              if (section < newStep) {
                if (checkStep[`section${newStep - 1}`] == true) {
                  fade(newStep);
                } else
                  toastError(
                    "Preencha todas as informações necessárias antes de avançar."
                  );
              } else if (section > newStep) {
                fadeInRight(newStep);
              }
            }
          } else {
            checkStep.section3 = false;
            if (newStep && newStep < section) {
              fadeInRight(newStep);
            } else {
              toastError("Preencha todos os dados obrigatórios.");
            }
          }
        } else {
          checkStep.section3 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 4) {
        if (
          locality !== "" &&
          address !== "" &&
          postalCode !== "" &&
          county !== ""
        ) {
          if (onlyString(county)) {
            if (onlyString(locality)) {
              if (containsPostalCode(postalCode)) {
                checkStep.section4 = true;
                if (!newStep) {
                  fade(section + 1);
                } else {
                  if (section < newStep) {
                    if (checkStep[`section${newStep - 1}`] == true) {
                      fade(newStep);
                    } else
                      toastError(
                        "Preencha todas as informações necessárias antes de avançar."
                      );
                  } else if (section > newStep) {
                    fadeInRight(newStep);
                  }
                }
              } else {
                checkStep.section4 = false;
                if (newStep && newStep < section) {
                  fadeInRight(newStep);
                } else {
                  toastError("O Código Postal é inválido. \nEx:1111-000");
                }
              }
            } else {
              checkStep.section4 = false;
              if (newStep && newStep < section) {
                fadeInRight(newStep);
              } else {
                toastError("Localidade inválida.");
              }
            }
          } else {
            checkStep.section4 = false;
            if (newStep && newStep < section) {
              fadeInRight(newStep);
            } else {
              toastError("Concelho inválido");
            }
          }
        } else {
          checkStep.section4 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 5) {
        if (ccNumber !== "" && ccExpiration !== "" && nif !== "") {
          ccExpiration.replace("-", ",");
          const datenow = new Date(ccExpiration);
          if (datenow.getTime() > dateToday.getTime()) {
            if (datenow.getTime() < dateCcMax.getTime()) {
              if (check_nif_number(nif)) {
                checkStep.section5 = true;
                if (!newStep) {
                  fade(section + 1);
                } else {
                  if (section < newStep) {
                    if (checkStep[`section${newStep - 1}`] == true) {
                      fade(newStep);
                    } else
                      toastError(
                        "Preencha todas as informações necessárias antes de avançar."
                      );
                  } else if (section > newStep) {
                    fadeInRight(newStep);
                  }
                }
              } else {
                checkStep.section5 = false;
                if (newStep && newStep < section) {
                  fadeInRight(newStep);
                } else {
                  toastError("Número de nif inválido.");
                }
              }
            } else {
              checkStep.section5 = false;
              if (newStep && newStep < section) {
                fadeInRight(newStep);
              } else {
                toastError("Data do documento de identificação inválida.");
              }
            }
          } else {
            checkStep.section5 = false;
            if (newStep && newStep < section) {
              fadeInRight(newStep);
            } else {
              toastError("Data do documento de identificação caducada.");
            }
          }
        } else {
          checkStep.section5 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 6) {
        if (
          academicDegree !== "" &&
          courseDesignation !== "" &&
          conclusionYear !== "" &&
          teachingQualification !== ""
        ) {
          if (conclusionYear >= 1950 && conclusionYear <= year) {
            if (
              (teachingQualification === "2" && cap) ||
              (teachingQualification === "1" && recruitmentGroup)
            ) {
              checkStep.section6 = true;
              if (!newStep) {
                fade(section + 1);
              } else {
                if (section < newStep) {
                  if (checkStep[`section${newStep - 1}`] == true) {
                    fade(newStep);
                  } else
                    toastError(
                      "Preencha todas as informações necessárias antes de avançar."
                    );
                } else if (section > newStep) {
                  fadeInRight(newStep);
                }
              }
            } else {
              checkStep.section6 = false;
              if (newStep && newStep < section) {
                fadeInRight(newStep);
              } else {
                toastError("Preencha todos os dados obrigatórios.");
              }
            }
          } else {
            checkStep.section6 = false;
            if (newStep && newStep < section) {
              fadeInRight(newStep);
            } else {
              toastError("Ano de conclusão inválido");
            }
          }
        } else {
          checkStep.section6 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 7) {
        if (office !== "") {
          if (office) {
            checkStep.section7 = true;
            if (!newStep) {
              fade(section + 1);
            } else {
              if (section < newStep) {
                if (checkStep[`section${newStep - 1}`] == true) {
                  fade(newStep);
                } else
                  toastError(
                    "Preencha todas as informações necessárias antes de avançar."
                  );
              } else if (section > newStep) {
                fadeInRight(newStep);
              }
            }
          } else {
            checkStep.section7 = false;
            if (newStep && newStep < section) {
              fadeInRight(newStep);
            } else {
              toastError("Cargo atual inválido.");
            }
          }
        } else {
          checkStep.section7 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 8) {
        if (iva !== "" && irs !== "") {
          checkStep.section8 = true;
          if (!newStep) {
            fade(section + 1);
          } else {
            if (section < newStep) {
              if (checkStep[`section${newStep - 1}`] == true) {
                fade(newStep);
              } else
                toastError(
                  "Preencha todas as informações necessárias antes de avançar."
                );
            } else if (section > newStep) {
              fadeInRight(newStep);
            }
          }
        } else {
          checkStep.section8 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Preencha todos os dados obrigatórios.");
          }
        }
      } else if (section === 9) {
        if (dataAuthorization === true) {
          checkStep.section9 = true;
          if (!newStep) {
            fade(section + 1);
          } else {
            if (section < newStep) {
              if (checkStep[`section${newStep}`] == true) {
                fade(newStep);
              }
            } else if (section > newStep) {
              fadeInRight(newStep);
            }
          }
        } else {
          checkStep.section9 = false;
          if (newStep && newStep < section) {
            fadeInRight(newStep);
          } else {
            toastError("Têm que aceitar os termos para continuar.");
          }
        }
      }
    };
    //Retroceder
    const handlePrevious = (step) => {
      fadeInRight(step);
    };

    //transforma data em json
    function formDataToJson(formData) {
      var object = {};
      formData.forEach((value, key) => (object[key] = value));
      return JSON.stringify(object);
    }

    //Insere user
    function submeterUser(formDataInfo) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/formadores`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: formDataToJson(formDataInfo),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result._id) {
              resolve(result._id);
              toastSuccess("Candidatura submetida com sucesso.");
              navigate("/");
            } else {
              reject(result);
            }
          });
      });
    }
    const handleNameChange = (e, index) => {
      const updatedOutrosDocs = [...outrosDocs];
      if (!updatedOutrosDocs[index]) {
        updatedOutrosDocs[index] = {
          name: "",
          file: null,
        };
      }
      updatedOutrosDocs[index].name = e.target.value;
      setOutrosDocs(updatedOutrosDocs);
    };

    const handleFileChange = (e, index) => {
      const updatedOutrosDocs = [...outrosDocs];
      updatedOutrosDocs[index].file = e.target.files[0];
      setOutrosDocs(updatedOutrosDocs);
    };

    //Submete candidatura
    async function submeterCandidatura() {
      let formDataInfo = new FormData();

      // Secção - 1
      formDataInfo.append("fullName", CapitalizarNomes(fullName));
      formDataInfo.append("birthDate", birthDate);
      formDataInfo.append("cellNumber", cellNumber);

      // Secção - 2
      formDataInfo.append("email", email);
      formDataInfo.append("password", password);
      // Secção - 3
      formDataInfo.append("parishNaturality", parishNaturality);
      formDataInfo.append("nationality", nationality);
      formDataInfo.append("parish", `${parishD} - ${parishN}`);

      //Secção - 4
      formDataInfo.append("locality", locality);
      formDataInfo.append("address", address);
      formDataInfo.append("county", county);
      formDataInfo.append("postalCode", postalCode);

      // Secção - 5
      formDataInfo.append("ccNumber", ccNumber);
      formDataInfo.append("ccExpiration", ccExpiration);
      formDataInfo.append("nif", nif);

      // Secção - 6
      formDataInfo.append("academicDegree", academicDegree);
      formDataInfo.append("courseDesignation", courseDesignation);
      formDataInfo.append("conclusionYear", conclusionYear);
      formDataInfo.append("teachingQualification", teachingQualification);
      formDataInfo.append("recruitmentGroup", recruitmentGroup);

      // Secção - 7
      formDataInfo.append("office", office);
      formDataInfo.append("cap", cap);
      formDataInfo.append(
        "professionalCertification",
        professionalCertification
      );

      // Secção - 8
      formDataInfo.append("iva", iva);
      formDataInfo.append("irs", irs);
      formDataInfo.append("iban", iban);

      // Secção - 9
      formDataInfo.append("dataAuthorization", dataAuthorization);
      formDataInfo.append("dataAuthorization2", dataAuthorization2);

      const options = {
        method: "POST",
        headers: { Accept: "application/json" },
        credentials: "same-origin",
      };

      let ccForm = new FormData();
      let ccResult = "";
      ccForm.append("cc", CC);
      options.body = ccForm;
      if (CC && CC !== "" && CC !== undefined && CC !== null) {
        const ccResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/mcc`,
          options
        );
        ccResult = await ccResponse.json();
      }

      let cvForm = new FormData();
      cvForm.append("cv", CV);
      options.body = cvForm;
      const cvResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/mcv`,
        options
      );
      const cvResult = await cvResponse.json();

      let chForm = new FormData();
      chForm.append("ch", CH);
      options.body = chForm;
      const chResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/mch`,
        options
      );
      const chResult = await chResponse.json();

      let ccpForm = new FormData();
      ccpForm.append("ccp", CCP);
      options.body = ccpForm;
      const ccpResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/mccp`,
        options
      );
      const ccpResult = await ccpResponse.json();

      if (IBANDOC) {
        let ibanForm = new FormData();
        ibanForm.append("ibancomp", IBANDOC);
        options.body = ibanForm;

        const ibanResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/mibancomp`,
          options
        );
        const ibanResult = await ibanResponse.json();
        formDataInfo.append("IBANDOC", ibanResult);
      }
      let arrayObject = [];
      for (let i = 0; i < numDoc; i++) {
        const outro = outrosDocs[i] && outrosDocs[i].file;
        if (outro) {
          let outroForm = new FormData();
          outroForm.append("outro", outro);
          const outroOptions = options; // Create separate options object
          outroOptions.body = outroForm;
          const outroResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/outro`,
            outroOptions
          );
          const outroResult = await outroResponse.json();
          const object = {};
          object.name = outrosDocs[i].name;
          object.file = outroResult;
          arrayObject[i] = object;
        }
      }
      if (arrayObject.length === 1) {
        formDataInfo.append("outros", JSON.stringify(arrayObject));
      } else formDataInfo.append("outros", JSON.stringify(arrayObject));
      // Secção - 10
      formDataInfo.append("cc", ccResult);
      formDataInfo.append("cv", cvResult);
      formDataInfo.append("ch", chResult);
      formDataInfo.append("ccp", ccpResult);

      // Submeter dados do candidato
      submeterUser(formDataInfo);
    }

    const checkSubmit = async (event) => {
      let form = document.getElementById("main-form");
      event.preventDefault();
      setCC(form.cc.files[0]);
      setCV(form.cv.files[0]);
      setCH(form.ch.files[0]);
      setCCP(form.ccp.files[0]);
      setIBANDOC(form.iban_doc.files[0]);
      if (
        document.getElementById("cv").value &&
        document.getElementById("ch").value &&
        document.getElementById("ccp").value
      ) {
        setModalIsOpen(true);
      } else toastError("Insira todos os ficheiros.");
    };
    //Função de submit
    let isSubmitting = false;

    async function handleSubmit(event) {
      // If the function is already submitting, return early
      if (isSubmitting) {
        return;
      }
      try {
        event.preventDefault();
        setLoading(true);
        isSubmitting = true;
        await submeterCandidatura();
      } finally {
        // Reset the flag regardless of whether the submission was successful or not
        isSubmitting = false;
        setLoading(false);
      }
    }

    //Altera nacionalidade
    const handleNationalityChange = (selectedNationality) => {
      setNationality(selectedNationality);
    };

    if (loading)
      return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
          <div className="loading"></div>
        </div>
      );

    function showConfirm() {
      // Display a confirm dialog
      var result = window.confirm(
        "Tem certeza de que deseja retroceder? Ao fazê-lo, todas as informações inseridas no formulário serão perdidas."
      );
      if (result === true) {
        navigate("/");
      }
    }

    return (
      <>
        <div className="flex flex-col items-center min-h-screen ">
          <Header />
          <button
            onClick={() => showConfirm()}
            className="back-button-div-noNavbar"
          >
            <img
              className="back-button-img"
              alt="Back"
              src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
            />
          </button>

          <div className="flex flex-col items-center flex-grow mt-8">
            <div className="text-center title">CANDIDATURA FORMADOR/A</div>
            <MultiStepForm steps={steps} currentStep={section - 1} />
            <form
              className="container-form"
              id="main-form"
              encType="multipart/form-data"
              onSubmit={checkSubmit}
            >
              <section>
                {section === 1 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="fullName">
                        Nome Completo*
                      </label>
                      <div>
                        <input
                          className="w-full input-field2 h-[40px]"
                          type="text"
                          id="fullName"
                          name="fullName"
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                          required
                        ></input>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="birthDate">
                        Data de Nascimento*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="birthDate"
                        name="birthDate"
                        ref={ref}
                        onFocus={() => (ref.current.type = "date")}
                        onBlur={() => (ref.current.type = "date")}
                        value={birthDate}
                        onChange={(e) => {
                          setBirthDate(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="cellNumber">
                        Nº de telemóvel*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="cellNumber"
                        name="cellNumber"
                        minLength={9}
                        maxLength={13}
                        value={cellNumber}
                        onChange={(e) => {
                          setCellNumber(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                  </div>
                )}
              </section>
              <section>
                {section === 2 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="email">
                        E-mail*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="relative">
                      <label className="label-input" htmlFor="password">
                        Password*
                      </label>
                      <div className="flex items-center justify-center">
                        <div className="relative w-full ">
                          <input
                            className="w-full input-field2 h-[40px]"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            minLength={8}
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            required
                          ></input>
                          <button
                            type="button"
                            onClick={toggleShowPassword}
                            className="absolute top-2 right-2"
                          >
                            <i
                              className={
                                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      <label className="label-input" htmlFor="password">
                        Confirmação de Password*
                      </label>
                      <div className="flex items-center justify-center">
                        <div className="relative w-full ">
                          <input
                            className="w-full input-field2 h-[40px]"
                            type={showPassword ? "text" : "password"}
                            id="checkPassword"
                            name="checkPassword"
                            minLength={8}
                            value={checkPass}
                            onChange={(e) => {
                              setCheckPass(e.target.value);
                            }}
                            required
                          ></input>
                          <button
                            type="button"
                            onClick={toggleShowPassword}
                            className="absolute top-2 right-2"
                          >
                            <i
                              className={
                                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>
              <section>
                {section === 3 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input">Nacionalidade*</label>
                      <br></br>
                      <CountryDropdown
                        defaultValue={nationality}
                        onChange={handleNationalityChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="parishNaturality">
                        Naturalidade (Freguesia de Nascença)*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="parishNaturality"
                        name="parishNaturality"
                        value={parishNaturality}
                        onChange={(e) => {
                          setParishNaturality(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="parish">
                        Naturalidade (Distrito de Nascença)*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="parishD"
                        name="parishD"
                        value={parishD}
                        onChange={(e) => {
                          setParishD(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="parish">
                        Naturalidade (Concelho de Nascença)*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="parishN"
                        name="parishN"
                        value={parishN}
                        onChange={(e) => {
                          setParishN(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                  </div>
                )}
              </section>
              <section>
                {section === 4 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="county">
                        Concelho*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="county"
                        name="county"
                        value={county}
                        onChange={(e) => {
                          setCounty(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="address">
                        Morada*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="locality">
                        Distrito*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="locality"
                        name="locality"
                        value={locality}
                        onChange={(e) => {
                          setLocality(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="postalCode">
                        Código Postal*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        pattern="[0-9]{4}[\-]?[0-9]{3}"
                        value={postalCode}
                        onChange={(e) => {
                          setPostalCode(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                  </div>
                )}
              </section>
              <section>
                {section === 5 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="ccNumber">
                        Número de Documento de Identificação*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="ccNumber"
                        name="ccNumber"
                        minLength={12}
                        maxLength={14}
                        value={ccNumber}
                        onChange={(e) => {
                          setCcNumber(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="ccExpiration">
                        Data de Validade Documento de Identificação*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="ccExpiration"
                        name="ccExpiration"
                        ref={ref}
                        onFocus={() => (ref.current.type = "date")}
                        onBlur={() => (ref.current.type = "date")}
                        value={ccExpiration}
                        onChange={(e) => {
                          setCcExpiration(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="nif">
                        Número de Contribuinte*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="nif"
                        name="nif"
                        minLength={9}
                        maxLength={9}
                        value={nif}
                        onChange={(e) => {
                          setNif(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                  </div>
                )}
              </section>
              <section>
                {section === 6 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="academic">
                        Grau Académico*
                      </label>
                      <select
                        onWheel={(e) => e.target.blur()}
                        className="text-lg selector2"
                        id="academicDegree"
                        name="academicDegree"
                        value={academicDegree}
                        onChange={(e) => {
                          setAcademicDegree(e.target.value);
                        }}
                        required
                      >
                        <option value="" disabled>
                          Selecione a sua opção
                        </option>
                        <option value="Não sabe ler nem escrever">
                          Não sabe ler nem escrever
                        </option>
                        <option value="Menos de 4 anos escolaridade">
                          Menos de 4 anos escolaridade
                        </option>
                        <option value="1º ciclo (4º ano)">
                          1º ciclo (4º ano)
                        </option>
                        <option value="2º ciclo (6º ano)">
                          2º ciclo (6º ano)
                        </option>
                        <option value="3º ciclo (9º ano)">
                          3º ciclo (9º ano)
                        </option>
                        <option value="Ensino Secundário">
                          Ensino Secundário
                        </option>
                        <option value="Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)">
                          Ensino Pós-Secundário não superior (ex CET Cursos de
                          Especialização Tecnológica)
                        </option>
                        <option value="Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)">
                          Cursos superiores de curta duração (ex TeSP Cursos
                          Técnicos Superiores Profissionais)
                        </option>
                        <option value="Bacharelato">Bacharelato</option>
                        <option value="Licenciatura">Licenciatura</option>
                        <option value="Licenciatura pré-Bolonha">
                          Licenciatura pré-Bolonha
                        </option>
                        <option value="Mestrado">Mestrado</option>
                        <option value="Mestrado Pré-Bolonha">
                          Mestrado Pré-Bolonha
                        </option>
                        <option value="Doutoramento">Doutoramento</option>
                      </select>
                    </div>

                    {(academicDegree != "" ||
                      academicDegree != "Não sabe ler nem escrever" ||
                      academicDegree != "Menos de 4 anos escolaridade" ||
                      academicDegree != "1º ciclo (4º ano)" ||
                      academicDegree != "2º ciclo (6º ano)" ||
                      academicDegree != "3º ciclo (9º ano)" ||
                      academicDegree != "Ensino Secundário") && (
                      <div className="mb-3">
                        <label className="label-input" htmlFor="academic">
                          Designação do Curso*
                        </label>
                        <input
                          className="w-full input-field2 h-[40px]"
                          type="text"
                          id="courseDesignation"
                          name="courseDesignation"
                          value={courseDesignation}
                          onChange={(e) => {
                            setCourseDesignation(e.target.value);
                          }}
                          required
                        ></input>
                      </div>
                    )}

                    <div className="mb-3">
                      <label className="label-input" htmlFor="conclusionYear">
                        Ano de conclusão*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="number"
                        id="conclusionYear"
                        name="conclusionYear"
                        minLength={4}
                        maxLength={4}
                        value={conclusionYear}
                        onChange={(e) => {
                          setConclusionYear(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label
                        className="label-input"
                        htmlFor="teachingQualification"
                      >
                        Habilitação para a docência*{" "}
                      </label>
                      <select
                        onWheel={(e) => e.target.blur()}
                        className="text-lg selector2"
                        id="teachingQualification"
                        name="teachingQualification"
                        value={teachingQualification}
                        onChange={(e) => {
                          setTeachingQualification(e.target.value);
                        }}
                        required
                      >
                        <option value="" disabled>
                          Selecione a sua opção
                        </option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>
                    {teachingQualification === "1" && (
                      <div className="mb-3">
                        <label
                          className="label-input"
                          htmlFor="recruitmentGroup"
                        >
                          Grupo de recrutamento*
                        </label>
                        <input
                          className="w-full input-field2 h-[40px]"
                          type="text"
                          id="recruitmentGroup"
                          name="recruitmentGroup"
                          value={recruitmentGroup}
                          onChange={(e) => {
                            setRecruitmentGroup(e.target.value);
                          }}
                          required
                        ></input>
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="label-input" htmlFor="cap">
                        Número de CAP / CCP de Formador/a
                        {teachingQualification === "1" ? "" : "*"}
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="cap"
                        name="cap"
                        maxLength={50}
                        value={cap}
                        onChange={(e) => {
                          setCap(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                  </div>
                )}

                {section === 7 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="office">
                        Profissão (formador ou outra)*
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="office"
                        name="office"
                        value={office}
                        onChange={(e) => {
                          setOffice(e.target.value);
                        }}
                        required
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label
                        className="label-input"
                        htmlFor="professionalCertification"
                      >
                        Outra Certificação Profissional:
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="professionalCertification"
                        name="professionalCertification"
                        value={professionalCertification}
                        onChange={(e) => {
                          setProfessionalCertification(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                )}
              </section>
              <section>
                {section === 8 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="iva">
                        IVA*
                      </label>
                      <select
                        onWheel={(e) => e.target.blur()}
                        className="selector2"
                        id="iva"
                        name="iva"
                        value={iva}
                        onChange={(e) => {
                          setIva(e.target.value);
                        }}
                        required
                      >
                        <option value="" disabled>
                          Selecione a sua opção
                        </option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="irs">
                        IRS (retenção na fonte)*
                      </label>
                      <select
                        onWheel={(e) => e.target.blur()}
                        className="selector2"
                        id="irs"
                        name="irs"
                        value={irs}
                        onChange={(e) => {
                          setIrs(e.target.value);
                        }}
                        required
                      >
                        <option value="" disabled>
                          Selecione a sua opção
                        </option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="Iban">
                        Número de IBAN:
                      </label>
                      <input
                        className="w-full input-field2 h-[40px]"
                        type="text"
                        id="Iban"
                        name="Iban"
                        maxLength={25}
                        value={iban}
                        onChange={(e) => {
                          setIban(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                )}
                {section === 9 && (
                  <div className="section">
                    <div>
                      <div className="mb-4 text-text-second-color opacity-90">
                        Indique-nos por favor se aceita que os seus dados
                        pessoais, possam ser facultados às Entidades Reguladoras
                        e/ou Financiadoras das Ações de Formação.
                      </div>
                      <input
                        type="checkbox"
                        id="dado1"
                        name="dado1"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        value="yes"
                        checked={dataAuthorization === true}
                        onChange={(e) =>
                          setDataAuthorization(e.target.checked ? true : false)
                        }
                      />
                      <label
                        className="mb-4 text-text-second-color opacity-90"
                        for="dado1"
                      >
                        * 1ª Declaro que tomei conhecimento e autorizo a
                        Cooperativa Comenius e seus cooperantes possam utilizar
                        os meus dados para processamento informático na gestão
                        da formação, o que inclui fornecer os dados à DGERT e/ou
                        às entidades cooperadoras e financiadoras dos cursos em
                        que estou inscrito.
                      </label>
                      <br />
                      <br />
                      <input
                        type="checkbox"
                        id="dado2"
                        name="dado2"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        value="yes"
                        checked={dataAuthorization2 === true}
                        onChange={(e) =>
                          setDataAuthorization2(e.target.checked ? true : false)
                        }
                      />
                      <label
                        className="mb-4 text-text-second-color opacity-90"
                        for="dado2"
                      >
                        {" "}
                        2ª Declaro que autorizo a Cooperativa e seus cooperantes
                        a utilizar os meus dados para divulgação de serviços
                        próprios
                      </label>
                    </div>
                  </div>
                )}

                {section === 10 && (
                  <div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="cc">
                        Cartão de Cidadão{" "}
                      </label>
                      <FileUploader
                        inputClassName={"max-w-[17rem] input-file"}
                        inputType={"file"}
                        inputId={"cc"}
                        inputName={"cc"}
                        accept={".pdf"}
                        allowedTypes={["application/pdf"]}
                        />
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="cvDoc">
                        Curriculum Vitae e/ou Ficha Curricular*{" "}
                      </label>
                      <FileUploader
                        inputClassName={"max-w-[17rem] input-file"}
                        inputType={"file"}
                        inputId={"cv"}
                        inputName={"cv"}
                        accept={".pdf"}
                        allowedTypes={["application/pdf"]}
                        />
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="certificate_doc">
                        Certificado de Habilitações*{" "}
                      </label>
                      <FileUploader
                        inputClassName={"max-w-[17rem] input-file"}
                        inputType={"file"}
                        inputId={"ch"}
                        inputName={"ch"}
                        accept={".pdf"}
                        allowedTypes={["application/pdf"]}
                        />
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="ccp_doc">
                        CCP / CAP de Formador/a*{" "}
                      </label>
                      <FileUploader
                        inputClassName={"max-w-[17rem] input-file"}
                        inputType={"file"}
                        inputId={"ccp"}
                        inputName={"ccp"}
                        accept={".pdf"}
                        allowedTypes={["application/pdf"]}
                        />
                    </div>
                    <div className="mb-3">
                      <label className="label-input" htmlFor="iban_doc">
                        Comprovativo de IBAN{" "}
                      </label>
                      <FileUploader
                        inputClassName={"max-w-[17rem] input-file"}
                        inputType={"file"}
                        inputId={"iban_doc"}
                        inputName={"iban_doc"}
                        accept={".pdf"}
                        allowedTypes={["application/pdf"]}
                        />
                    </div>
                    <div className="mb-1">
                      <h1 className="mt-8 text-center subtitle">
                        Adicionar Ficheiros
                      </h1>
                      <div className="flex flex-col justify-center mb-2">
                        <label className="text-center text-text-main-color">
                          Quantos outros documentos deseja inserir:
                        </label>
                        <input
                          className="w-1/3 mx-auto input-field"
                          type="number"
                          value={numDoc}
                          onChange={(e) => {
                            setNumDoc(e.target.value);
                          }}
                        ></input>
                      </div>
                      <div className="flex flex-col items-center w-full pt-4 overflow-y-auto rounded-lg max-h-156 bg-gray-50">
                        {Array.from({ length: numDoc }).map((_, index) => (
                          <div key={index} className=" m-2">
                            <h4 className="mb-1 text-left uppercase text-text-second-color opacity-90">
                              Documento {index + 1}
                            </h4>
                            <div className="flex w-full text-left">
                              <label
                                className="w-2/5 text-text-main-color"
                                htmlFor={`fullName${index}`}
                              >
                                Nome do documento:
                              </label>
                              <input
                                className="w-3/5 input-field"
                                type="text"
                                id={`fullName${index}`}
                                name={`fullName${index}`}
                                value={outrosDocs[index]?.name}
                                onChange={(e) => handleNameChange(e, index)}
                              />
                            </div>
                            <div className="flex w-full mt-2 mb-3 text-left">
                              <label
                                className="w-2/5 text-text-main-color"
                                htmlFor={`outro${index}`}
                              >
                                Ficheiro:
                              </label>
                              <FileUploader
                              inputClassName={"w-3/5 input-file"}
                              inputType={"file"}
                              inputId={`outro${index}`}
                              inputName={`outro${index}`}
                              accept={".pdf"}
                              allowedTypes={["application/pdf"]}
                              onChangeSpecial={(e) => handleFileChange(e, index)}
                              />
                            </div>
                            <hr />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-center mt-3">
                      <input className="button-geral" type="submit" />
                    </div>
                  </div>
                )}
              </section>

              <Modal
                isOpen={modalIsOpen}
                className="fixed inset-0 flex items-center justify-center"
              >
                <div
                  className="absolute w-full h-full"
                  onClick={() => setModalIsOpen(false)}
                ></div>
                <div className="relative flex flex-col items-center px-10 mx-5 my-5 overflow-y-scroll shadow-2xl bg-secundary-color py-7 rounded-2xl max-h-128 sm:max-h-156">
                  <div>
                    <h1 className="title">Dados do Formulário</h1>
                  </div>
                  <div>
                    <p className="p-1 text-text-second-color">
                      <strong>Nome Completo:</strong> {fullName}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Data de nascimento:</strong> {birthDate}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Numero de telemóvel:</strong> {cellNumber}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Email:</strong> {email}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Morada:</strong> {address}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Nacionalidade:</strong> {nationality}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Naturalidade:</strong> {`${parishD} - ${parishN}`}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Concelho:</strong> {county}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Localidade:</strong> {locality}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Concelho:</strong> {county}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Código postal:</strong> {postalCode}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Número cartão de cidadão:</strong> {ccNumber}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Data de validade CC:</strong> {ccExpiration}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Número Contribuinte:</strong> {nif}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Grau Académico:</strong> {academicDegree}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Designação do Curso:</strong> {courseDesignation}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Grupo de recrutamento:</strong> {recruitmentGroup}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Número de CAP / CCP de Formador/a:</strong> {cap}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Habilitação para a docência:</strong>{" "}
                      {teachingQualification === 2 ? "Não" : "Sim"}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Outra Certificação Profissional:</strong>{" "}
                      {professionalCertification}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>IVA:</strong> {iva === "2" ? "Não" : "Sim"}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>IRS:</strong> {irs === "2" ? "Não" : "Sim"}
                    </p>
                    <p className="p-1 text-text-second-color">
                      <strong>Utilização de dados pessoais:</strong>{" "}
                      {dataAuthorization2 === 2 ? "Não" : "Sim"}
                    </p>
                  </div>
                  <div className="flex flex-row justify-center w-full mt-5">
                    <button
                      className="w-24 button-confirmar"
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </button>
                  </div>
                  <button
                    className="absolute top-1.5 right-1.5"
                    onClick={() => setModalIsOpen(false)}
                  >
                    <AiOutlineCloseCircle
                      size={24}
                      className="hover:text-text-error"
                    />
                  </button>
                </div>
              </Modal>
              <br />
              <div className="mb-4">
                <h3 className="m-1 text-main-color">Legenda</h3>
                <p className="m-1 text-xs text-text-second-color">
                  Todos os campos identificados com * são obrigatórios.
                </p>
                <p className="m-1 text-xs text-text-second-color">
                  Esta candidatura é válida para todas as Entidades Formadoras
                  que fazem parte do sistema gerido Cooperativa Comenius/Magna.
                </p>
              </div>
              {section !== 1 && (
                <button
                  onClick={() => handlePrevious(section - 1)}
                  type="button"
                  className="button-back-form"
                >
                  <img className="invert" src={Next} alt="Back" />
                </button>
              )}
              {section !== 10 && (
                <button
                  onClick={() => handleNext(null)}
                  type="button"
                  className="button-next-form"
                >
                  <img className="invert" src={Next} alt="Next" />
                </button>
              )}
            </form>
          </div>
          <div className="w-full mt-24 sm:bottom-0 sm:mt-0">
            <Footer />
          </div>
        </div>
      </>
    );
}

export default Candidatura