import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Modal from 'react-modal';
import Cookies from "js-cookie";
import { FiDownload } from "react-icons/fi";

//Candidatura individual
export default function CoordenadorIndividual(token, type) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [error, setError] = useState(null);
    const [data2, setData2] = useState(0);
    const [modal, setModal] = useState(false);
    const [estadoAdmin, setEstadoAdmin] = useState(false);
    const [verIntegracaoSistema, setVerIntegracaoSistema] = useState(false);
    const [text, setText] = useState("");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-coordinatorsCoordinators-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    document.data = data

    const params = useParams();
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < 10) {
            setNumPaginas(1)
        } else if (data.length % 10 === 0) {
            setNumPaginas(data.length / 10)
        } else {
            setNumPaginas(Math.ceil(data.length / 10))
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * 10)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * 10;
            maxIndex = (paginaAtual * 10);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    useEffect(() => {
        Cookies.set("SuperAdmin-coordinatorsCoordinators-Pag", paginaAtual);
        getCoordenadores()// eslint-disable-next-line
    }, [loading, paginaAtual, nPaginas]);

    //Obtem Coordenadores
    function getCoordenadores() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result)
                    paginacaoTabelas(result.percurso);
                    let contador = 0
                    result.percurso.forEach(cont => {
                        if (cont.fechoAdmin === false) {
                            contador++
                        }
                    });
                    setData2(contador);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );

    };
    //passa para a ultima página
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    //Altera se o coordenador está invalido para valido
    function validarCoordenador() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_coordinator}/validar/coordenador`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        }).then((result) => {
            result.json().then((resp) => {
                getCoordenadores()
                toastSuccess('Estado do Coordenador alterado com sucesso!')
            })

        })
    }
    //Altera se o coordenador está valido para invalido
    function desvalidarCoordenador() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_coordinator}/desvalidar/coordenador`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        }).then((result) => {
            result.json().then((resp) => {
                getCoordenadores()
                toastSuccess('Estado do Coordenador alterado com sucesso!')
            })

        })
    }
    //altera estado do coordenador
    function submeterEstado() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/estadoPerfil/${data._id}/coordenador`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            body: JSON.stringify({
                profile: (!data.profileValid),
                text: text,
            })
        }).then((result) => {
            result.json().then((resp) => {
                getCoordenadores()
                setEstadoAdmin(false)
                toastSuccess('Estado do Perfil alterado com sucesso!')
            })

        })
    }
    //altera estado do admin
    function alterarEstadoPerfilAdmin() {
        setEstadoAdmin(true)
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={type} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={type} />}
                </div>
                {token === "admin1" &&<Link to={'/MenuAdmin/'+ params.id_candidatura+'/coordinators'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>}
               {token === "admin2" &&  <Link to={'/MenuSuperAdmin/coordinators'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>}
                <div className="flex-grow w-full mt-8">
                    <div className="mx-10">
                        <div className="items-center sm:flex sm:ml-24">
                            <div className="mx-auto">
                                <img className="object-contain h-32 mx-auto w-52 rounded-3xl sm:m-0" alt="profile" src={data.photo === undefined || data.photo === '' ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" />
                            </div>
                            <div className='mx-auto sm:ml-16'>
                                {<h2 className="text-2xl font-bold text-text-main-color" >{data.fullName}</h2>}
                                <div className="justify-around mt-2 font-medium sm:flex text-text-second-color">
                                    <div className="px-1">
                                        <p>Percursos em atividade</p>
                                        {data2 && data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos finalizados</p>
                                        {data && data.percurso.length - data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos total</p>
                                        {data && data.percurso.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!data.tipo && data.profileValid === true ? <>
                            {estadoAdmin === false &&
                                <button className="mt-2 button-geral" onClick={() => alterarEstadoPerfilAdmin()}>Tornar Perfil Incompleto</button>
                            }</>
                            : <>
                                {estadoAdmin === false &&
                                    <button className="mt-2 button-geral" onClick={() => alterarEstadoPerfilAdmin()}>Tornar Completo</button>
                                }
                            </>}
                        {estadoAdmin === true && <div className="mt-2">
                            <p className="text-text-main-color">Caso deseja justificar a razão da alteração deixe um comentário:</p>
                            <p className="text-text-main-color">Este texto irá ser replicado no email gerado pelo sistema.</p>
                            <div>
                                <textarea className="max-w-lg input-textarea" onChange={(e) => setText(e.target.value)} />
                            </div>
                            <div>
                                <button className="inline w-24 mr-3 button-cancelar" onClick={() => setEstadoAdmin(false)}>Cancelar</button>
                                <button className="w-24 button-geral" onClick={() => submeterEstado()}>Submeter</button>
                            </div>
                        </div>}

                        <div className="my-6">
                            <hr />
                        </div>

                        <div className="mb-5 sm:ml-24">
                            <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                            {!data.tipo && <div className="my-1 mb-2 text-center sm:text-left">
                                {data.fullName && data.cellNumber && data.email && data.nif && data.address && data.locality && ((data.cc_path && data.cv_path && data.ch_path && data.ccp_path && data.tipo === false) || data.tipo === true) && data.profileValid === true ? <div className="text-text-correct">Perfil preenchido </div> : <div className="text-text-error">Dados do perfil por preencher</div>}
                            </div>}
                            <div className="grid mt-4 sm:grid-cols-2">
                                <div>
                                    <div className="text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">NºTelemóvel:</div>
                                        {data.cellNumber !== 0 ? <div className="mt-1 text-lg text-text-main-color"> {data.cellNumber}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                        {data.email ? <div className="mt-1 text-lg text-text-main-color">  {data.email}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                        {data.nif ? <div className="mt-1 text-lg text-text-main-color">  {data.nif}</div> : <div className="input-error-profile" />}
                                    </div>
                                    {data.tipo === false && <>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Número do Documento de Identificação:</div>
                                            {data.ccNumber ? <div className="mt-1 text-lg text-text-main-color">  {data.ccNumber}</div> : <div className="input-error-profile" />}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Nacionalidade:</div>
                                            {data.nationality ? <div className="mt-1 text-lg text-text-main-color">  {data.nationality}</div> : <div className="input-error-profile" />}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Naturalidade:</div>
                                            {data.parish ? <div className="mt-1 text-lg text-text-main-color">  {data.parish}</div> : <div className="input-error-profile" />}
                                        </div>

                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Iva:</div>
                                            {data.iva ? <div className="mt-1 text-lg text-text-main-color">{data.iva === 1 ? "Sim" : "Não"}</div>
                                                : <div className="input-error-profile" />}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Irs:</div>
                                            {data.irs ? <div className="mt-1 text-lg text-text-main-color">{data.irs === 1 ? "Sim" : "Não"}</div>
                                                : <div className="input-error-profile" />}
                                        </div>


                                    </>}
                                </div>
                                <div>
                                    {data.tipo === false && <>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Morada:</div>
                                            {data.address ? <div className="mt-1 text-lg text-text-main-color">  {data.address}</div> : <div className="input-error-profile" />}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Concelho:</div>
                                            {data.county ? <div className="mt-1 text-lg text-text-main-color">  {data.county}</div> : <div className="input-error-profile" />}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Código Postal:</div>
                                            {data.postalCode ? <div className="mt-1 text-lg text-text-main-color">  {data.postalCode}</div> : <div className="input-error-profile" />}
                                        </div>
                                    </>}
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                        {data.locality ? <div className="mt-1 text-lg text-text-main-color">{data.locality}</div> : <div className="input-error-profile" />}
                                    </div>

                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Tipo:</div>
                                        <div className="mt-1 text-lg text-text-main-color">{data.tipo ? "Interno" : "Externo"}</div>
                                    </div>

                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="mt-1 text-xs uppercase text-text-second-color opacity-90">Estado:</div>
                                        {data.valid === true ?
                                            <div className="mt-1 text-lg text-text-main-color">Autorizado
                                                <button className="mx-auto mt-2 sm:mx-0 button-cancelar" onClick={() => desvalidarCoordenador()}>Excluir</button>
                                            </div> :
                                            <div className="mt-1 text-lg text-text-main-color"> Excluido
                                                <button className="mx-auto mt-2 sm:mx-0 button-confirmar" onClick={() => validarCoordenador()}>Autorizar</button>
                                            </div>}
                                    </div>

                                </div>
                            </div>

                            <h3 className="mt-8 text-3xl font-bold text-center text-text-main-color">Ficheiros</h3>
                            <div className="mt-3">
                                <div className="grid w-1/2 grid-cols-1 mx-auto sm:grid-cols-2 lg:grid-cols-4">
                                    {data.tipo === false ?
                                        <> {data.cc_path ?
                                            <a href={data.cc_path} target="_blank" rel="noreferrer" download>
                                                <button className="button-files">Documento de Identificação </button>
                                            </a> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Documento de Identificação </button>
                                            </div>}
                                            {data.cv_path && data.cv_path.length > 0 ?
                                                <div>
                                                    <button className="button-files" onClick={() => setModal(true)}>Curriculum Vitae</button>
                                                </div>
                                                :
                                                <div className="tooltip" data-tip="Sem Documento">
                                                    <button className="button-files-empty">Curriculum Vitae</button>
                                                </div>
                                            }
                                            {data.ch_path ? <a href={data.ch_path} target="_blank" rel="noreferrer" download>
                                                <button className="button-files"> Certificado de Habilitações</button>
                                            </a> :
                                                <div className="tooltip" data-tip="Sem Documento">
                                                    <button className="button-files-empty"> Certificado de Habilitações</button>
                                                </div>}
                                            {data.ccp_path ? <a href={data.ccp_path} target="_blank" rel="noreferrer" download>
                                                <button className="button-files"> Certificação de Competências Pedagógicas</button>
                                            </a> :
                                                <div className="tooltip" data-tip="Sem Documento">
                                                    <button className="button-files-empty"> Certificação de Competências Pedagógicas</button>
                                                </div>}
                                        </> : ""}
                                </div>
                            </div>
                            <button className="m-1 mt-4 button-geral" onClick={() => setVerIntegracaoSistema(!verIntegracaoSistema)}>
                                Ver integração com o sistema
                            </button>
                        </div>

                        {verIntegracaoSistema === true ?
                            <div>
                                <h1 className="mt-8 title">Interação com o Sistema</h1>
                                {data && data.percurso.length > 0 ? <> <div className="table-container">
                                    <table className="my-table">
                                        <thead className="bg-main-color">
                                            <tr className="text-white h-14">
                                                <th className="p-2 min-w-[150px] border border-white">Curso</th>
                                                <th className="p-2 min-w-[150px] border border-white">Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-text-main-color">
                                            {dataToDisplay.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="border border-white">{item.name}</td>
                                                    {item.fechoAdmin === true ? <td className="border border-white">Ativo</td> : <td className="border border-white">Inativo</td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                    <div className="flex items-center justify-center mt-4 mb-8">
                                        <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                            <AiOutlineDoubleLeft />
                                        </button>
                                        <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                            <AiOutlineLeft />
                                        </button>
                                        <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                        <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                            <AiOutlineRight />
                                        </button>
                                        <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                            <AiOutlineDoubleRight />
                                        </button>
                                    </div> </> : <p className="third-title">Não existem dados</p>}
                            </div> : ""}

                        <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center">
                            <div className="absolute w-full h-full" onClick={() => setModal(false)}></div>
                            <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                                <div>
                                    <h1 className="subtitle">Currículos</h1>
                                </div>
                                <div className="modal-content">
                                    {data.cv_path && <>{data.cv_path.map((value, index) => (
                                        <div key={value}>
                                            <div className="flex items-center m-1.5">
                                                <span className="mr-2 text-text-main-color">CV - {index + 1}:</span>
                                                <a className="mr-2 button-geral" href={value} target="_blank" rel="noreferrer" download>
                                                    <FiDownload />
                                                </a>
                                            </div>
                                        </div>
                                    ))}</>}
                                </div>
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModal(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}