import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import Footer from "../Footer";
import 'react-toastify/dist/ReactToastify.css';
import toastError from "../Toast/toastError";
import toastSucess from "../Toast/toastSuccess";

import 'react-toastify/dist/ReactToastify.css';
import containsPostalCode from "../FormChecks/containsPostalCode/containsPostalCode";
import onlyString from "../FormChecks/onlyString/onlyString";
import options from "../FormChecks/areaCodigoFormacao/areaCodigoFormacao";
import { useSpring, animated } from "react-spring";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";

import excelGenerator from "../Excel/cronogramaGeral";
import { AiOutlineCloseCircle } from "react-icons/ai";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";

//Passos do formulario
//Passos do formulario
const steps = [
    "FORMATO DO CURSO/PERCURSO",
    "DADOS DO CURSO/PERCURSO",
    "DADOS DO CURSO/PERCURSO",
    "DADOS DO CURSO/PERCURSO",
    "DISCIPLINAS DO CURSO/PERCURSO",
    "DISCIPLINAS DO CURSO/PERCURSO",
    "FORMADORES",
    "PRECEDÊNCIAS",
];

function AddCurso(token, route, route2) {
    let Next = process.env.PUBLIC_URL + "/Next.svg"
    // Variaveis para UseEffect
    const [checkStep, setCheckStep] = useState({ section1: false, section2: false, section3: false, section4: false, section5: false, section6: false, section7: false, section8: false })
    const [section, setSection] = useState(1);
    const [cont, setCont] = useState(0)
    const inputRefs = useRef([]); // Create a ref array
    // Variaveis com Funcionalidades
    const params = useParams();
    const navigate = useNavigate();
    const [templateList, setTemplateList] = useState(0)
    const [coordenadorList, setCoordenadorList] = useState(0)
    const [entFormadoraList, setEntFormadoraList] = useState(0)
    const [entFinanciadoraList, setEntFinanciadoraList] = useState(0)
    const [operacoesList, setOperacoesList] = useState(0)
    const [moodleList, setMoodleList] = useState(0)
    const [formadoresList, setFormadoresList] = useState(0)
    // Loading
    const [loading, setLoading] = useState(false);

    //Dados guardados
    // Secção - 1 - Dados Informativos
    const [template, setTemplate] = useState("")
    const [assinaturas, setAssinaturas] = useState("")
    const [name, setName] = useState("")
    const [operacao, setOperacao] = useState("")
    const [fullOperacao, setFullOperacao] = useState("")
    const [morada, setMorada] = useState("")
    const [localidade, setLocalidade] = useState("")
    const [codigoPostal, setCodigoPostal] = useState("")
    const [dateBegin, setDateBegin] = useState("")
    const [dateEnd, setDatend] = useState("")
    const [type, setType] = useState("")
    const [duration, setDuration] = useState("")
    const [codeInterno, setCodeInterno] = useState("")
    const [codeSIGO, setCodeSigo] = useState("")
    const [edition, setEdition] = useState(null)
    const [modalidade, setModalidade] = useState("")
    const [nivel, setNivel] = useState("")
    const [moodle, setMoodle] = useState("")
    const [acaoN, setAcaoN] = useState("")
    const [cursoN, setCursoN] = useState("")
    const [horario, setHorario] = useState("")
    const [areaCodigoFormacao, setAreaCodigoFormacao] = useState("")

    const [isChecked, setIsChecked] = useState("0");
    const [guardar, setGuardar] = useState("");

    const [percurso, setPercurso] = useState([]);
    const [templateName, setTemplateName] = useState("");

    // Arrays LocaisufcdsSelecionadas
    const [ufcdsGlobais, setUfcdsGlobais] = useState(null);
    const [ufcdsSelecionadas, setUfcdsSelecionadas] = useState([]);
    const [ufcdsAssociadas, setUfcdsAssociadas] = useState([]);
    const [ufcdsTodas, setUfcdsTodas] = useState([]);
    const [coordenador, setCoordenador] = useState("")
    const [formador, setFormador] = useState([]);

    const [entFormadora, setEntFormadora] = useState("");
    const [entFinanciadora, setEntFinanciadora] = useState("")




    //sleep para corrigir função async
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    function dateLimit() {
        const today = new Date();
        const futureDate = new Date(today);
        futureDate.setFullYear(futureDate.getFullYear() + 10);
        return futureDate.toISOString().split('T')[0];
    }

    // Mapa das UFCDs Modelo selecionadas
    function RenderList() {
        return (
            <>
                {ufcdsSelecionadas.map((item, index) =>
                    <div className={"flex flex-col items-center justify-center mt-3 p-4 bg-blue-50 rounded-lg shadow-lg relative"} key={index}>
                        <p className="mr-2 third-title">Disciplina: {item.codeUfcd} - {item.name}</p>
                        <div className="flex flex-col mt-1">
                            <button className="absolute tooltip top-1 right-1" data-tip={"Remover Disciplina"} onClick={() => { rejeitarUfcd(item.codeUfcd); setCont(cont + 1) }}>
                                <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                            </button>
                            <div>
                                <p className="label-input">Data de Inicio*</p>
                                <input className="w-48 input-field2" min={dateBegin} max={dateLimit()} id={"dateBegin" + index} type="date" defaultValue={item.dateBegin} onBlur={(e) => { item.dateBegin = e.target.value }} />
                            </div>
                            {(type === "Online" || type === "Misto") && <div className="mt-2">
                                <p className="label-input">Código Moodle*</p>
                                <input className="w-48 input-field2" defaultValue={item.codeMoodle} id={"codeMoodle" + index} onBlur={(e) => item.codeMoodle = e.target.value} required />
                            </div>}
                        </div>
                    </div>)}
                {modalidade === "Cursos de Educação e Formação de Adultos" && <p className="mb-4 text-text-main-color">Contêm PRA</p>}
            </>
        )
    }
    //Define o numero de horas teoricas ou praticas consoante se o curso é online ou presencial
    function RenderDisciplinas() {
        if (type === "Online") {
            ufcdsTodas.forEach(element => {
                element.TTime = parseInt(element.cargaHoraria)
                element.PTime = 0
            });
        }
        else if (type === "Presencial") {
            ufcdsTodas.forEach(element => {
                element.PTime = parseInt(element.cargaHoraria)
                element.TTime = 0
            });
        }
        return (
            <>
                {ufcdsTodas.map((item, index) => <>
                    {item.name !== "PRA" &&
                        <div className="relative flex flex-col items-center justify-center p-4 mt-3 rounded-lg shadow-lg bg-blue-50" key={index}>
                            <p className="mr-2 third-title">{item.codeUfcd} - {item.name}</p>
                            <p className="mt-2 label-input">Data de Inicio*</p>
                            <div className="flex flex-col mt-1">
                                <input defaultValue={item.dateBegin} className="w-48 input-field2" min={dateBegin} max={dateLimit()} id={"dateBegin" + index} type="date" onBlur={(e) => { item.dateBegin = e.target.value }} />
                            </div>
                            {type === "Misto" ? <>
                                <p className="mt-4 label-input">Digite as Horas Teoricas*</p>
                                <select onWheel={(e) => e.target.blur()} className="w-48 mt-1 selector2-disabled" name="Ttime" id={"TTime" + index} defaultValue={item.TTime} onChange={(e) => { item.TTime = parseInt(e.target.value); item.PTime = (item.cargaHoraria - e.target.value); setCont(cont + 1) }}>
                                    <option disabled value={-1}>Selecione uma Opção</option>
                                    {Array.from({ length: parseInt(item.cargaHoraria) + 1 }, (_, i) => (
                                        <option key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>

                                <p className="mt-3 label-input">Horas Práticas(Presencial)* </p>
                                <p className="label-input ">(Atribuidas automaticamente após selecionar as Teóricas): </p>
                                <input className="w-48 mt-1 input-field2" type="number" defaultValue={item.TTime === 0 ? item.cargaHoraria : item.PTime} readOnly></input>
                            </> : <>
                                <p className="mt-4 label-input">Digite as Horas Teoricas*</p>
                                <input className="w-48 mt-1 input-field2" name="Ttime" id={"TTime" + index} value={item.TTime} readOnly />
                                <p className="mt-3 label-input">Horas Práticas(Presencial)*</p>
                                <p className="label-input ">(Atribuidas automaticamente após selecionar as Teóricas): </p>
                                <input className="w-48 mt-1 input-field2" type="number" defaultValue={item.PTime} readOnly></input>
                            </>}
                            <button className="tooltip absolute top-1.5 right-1.5" data-tip={"Remover Disciplina"} onClick={() => { rejeitarUfcd(item.codeUfcd); setCont(cont + 1) }}>
                                <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                            </button>
                        </div>
                    }
                </>)
                }
            </>
        )
    }

    //Função para drag a posição das disciplinas
    function RenderOrderDisciplinas() {
        const handleDragEnd = (result) => {
            if (!result.destination) return;
            const updatedData = [...ufcdsTodas];
            const [reorderedItem] = updatedData.splice(result.source.index, 1);
            updatedData.splice(result.destination.index, 0, reorderedItem);

            // Update positions
            updatedData.forEach((item, index) => {
                item.position = index;
            });
            setUfcdsTodas(updatedData);

        };

        return (
            <>
                <div>
                    <h1 className="third-title">Arraste para mudar a precedência da disciplina</h1>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="list">
                            {(provided) => (
                                <ul {...provided.droppableProps} ref={provided.innerRef}>
                                    {ufcdsTodas.map((item, index) => (
                                        <Draggable key={index} draggableId={item.codeUfcd} index={index}>
                                            {(provided) => (
                                                <li className="flex flex-col items-center justify-center p-4 mt-3 rounded-lg shadow-lg bg-blue-50 text-text-main-color"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {
                                                        `Nome: ${item.name}| Código: ${item.codeUfcd} | Data final prevista: ${!item.dateEnd ? addOneMonthWithoutWeekends(item.dateBegin, item.cargaHoraria) : item.dateEnd
                                                        } | Posição: ${index}`
                                                    }

                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </>);
    };

    // Mapa das Combinaçoes UFCDs Modelo + Formador
    // Mapa das Combinaçoes UFCDs Modelo + Formador
    function RenderUfcdFormador() {
        return (
            <>
                {ufcdsAssociadas.map((item, index) => {
                    // Filter out formadores that are already associated with the current item
                    const availableFormadores = formadoresList.filter(
                        (formador) => !item.formador.some(associated => associated.formadorID === formador._id)
                    );

                    return (
                        <div key={index} className="relative flex flex-col items-center justify-center p-4 mx-4 mt-3 rounded-lg shadow-lg bg-blue-50">
                            <p className="text-main-color">{item.codeUfcd} - {item.name} -</p>

                            {/* Display associated formadores */}
                            {item.formador.map((formador, formadorIndex) => (
                                <div className="flex flex-row justify-between gap-6" key={formadorIndex}>
                                    <p>{formador.formador}</p>
                                    <button
                                        data-tip={"Desassociar Formador"}
                                        onClick={() => {
                                            desassociarUfcd(item.codeUfcd, formador.formadorID);
                                            setCont((prev) => prev + 1);
                                        }}
                                    >
                                        <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                    </button>
                                </div>
                            ))}

                            {/* Date picker for dateBegin */}
                            {!item.dateBegin && (
                                <div>
                                    <input
                                        className="mr-2 input-field2"
                                        min={dateBegin}
                                        max={dateLimit()}
                                        id={"dateBegin" + index}
                                        type="date"
                                    />
                                    <button
                                        className="mr-2 button-geral"
                                        type="button"
                                        onClick={() => {
                                            item.dateBegin = document.getElementById("dateBegin" + index).value;
                                            setCont((prev) => prev + 1);
                                        }}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            )}

                            {/* Filtered datalist */}
                            <datalist id={`formadores-2-${index}`}>
                                {availableFormadores.map((formador) => (
                                    <option
                                        key={formador._id}
                                        value={formador.fullName}
                                        label={formador.fullName}
                                        data-id={formador._id} // Use data-id attribute to store _id
                                    />
                                ))}
                            </datalist>

                            {/* Input for selecting new formador */}
                            <input
                                className="selector2"
                                id={`list-formadores-2-${item.codeUfcd}`}
                                list={`formadores-2-${index}`} // Link input to unique datalist
                                onChange={(e) => guardarMaisFormador(e, index, document.getElementById(`list-formadores-2-${item.codeUfcd}`))}
                            />
                        </div>
                    );
                })}
            </>
        );
    }


    // UseEffect
    useEffect(() => {
        const mergedUfcds = [
            ...ufcdsSelecionadas,
            ...ufcdsAssociadas,
            modalidade === "Cursos de Educação e Formação de Adultos"
                ? {
                    name: "PRA",
                    codeUfcd: "00000",
                    dateBegin: dateBegin,
                    dateEnd: dateEnd ? dateEnd : "",
                    cargaHoraria: 50
                }
                : undefined
        ].filter(item => item !== undefined);
        setUfcdsTodas(mergedUfcds);
        if (ufcdsTodas.length > 0) {
            // Calculate and set the last dateEnd here
            const lastDateEnd = ufcdsTodas.reduce((latest, ufcd) => {
                if (addOneMonthWithoutWeekends(ufcd.dateBegin, ufcd.cargaHoraria) > latest) {
                    return addOneMonthWithoutWeekends(ufcd.dateBegin, ufcd.cargaHoraria);
                }
                return latest;
            }, "");
            setDatend(lastDateEnd);
        }
        // DropDowns 
        if (section === 1) {
            getTemplates()
            getOperacoes()
            getCoordenadores()
            getSelecionadas()
            getMoodle()
        }
        ufcdsGlobaisPorSelecionar()
        ufcds()
        ufcdsGlobaisSelecionadas()

    }, [section, cont, isChecked, guardar, percurso, type])
    // UseEffect
    useEffect(() => {
        getFormadores2()
    }, [])
    useEffect(() => {
        getOperacao(operacao)
    }, [operacao])

    // ------------------------------Animações------------------------------------

    //Função para mudar a cor caso ja tenha preenchido esse step
    const MultiStepForm = ({ steps, currentStep }) => {

        const percentage = (100 * (currentStep + 1)) / steps.length

        /* Animação do react spring */
        const styles = useSpring({
            from: {
                "--value": 0,
            },
            to: {
                "--value": percentage,
            },
        });

        return (
            <>
                <div className='items-center justify-center hidden xl:flex'>
                    {steps.map((step, index) => (
                        <div key={index} onClick={() => { handleNext(index + 1) }} className='flex items-center justify-center' >
                            <p className={index === currentStep ? 'step-active' : checkStep[`section${index + 1}`] == true ? 'step-done' : 'step-inactive'}>{step}</p>
                            {index !== steps.length - 1 && <i className="m-1 text-gray-600 fas fa-caret-right"></i>}
                        </div>
                    ))}
                </div>

                <div className="flex flex-col items-center justify-center sm:flex-row xl:hidden">
                    <animated.div className="border radial-progress bg-secundary-color border-text-main-color text-main-color" style={styles}>
                        {currentStep + 1} de {steps.length}
                    </animated.div>
                    <div className="flex flex-col items-center justify-center sm:ml-10 md:ml-32">
                        <div className="text-text-second-color">{steps[currentStep]}</div>
                        {steps[currentStep + 1] ? <div className="text-text-second-color">Próximo: {steps[currentStep + 1]}</div> : ""}
                    </div>
                </div>
            </>
        );
    };
    //Proxima secção
    //Proxima secção
    function sectionNext(step) {
        setSection(step);
    }
    //secção antes
    //Secção anterior
    function sectionBack(step) {
        setSection(step);
    }
    //animação
    //Animação fade
    function fade(step) {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.add('fadeOutLeft')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutLeft')
            sectionNext(step)
            document.querySelector('#main-form').classList.add('fadeInRight')
        }, 500);
    }
    //animação reverse
    //Animação fade reverse
    function fadeInRight(step) {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.add('fadeOutRight')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutRight')
            sectionBack(step)
            document.querySelector('#main-form').classList.add('fadeInLeft')
        }, 500);
    }

    //Retroceder 
    const handlePrevious = (step) => {
        fadeInRight(step);
    }

    //Avançar
    const handleNext = (newStep) => {

        dateBegin.replace("-", ',')
        const datenow = new Date(dateBegin)
        //Curso Modelo
        if (section === 1) {
            if (isChecked === "1") {
                if (template) {
                    guardarTemplate()
                    checkStep.section1 = true
                    if (!newStep) {
                        fade(section + 1)
                    } else {
                        if (section < newStep) {
                            if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep)
                            } else toastError("Preencha todas as informações necessárias antes de avançar.")
                        } else if (section > newStep) {
                            fadeInRight(newStep)
                        }
                    }
                } else if (section > newStep) {
                    fadeInRight(newStep)
                }
            }
            else {
                checkStep.section1 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
        }

        if (section === 2) {
            if (name && operacao && modalidade && nivel && edition && duration && horario) {
                if (edition > 0 && edition < 1000 && Number.isInteger(Number(edition))) {
                    checkStep.section2 = true
                    if (!newStep) {
                        fade(section + 1)
                    }
                    else {
                        if (section < newStep) {
                            if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep)
                            }
                        } else if (section > newStep) {
                            fadeInRight(newStep)
                        }
                    }
                }
                else {
                    checkStep.section2 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else toastError("A edição deve ser um número inteiro entre 0 e 1000.");
                }
            } else {
                checkStep.section2 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Preencha todos os campos obrigatórios.");
            }
        }
        if (section === 3) {
            if (!codeInterno ) {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Insira o Código Interno.");
            }
            else if (!operacao ) {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Selecione uma Operação.");
            }
            else if (!(((type === "Online" || type === "Misto") && moodle) || type === "Presencial") ) {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError((type === "Online" || type === "Misto")&&"Selecione um Moodle" );
            }
            else if (!areaCodigoFormacao ) {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Insira uma Área de Educação e Formação.");
            }
            else if (!(assinaturas === "true" || assinaturas === "false") ) {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Selecione um Formato de Impressão das Folhas de Presença.");
            }
          
            else if (!coordenador ) {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Selecione um/a Coordenador/a.");
            }
            else {
                checkStep.section3 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        }
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
        }
        if (section === 4) {
            if (!morada && !operacao && type !== "Online") {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Insira uma morada.");
            }
            else if (!codigoPostal && type !== "Online") {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Selecione um código postal.");
            }
            else if (!containsPostalCode(codigoPostal) && type !== "Online") {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("O Código Postal é inválido. \nEx:1111-000")
            }
            else if (!localidade && type !== "Online") {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Selecione a localidade.");
            }
            else if (!onlyString(localidade) && type !== "Online") {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Localidade inválida.")
            }
            else if (!dateBegin) {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Selecione uma data inicial.");
            }
            else {
                checkStep.section4 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        }
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
        }
        const allHavecodeMoodle = [...ufcdsAssociadas, ...ufcdsSelecionadas].every(
            (item) =>
                item.codeMoodle !== ""
        );
        //Formato do Curso
        if (section === 5) {
            if (ufcdsSelecionadas.length > 0 || ufcdsAssociadas.length > 0) {
                if ((allHavecodeMoodle && (type === "Misto" || type === "Online")) || type === "Presencial") {
                    checkStep.section5 = true
                    if (!newStep) {
                        fade(section + 1)
                    } else {
                        if (section < newStep) {
                            if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep)
                            }
                        } else if (section > newStep) {
                            fadeInRight(newStep)
                        }
                    }
                }
                else {
                    checkStep.section5 = false
                    toastError("Disciplinas sem Código Moodle.");
                }
            }
            else {
                checkStep.section5 = false
                toastError("Insira pelo menos uma UFCD");
            }
        }


        const allHaveValidDateBegin = [...ufcdsAssociadas, ...ufcdsSelecionadas].every(
            (item) =>
                item.dateBegin !== "" &&
                item.TTime >= 0 &&
                item.PTime >= 0
        );

        //Formadores
        if (section === 6) {
            if (allHaveValidDateBegin) {
                checkStep.section6 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        }
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
            else {
                checkStep.section6 = false
                toastError("Dados em falta");
            }
        }

        //Formadores
        if (section === 7) {
            {
                checkStep.section7 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        }
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
        }

        //Formadores
        if (section === 8) {
            {
                checkStep.section8 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        }
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
        }
    }
    // ---------------------------------fim Animações------------------------------------

    // -----------------------------------GETs-------------------------------------------
    //Obtem ufcds 
    function getSelecionadas() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`, {
            headers: {
                "authorization": localStorage.getItem(token)
            }
        })
            .then(response => response.json())
            .then(result => {
                setUfcdsGlobais(result);
            });
    }
    // Get templates
    function getUfcdsTemplate(id) {
        fetch(`${process.env.REACT_APP_API_URL}/templates/percurso/${id}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then(res => res.json())
            .then((result) => {
                result.ufcdsGlobal.forEach(element => {
                    async function verify() {
                        if (ufcdsSelecionadas.some((x) => x.codeUfcd === element.codeUfcd) === false && ufcdsAssociadas.some((x) => x.codeUfcd === element.codeUfcd) === false) {
                            await ufcdsSelecionadas.push({ name: element.name, cargaHoraria: element.cargaHoraria, codeUfcd: element.codeUfcd, dateBegin: "", TTime: 0, PTime: 0, codeMoodle: "" });
                            const retirar = ufcdsGlobais.findIndex((x) => x.codeUfcd === element.codeUfcd)
                            await ufcdsGlobais.splice(retirar, 1)
                        }
                    }
                    verify()
                });
                toastSucess("Utilização de template confirmada.")
            })

    }
    // ---------------------------------Fim GETs---------------------------------------

    //  ------------------------------DROPDOWNS---------------------------------------

    // DropDown dos Templates
    function getTemplates() {
        fetch(`${process.env.REACT_APP_API_URL}/templates/`, {
            headers: {
                "authorization": localStorage.getItem(token)
            }
        })
            .then(response => response.json())
            .then(result => {
                setTemplateList(result)
            });
    }


    // DropDown das UFCDs
    function ufcds() {
        const datalist = document.getElementById('ufcd')
        if (datalist) {
            // Display Inicial
            datalist.innerHTML = '<option value="" disabled>Selecionar UFCD</option>';
            ufcdsGlobais.forEach(ufcd => {
                const option = document.createElement('option');
                option.value = ufcd.name;
                option.setAttribute('data-id', ufcd._id); // Use data-id attribute for _id
                option.setAttribute('data-code', ufcd.codeUfcd);
                option.text = ufcd.codeUfcd + " - " + ufcd.name; // Set label attribute to categorias.name
                datalist.appendChild(option);
            });
        }
    }
    //Obter coordenadores
    function getCoordenadores() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores`, {
            headers: {
                'authorization': localStorage.getItem(token) // fixed the localStorage call
            }
        })
            .then(response => response.json())
            .then(result => {
                // Sorting the result alphabetically by fullName
                const sortedResult = result.sort((a, b) => {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                    return 0;
                });
                setCoordenadorList(sortedResult); // Update the state with the sorted list
            })
            .catch(error => {
                console.error('Error fetching coordenadores:', error); // Added error handling
            });
    }


    ////Obter Operações
    function getOperacoes() {
        fetch(`${process.env.REACT_APP_API_URL}/Operacao`, {
            headers: {
                "authorization": localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                let filtredOperations = result.filter(
                    (item) => item.entFormadora && item.entFormadora.length > 0 &&
                        item.tipologia && (item.tipologia === "Formação Não Financiada" ||
                       (item.tipologia !== "Formação Não Financiada" && item.entFinanciadora && item.entFinanciadora.length > 0))
                );

                if (token === "admin1") {
                    fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
                        headers: {
                            "authorization": localStorage.getItem(token)
                        }
                    })
                        .then((res) => res.json())
                        .then((entidadesFormadoras) => {
                            let entFormadoras = [];

                            // Collect unique entFormadora IDs
                            entidadesFormadoras.entFormadora.forEach(element => {
                                if (!entFormadoras.includes(element._id)) {
                                    entFormadoras.push(element._id);
                                }
                            });

                            // Filter filtredOperations based on entFormadora IDs
                            const filtredResults = filtredOperations.filter(operation =>
                                operation.entFormadora.some(item => entFormadoras.includes(item._id))
                            );

                            setOperacoesList(filtredResults)
                        })
                        .catch((error) => console.error("Error fetching data:", error));
                }
                else setOperacoesList(filtredOperations)

            });
    }
    //Obter Moodle
    function getMoodle() {
        fetch(`${process.env.REACT_APP_API_URL}/moodle`, {
            headers: {
                "authorization": localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setMoodleList(result)
            });
    }

    function guardarPrimeiroFormador(e, item,input) {
        const selectedOption = formadoresList.find(
            (formador) => formador.fullName === e.target.value
        );
        
        if (selectedOption) {
            guardarUFCDsFormadores(
                item.codeUfcd,
                item.name,
                selectedOption, // Pass the selected formador directly
                input, // Ensure this is the correct ref for your input
                item.dateBegin,
                item.cargaHoraria,
                item.codeMoodle
            );
            setCont(cont + 1);
        } else {
        }
    }
    
    function guardarMaisFormador(e, index, input) {
        const selectedOption = formadoresList.find(
            (formador) => formador.fullName === e.target.value
        );
        if (selectedOption) {
            guardarMaisUFCDsFormadores(
                selectedOption._id,
                selectedOption.fullName,
                index,
                input,
            );
            setCont(cont + 1);
        }
    };


    function getFormadores2() {
        fetch(`${process.env.REACT_APP_API_URL}/formadores/validados`, {
            headers: {
                "authorization": localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                const sortedResult = result.sort((a, b) => {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                    return 0;
                });
                setFormadoresList(sortedResult)

            });
    }
    //DropDown UFCDs Modelo
    function ufcdsGlobaisPorSelecionar() {
        const datalist = document.getElementById('ufcds-globais-por-selecionar')
        if (datalist) {
            // Display Inicial
            datalist.innerHTML = '<option value="" disabled selected>Selecionar UFCD</option>';
            if (ufcdsGlobais.length > 0) {
                ufcdsGlobais.forEach(ufcd => {
                    const option = document.createElement('option');
                    option.value = ufcd.codeUfcd;
                    option.label = ufcd.codeUfcd + " - " + ufcd.name;
                    option.setAttribute('data-id', ufcd._id); // Use data-id attribute for _id
                    option.setAttribute('data-code', ufcd.codeUfcd);
                    datalist.appendChild(option);
                });
            }

        }
    }

    //Dropdown Ufcds Selecionadas
    function ufcdsGlobaisSelecionadas() {
        const datalist = document.getElementById('ufcds-globais-selecionadas')
        if (datalist) {
            // Display Inicial
            datalist.innerHTML = '<option value="" disabled selected>Selecione a UFCD</option>';
            ufcdsSelecionadas.forEach(ufcd => {
                const option = document.createElement('option');
                option.value = ufcd.codeUfcd;
                option.label = ufcd.codeUfcd + " - " + ufcd.name;
                option.setAttribute('data-id', ufcd._id); // Use data-id attribute for _id
                option.setAttribute('data-code', ufcd.codeUfcd);
                datalist.appendChild(option);
            });
        }
    }
    // ----------------------------------Fim DROPDOWNS-----------------------------------  

    // ----------------------------------PATCH & SAVE-------------------------------------

    // Selecionar UFCD Modelo --> Opção sem template
    async function addUfcdGlobalButton(el, dl) {
        if (el.value.trim() !== '') {
            var opSelected = dl.querySelector(`[ value="${el.value}"]`);
            var option = document.createElement("option");
            option.value = opSelected.value;
            option.text = opSelected.getAttribute('data-id');
            option.code = opSelected.getAttribute('data-code');
            const retirar = ufcdsGlobais.findIndex((x) => x.codeUfcd === option.value)
            await ufcdsSelecionadas.push({ name: ufcdsGlobais[retirar].name, cargaHoraria: ufcdsGlobais[retirar].cargaHoraria, codeUfcd: ufcdsGlobais[retirar].codeUfcd, dateBegin: "", TTime: 0, PTime: 0, codeMoodle: "" })
            await ufcdsGlobais.splice(retirar, 1)
            setCont(cont + 1)
        }
    }

    //rejeitar selecionadas
    async function rejeitarUfcd(codeUfcd) {
        const retirar = ufcdsSelecionadas.findIndex((x) => x.codeUfcd === codeUfcd)
        await ufcdsGlobais.push(ufcdsSelecionadas[retirar]);
        await ufcdsSelecionadas.splice(retirar, 1)
    }

    // Guardar formadores associado a ufcd
    async function guardarUFCDsFormadores(codigoUfcd, nomeUfcd, selectedFormador,input, dateBeginUfcd, cargaHoraria, codeMoodle) {
    
        // Check if a formador is selected
        if (selectedFormador) {
            const retirarIndex = ufcdsSelecionadas.findIndex((x) => x.codeUfcd === codigoUfcd);
            const selectedUfcd = ufcdsSelecionadas[retirarIndex];
    
            if (selectedUfcd) {
                // Create the new UFCD association
                const newUfcdAssociation = {
                    codeUfcd: codigoUfcd,
                    name: nomeUfcd,
                    formador: [{ formador: selectedFormador.fullName, formadorID: selectedFormador._id }],
                    dateBegin: dateBeginUfcd,
                    cargaHoraria: cargaHoraria,
                    TTime: selectedUfcd.TTime,
                    PTime: selectedUfcd.PTime,
                    codeMoodle: codeMoodle
                };
    
                // Update the state
                setUfcdsAssociadas((prevAssociadas) => [...prevAssociadas, newUfcdAssociation]);
                setUfcdsSelecionadas((prevSelecionadas) => {
                    // Remove the selected UFCD from ufcdsSelecionadas
                    const updatedSelecionadas = [...prevSelecionadas];
                    updatedSelecionadas.splice(retirarIndex, 1);
                    return updatedSelecionadas;
                });
    
                // Add formador to the formador list if not already included
                if (!formador.includes(selectedFormador._id)) {
                    setFormador((prevFormador) => [...prevFormador, selectedFormador._id]);
                }
                input.value = ""
            }
        }
        // Reset the input state if applicable (optional)
        setCont(cont + 1);
    }
    
    async function guardarMaisUFCDsFormadores(id, name, index, input) {
        if (id.trim() !== '') {
            // Check if the formadorID is not already in the array
            if (!ufcdsAssociadas[index].formador.find(item => item.formadorID === id)) {
                await ufcdsAssociadas[index].formador.push({ formador: name, formadorID: id });
                setCont(cont + 1);
                if (formador.includes(id) === false) {
                    formador.push(id);
                }
                input.value =""
            }
        }
    }
    // Desassociar UFCD Modelo
    function desassociarUfcd(codeUfcd, formadorID) {
        const retirarIndex = ufcdsAssociadas.findIndex((x) => x.codeUfcd === codeUfcd);
        if (retirarIndex !== -1) {
            const retirarItem = ufcdsAssociadas[retirarIndex];
            const formadores = retirarItem.formador.filter((x) => x.formadorID !== formadorID);
            if (formadores.length > 0) {
                // If there are still formadores associated with the UFCD, update the formadores array
                ufcdsAssociadas[retirarIndex].formador = formadores;
            } else {
                // If no formadores are left associated with the UFCD, move it back to ufcdsSelecionadas
                ufcdsSelecionadas.push(retirarItem);
                ufcdsAssociadas.splice(retirarIndex, 1);
            }
            setCont(cont + 1);
        } else {
        }
    }
    //Guardar modelo e definir as informações
    function guardarTemplate() {
        fetch(`${process.env.REACT_APP_API_URL}/templates/${template}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then(res => res.json())
            .then((result) => {
                setName(result.name)
                setDuration(result.duracao)
                setNivel(result.nivel)
                setAreaCodigoFormacao(result.area)
            })
    }

    // ----------------------------------fim PATCH & SAVE-------------------------------------

    // ----------------------------------CRIAR & ASSOCIAR-------------------------------------
    // Post dos dados informativos
    function createPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem(token)
                },
                body: JSON.stringify({
                    name: name,
                    type: type,
                    address: morada,
                    locality: localidade,
                    assinaturas: assinaturas,
                    postalCode: codigoPostal,
                    dateBegin: dateBegin,
                    dateEnd: dateEnd,
                    codeInterno: codeInterno,
                    codeSIGO: codeSIGO,
                    entFormadora: entFormadora,
                    entFinanciadora: entFinanciadora&&entFinanciadora,
                    coordenadorId: coordenador,
                    operacao: operacao,
                    moodle: moodle,
                    modalidade: modalidade,
                    edicoes: edition,
                    nivel: nivel,
                    horario: horario,
                    acaoN: acaoN,
                    cursoN: cursoN,
                    areaCodigoFormacao: areaCodigoFormacao,
                    duration: duration,
                })
            })
            .then(res => res.json())
            .then(async (result) => {
                try {
                    const id = result._id;
                    if (id) {
                        // Execute other asynchronous functions concurrently using Promise.all
                        // Await each asynchronous function separately 
                        await adicionarPra(id);
                        await criarUfcds(id);
                        await associarFormadores(id);
                    }
                    // Now call getPercurso()

                    // Wait for all promises in Promise.all to complete before calling getPercurso
                } catch (error) {
                    console.error(error);
                    // Handle errors here
                }
            })
    }
    //Criar Template.
    async function createTemplate() {
        let arrayUfcds = []

        await ufcdsAssociadas.forEach(element => {
            fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal/percurso/${element.codeUfcd}`, {
                headers: {
                    "authorization": localStorage.getItem(token)
                }
            })
                .then(response => response.json())
                .then(result => {
                    arrayUfcds.push(result)

                })
        });
        await ufcdsSelecionadas.forEach(element => {
            fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal/percurso/${element.codeUfcd}`, {
                headers: {
                    "authorization": localStorage.getItem(token)
                }
            })
                .then(response => response.json())
                .then(result => {
                    arrayUfcds.push(result)

                })
        });

        await sleep(1000)
        await fetch(`${process.env.REACT_APP_API_URL}/templates/percurso`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    name: templateName,
                    duracao: duration,
                    nivel: nivel,
                    area: areaCodigoFormacao,
                    ufcdsGlobal: arrayUfcds,
                })
            })
            .then(res => res.json())
            .then((result) => {
                toastSucess("Template criado com sucesso.")
                setGuardar("")
                document.getElementById("guardar").value = ""
            })
    }

    //Associar formadores ao percurso
    function associarFormadores(id) {
        formador.forEach(element => {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/formadores`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "authorization": localStorage.getItem(token)
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        percursoId: id,
                        formadores: element,
                    })
                })
                .then(res => res.json())
                .then((result) => {

                })
        })
    }
    //Adicionar hierarquia e percursoID
    async function associarPosicao(id) {
        const promises = ufcdsTodas.map(async (ufcd, i) => {
            await fetch(`${process.env.REACT_APP_API_URL}/ufcds/Hierarquias/${ufcd.codeUfcd}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    hierarquia: i,
                    percursoId: id,
                })
            });
        });

        // Wait for all promises to complete
        await Promise.all(promises);

        await getPercurso(id);
    }
    //Adicionar Pra
    function adicionarPra(id) {
        if (modalidade === "Cursos de Educação e Formação de Adultos") {
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/Pra`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "authorization": localStorage.getItem(token)
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        coordenadorId: coordenador,
                        idPercurso: id,
                        dateBegin: dateBegin,
                        dateEnd: dateEnd
                    })
                })
                .then(res => res.json())
                .then((result) => {

                })
        }
    }
    //Mês sem fim de semana
    function addOneMonthWithoutWeekends(date, duration) {
        const diff = duration / 25;
        const newDate = new Date(date);
        const daysToAdd = diff * 4 * 7;
        newDate.setDate(newDate.getDate() + daysToAdd);

        // Adjust to skip weekends
        newDate.setDate(newDate.getDate() + (newDate.getDay() === 0 ? 1 : 0)); // Move to Monday if Sunday
        if (newDate.getDay() === 6) {
            newDate.setDate(newDate.getDate() + 2); // Move to Monday if Saturday
        }

        // Format the date as YYYY-MM-DD
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }
    // Criar UFCDS
    async function criarUfcds(id) {
        const promises = ufcdsTodas.map(async (ufcd) => {
            const dateEnd = addOneMonthWithoutWeekends(ufcd.dateBegin, ufcd.cargaHoraria);
            if (ufcd.formador && ufcd.formador.length > 0) {
                const formadoresArray = ufcd.formador.map(formador => ({ formadorID: formador.formadorID }));
                await fetch(`${process.env.REACT_APP_API_URL}/ufcds/percurso`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "authorization": localStorage.getItem(token)
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        name: ufcd.name,
                        codeUfcd: ufcd.codeUfcd,
                        formato: ufcd.PTime === 0 ? "Online" : ufcd.TTime === 0 ? "Presencial" : "Misto",
                        cargaHoraria: ufcd.cargaHoraria,
                        formador: formadoresArray,
                        dateBegin: ufcd.dateBegin,
                        dateEnd: dateEnd,
                        PTime: ufcd.PTime,
                        TTime: ufcd.TTime,
                        codeMoodle: ufcd.codeMoodle,
                        percursoId: id,
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        // Handle the response if needed
                    });
            } else if ((!ufcd.formador || ufcd.formador.length === 0) && ufcd.name !== "PRA") {
                await fetch(`${process.env.REACT_APP_API_URL}/ufcds/semFormador/percurso`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "authorization": localStorage.getItem(token)
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        name: ufcd.name,
                        codeUfcd: ufcd.codeUfcd,
                        cargaHoraria: ufcd.cargaHoraria,
                        formato: ufcd.PTime === 0 ? "Online" : ufcd.TTime === 0 ? "Presencial" : "Misto",
                        dateBegin: ufcd.dateBegin,
                        dateEnd: dateEnd,
                        PTime: ufcd.PTime,
                        TTime: ufcd.TTime,
                        codeMoodle: ufcd.codeMoodle,
                        percursoId: id,
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        // Handle the response if needed
                    });
            }
        });
        // Wait for all promises to complete
        await Promise.all(promises);
        await associarPosicao(id);
    }

    //Obter percurso individual pelo id
    async function getPercurso(id) {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then(res => res.json())
            .then(async (result) => {
                await excelGenerator(result)
                await toastSucess("Curso criado com sucesso.")
                await navigate(route)
            })

    }
    //Obter percurso individual pelo id
    function getOperacao(id) {
        if (id) {
            fetch(`${process.env.REACT_APP_API_URL}/operacao/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            })
                .then(res => res.json())
                .then((result) => {
                    setFullOperacao(result)
                    if(result.tipologia !== "Formação Não Financiada"){
                        setEntFinanciadora(result.entFinanciadora[0]._id)
                    }
                    setEntFormadora(result.entFormadora[0]._id)

                })
        }
    }
    // --------------------------------fim CRIAR & ASSOCIAR-------------------------------------


    // -------------------------------------SUBMETER--------------------------------------------

    // Submeter Percurso
    async function submeterPercurso(e) {
        e.preventDefault()
        setLoading(true)
        await createPercurso()
    }
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    // --------------------------------------fim SUBMETER---------------------------------------

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"criarCursos"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"criarCursos"} />}
                </div>
                <Link to={route2} className="mt-4 back-button-div-noNavbar">
                    <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                </Link>
                <div className="flex-grow mt-20 sm:mt-8 sm:mx-0">
                    <div className="title">FICHA DE CRIAÇÃO DE CURSO/PERCURSO</div>
                    <MultiStepForm steps={steps} currentStep={section - 1} />
                    <form className="container-form md:w-[650px] shadow-2xl pt-3 px-10 pb-14 mb-12 rounded-lg mx-auto mt-8" id="main-form" encType="multipart/form-data" >
                        <section>
                            {section === 1 && (
                                <div>
                                    <p className="subtitle">FORMATO DO CURSO/PERCURSO</p>
                                    <div>
                                        <label className="label-input" htmlFor="sel">Deseja utilizar curso Modelo</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" name="sel" id="sel" value={isChecked} onChange={(e) => setIsChecked(e.target.value)}>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </select>
                                    </div>
                                    {isChecked === "1" ?
                                        <div>
                                            <div className="mt-2">
                                                <label className="label-input" htmlFor="seletor">Curso Modelo:</label>
                                                <select onWheel={(e) => e.target.blur()} className="selector2" name="seletor" id="seletor" defaultValue={template} onChange={(e) => { setTemplate(e.target.value); getUfcdsTemplate(document.getElementById('seletor').value) }}>
                                                    <option value={""} disabled>Selecione um template</option>
                                                    {templateList.map((item) => (
                                                        <option key={item._id} value={item._id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        : <div>
                                        </div>
                                    }
                                </div>
                            )}
                        </section>
                        <section>
                            {section === 2 && (<div>
                                <p className="subtitle">DADOS DO CURSO/PERCURSO</p>
                                <div className="flex flex-col items-center">
                                    <div className="w-3/5 ">
                                        {isChecked === "0" &&
                                            <div className="flex flex-col mt-2">
                                                <label className="label-input" htmlFor="name">Nome*:</label>
                                                <input className="input-field2" type="text" id="Name" name="Name" defaultValue={name} onChange={(e) => { setName(e.target.value) }} required></input>
                                            </div>}
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="operacao" >Operação*:</label>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" name="operacao" id="operacao" defaultValue={operacao} onChange={(e) => { setOperacao(e.target.value) }} required>
                                                <option value="" disabled>Selecione uma Operação</option>
                                                {operacoesList.map((item) => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.codigoOperacao} - {item.nomeTipologia}, {item.entFinanciadora&& item.entFinanciadora.length>0 ?item.entFinanciadora[0].name: "Não Financiada"}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="Edition">Edição*:</label>
                                            <input className="input-field2" type="number" id="Edition" name="Edition" defaultValue={edition} onChange={(e) => { setEdition(e.target.value) }} required></input>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="Modalidade">Modalidade*:</label>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="Modalidade" name="Modalidade" defaultValue={modalidade} onChange={(e) => { setModalidade(e.target.value) }} required>
                                                <option value={""} disabled  > Selecionar a Modalidade</option>
                                                <option value="AEF à medida">AEF à medida</option>
                                                <option value="Cursos de Aprendizagem">Cursos de Aprendizagem</option>
                                                <option value="Cursos de Educação e Formação para Jovens">Cursos de Educação e Formação para Jovens</option>
                                                <option value="Cursos de Educação e Formação de Adultos">Cursos de Educação e Formação de Adultos</option>
                                                <option value="Curso de Especialização Tecnológica">Curso de Especialização Tecnológica</option>
                                                <option value="Cursos Profissionais">Cursos Profissionais</option>
                                                <option value="Formação Empresarial Conjunta e Formação Ação">Formação Empresarial Conjunta e Formação Ação</option>
                                                <option value="Formação Modular Certificada">Formação Modular Certificada</option>
                                                <option value="Formação Complementar">Formação Complementar</option>
                                                <option value="Português Língua de Acolhimento">Português Língua de Acolhimento</option>
                                                <option value="Outra Formação Profissional">Outra Formação Profissional</option>
                                            </select>
                                        </div>
                                        {isChecked === "0" && <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="Nivel">Nível*:</label>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="Nivel" name="Nivel" defaultValue={nivel} onChange={(e) => { setNivel(e.target.value) }} required>
                                                <option value={""} disabled  > Selecionar o nível</option>
                                                <option value="2">2</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="N.A.">N.A.</option>

                                            </select>
                                        </div>}
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="horario">Horário*:</label>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" id="horario" name="horario" defaultValue={horario} onChange={(e) => { setHorario(e.target.value) }}>
                                                <option value={""} disabled > Selecionar Tipo de Horário</option>
                                                <option value={"Laboral"}>Laboral</option>
                                                <option value={"Pós-laboral"}> Pós-laboral</option>
                                                <option value={"Misto"}>Misto</option>
                                            </select>
                                        </div>
                                        {isChecked === "0" && <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="Duration">Duração*:</label>
                                            <input className="input-field2" type="number" id="Duration" name="Duration" defaultValue={duration} onChange={(e) => { setDuration(e.target.value) }} required></input>
                                        </div>}

                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="type">Tipo de curso*: </label>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" id="type" name="type" defaultValue={type} onChange={(e) => { setType(e.target.value) }}>
                                                <option value={""} disabled > Selecionar Tipo de Curso</option>
                                                <option value={"Online"}> Online</option>
                                                <option value={"Misto"}> Misto</option>
                                                <option value={"Presencial"}> Presencial</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>)}

                        </section>
                        <section>
                            {section === 3 && (
                                <div>
                                    <p className="subtitle">DADOS DO CURSO/PERCURSO</p>
                                    <div>
                                        {isChecked === "0" && <div>
                                            <label className="label-input" htmlFor="areaCodigoFormacao">Área de Educação e Formação*:</label>
                                            <input className="selector2" type="text" list="options" id="areaCodigoFormacao" name="areaCodigoFormacao" placeholder="Selecione uma Área de Formação" defaultValue={areaCodigoFormacao} onChange={(e) => { setAreaCodigoFormacao(e.target.value) }} />
                                            <datalist id="options">
                                                {options.map((option, index) => (
                                                    <option key={index} value={option} />
                                                ))}
                                            </datalist>
                                        </div>}
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="codeInterno">Código Interno*:</label>
                                            <input className="input-field2" type="text" id="codeInterno" name="codeInterno" defaultValue={codeInterno} onChange={(e) => { setCodeInterno(e.target.value) }} required></input>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="codeSIGO">Código SIGO:</label>
                                            <input className="input-field2" type="text" id="codeSIGO" name="codeSIGO" defaultValue={codeSIGO} onChange={(e) => { setCodeSigo(e.target.value) }} required></input>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="nCursoBalcao">Número Curso Balcão:</label>
                                            <input className="input-field2" type="text" id="nCursoBalcao" name="nCursoBalcao" defaultValue={cursoN} onChange={(e) => { setCursoN(e.target.value) }} required></input>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="acaoCursoBalcao">Ação Curso Balcão:</label>
                                            <input className="input-field2" type="text" id="acaoCursoBalcao" name="acaoCursoBalcao" defaultValue={acaoN} onChange={(e) => { setAcaoN(e.target.value) }} required></input>
                                        </div>
                                        {(type === "Online" || type === "Misto") && <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="operacao" >Moodle*:</label>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" name="operacao" id="operacao" defaultValue={moodle} onChange={(e) => { setMoodle(e.target.value) }} required>
                                                <option value="" disabled>Selecione um Moodle</option>
                                                {moodleList.map((item) => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>}
                                        {/*  <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="entFormadora">Entidade Formadora*:</label>
                                            {entFormadoraList.length > 0 && <select onWheel={(e) => e.target.blur()} className="selector2" id="entFormadora" name="entFormadora" defaultValue={entFormadora} onChange={(e) => { setEntFormadora(e.target.value) }} >
                                                <option value="" disabled>Selecione uma opção</option>
                                                {entFormadoraList.map((value) => (
                                                    <option value={value._id} key={value._id} selected>
                                                        {value.name}
                                                    </option>
                                                ))}
                                            </select>}
                                        </div> */}
                                    </div>
                                    {/* {(operacao !== "64fef8adbe64f6dd1943220b" && operacao) && <div>
                                        <label className="label-input" htmlFor="entFinanciadora">Entidade Financiadora*:</label>
                                        {entFinanciadoraList.length > 0 && <select onWheel={(e) => e.target.blur()} className="selector2" id="entFinanciadora" name="entFinanciadora" defaultValue={entFinanciadora} onChange={(e) => { setEntFinanciadora(e.target.value) }}>
                                            <option value="" disabled>Selecione uma opção</option>
                                            {entFinanciadoraList.map((value) => (
                                                <option value={value._id} key={value._id} >
                                                    {value.name}
                                                </option>
                                            ))}
                                        </select>}
                                    </div>} */}
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="assinaturas">Como deseja imprimir as folhas de Presença*:</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" name="assinaturas" id="assinaturas" defaultValue={assinaturas} onChange={(e) => setAssinaturas(e.target.value)} >
                                            <option value="" disabled>Selecione uma opção</option>
                                            <option value={true} >1 assinatura por hora</option>
                                            <option value={false} >1 assinatura por evento</option>
                                        </select>
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="coordenador">Coordenador*: </label>
                                        {coordenadorList && coordenadorList.length > 0 && <select onWheel={(e) => e.target.blur()} className="selector2" name="coordenador" id="coordenador" value={coordenador} onChange={(e) => setCoordenador((e.target.value))}>
                                            <option value="" disabled>Selecione uma opção</option>
                                            {coordenadorList.map((value) => (
                                                <option value={value._id} key={value._id} selected>
                                                    {value.fullName}
                                                </option>
                                            ))}</select>}
                                    </div>
                                </div>
                            )}
                        </section>
                        <section>
                            {section === 4 && (<div>
                                <p className="subtitle">DADOS DO CURSO/PERCURSO</p>
                                {type !== "Online" && <>
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="morada">Morada:</label>
                                        <input className="input-field2" type="text" id="morada" name="morada" value={morada} onChange={(e) => { setMorada(e.target.value) }}></input>
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="postalCode">Código Postal*:</label>
                                        <input className="input-field2" type="text" id="postalCode" name="postalCode" value={codigoPostal} onChange={(e) => { setCodigoPostal(e.target.value) }} ></input>
                                    </div>
                                </>}
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="local">Localidade*:</label>
                                    <input className="input-field2" type="text" id="local" name="local" value={localidade} onChange={(e) => { setLocalidade(e.target.value) }} ></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="dateBegin">Data de Início*: </label>
                                    <input className="input-field2" type="date" name="dateBegin" id="dateBegin" value={dateBegin} max={dateLimit()} onChange={(e) => setDateBegin(e.target.value)}></input>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 5 && (<div>
                                <p className="subtitle">DISCIPLINAS DO CURSO/PERCURSO</p>
                                {(ufcdsSelecionadas.length > 0 || ufcdsAssociadas.length > 0) && <label className="third-title">Disciplinas adicionadas:</label>}
                                {isChecked === "1" ? <div className="flex flex-col max-w-sm mx-auto">
                                    <div>
                                        <RenderList />
                                        <RenderUfcdFormador />
                                    </div>
                                    <div className="flex flex-col mt-5">
                                        <label className="label-input" htmlFor="ufcds-globais-por-selecionar">Adicionar Disciplina:
                                            <input className="input-field2" onChange={(e) => {
                                                const input = e.target;
                                                const datalist = document.getElementById('ufcds-globais-por-selecionar');
                                                const optionExists = Array.from(datalist.options).some((option) => option.value === input.value);

                                                if (optionExists) {
                                                    addUfcdGlobalButton(input, datalist);
                                                    input.value = "";
                                                }
                                            }} list="ufcds-globais-por-selecionar" />                                        </label>
                                        <datalist id="ufcds-globais-por-selecionar">
                                        </datalist>
                                    </div>
                                </div>
                                    : <div className="flex flex-col">
                                        <RenderList />
                                        <RenderUfcdFormador />
                                        <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="ufcds-globais-por-selecionar">Disciplinas:
                                                <input className="selector2" list="ufcds-globais-por-selecionar" onChange={(e) => {
                                                    const input = e.target;
                                                    const datalist = document.getElementById('ufcds-globais-por-selecionar');
                                                    const optionExists = Array.from(datalist.options).some((option) => option.value === input.value);

                                                    if (optionExists) {
                                                        addUfcdGlobalButton(input, datalist);
                                                        input.value = "";
                                                    }
                                                }} />
                                            </label>
                                            <datalist id="ufcds-globais-por-selecionar">
                                            </datalist>
                                        </div>
                                        <label className="mt-2 label-input" for="guardar">Deseja guardar como curso modelo?</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" name="guardar" id="guardar" value={guardar} onChange={(e) => setGuardar(e.target.value)}>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </select>
                                        {guardar === "1" ? <div className="flex flex-col mt-2">
                                            <label className="label-input" htmlFor="templateName">Nome do curso modelo</label>
                                            <input className="input-field2" type="text" id="templateName" name="templateName" value={templateName} onChange={(e) => { setTemplateName(e.target.value) }} required></input>
                                            <div>
                                                <button type="button" className="w-1/3 mt-4 button-geral" onClick={(e) => { createTemplate(); document.getElementById('templateName').value = "" }}>Adicionar</button>
                                            </div>
                                        </div> : ""}
                                    </div>
                                }
                            </div>
                            )}
                        </section>
                        <section>
                            {section === 6 && (<div>
                                <p className="subtitle">DISCIPLINAS DO CURSO/PERCURSO</p>
                                {ufcdsAssociadas.length > 0 && <label className="third-title">Combinações</label>}
                                <RenderDisciplinas />
                            </div>)}
                        </section>
                        <section>
                            {section === 7 && (<div >
                                <p className="subtitle">FORMADORES</p>
                                {ufcdsAssociadas.length > 0 && <label className="third-title">Combinações</label>}
                                <div>
                                    <div>
                                        {ufcdsSelecionadas.map((item, index) => (
                                            <div key={index} className="flex flex-col items-center justify-center p-4 mx-4 mt-3 rounded-lg shadow-lg bg-blue-50">
                                                <input
                                                    type="hidden"
                                                    id={`ufcds-global-selecionadas${index}`}
                                                    list="ufcds-globais-selecionadas"
                                                    value={item.codeUfcd}
                                                />
                                                <p className="text-text-main-color">{item.codeUfcd} - {item.name}</p>

                                                <datalist id={`formadores-${item.codeUfcd}`}> {/* Use unique ID for each datalist */}
                                                    {formadoresList.map(formador => (
                                                        <option
                                                            key={formador._id}
                                                            value={formador.fullName}
                                                            label={formador.fullName}
                                                            data-id={formador._id} // Use data-id attribute to store _id
                                                        />
                                                    ))}
                                                </datalist>

                                                <input
                                                    className="selector2"
                                                    id={`list-formadores-${item.codeUfcd}`} // Ensure unique input ID
                                                    list={`formadores-${item.codeUfcd}`} // Ensure it matches the unique datalist ID
                                                    onChange={(e) => guardarPrimeiroFormador(e, item, document.getElementById(`list-formadores-${item.codeUfcd}`))} // Pass the correct item
                                                />
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <RenderUfcdFormador />
                            </div>)}
                        </section>
                        <section>
                            {section === 8 && (<div>
                                <p className="subtitle">PRECEDÊNCIAS</p>
                                {ufcdsAssociadas.length > 0 && <label className="third-title">Combinações</label>}
                                <RenderOrderDisciplinas />
                            </div>)}
                        </section>
                        {section === 8 ? <button type="submit" onClick={submeterPercurso} className="mt-4 button-geral">Submeter</button> : ""}
                        {section !== 1 && (<button onClick={() => handlePrevious(section - 1)} type="button" className="button-back-form"><img className="invert" src={Next} alt="Back" /></button>)}
                        {section !== 8 && (<button onClick={() => handleNext(null)} type="button" className="button-next-form"><img className="invert" src={Next} alt="Next" /></button>)}
                    </form>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    )
}
export default AddCurso