import React from "react";
import { AreaIndividual } from "./styled";
import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import FileUploader from "../../../../utils/validationFileUploader";
export default function MenuCoordenadorIndividual() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alterar, setAlterar] = useState(false);
    document.data = data
    const [nPaginas, setNumPaginas] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coordenadores-formandoInd-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const params = useParams();

    useEffect(() => {
        Cookies.set(`Coordenadores-formandoInd-itm1`, itemsPag)
        getCandidaturas()
    }, [loading, paginaAtual, nPaginas, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //Função para mostrar todas as candidaturas
    //Obtem candidaturas
    function getCandidaturas() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    fetch(`${process.env.REACT_APP_API_URL}/formandos/historico/${result.nif}`, {
                        headers: {
                            'authorization': localStorage.getItem("coord")
                        }
                    })
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                setData2(result);
                                paginacaoTabelas(result[0].formandos);
                                setLoading(false);
                            },
                            (error) => {
                                console.error("Error fetching data: ", error);
                                setError(error);
                            }
                        );
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }

    //Submete ficheiros
    async function submeterFicheiros(e) {
        e.preventDefault()
        let form = document.getElementById("form")

        let formDataInfo = new FormData()
        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }

        if (document.getElementById("cc").value) {
            const cc = form.cc.files[0]
            let ccForm = new FormData()
            ccForm.append('cc', cc)
            options.body = ccForm
            const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, options)
            const ccResult = await ccResponse.json()
            formDataInfo.append('cc', ccResult)
        }
        if (document.getElementById("dce")) {
            if (document.getElementById("dce").value) {
                const dce = form.dce.files[0]
                let dceForm = new FormData()
                dceForm.append('dce', dce)
                options.body = dceForm
                const dceResponse = await fetch(`${process.env.REACT_APP_API_URL}/mdce`, options)
                const dceResult = await dceResponse.json()
                formDataInfo.append('dce', dceResult)
            }
        }

        if (document.getElementById("ch").value) {
            const ch = form.ch.files[0]
            let chForm = new FormData()
            chForm.append('ch', ch)
            options.body = chForm
            const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, options)
            const chResult = await chResponse.json()
            formDataInfo.append('ch', chResult)
        }
        if (document.getElementById("ibancomp").value) {
            const ibancomp = form.ibancomp.files[0]
            let ibancompForm = new FormData()
            ibancompForm.append('ibancomp', ibancomp)
            options.body = ibancompForm
            const ibancompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mibancomp`, options)
            const ibancompResult = await ibancompResponse.json()
            formDataInfo.append('ibancomp', ibancompResult)
        }
        if (document.getElementById("rv")) {
            if (document.getElementById("rv").value) {
                const rv = form.rv.files[0]
                let rvForm = new FormData()
                rvForm.append('rv', rv)
                options.body = rvForm
                const rvResponse = await fetch(`${process.env.REACT_APP_API_URL}/rv`, options)
                const rvResult = await rvResponse.json()
                formDataInfo.append('rv', rvResult)
            }
        }

        fetch(`${process.env.REACT_APP_API_URL}/formandos/${data._id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
                body: formDataToJson(formDataInfo)
            }).then((result) => {
                result.json().then((resp) => {
                    toastSuccess("Ficheiros submetidos com sucesso.")
                    setAlterar(false)
                })
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <Header sideMenu={"true"} />
            <AreaIndividual>
                <main>
                    <Link to={"/menucoordenador/" + params.id_candidatura + "/turmas/turma/" + params.id_curso} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <div className="container-all">
                        <div className="dadosCandidato">
                            <div>
                                <h1>Perfil</h1>
                            </div>
                            <div className="dado"><div>Nome Completo: </div> <div> {data.fullName}</div></div>
                            <div className="dado"><div>Género: </div> <div> {data.gender}</div></div>
                            <div className="dado"><div>Data de nascimento: </div> <div> {data.birthDate}</div></div>
                            <div className="dado"><div>Número de telefone: </div> <div>{data.cellNumber}</div></div>
                            <div className="dado"><div>E-mail:</div> <div> {data.email}</div></div>
                            <div className="dado"><div>Morada:</div> <div> {data.address}</div></div>
                            <div className="dado"><div>Localidade: </div> <div>{data.locality}</div></div>
                            <div className="dado"><div>Código Postal: </div> <div>{data.postalCode}</div></div>
                            <div className="dado"><div>Tipo Documento Identificação: </div> <div>{data.documentType}</div></div>
                            <div className="dado"><div>Nº Documento Identificação: </div> <div>{data.idNumber}</div></div>
                            <div className="dado"><div>Validade Documento Identificação: </div> <div>{data.idExpiration}</div></div>
                            <div className="dado"><div>Naturalidade: </div> <div>{data.parish}</div></div>
                            <div className="dado"><div>Nacionalidade: </div> <div>{data.nationality}</div></div>
                            <div className="dado"><div>NIF:</div> <div> {data.nif}</div></div>
                            <div className="dado"><div>NISS:</div> <div> {data.niss}</div></div>
                            <div className="dado"><div>Centro Qualifica:</div> <div> {data.CQEP}</div></div>
                            {data.CQEP === "Sim" ? <div className="dado"><div>Origem Centro Qualifica:</div> <div> {data.originCQEP}</div></div> : ""}
                            <div className="dado"><div>Grau Académico: </div> <div>{data.academicDegree}</div></div>
                            <div className="dado"><div>Situação Laboral:</div> <div> {data.employmentSituation}</div></div>
                            {data.employmentSituation.includes("Empregados por conta de outrem") ? <div className="dado"><div>Profissão/Função:</div> <div> {data.job}</div></div> : ""}
                            {data.employmentSituation.includes('Empregados por conta própria') ? <div className="dado"><div>Profissão/Função:</div> <div> {data.job}</div></div> : ""}
                            {data.employmentSituation.includes("Empregados por conta de outrem") ? <div className="dado"><div>NIF Entidade Empregadora:</div> <div> {data.nifEmployer}</div></div> : ""}
                            {data.employmentSituation.includes('Empregados por conta própria') ? <div className="dado"><div>NIF Entidade Empregadora:</div> <div> {data.nifEmployer}</div></div> : ""}
                            {data.employmentSituation === 'Desempregados à procura de novo emprego - DLD' ? <div className="dado"><div>Data de inicio da situação face ao desemprego:</div> <div> {data.employmentSituationDate}</div></div> : ""}
                            {data.employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ? <div className="dado"><div>Data de inicio da situação face ao desemprego:</div> <div> {data.employmentSituationDate}</div></div> : ""}
                            {data.employmentSituation === 'Desempregados à procura do 1º emprego' ? <div className="dado"><div>Data de inicio da situação face ao desemprego:</div> <div> {data.employmentSituationDate}</div></div> : ""}
                            {data.employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ? <div className="dado"><div>Data de inicio da situação face ao desemprego:</div> <div> {data.employmentSituationDate}</div></div> : ""}
                            {data.employmentSituation === 'Inativos - Outros' ? <div className="dado"><div>Data de inicio da situação face ao desemprego:</div> <div> {data.employmentSituationDate}</div></div> : ""}
                            {data.employmentSituation === 'Desempregados à procura de novo emprego - DLD' ? <div className="dado"><div>Beneficiário de Prestação Social:</div> <div> {data.beneficiarySS}</div></div> : ""}
                            {data.employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ? <div className="dado"><div>Beneficiário de Prestação Social:</div> <div> {data.beneficiarySS}</div></div> : ""}
                            {data.employmentSituation === 'Desempregados à procura do 1º emprego' ? <div className="dado"><div>Beneficiário de Prestação Social:</div> <div> {data.beneficiarySS}</div></div> : ""}
                            {data.employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ? <div className="dado"><div>Beneficiário de Prestação Social:</div> <div> {data.beneficiarySS}</div></div> : ""}
                            {data.employmentSituation === 'Inativos - Outros' ? <div className="dado"><div>Beneficiário de Prestação Social:</div> <div> {data.beneficiarySS}</div></div> : ""}
                            <div className="dado"><div>Estado de Inscrição:</div> <div>{data.valid}</div></div>

                            {alterar === false ? <>
                                <div className="text-center subtitle">Ficheiros de Identificação</div>
                                <div className="buttons">
                                    {data.cc_path ? <a href={data.cc_path} target="_blank" rel="noreferrer" download><button className="filed">Documento de Identificação </button></a> : <a href={data.cc_path} target="_blank" rel="noreferrer" download><button>Documento de Identificação </button></a>}
                                    {data.employmentSituation.includes("Empregados por conta de outrem") || data.employmentSituation.includes('Empregados por conta própria') ? <>{data.rv_path ? <a href={data.rv_path} target="_blank" rel="noreferrer" download><button className="filed"> Comprovativo de Situação Profissional</button></a> : <a href={data.rv_path} target="_blank" rel="noreferrer" download><button> Comprovativo de Situação Profissional</button></a>}</> : ""}
                                    {data.employmentSituation === "Desempregados à procura de novo emprego - DLD" || data.employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || data.employmentSituation === "Desempregados à procura do 1º emprego" ? <>{data.dce_path ? <a href={data.dce_path} target="_blank" rel="noreferrer" download><button className="filed"> DCE </button></a> : <a href={data.dce_path} target="_blank" rel="noreferrer" download><button> DCE </button></a>}</> : ""}
                                    {data.ch_path ? <a href={data.ch_path} target="_blank" rel="noreferrer" download><button className="filed"> Certificado de Habilitações</button></a> : <a href={data.ch_path} target="_blank" rel="noreferrer" download><button> Certificado de Habilitações</button></a>}
                                    {data.ibancomp_path ? <a href={data.ibancomp_path} target="_blank" rel="noreferrer" download><button className="filed"> IBAN </button></a> : <a href={data.ibancomp_path} target="_blank" rel="noreferrer" download><button> IBAN </button></a>}
                                </div>
                                <div >
                                    <button id="alterar-botao" onClick={() => setAlterar(true)}>
                                        Alterar Ficheiros de Identificação
                                    </button>
                                </div> </> : <div>
                                <form className="container" id="form" onSubmit={submeterFicheiros}>
                                    <div className="title">
                                        <h1>Insira os seguintes ficheiros</h1>
                                    </div>
                                    <div className="files">
                                        <div className="dado">
                                            <div className="dado">Documento de Identificação: </div>
                                            <div>
                                            <FileUploader
                                                inputClassName={"file"}
                                                inputType={"file"}
                                                inputId={"cc"}
                                                inputName={"cc"}
                                                inputAccept={".pdf"}
                                                allowedTypes={["application/pdf"]}
                                                />
                                            </div>
                                        </div>
                                        {data.employmentSituation.includes("Empregados por conta de outrem") || data.employmentSituation.includes('Empregados por conta própria') ?
                                            <>
                                                {data.rv_path ? <a href={data.rv_path} target="_blank" rel="noreferrer" download>
                                                    <button className="button-files"> Comprovativo de Situação Profissional</button>
                                                </a> :
                                                    <a className="tooltip" data-tip="Sem Documento">
                                                        <button className="button-files-empty"> Comprovativo de Situação Profissional</button>
                                                    </a>}
                                            </> :
                                            ""}
                                        {data.employmentSituation === "Desempregados à procura de novo emprego - DLD" || data.employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || data.employmentSituation === "Desempregados à procura do 1º emprego" || data.employmentSituation === "Inativos - A frequentarem ações de educação ou formação" || data.employmentSituation === "Inativos - Outros" ?
                                            <>
                                                {data.dce_path ?
                                                    <a href={data.dce_path} target="_blank" rel="noreferrer" download>
                                                        <button className="button-files"> DCE </button>
                                                    </a> :
                                                    <a className="tooltip" data-tip="Sem Documento">
                                                        <button className="button-files-empty"> DCE </button>
                                                    </a>}
                                            </> : ""}
                                        <div className="dado">
                                            <div htmlFor="ch">Certificado de Habilitações: </div>
                                            <div>
                                            <FileUploader
                                                inputClassName={"file"}
                                                inputType={"file"}
                                                inputId={"ch"}
                                                inputName={"ch"}
                                                inputAccept={".pdf"}
                                                allowedTypes={["application/pdf"]}
                                                />
                                            </div>
                                        </div>
                                        <div className="dado">
                                            <div htmlFor="ibancomp">Comprovativo de IBAN: </div>
                                            <div>
                                            <FileUploader
                                                inputClassName={"file"}
                                                inputType={"file"}
                                                inputId={"ibancomp"}
                                                inputName={"ibancomp"}
                                                inputAccept={".pdf"}
                                                allowedTypes={["application/pdf"]}
                                                />
                                            </div>
                                        </div>
                                        <p className="formato">*Formato suportado .pdf</p>
                                        <div className="botoes">
                                            <button id="alterar-botao" onClick={() => setAlterar(false)}>
                                                Cancelar
                                            </button>
                                            <input className="submeter" type="submit" value={"Submeter"} />
                                        </div>
                                    </div>
                                </form>
                            </div>}

                        </div>
                        <div className="dadosCandidato">
                            <div>
                                <h1>Integração com o Sistema</h1>
                            </div>
                            <div className="dado"><div>Total de Inscrições: </div> <div> {data2[0].formandos.length}</div></div>
                            <div className="dado"><div>Inscrições Aprovadas: </div> <div> {data2[0].contagemValidadas}</div></div>
                            <div className="dado"><div>Inscrições Não Aprovadas: </div> <div> {data2[0].formandos.length - data2[0].contagemValidadas}</div></div>
                            {data && data.length > 0 ? <>
                                <div className="flex justify-center items-center mb-1">
                                    <label className="text-text-main-color">Dados por página: </label>
                                    <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                        value={itemsPag}
                                        onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="table-containerSideMenu">
                                    <table className="my-table">

                                        <thead className="bg-main-color">
                                            <tr className="text-white h-14">
                                                <th className="p-2 min-w-[80px] border border-white">Curso</th>
                                                <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-text-main-color">
                                            {dataToDisplay.map((item, index) => (
                                                <tr key={index}>
                                                    {item.percurso ? <td className="border border-white">{item.percurso[0].name}</td> : <td />}
                                                    {item.valid === "Desistiu" ? <td>Desistiu</td> : <>{item.valid === "Validado" ? <td >Inscrito</td> : <>{item.valid === "Por Validar" ? <td>Candidato</td> : <td>Excluído</td>}</>}</>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex items-center justify-center mt-4 mb-8">
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                        <AiOutlineDoubleLeft />
                                    </button>
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                        <AiOutlineLeft />
                                    </button>
                                    <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                        <AiOutlineRight />
                                    </button>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                        <AiOutlineDoubleRight />
                                    </button>
                                </div></> : <p className="third-title">Não existem dados</p>}
                        </div>
                    </div>
                </main>
            </AreaIndividual >
            <Footer />
        </>
    );
}

