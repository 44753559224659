import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogBody,
} from "@material-tailwind/react";
import { BsFillPencilFill } from "react-icons/bs";
import toastSuccess from "../Toast/toastSuccess";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { IoMdRemoveCircle } from "react-icons/io";
import FileUploader from "../../utils/validationFileUploader";

export default function ModalDownloadFormandos({ id_curso, topicoTitulo, topicoAPI, icon, user, tooltip }) {
    const [open, setOpen] = useState(false);
    const [alterar, setAlterar] = useState(-1);
    const [cont, setCont] = useState(0);
    const [data, setData] = useState("");
    const [mapTopico, setMapTopico] = useState("");
    const [nome, setNome] = useState("");
    const [file, setFile] = useState("");

    useEffect(() => {
        getTopico()
    }, [cont, open])
    //Download ficheiro
    //Download do ficheiro
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank'); // Opens the link in a new tab/window
        link.setAttribute('download', ''); // Optional, sets the download attribute to trigger download in some browsers
        link.click();
    }

    function getTopico() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(user)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setMapTopico(result[topicoAPI])
            })
    }
    //Abre e fecha modal
    const handleOpen = () => {
        setOpen(!open);
        setAlterar(false)
    }
    //Formata data
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const hour = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year} - ${hour}:${min}`;
    };

    //1o Nome
    function firstName(item) {
        if (item) {
            const nameUser = item;
            const words = nameUser.split(' ');
            if (words.length > 0) {
                const firstWord = words[0];

                return firstWord;

            } else {
                return words;
            }
        }
    }

    //Posta ficheiro no cloudinary
    async function cloudinaryFicheiro(index) {
        const date = new Date();
        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }
        if (document.getElementById("ficheiro") && document.getElementById("ficheiro").value) {
            const ficheiro = document.getElementById("ficheiro").files[0]
            if ((ficheiro.name.includes(".xlsx") === true) || (ficheiro.name.includes(".csv") === true)) {
                let ficheiroForm = new FormData()
                ficheiroForm.append('excel', ficheiro)
                options.body = ficheiroForm
                const ficheiroResponse = await fetch(`${process.env.REACT_APP_API_URL}/cronograma/especifico`, options)
                const ficheiroResult = await ficheiroResponse.json()
                await fetch(`${process.env.REACT_APP_API_URL}/formandos/${id_curso}/DTP/editar`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(user)
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        topicoAPI: topicoAPI,
                        index: index,
                        nome: nome,
                        file: ficheiroResult,
                        date: date,
                        idUtilizador: localStorage.getItem("id")
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        toastSuccess("Ficheiro Uploaded com sucesso")
                        setOpen(!open)
                        setCont(cont + 1)
                        setAlterar(!alterar)
                    })
            }
            else {
                const ficheiro = document.getElementById("ficheiro").files[0]
                let ficheiroForm = new FormData()
                ficheiroForm.append('ficheiro', ficheiro)
                options.body = ficheiroForm
                const ficheiroResponse = await fetch(`${process.env.REACT_APP_API_URL}/ficheiro`, options)
                const ficheiroResult = await ficheiroResponse.json()
                await fetch(`${process.env.REACT_APP_API_URL}/formandos/${id_curso}/DTP/editar`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(user)
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        index: index,
                        topicoAPI: topicoAPI,
                        nome: nome,
                        file: ficheiroResult
                    })
                })
                    .then(res => res.json())
                    .then((result) => {

                        toastSuccess("Ficheiro Uploaded com sucesso")
                        setOpen(!open)
                        setCont(cont + 1)
                        setAlterar(!alterar)
                    })
            }

        }

    }
    //Elimina ficheiro
    async function eliminarFicheiro(index) {

        await fetch(`${process.env.REACT_APP_API_URL}/formandos/${id_curso}/DTP/remover`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(user)
            },
            method: 'PATCH',
            body: JSON.stringify({
                topicoAPI: topicoAPI,
                index: index
            })
        })
            .then(res => res.json())
            .then((result) => {
                toastSuccess("Ficheiro eliminado com sucesso")
                getTopico()
                setCont(cont + 1)
            })
    }

    return (
        <main>
            <button className={mapTopico &&mapTopico.length > 0? "text-xl tooltip text-green-600": "text-xl tooltip text-text-main-color"} onClick={handleOpen} variant="gradient" data-tip={tooltip}>{icon}</button>
            <Dialog className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato" open={open} handler={handleOpen}>
                <div className="flex items-center justify-center h-screen">
                    <div className="absolute w-full h-full" onClick={handleOpen}></div>
                    <div className="relative z-50 p-2 rounded-lg shadow-lg max-h-128 w-128 overflow-auto bg-secundary-color shadow-gray-600">
                        <div className="flex flex-col justify-center my-2">
                            <h4 className="mx-4 text-center small-title">{topicoTitulo}</h4>
                            <DialogBody>
                                {data && mapTopico && <>
                                    {mapTopico.map((item, index) => (
                                        <div key={index}>
                                            {alterar !== index ? <>
                                                <div className="flex justify-between w-full mt-6 text-left">
                                                    {item.file &&
                                                        <div>
                                                            <span className="mr-1 label-input">Documento:</span><a className="link" htmlFor={`fullName`} href={item.file} target="_blank">{item.name}</a><br />
                                                            <span className="mr-1 label-input ">Editado por: <span className="normal-case link tooltip" data-tip={item.nameUser}>{firstName(item.nameUser)}</span> -{formatDate(item.date)}</span><br />
                                                        </div>}
                                                    <div className="flex">
                                                        <button className="mr-2 button-geral" onClick={() => { setAlterar(index) }}><BsFillPencilFill /></button>
                                                        <button className="mr-2 button-geral" onClick={() => { downloadFile(item.file) }}><FiDownload /></button>
                                                        <button className="button-cancelar w-fit" onClick={() => { eliminarFicheiro(index) }}><IoMdRemoveCircle /></button>
                                                    </div>
                                                </div>
                                            </> : <>
                                                <div className="flex w-full text-left">
                                                    <label className="w-2/5 text-text-main-color" htmlFor={`fullName`}>Nome do documento:</label>
                                                    <input className="w-3/5 input-field" type="text" id={`fullName`} name={`fullName`} onChange={(e) => setNome(e.target.value)} />
                                                </div>
                                                <div className="flex w-full mt-2 mb-3 text-left">
                                                    <label className="w-2/5 text-text-main-color" htmlFor={`outro`}>Ficheiro:</label>
                                                    <FileUploader
                                                        inputClassName={"w-3/5 input-file"}
                                                        inputType={"file"}
                                                        inputId={"ficheiro"}
                                                        inputName={"ficheiro"}
                                                        inputAccept={".pdf, .csv, .xlsx"}
                                                        allowedTypes={["application/pdf", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                                        />
                                                </div>
                                                <div className="flex justify-center">
                                                    <button className="button-cancelar" onClick={() => setAlterar(-1)}>Cancelar</button>
                                                    <button className="mr-2 button-confirmar" onClick={() => cloudinaryFicheiro(index)}>Submeter</button>
                                                </div>
                                            </>}
                                        </div>
                                    ))}
                                </>}

                            </DialogBody>
                            <hr />
                        </div>
                        <button className="absolute top-1.5 right-1.5" onClick={handleOpen}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </div>
            </Dialog >
        </main>
    );
}