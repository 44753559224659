import React from "react";
import { Tooltip } from 'react-tooltip'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

export default function ConfirmToaster({ css, icon, title, message, data, confirm, id, tooltip, type }) {
    const [open, setOpen] = React.useState(false);
    const [sendMail, setSendMail] = React.useState("");
    const handleOpen = () => {
        setOpen(!open);
    }
    function confirmCondition() {
        if (title === "Anular") {
            if (!data) {
                return confirm(id, sendMail)
            }
            else {
                if (type === "aceitar") {
                    data.forEach(element => {
                        confirm(element._id, sendMail)
                    });
                }
            }
        }
        else {
            if (!data) {
                return confirm(id)
            }
            else {
                if (type === "aceitar") {
                    data.forEach(element => {
                        confirm(element._id)
                    });
                }
            }
        }
    }
    return (
        <>
            <button className={css} onClick={handleOpen} data-tooltip-id={"tooltipUnic" + id + type}
                // data-tooltip-content={tooltip}
                data-tooltip-place="top">
                {icon}
            </button>
            {tooltip &&
                <Tooltip id={"tooltipUnic" + id + type}>{tooltip}</Tooltip>
            }
            <Dialog className="fixed inset-0 w-screen h-screen m-0 bg-opacity-20 backdrop-blur-sm" open={open} handler={handleOpen}>
                {/* Overlay with backdrop blur */}
                <div className="fixed inset-0 h-full bg-opacity-5"></div>

                {/* Dialog content */}
                <div className="flex items-center justify-center h-screen">
                    <div className="z-50 p-4 rounded-lg shadow-lg max-h-128 w-128 bg-main-color brightness-150 shadow-gray-600 ">
                        <DialogHeader className="mb-0 text-white title" >{title}</DialogHeader>
                        <DialogBody className="text-white" divider>{message}
                            {title === "Anular" && <div className="mt-2" >
                                <h1 className="text-white">Deseja enviar email informativo?</h1>
                                <div className="flex items-center">
                                    <select onWheel={(e) => e.target.blur()} id="sendMail" className="input-field" defaultValue={sendMail ? sendMail : ""} onChange={(e) => setSendMail(e.target.value)}>
                                        <option value={""} disabled>Selecione uma opção</option>
                                        <option value={"false"}>Não</option>
                                        <option value={"true"}>Sim</option>
                                    </select>
                                </div>
                            </div>}
                        </DialogBody>
                        <DialogFooter className="flex justify-between">
                            <Button
                                variant="text"
                                color="red"
                                onClick={handleOpen}
                                className="w-32 p-2 text-sm text-white bg-text-error hover:brightness-75"
                            >
                                Cancelar
                            </Button>
                            {id ? (
                                <Button
                                    className={`w-32 p-2 text-sm ${sendMail || title !== "Anular"
                                        ? "text-white bg-text-correct hover:brightness-75"
                                        : "cursor-not-allowed bg-slate-600 text-logout-color"
                                        }`}
                                    variant="gradient"
                                    color="green"
                                    onClick={() => { if (sendMail || title !== "Anular") { confirmCondition(); handleOpen() } }}
                                >
                                    Confirmar
                                </Button>
                            ) : (
                                <Button
                                    className={`w-32 p-2 text-sm ${sendMail || title !== "Anular"
                                        ? "text-white bg-text-correct hover:brightness-75"
                                        : "cursor-not-allowed bg-slate-600 text-logout-color"
                                        }`}
                                    variant="gradient"
                                    color="green"
                                    onClick={() => { if (sendMail || title !== "Anular") { confirmCondition(); handleOpen() } }}
                                >
                                    Confirmar
                                </Button>
                            )}
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>
        </>
    );
}