export default function DetermineCourseStatus(dateBegin, dateEnd) {
    const now = new Date().toISOString().split("T")[0];

    if (dateBegin <= now && dateEnd >= now) {
      return "A decorrer";
    }
    if (dateEnd < now) {
      return "Terminado";
    }
    if (dateBegin > now) {
      return "Planeado";
    }
  };