const Nacoes = (value, type) => {
    const countries = [
        { number: 191, value: 'Portugal', label: 'Portuguesa' },
        { number: 1, value: 'Afeganistão', label: 'Afegã' },
        { number: 2, value: 'África do Sul', label: 'Sul-africana' },
        { number: 3, value: 'Albânia', label: 'Albanesa' },
        { number: 4, value: 'Alemanha', label: 'Alemã' },
        { number: 5, value: 'Andorra', label: 'Andorrana' },
        { number: 6, value: 'Angola', label: 'Angolana' },
        { number: 7, value: 'Anguila', label: 'Anguilana' },
        { number: 8, value: 'Antártida', label: 'Antártica' },
        { number: 9, value: 'Antígua e Barbuda', label: 'Antiguana' },
        { number: 10, value: 'Antilhas Holandesas', label: 'Neerlandesa' },
        { number: 11, value: 'Arábia Saudita', label: 'Saudita' },
        { number: 12, value: 'Argélia', label: 'Argelina' },
        { number: 13, value: 'Argentina', label: 'Argentina' },
        { number: 14, value: 'Armênia', label: 'Armênia' },
        { number: 15, value: 'Aruba', label: 'Arubana' },
        { number: 16, value: 'Austrália', label: 'Australiana' },
        { number: 17, value: 'Áustria', label: 'Austríaca' },
        { number: 18, value: 'Azerbaijão', label: 'Azeri' },
        { number: 19, value: 'Bahamas', label: 'Bahamiana' },
        { number: 20, value: 'Bahrein', label: 'Barenita' },
        { number: 21, value: 'Bangladesh', label: 'Bangladechiana' },
        { number: 22, value: 'Barbados', label: 'Barbadense' },
        { number: 23, value: 'Bielorrússia', label: 'Bielorrussa' },
        { number: 24, value: 'Bélgica', label: 'Belga' },
        { number: 25, value: 'Belize', label: 'Belizenha' },
        { number: 26, value: 'Benin', label: 'Beninense' },
        { number: 27, value: 'Bermudas', label: 'Bermudense' },
        { number: 28, value: 'Bolívia', label: 'Boliviana' },
        { number: 29, value: 'Bósnia e Herzegovina', label: 'Bósnia' },
        { number: 30, value: 'Botsuana', label: 'Botsuanesa' },
        { number: 31, value: 'Brasil', label: 'Brasileira' },
        { number: 32, value: 'Brunei', label: 'Bruneína' },
        { number: 33, value: 'Bulgária', label: 'Búlgara' },
        { number: 34, value: 'Burkina Faso', label: 'Burquinense' },
        { number: 35, value: 'Burundi', label: 'Burundiana' },
        { number: 36, value: 'Butão', label: 'Butanesa' },
        { number: 37, value: 'Cabo Verde', label: 'Cabo-verdiana' },
        { number: 38, value: 'Camarões', label: 'Camaronesa' },
        { number: 39, value: 'Camboja', label: 'Cambojana' },
        { number: 40, value: 'Canadá', label: 'Canadense' },
        { number: 41, value: 'Cazaquistão', label: 'Cazaque' },
        { number: 42, value: 'Chade', label: 'Chadiana' },
        { number: 43, value: 'Chile', label: 'Chilena' },
        { number: 44, value: 'China', label: 'Chinesa' },
        { number: 45, value: 'Chipre', label: 'Cipriota' },
        { number: 46, value: 'Singapura', label: 'Cingapuriana' },
        { number: 47, value: 'Colômbia', label: 'Colombiana' },
        { number: 48, value: 'Congo', label: 'Congolesa' },
        { number: 49, value: 'Coreia do Norte', label: 'Norte-coreana' },
        { number: 50, value: 'Coreia do Sul', label: 'Sul-coreana' },
        { number: 51, value: 'Costa do Marfim', label: 'Costamarfinense' },
        { number: 52, value: 'Costa Rica', label: 'Costarriquenha' },
        { number: 53, value: 'Croácia', label: 'Croata' },
        { number: 54, value: 'Cuba', label: 'Cubana' },
        { number: 55, value: 'Dinamarca', label: 'Dinamarquesa' },
        { number: 56, value: 'Djibuti', label: 'Djibutiana' },
        { number: 57, value: 'Dominica', label: 'Dominicana' },
        { number: 58, value: 'Egito', label: 'Egípcia' },
        { number: 59, value: 'El Salvador', label: 'Salvadorenha' },
        { number: 60, value: 'Emirados Árabes Unidos', label: 'Emirati' },
        { number: 61, value: 'Equador', label: 'Equatoriana' },
        { number: 62, value: 'Eritreia', label: 'Eritreia' },
        { number: 63, value: 'Eslováquia', label: 'Eslovaca' },
        { number: 64, value: 'Eslovênia', label: 'Eslovena' },
        { number: 65, value: 'Espanha', label: 'Espanhola' },
        { number: 66, value: 'Estados Unidos', label: 'Americana' },
        { number: 67, value: 'Estônia', label: 'Estónia' },
        { number: 68, value: 'Etiópia', label: 'Etíope' },
        { number: 69, value: 'Federação Russa', label: 'Russa' },
        { number: 70, value: "Fiji", label: "Fijiana" },
        { number: 71, value: "Filipinas", label: "Filipina" },
        { number: 72, value: "Finlândia", label: "Finlandesa" },
        { number: 73, value: "França", label: "Francesa" },
        { number: 74, value: "Gabão", label: "Gabonense" },
        { number: 75, value: "Gâmbia", label: "Gambiana" },
        { number: 76, value: "Gana", label: "Ghanaiana" },
        { number: 77, value: "Geórgia", label: "Georgiana" },
        { number: 78, value: "Gibraltar", label: "Gibraltina" },
        { number: 79, value: "Reino Unido", label: "Britânica" },
        { number: 80, value: "Granada", label: "Granadina" },
        { number: 81, value: "Grécia", label: "Grega" },
        { number: 82, value: "Gronelândia", label: "Gronelandesa" },
        { number: 83, value: "Guadalupe", label: "Guadalupense" },
        { number: 84, value: "Guam (Território dos Estados Unidos)", label: "Guamesa" },
        { number: 85, value: "Guatemala", label: "Guatemalteca" },
        { number: 86, value: "Guernsey", label: "Guernseyana" },
        { number: 87, value: "Guiana", label: "Guyanense" },
        { number: 88, value: "Guiana Francesa", label: "Francesa" },
        { number: 89, value: "Guiné", label: "Guineense" },
        { number: 90, value: "Guiné Equatorial", label: "Equatorial" },
        { number: 91, value: "Guiné-Bissau", label: "Guineense" },
        { number: 92, value: "Haiti", label: "Haitiana" },
        { number: 93, value: "Holanda", label: "Holandesa" },
        { number: 94, value: "Honduras", label: "Hondurenha" },
        { number: 95, value: "Hong Kong", label: "Hong Kongueza" },
        { number: 96, value: "Hungria", label: "Húngara" },
        { number: 97, value: "Iêmen", label: "Iemenita" },
        { number: 98, value: "Ilha Bouvet (Território da Noruega)", label: "Bouvetense" },
        { number: 99, value: "Ilha do Homem", label: "Manês" },
        { number: 100, value: "Ilha Natal", label: "Natalense" },
        { number: 101, value: "Ilha Pitcairn", label: "Pitcairnense" },
        { number: 102, value: "Ilha Reunião", label: "Reunionense" },
        { number: 103, value: "Ilhas Aland", label: "Alandense" },
        { number: 104, value: "Ilhas Cayman", label: "Caymanense" },
        { number: 105, value: "Ilhas Cocos", label: "Cocosense" },
        { number: 106, value: "Ilhas Comores", label: "Comorense" },
        { number: 107, value: "Ilhas Cook", label: "Cookiana" },
        { number: 108, value: "Ilhas Falkland (Malvinas)", label: "Falklandense" },
        { number: 109, value: "Ilhas Faroes", label: "Faroeense" },
        { number: 110, value: "Ilhas Geórgia do Sul e Sandwich do Sul", label: "Sul-Georgiana" },
        { number: 111, value: "Ilhas Heard e McDonald (Território da Austrália)", label: "Heardense" },
        { number: 112, value: "Ilhas Marianas do Norte", label: "Mariana do Norte" },
        { number: 113, value: "Ilhas Marshall", label: "Marshallense" },
        { number: 114, value: "Ilhas Menores dos Estados Unidos", label: "Ilhas Menores" },
        { number: 115, value: "Ilhas Norfolk", label: "Norfolquense" },
        { number: 116, value: "Ilhas Seychelles", label: "Seychellense" },
        { number: 117, value: "Ilhas Solomão", label: "Solomonense" },
        { number: 118, value: "Ilhas Svalbard e Jan Mayen", label: "Svalbardense" },
        { number: 119, value: "Ilhas Tokelau", label: "Tokelauano" },
        { number: 120, value: "Ilhas Turks e Caicos", label: "Turkcaico" },
        { number: 121, value: "Ilhas Virgens (Estados Unidos)", label: "Virgínia Americana" },
        { number: 122, value: "Ilhas Virgens (Inglaterra)", label: "Virgínia Britânica" },
        { number: 123, value: "Ilhas Wallis e Futuna", label: "Wallisense" },
        { number: 124, value: "Índia", label: "Indiana" },
        { number: 125, value: "Indonésia", label: "Indonésia" },
        { number: 126, value: "Irã", label: "Iraniana" },
        { number: 127, value: "Iraque", label: "Iraquiana" },
        { number: 128, value: "Irlanda", label: "Irlandesa" },
        { number: 129, value: "Islândia", label: "Islandesa" },
        { number: 130, value: "Israel", label: "Israelita" },
        { number: 131, value: "Itália", label: "Italiana" },
        { number: 132, value: "Jamaica", label: "Jamaicana" },
        { number: 133, value: "Japão", label: "Japonesa" },
        { number: 134, value: "Jersey", label: "Jerseyana" },
        { number: 135, value: "Jordânia", label: "Jordana" },
        { number: 136, value: "Quênia", label: "Queniana" },
        { number: 137, value: "Kiribati", label: "Kiribatiana" },
        { number: 138, value: "Kuwait", label: "Kuwaitiana" },
        { number: 139, value: "Quirguistão", label: "Quirguiz" },
        { number: 140, value: "Laos", label: "Laosiana" },
        { number: 141, value: "Letônia", label: "Letã" },
        { number: 142, value: "Lesoto", label: "Lesotiana" },
        { number: 143, value: "Líbano", label: "Libanesa" },
        { number: 144, value: "Libéria", label: "Liberiana" },
        { number: 145, value: "Líbia", label: "Líbia" },
        { number: 146, value: "Liechtenstein", label: "Liechtensteinense" },
        { number: 147, value: "Lituânia", label: "Lituana" },
        { number: 148, value: "Luxemburgo", label: "Luxemburguesa" },
        { number: 149, value: "Macau", label: "Macauense" },
        { number: 150, value: "Macedônia (República Yugoslava)", label: "Macedônia" },
        { number: 151, value: "Madagascar", label: "Madagascariana" },
        { number: 152, value: "Malásia", label: "Malásia" },
        { number: 153, value: "Malawi", label: "Malawiana" },
        { number: 154, value: "Maldivas", label: "Maldiviana" },
        { number: 155, value: "Mali", label: "Maliana" },
        { number: 156, value: "Malta", label: "Maltês" },
        { number: 157, value: "Marrocos", label: "Marroquina" },
        { number: 158, value: "Martinica", label: "Martinicana" },
        { number: 159, value: "Maurício", label: "Mauriciano" },
        { number: 160, value: "Mauritânia", label: "Mauritana" },
        { number: 161, value: "Mayotte", label: "Mayottiana" },
        { number: 162, value: "México", label: "Mexicana" },
        { number: 163, value: "Micronésia", label: "Micronésia" },
        { number: 164, value: "Moçambique", label: "Moçambicana" },
        { number: 165, value: "Moldova", label: "Moldava" },
        { number: 166, value: "Mônaco", label: "Monegasca" },
        { number: 167, value: "Mongólia", label: "Mongoliana" },
        { number: 168, value: "Montenegro", label: "Montenegrina" },
        { number: 169, value: "Montserrat", label: "Montserratense" },
        { number: 170, value: "Myanmar (Ex-Burma)", label: "Birmânia" },
        { number: 171, value: "Namíbia", label: "Namíbiana" },
        { number: 172, value: "Nauru", label: "Nauruana" },
        { number: 173, value: "Nepal", label: "Nepalesa" },
        { number: 174, value: "Nicarágua", label: "Nicaraguense" },
        { number: 175, value: "Níger", label: "Nigeriana" },
        { number: 176, value: "Nigéria", label: "Nigeriana" },
        { number: 177, value: "Niue", label: "Niueana" },
        { number: 178, value: "Noruega", label: "Norueguesa" },
        { number: 179, value: "Nova Caledônia", label: "Nova Caledônia" },
        { number: 180, value: "Nova Zelândia", label: "Neozelandesa" },
        { number: 181, value: "Omã", label: "Omanita" },
        { number: 182, value: "Palau", label: "Palauana" },
        { number: 183, value: "Panamá", label: "Panamenha" },
        { number: 184, value: "Papua-Nova Guiné", label: "Papuásia" },
        { number: 185, value: "Paquistão", label: "Paquistanesa" },
        { number: 186, value: "Paraguai", label: "Paraguaia" },
        { number: 187, value: "Peru", label: "Peruana" },
        { number: 188, value: "Polinésia Francesa", label: "Franco-polinésia" },
        { number: 189, value: "Polônia", label: "Polonesa" },
        { number: 190, value: "Porto Rico", label: "Portorriquenha" },
        { number: 192, value: "Qatar", label: "Qatariana" },
        { number: 193, value: "República Centro-Africana", label: "Centro-africana" },
        { number: 194, value: "República Democrática do Congo (ex-Zaire)", label: "Congolesa" },
        { number: 195, value: "República Dominicana", label: "Dominicana" },
        { number: 196, value: "República Tcheca", label: "Tcheca" },
        { number: 197, value: "Romênia", label: "Romena" },
        { number: 198, value: "Ruanda", label: "Rwanda" },
        { number: 199, value: "Saara Ocidental (Ex-Spanish Sahara)", label: "Sahariana" },
        { number: 200, value: "Saint Vincente e Granadinas", label: "Saint-vincentiana" },
        { number: 201, value: "Samoa Americana", label: "Samoana americana" },
        { number: 202, value: "Samoa Ocidental", label: "Samoana ocidental" },
        { number: 203, value: "San Marino", label: "Sanmarinense" },
        { number: 204, value: "Santa Helena", label: "Santa-helenense" },
        { number: 205, value: "Santa Lúcia", label: "Santa-luciana" },
        { number: 206, value: "São Bartolomeu", label: "São-bartolomeense" },
        { number: 207, value: "São Cristóvão e Névis", label: "São-cristovense" },
        { number: 208, value: "São Martim", label: "São-martinense" },
        { number: 209, value: "São Tomé e Príncipe", label: "São-tomense" },
        { number: 210, value: "Senegal", label: "Senegalesa" },
        { number: 211, value: "Serra Leoa", label: "Serra-leonesa" },
        { number: 212, value: "Sérvia", label: "Sérvia" },
        { number: 213, value: "Síria", label: "Síria" },
        { number: 214, value: "Somália", label: "Somali" },
        { number: 215, value: "Sri Lanka", label: "Sri-lanquês" },
        { number: 216, value: "St. Pierre and Miquelon", label: "Pietrense" },
        { number: 217, value: "Suazilândia", label: "Suazilandesa" },
        { number: 218, value: "Sudão", label: "Sudanesa" },
        { number: 219, value: "Suécia", label: "Sueca" },
        { number: 220, value: "Suíça", label: "Suíça" },
        { number: 221, value: "Suriname", label: "Surinamesa" },
        { number: 222, value: "Tadjiquistão", label: "Tajiquistão" },
        { number: 223, value: "Tailândia", label: "Tailandesa" },
        { number: 224, value: "Taiwan", label: "Taiwanesa" },
        { number: 225, value: "Tanzânia", label: "Tanzaniana" },
        { number: 226, value: "Território Britânico do Oceano Índico", label: "Britânico" },
        { number: 227, value: "Territórios do Sul da França", label: "Francesa do Sul" },
        { number: 228, value: "Territórios Palestinos Ocupados", label: "Palestina" },
        { number: 229, value: "Timor Leste (Ex-East Timor)", label: "Timorense" },
        { number: 230, value: "Togo", label: "Togolesa" },
        { number: 231, value: "Tonga", label: "Tongaiana" },
        { number: 232, value: "Trinidad and Tobago", label: "Trinidadiana" },
        { number: 233, value: "Tunísia", label: "Tunisiana" },
        { number: 234, value: "Turcomenistão", label: "Turcomana" },
        { number: 235, value: "Turquia", label: "Turca" },
        { number: 236, value: "Tuvalu", label: "Tuvalua" },
        { number: 237, value: "Ucrânia", label: "Ucraniana" },
        { number: 238, value: "Uganda", label: "Ugandense" },
        { number: 239, value: "Uruguai", label: "Uruguaia" },
        { number: 240, value: "Uzbequistão", label: "Uzbeque" },
        { number: 241, value: "Vanuatu", label: "Vanuatuana" },
        { number: 242, value: "Vaticano", label: "Vaticana" },
        { number: 243, value: "Venezuela", label: "Venezuelana" },
        { number: 244, value: "Vietnã", label: "Vietnamita" },
        { number: 245, value: "Zâmbia", label: "Zambiana" },
        { number: 246, value: "Zimbábue", label: "Zimbabuana" }
    ];


    if (type === "true") {
        const targetCountry = countries.find(country => country.label === value);
        const defaultLabel = targetCountry ? `${targetCountry.number} - ${targetCountry.label} ` : null;
        return defaultLabel;
    }
    else {
        const targetCountry = countries.find(country => value.includes(country.value));
        const defaultLabel = targetCountry ? targetCountry.label : null;

        return defaultLabel;
    }

};

export default Nacoes;